export const DEFAULT_CONFIG = {
  components: [
    {
      type: 'preAmount',
      key: 'preAmount'
    },
    {
      label: 'Amount',
      placeholder: 'Amount',
      type: 'amount',
      key: 'amount',
      min: 1,
      max: 100,
      validate: {
        required: true,
        errorMessage: 'validation.deposit.payment.requiredAmount'
      }
    },
    {
      type: 'button',
      label: 'Deposit',
      key: 'submit'
    }
  ]
};

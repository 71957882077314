export const getFeatureToggle = (setting) => {
  return {
    notification: {
      enabled: !!setting?.enable_notification
    },
    transaction: {
      sportsbook: {
        enabled: !!setting?.show_sportbook_transaction
      }
    },
    deposit: {
      promotion: {
        enabled: !!setting?.payments?.deposit_with_promotion
      }
    },
    withdraw: {
      email: {
        required:
          !!setting?.players?.enable_player_email_verification &&
          !!setting?.players?.require_email_verification_options?.withdraw
      },
      kyc: {
        required:
          !!setting?.players?.player_kyc && !!setting?.players?.require_kyc_options?.withdraw
      }
    },
    signup: {
      otp: {
        enabled: !!setting?.players?.require_otp
      },
      captcha: {
        enabled: !!setting?.players?.require_player_signup_captcha
      }
    },
    login: {
      otp: {
        enabled: !!setting?.players?.require_signin_otp
      },
      phoneNumber: {
        enabled: !!setting?.players?.enable_login_by_phone_number
      },
      email: {
        enabled: !!setting?.players?.enable_login_by_email
      },
      captcha: {
        enabled: !!setting?.players?.require_player_signin_captcha
      }
    },
    kyc: {
      enabled: !!setting?.players?.player_kyc,
      options: setting?.players?.require_kyc_options || {}
    },
    vip: {
      enabled: !!setting?.players?.player_vip
    },
    game: {
      recent: {
        enabled: !!setting?.players?.enable_player_recent_game
      },
      favorite: {
        enabled: !!setting?.players?.enable_player_favorite_game
      }
    },
    bonus: {
      recommend: {
        enabled: !!setting?.players?.enable_bonus_recommendation
      }
    },
    email: {
      verification: {
        enabled: !!setting?.players?.enable_player_email_verification,
        options: setting?.players?.require_email_verification_options || {}
      }
    },
    broadcasting: {
      enabled: !!setting?.enable_broadcasting,
      deposit_approved: {
        enabled: !!setting?.enable_broadcast_deposit_approved
      },
      deposit_failed: {
        enabled: !!setting?.enable_broadcast_deposit_failed
      }
    },
    cashback: {
      enabled: !!setting?.players?.enable_player_vip_progress
    },
    restrictCountry: {
      enabled: !!setting?.country_restricted
    }
  };
};

import Status from '@/@crema/core/Status';
import { WITHDRAW_STATUS } from '@/shared/constants/AppEnums';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const StatusWithdraw = ({ status, statusName }) => {
  const { formatMessage } = useIntl();
  const mapColorAndName = (code) => {
    switch (code) {
      case WITHDRAW_STATUS.Declined:
        return ['error', formatMessage({ id: 'transaction.withdraw.canceled' })];
      case WITHDRAW_STATUS.PaymentProcessing:
        return ['warning', formatMessage({ id: 'transaction.status.processing' })];
      case WITHDRAW_STATUS.Pending:
        return ['pending', formatMessage({ id: 'transaction.status.pending' })];
      case WITHDRAW_STATUS.Paid:
        return ['success', formatMessage({ id: 'transaction.status.approved' })];
      default:
        return [];
    }
  };
  const [variant, name = statusName] = mapColorAndName(status);
  return (
    <Status variant={variant} type="round">
      {name}
    </Status>
  );
};

StatusWithdraw.propTypes = {
  status: PropTypes.number.isRequired,
  statusName: PropTypes.string.isRequired
};

export default StatusWithdraw;

import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

const getProfile = () => {
  return API_SERVICE.get(getApiEndpoint('profile'));
};

const getBanks = (data) => {
  return API_SERVICE.get(getApiEndpoint('banks'), { params: data });
};

const updateProfile = (data) => {
  return API_SERVICE.put(getApiEndpoint('profile'), data);
};

// get document type for create kyc
const getSignUpDocuments = () => {
  return API_SERVICE.get(getApiEndpoint('kyc-requests/document-types'));
};

// get kyc requested of current player
const getKycDocument = () => {
  return API_SERVICE.get(getApiEndpoint('kyc-requests/verification'));
};

// will create a kyc request
const submitKycRequest = (data) => {
  return API_SERVICE.post(getApiEndpoint('kyc-requests'), data);
};

const resubmitKycRequest = (data) => {
  return API_SERVICE.post(getApiEndpoint(`kyc-requests/${data.id}/resubmit`), data);
};

const getCountry = () => {
  return API_SERVICE.get(`/system/api/v1/countries`);
};

const getStates = (countryId) => {
  return API_SERVICE.get(`/system/api/v1/states/${countryId}`);
};

const getCities = (stateId) => {
  return API_SERVICE.get(`/system/api/v1/cities/${stateId}`);
};

export {
  getProfile,
  updateProfile,
  getBanks,
  getSignUpDocuments,
  getKycDocument,
  submitKycRequest,
  resubmitKycRequest,
  getCountry,
  getStates,
  getCities
};

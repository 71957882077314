import { makeStyles } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  round: {
    borderRadius: theme.shape.borderRadius
  }
}));

export default function Skeleton({ variant, width, height, ...nest }) {
  const classes = useStyles();
  const className = variant === 'rect' ? classes.round : '';
  return (
    <MuiSkeleton
      animation="wave"
      variant={variant}
      width={width}
      height={height}
      className={className}
      {...nest}
    />
  );
}

Skeleton.propTypes = {
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Skeleton.defaultProps = {};

import { Icon, IconButton } from '@/@crema';
import AmountCurrencyCode from '@/@crema/core/Amount/AmountCurrencyCode';
import NotAvailable from '@/@crema/core/DataTable/NotAvailable';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { getCMSName } from '@/@crema/utility/utils';
import {
  onCancelTransaction,
  onClearSelectedTransaction,
  onGetTransactionList,
  onResetTransactionList,
  onSetSelectedTransaction
} from '@/redux/actions';
import { DEPOSIT_STATUS, WITHDRAW_BY, WITHDRAW_STATUS } from '@/shared/constants/AppEnums';
// import ICON_FONTS from '@/shared/constants/IconFonts';
import { Box } from '@material-ui/core';
import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonCancel from '../components/ButtonCancel';
import CancelButtonWithConfirm from '../components/ButtonCancelWithConfirm';
import InstructionDetail from '../components/InstructionDetail';
import StatusDeposit from '../components/Status/StatusDeposit';
import StatusWithdraw from '../components/Status/StatusWithdraw';
import renderBetSelection from './renderBetSelection';
import renderBetType from './renderBetType';
import renderDateTime from './renderDateTime';
import renderDepositBy from './renderDepositBy';
import renderStatusSportsbook from './renderStatusSportsbook';
import renderTextCopy from './renderTextCopy';

export const getValueByPathAndSetDefaultValue = (
  data,
  pathName,
  pathNameDefaultValue = '',
  defaultValue = ''
) => {
  const defaultData = get(data, pathNameDefaultValue, defaultValue);
  return get(data, pathName, defaultData);
};

const getPath =
  (k, dv = null) =>
  // eslint-disable-next-line react/display-name
  (value, item) => {
    const path = get(item, k, dv);
    if (path) {
      return get(item, k, dv);
    }
    return <NotAvailable />;
  };

const renderAmount =
  (k, showPrefix = false) =>
  // eslint-disable-next-line react/display-name
  (value, item) => {
    const code =
      item?.payment_option?.cms_property?.code ||
      item?.payment_option?.currency_code ||
      item?.currency_code;
    return <AmountCurrencyCode value={item?.[k]} showPrefix={showPrefix} currencyCode={code} />;
  };

const renderCMSName = (k) => (value, item) => {
  const name = getCMSName(item?.[k]);
  return name;
};

// eslint-disable-next-line react/display-name
const renderStatus = (C) => (value, item) => {
  return <C status={item.status} statusName={item.status_name} />;
};

const renderButtonActionDeposit = (value, item, expanded, toggleExpanded, onActionClick) => {
  const handleCancelDeposit = () => {
    onActionClick(item);
  };

  if (item?.status !== DEPOSIT_STATUS.Pending) return null;

  return <ButtonCancel onClick={handleCancelDeposit} />;
  // return <ButtonMoreInfo expanded={expanded} onClick={toggleExpanded} />;
};

const renderButtonActionWithdraw = (value, item, _expanded, _toggleExpanded, onActionClick) => {
  const handleCancelWithdraw = () => {
    onActionClick(item);
  };

  if (item?.status !== WITHDRAW_STATUS.Pending) return null;

  return <ButtonCancel onClick={handleCancelWithdraw} />;
};

const renderInstructionDetail = (value, item) => {
  if (item?.status !== DEPOSIT_STATUS.Pending) {
    return null;
  }

  return item?.instruction_message ? <InstructionDetail html={item.instruction_message} /> : null;
};

const renderButtonCancelDeposit = (value, item) => {
  if (item?.status !== DEPOSIT_STATUS.Pending) {
    return null;
  }
  return <CancelButtonWithConfirm transactionType={TRANSACTION.depositHistory} />;
};

const renderButtonCancelWithdraw = (value, item) => {
  if (item?.status !== WITHDRAW_STATUS.Pending) return null;
  return <CancelButtonWithConfirm transactionType={TRANSACTION.withdrawalHistory} />;
};

const renderButtonCancelWithdrawDrawer = (value, item) => {
  if (item?.status !== WITHDRAW_STATUS.Pending) return null;
  return (
    <Box display="flex">
      <CancelButtonWithConfirm transactionType={TRANSACTION.withdrawalHistory} />
    </Box>
  );
};

const renderWithdrawBy = (value, item) => {
  if (item?.withdraw_by !== WITHDRAW_BY.CRYPTO) {
    return (
      item?.player_bank?.bank_info?.cms_property?.group?.name ?? item?.player_bank?.bank_info?.name
    );
  }
  return item?.withdraw_by_name;
};

// eslint-disable-next-line react/display-name
const renderBetAmount = (k) => (value, item) => {
  if (item?.is_free_bet) return <IntlMessages id="game.freeBet" />;

  return <AmountCurrencyCode value={item[k]} currencyCode={item?.base_currency} />;
};

// eslint-disable-next-line react/display-name
const renderPayout = (k) => (value, item) => {
  if (item?.is_free_bet) return <IntlMessages id="game.freeBet" />;

  return <AmountCurrencyCode value={item[k]} currencyCode={item?.base_currency} />;
};

const renderBtnExpandRow = (value, item, expanded, toggleExpanded, onActionClick) => {
  const handleClick = (event) => {
    event.stopPropagation();
    toggleExpanded();
  };

  return (
    <IconButton size="small" onClick={handleClick}>
      <Icon name={expanded ? 'keyboard-arrow-up' : 'keyboard-arrow-down'} />
    </IconButton>
  );
};

export const TRANSACTION = {
  depositHistory: 'depositHistory',
  withdrawalHistory: 'withdrawalHistory',
  gameHistory: 'gameHistory',
  slotHistory: 'slotHistory',
  liveCasinoHistory: 'liveCasinoHistory',
  sportHistory: 'sportHistory'
};

export const TRANSACTION_TYPES = [
  {
    type: TRANSACTION.depositHistory,
    label: 'transaction.deposit',
    href: '/account/transactions/deposit'
  },
  {
    type: TRANSACTION.withdrawalHistory,
    label: 'transaction.withdrawal',
    href: '/account/transactions/withdrawal'
  },
  {
    type: TRANSACTION.gameHistory,
    label: 'transaction.game',
    href: '/account/transactions/game'
  },
  {
    type: TRANSACTION.sportHistory,
    label: 'transaction.sport',
    href: '/account/transactions/sportsbook'
  }
];

export const configure = {
  fields: {
    expandRow: {
      label: '',
      renderContent: renderBtnExpandRow
    },
    betSelection: {
      label: 'Bet Selection',
      renderContent: renderBetSelection
    },
    betType: {
      label: 'Bet Type',
      renderContent: renderBetType
    },
    statusSportsbook: {
      id: 'statusSportsbook',
      label: 'Status',
      renderContent: renderStatusSportsbook
    },
    amount: {
      id: 'amount',
      label: 'common.amount',
      name: 'amount',
      renderContent: renderAmount('amount')
    },
    amountWithPrefix: {
      id: 'amountWithPrefix',
      name: 'amount',
      renderContent: renderAmount('amount', true)
    },
    paymentName: {
      id: 'paymentName',
      label: 'transaction.deposit.paymentOptionName',
      renderContent: renderCMSName('payment_option')
    },
    depositBy: {
      id: 'depositBy',
      label: 'transaction.deposit.depositBy',
      name: 'payment_option',
      width: 130,
      renderContent: renderDepositBy
    },
    statusDeposit: {
      id: 'statusDeposit',
      label: 'common.status',
      renderContent: renderStatus(StatusDeposit)
    },
    createdAt: {
      id: 'createdAt',
      label: 'common.date',
      name: 'created_at',
      renderContent: renderDateTime
    },
    updatedAt: {
      id: 'updatedAt',
      label: 'common.processedTime',
      name: 'updated_at',
      renderContent: renderDateTime
    },
    action: {
      id: 'action',
      label: 'account.transaction.instruction.moreInfoBtn',
      renderContent: renderButtonActionDeposit
    },
    actionWithdraw: {
      id: 'actionWithdraw',
      label: 'account.transaction.withdraw.Action',
      renderContent: renderButtonActionWithdraw
    },
    instructionDetail: {
      id: 'instructionDetail',
      renderContent: renderInstructionDetail
    },
    cancelDeposit: {
      id: 'cancelDeposit',
      renderContent: renderButtonCancelDeposit
    },
    crypto: {
      id: 'crypto',
      label: 'account.wallet.title',
      renderContent: getPath('currency_code')
    },
    address: {
      id: 'address',
      label: 'account.withdraw.address',
      renderContent: getPath('address')
    },
    blockchainNetwork: {
      id: 'blockchainNetwork',
      label: 'account.withdraw.network',
      renderContent: getPath('blockchain_network.network_code', null)
    },
    withdrawBy: {
      id: 'withdrawBy',
      label: 'transaction.withdrawBy',
      renderContent: renderWithdrawBy
    },
    statusWithdraw: {
      id: 'statusWithdraw',
      label: 'common.status',
      renderContent: renderStatus(StatusWithdraw)
    },
    bankName: {
      id: 'bankName',
      label: 'transaction.bankName',
      name: 'player_bank.bank_name'
    },
    bankAccountName: {
      id: 'bankAccountName',
      label: 'transaction.bankAccountName',
      name: 'player_bank.account_name'
    },
    bankAccountNumber: {
      id: 'bankAccountNumber',
      label: 'transaction.bankAccountNumber',
      name: 'player_bank.account_number'
    },
    cancelWithdraw: {
      id: 'cancelWithdraw',
      renderContent: renderButtonCancelWithdraw
    },
    cancelWithdrawDrawer: {
      id: 'cancelWithdrawDrawer',
      renderContent: renderButtonCancelWithdrawDrawer
    },
    providerName: {
      id: 'providerName',
      label: 'transaction.providerName',
      renderContent: renderCMSName('game_provider')
    },
    gameType: {
      id: 'gameType',
      label: 'transaction.gameTypeName',
      renderContent: getPath('game.game_type.name')
    },
    gameName: {
      id: 'gameName',
      label: 'transaction.gameName',
      renderContent: renderCMSName('game')
    },
    settleTime: {
      id: 'settleTime',
      label: 'transaction.settleTime',
      name: 'settle_time',
      renderContent: renderDateTime
    },
    betTime: {
      id: 'betTime',
      label: 'transaction.betTime',
      name: 'bet_time',
      renderContent: renderDateTime
    },
    betAmount: {
      id: 'betAmount',
      label: 'transaction.betAmount',
      renderContent: renderBetAmount('bet_amount')
    },
    payout: {
      id: 'payout',
      label: 'transaction.payout',
      renderContent: renderPayout('payout')
    },
    baseCurrency: {
      id: 'baseCurrency',
      label: 'common.currency',
      renderContent: getPath('base_currency')
    },
    tag: {
      id: 'tag',
      label: 'account.withdraw.tag',
      renderContent: getPath('tag')
    },
    memo: {
      id: 'memo',
      label: 'account.withdraw.memo',
      renderContent: getPath('memo')
    },
    txID: {
      id: 'txID',
      label: 'account.withdraw.txId',
      renderContent: getPath('txid')
    },
    uuid: {
      id: 'uuid',
      name: 'uuid',
      label: 'transaction.id',
      renderContent: renderTextCopy
    },
    roundId: {
      id: 'roundId',
      name: 'round_id',
      label: 'transaction.id',
      renderContent: renderTextCopy
    }
  }
};

export const useSelectedItem = () => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);

  const onSelectItem = useCallback(
    (item) => {
      dispatch(onSetSelectedTransaction(item));
      setIsSelected(true);
    },
    [dispatch]
  );

  const onUnselectItem = useCallback(() => {
    dispatch(onClearSelectedTransaction());
    setIsSelected(false);
  }, [dispatch]);

  return {
    isSelected,
    onSelectItem,
    onUnselectItem
  };
};

export const useCancelSelectedItemDialog = (transactionType) => {
  const dispatch = useDispatch();
  const [openDialogConfirmCancel, setOpenDialogConfirmCancel] = useState(false);

  const onOpenDialogConfirmCancel = useCallback(
    (item, callback) => {
      setOpenDialogConfirmCancel(true);
      dispatch(onSetSelectedTransaction(item));
      callback?.();
    },
    [dispatch]
  );

  const onCloseDialogConfirmCancel = useCallback(() => {
    setOpenDialogConfirmCancel(false);
  }, []);

  const onCancel = useCallback(async () => {
    setOpenDialogConfirmCancel(false);
    dispatch(onCancelTransaction(transactionType));
  }, [dispatch, transactionType]);

  return {
    openDialogConfirmCancel,
    onOpenDialogConfirmCancel,
    onCloseDialogConfirmCancel,
    onCancel
  };
};

export const useGetData = (transactionType) => {
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    await dispatch(onGetTransactionList(transactionType));
  }, [dispatch, transactionType]);

  const onLoadMore = useCallback(async () => {
    await dispatch(onGetTransactionList(transactionType));
  }, [dispatch, transactionType]);

  const resetData = useCallback(() => {
    dispatch(onResetTransactionList(transactionType));
  }, [dispatch, transactionType]);

  useEffect(() => {
    getData();
    return resetData;
  }, [getData, resetData]);

  return { onLoadMore };
};

import { withStyles } from '@material-ui/core';

const borderRadiusMediumClasses = ['.MuiButton-root'].join(',');

const GlobalBorderRadius = withStyles(
  (theme) => ({
    '@global': {
      //   [borderRadiusMediumClasses]: {
      //     borderRadius: theme.shape.borderRadiusMedium
      //   }
    }
  }),
  { name: 'GlobalBorderRadius' }
)(() => null);

export default GlobalBorderRadius;

import {
  WITHDRAWAL_BANKS_GET_ERROR,
  WITHDRAWAL_BANKS_GET_START,
  WITHDRAWAL_BANKS_GET_SUCCESS,
  WITHDRAWAL_RESET,
  WITHDRAWAL_RESET_BANK_TRANSFER,
  WITHDRAWAL_SET_BANKS,
  WITHDRAWAL_SET_FORM_STATUS,
  WITHDRAWAL_SET_PLAYER_BANKS_ERROR,
  WITHDRAWAL_SET_PLAYER_BANKS_START,
  WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS,
  WITHDRAWAL_SET_PREVENTED,
  WITHDRAWAL_SET_SELECTED,
  WITHDRAWAL_SET_SELECTED_PLAYER_BANK,
  WITHDRAWAL_SET_SUBMIT_DATA
} from '@/shared/constants/ActionTypes';
import { FORM_STATUS, KYC_STATUS } from '@/shared/constants/AppEnums';
import { useSelector } from 'react-redux';
import { useFeatureToggle } from './App';
import { useAuthUser } from './Auth';

const INIT_STATE = {
  formStatus: FORM_STATUS.NEW,
  prevented: true,
  banks: [],
  selected: null,
  selectedPlayerBank: null,
  submitData: null,
  playerBanks: [],
  loading: false,
  list: [],
  group: []
};

export const useWithdrawalBanks = () => {
  return useSelector(({ withdrawal }) => withdrawal.banks);
};

export const useWithdrawalPrevented = () => {
  return useSelector(({ withdrawal }) => withdrawal.prevented);
};

export const useWithdrawalSelected = () => {
  return useSelector(({ withdrawal }) => withdrawal.selected);
};

export const useWithdrawalFormStatus = () => {
  return useSelector(({ withdrawal }) => withdrawal.formStatus);
};

export const useWithdrawalSubmitData = () => {
  return useSelector(({ withdrawal }) => withdrawal.submitData);
};

export const useWithdrawVerification = () => {
  const featureToggle = useFeatureToggle();
  const { email_verified_at, kyc_status } = useAuthUser() || {};
  const prevented = useWithdrawalPrevented();
  const needToVerifyEmail = featureToggle.withdraw.email.required && !email_verified_at;
  const needToVerifyKyc =
    featureToggle.withdraw.kyc.required && !(kyc_status === KYC_STATUS.APPROVED);

  return {
    needToVerifyEmail,
    needToVerifyKyc,
    disabled: prevented || needToVerifyKyc || needToVerifyEmail,
    prevented
  };
};

export const useWithdraw = () => {
  return useSelector(({ withdrawal }) => withdrawal);
};

export const usePlayerBanks = () => {
  return useSelector(({ withdrawal }) => withdrawal.playerBanks);
};

export const WithdrawalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WITHDRAWAL_SET_BANKS:
      return {
        ...state,
        banks: action.payload
      };
    case WITHDRAWAL_SET_PREVENTED:
      return {
        ...state,
        prevented: action.payload
      };
    case WITHDRAWAL_SET_SELECTED:
      return {
        ...state,
        selected: action.payload
      };
    case WITHDRAWAL_SET_FORM_STATUS:
      return {
        ...state,
        formStatus: action.payload
      };
    case WITHDRAWAL_SET_SUBMIT_DATA:
      return {
        ...state,
        submitData: action.payload
      };
    case WITHDRAWAL_SET_PLAYER_BANKS_START:
      return {
        ...state,
        loading: true
      };
    case WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS:
      return {
        ...state,
        playerBanks: action.payload,
        loading: false
      };
    case WITHDRAWAL_SET_PLAYER_BANKS_ERROR:
      return {
        ...state,
        loading: false
      };
    case WITHDRAWAL_BANKS_GET_START:
      return {
        ...state,
        loading: true
      };
    case WITHDRAWAL_BANKS_GET_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        group: action.payload.group,
        loading: false
      };
    case WITHDRAWAL_BANKS_GET_ERROR:
      return {
        ...state,
        loading: false
      };
    case WITHDRAWAL_RESET:
      return INIT_STATE;
    case WITHDRAWAL_RESET_BANK_TRANSFER:
      return {
        ...state,
        playerBanks: [],
        selectedPlayerBank: null
      };
    case WITHDRAWAL_SET_SELECTED_PLAYER_BANK:
      return {
        ...state,
        selectedPlayerBank: action.payload
      };
    default:
      return state;
  }
};

const { makeStyles, fade, darken } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  transactionItemGame: {
    display: 'grid',
    gridTemplateAreas: `"side head" "side body"`,
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(4),
    rowGap: theme.spacing(3),
    width: '100%',
    padding: `${theme.spacing(2)}px 0`
  },
  side: {
    gridArea: 'side'
  },
  head: {
    gridArea: 'head',
    '& div': {
      lineHeight: '1'
    }
  },
  body: {
    gridArea: 'body',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.1',
    '& div div:first-child': {
      marginBottom: theme.spacing(1)
    }
  },
  image: {
    width: 48
  },
  descItem1: {
    flexShrink: 1,
    flexGrow: 2,
    marginRight: theme.spacing(2),
    lineHeight: 1.66
  },
  descItem2: {
    flexShrink: 0,
    flexGrow: 1
  },
  descItem2Inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  textDesc: {
    color: fade(theme.palette.text.primary, 0.5)
  },
  oneLine: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
}));

export default useStyles;

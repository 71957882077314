import { Icon } from '@/@crema';
import { Drawer, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import TransactionInformationDrawer from './TransactionInformationDrawer';
import useStyles from './index.style';

function DrawerDetail({ open, data, onClose, title, fields }) {
  const classes = useStyles();

  return (
    <Drawer
      id="drawer-game-detail"
      anchor="right"
      open={open}
      onClose={onClose}
      ModalProps={{ keepMounted: true }}
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawerPaper
      }}>
      <div className={classes.header}>
        <Typography variant="h4" className="bold" id="transaction-dialog-title">
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={onClose} disableRipple className={classes.btnClose}>
          <Icon name="close" />
        </IconButton>
      </div>
      <div className="pt-6">
        <TransactionInformationDrawer data={data} fields={fields} />
      </div>
    </Drawer>
  );
}

DrawerDetail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

DrawerDetail.defaultProps = {
  open: false,
  onClose: () => {}
};

export default DrawerDetail;

import { fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  instruction: {
    backgroundColor: fade(theme.palette.primary.main, 0.15),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3)
  }
}));

export default useStyles;

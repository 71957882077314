import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import asyncComponent from '@/@crema/utility/asyncComponent';
import Affiliate from '@/modules/Affiliate';
import { useMemo } from 'react';

const AppHeader = asyncComponent(() => import('../AppHeader'));
const AppFooter = asyncComponent(() => import('../AppFooter'));
const Main = asyncComponent(() => import('../Main'));
const Popup = asyncComponent(() => import('@/modules/Popup'), false);
const InfoView = asyncComponent(() => import('../InfoView'), false);
const TopProgressBar = asyncComponent(() => import('../TopProgressBar'), false);
const DrawerLeft = asyncComponent(() => import('../DrawerLeft'), false);

const MainLayout = ({ children }) => {
  const isSM = useBreakPointDown('sm');

  const ComponentMemo = useMemo(() => {
    if (!isSM) return null;
    const AppBottomNavigation = asyncComponent(() => import('../AppBottomNavigation'));
    const GameDetailBottomSheet = asyncComponent(() => import('../GameDetailBottomSheet'));

    return (
      <>
        <GameDetailBottomSheet />
        <AppBottomNavigation />
      </>
    );
  }, [isSM]);

  return (
    <>
      <TopProgressBar />
      <AppHeader />
      {/* <PlayerAnimation /> */}
      <Main>{children}</Main>
      <DrawerLeft />
      <AppFooter />
      {/* based popup */}
      <Popup />
      {/* toast */}
      <InfoView />
      <Affiliate />
      {ComponentMemo}
    </>
  );
};

export default MainLayout;

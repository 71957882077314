import { isServer } from '@/shared/constants/AppConst';
import { POPUP_NO_AUTH } from '@/shared/constants/PopupConst';
import Router from 'next/router';
import { logd } from './Logging';

const POPUP_PARAMS = [
  'overlay',
  'token',
  'email',
  'kioskCode',
  'payment',
  'result',
  'transactionId',
  'promotionId',
  'requestid',
  'responseid',
  'status'
];

export const updateQueryPopup = (query = {}) => {
  logd('updateQueryPopup.query', query);
  let url = window.location.href.replace('/signup', '');
  logd('updateQueryPopup.url', url);

  if (query.overlay === POPUP_NO_AUTH.SIGNUP) {
    url =
      Router.locale === Router.defaultLocale
        ? `${window.location.origin}/signup/`
        : `${window.location.origin}/${Router.locale}/signup/`;
  } else {
    Object.keys(query).forEach((key) => {
      url = updateQueryStringParam(url, key, query[key]);
    });
  }

  logd('updateQueryPopup.url', url);
  window.history.replaceState({ ...window.history.state, old: window.location.href }, '', url);
};

export const closeAllPopup = () => {
  let url = window.location.href;
  if (url.includes('/signup')) {
    let oldUrl = window.history.state.old;
    logd('closeAllPopup.oldUrl', oldUrl);
    if (oldUrl) {
      POPUP_PARAMS.forEach((key) => {
        oldUrl = updateQueryStringParam(oldUrl, key, '');
      });
    } else {
      oldUrl = Router.locale === Router.defaultLocale ? '/' : `/${Router.locale}/`;
    }

    url = oldUrl;
  } else {
    POPUP_PARAMS.forEach((key) => {
      url = updateQueryStringParam(url, key, '');
    });
  }

  logd('closeAllPopup.url', url);

  window.history.replaceState({ ...window.history.state, old: null }, '', url);
};

/**
 * Custom router push remove all  current query
 * @returns {*}
 * Ex: routerRemoveQuery();
 */
export const routerRemoveQuery = () => {
  if (isServer) return;
  Router.push(
    {
      pathname: Router.pathname,
      query: {}
    },
    undefined,
    { shallow: true, scroll: false }
  ).then(() => logd('success'));
};

/**
 * Custom router push handle client side query
 * @param pathname as String
 * @param query as Object
 * @param shallow as Boolean
 * @param isUseOldQuery as Boolean
 * Ex: routerPush('/promotions');
 */
export const routerPush = (
  pathname,
  query = {},
  shallow = false,
  isUseOldQuery = false,
  scroll = true
) => {
  if (isServer) return;
  // delay a little bit for client side effect.
  // Ex: button click success to close modal
  const newQuery = isUseOldQuery
    ? {
        ...Router.query,
        ...query
      }
    : {
        ...query
      };

  logd('routerPush.newQuery', { newQuery, query, routerQuery: Router.query });

  Object.keys(newQuery).forEach((key) => {
    const value = newQuery[key];
    if (Array.isArray(value)) {
      if (value.length === 0) {
        delete newQuery[key];
      }
    }
  });

  Router.push(
    {
      pathname: pathname,
      query: newQuery
    },
    undefined, // next js 10 don't need handle hrefAs anymore
    { shallow, scroll }
  );
};

/**
 * router on back
 * @returns {*}
 * Ex: routerBack();
 */
export const routerBack = () => {
  if (isServer) return;
  Router.back();
};

function getNameSlug(params) {
  return params?.name_slug || params?.name || '';
}

export const getLaunchGameLatestBetsHref = (item) => {
  if (item && item.name_slug) {
    return `/casino-game/${item.name_slug}`;
  }
  return '#';
};

export const getLaunchGameHref = (item) => {
  if (item && item.slug) {
    return `/casino-game/${item.slug}`;
  }
  return '#';
};

export const getLaunchDemoGameHref = (item) => {
  if (item && item.slug) {
    return `/casino-game/${item.slug}?mode=trial`;
  }
  return '#';
};

export const routerReplace = (url) => {
  if (isServer) return;
  Router.replace(url);
};

export const redirectTo = (destination, { res, status = 302 } = {}) => {
  if (res) {
    console.log('redirectTo', destination, status);
    res.writeHead(status, { Location: destination });
    res.end();
  }
};

export const routerReload = () => {
  Router.replace(Router.asPath);
};

/**
 * Replace Next.js route with new route
 * Bypassing Next.js routing system
 * Avoiding re-rendering of components
 *
 * @see https://github.com/vercel/next.js/discussions/18072
 * @param {string} key - Route parameter
 * @param {string} value - New parameter value
 */
export const updateQueryStringParam = (url, key, value) => {
  // remove the hash part before operating on the url
  const i = url.indexOf('#');
  const hash = i === -1 ? '' : url.substring(i);
  url = i === -1 ? url : url.substring(0, i);

  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';

  if (!value) {
    // remove key-value pair if value is specifically null
    url = url.replace(new RegExp('([?&]?)' + key + '=[^&]*', 'i'), '');
    if (url.slice(-1) === '?') {
      url = url.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (url.indexOf('?') === -1) url = url.replace(/&/, '?');
  } else if (url.match(re)) {
    url = url.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    url = url + separator + key + '=' + value;
  }
  url = url + hash;

  return url;
};

export const fromEntries = (iterable) => {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

export function getQueryParams(locales = []) {
  try {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = fromEntries(urlSearchParams.entries()) || {};
    const signupLinks = locales.map((l) => `/${l}/signup/`).concat('/signup/');
    let pathname = window.location.pathname;
    if (!pathname.endsWith('/')) {
      pathname = `${pathname}/`;
    }
    if (signupLinks.includes(pathname)) {
      params.overlay = POPUP_NO_AUTH.SIGNUP;
    }
    return params;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export function tryOpenUrlInNewTab({ url, target = '_blank', w, h }) {
  try {
    let features = '';

    if (target !== '_blank') {
      // Fixes dual-screen position                             Most browsers      Firefox
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      const top = (height - h) / 2 / systemZoom + dualScreenTop;

      features = `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `;
    }

    const newWindow = window.open(url, target, features);

    if (newWindow) {
      if (window.focus) newWindow.focus();

      return newWindow;
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

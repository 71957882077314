import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * @params
 * key: 'xs', 'sm', 'md', 'lg', 'xl'
 * @returns {*}
 * Ex: useBreakPointUp('md');
 */
export function useBreakPointUp(key) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(key));
}

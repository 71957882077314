import { logd } from '@/@crema/utility/Logging';
import { FastField, getIn } from 'formik';
import { useState } from 'react';
import BaseDatePicker from '../../Inputs/DatePicker';

const _DatePicker = ({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue },
  helperText,
  ...nest
}) => {
  const [value, setValue] = useState(field.value ? field.value : '');

  const onChange = (value) => {
    setValue(value);
    setFieldValue(field.name, value);
  };

  const onBlur = (e) => {
    try {
      if (e.persist) {
        e.persist();
      }
      setFieldValue(field.name, value);
      setTimeout(() => fieldOnBlur(e ?? {}), 100);
    } catch (error) {
      logd(`TextField.onBlur.${field.name}.error`, error);
    }
  };

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <BaseDatePicker
      {...field}
      {...nest}
      error={showError}
      helperText={showError ? fieldError : helperText}
      disabled={disabled ?? isSubmitting}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

const DateTimePicker = (props) => {
  return <FastField {...props} component={_DatePicker}></FastField>;
};

export default DateTimePicker;

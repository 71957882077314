var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  enabled:
    process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,

  normalizeDepth: 0, // disable normalization objects

  beforeSend(event) {
    try {
      if (event.extra) {
        if (event.extra.data) {
          if (event.extra.data.account_id) {
            delete event.extra.data.account_id;
          }
          if (event.extra.data.card_holder) {
            delete event.extra.data.card_holder;
          }
          if (event.extra.data.card_number) {
            delete event.extra.data.card_number;
          }
          if (event.extra.data.cvv) {
            delete event.extra.data.cvv;
          }
        }
      }
      return event;
    } catch (e) {
      console.error(e);
      return event;
    }
  },

  allowUrls: [/https?:\/\/mc-fe.sgldemo\.xyz/],

  ignoreErrors: [
    /Service( )?Worker/i,
    /from accessing a cross-origin frame\. Protocols, domains, and ports must match\./i,
    /Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type/i,
    /runCustomize is not defined/i,
    /Unexpected identifier/i,
    /processRandomSelector is not defined/i,
    /Unexpected token 'function'/i,
    /Network Error/i,
    /One( )?Signal/i,
    /Script load failed/i,
    /Non-Error promise rejection captured with value/i,
    /Cannot read property 'document' of null/i,
    /globalInfo is not defined/i,
    /__gCrWeb/i,
    /InvalidStateError: The object is in an invalid state/i,
    /The transaction was aborted, so the request cannot be fulfilled/i,
    /Non-Error promise rejection captured with keys: code, error, message/i,
    /TypeError: 'handleEvent' property of event listener should be callable/i
  ]
});

import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ZoomTransition from '../Transition/ZoomTransition';

/**
 * @type {DialogProps}
 */
export default function Dialog({
  name,
  open,
  children,
  fullScreen,
  onBackdropClick,
  onEntered,
  ...nest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isXS = useBreakPointDown('xs');

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const isFullscreen = useMemo(() => {
    return fullScreen && isXS;
  }, [fullScreen, isXS]);

  const _onBackdropClick = useCallback(() => {
    setIsOpen(false);
    onBackdropClick?.();
  }, [onBackdropClick]);

  const _onEntered = useCallback(() => {
    onEntered?.();
  }, [onEntered]);

  return (
    <MuiDialog
      id={`${name}-dialog`}
      open={isOpen}
      fullScreen={isFullscreen}
      maxWidth="md"
      TransitionComponent={ZoomTransition}
      aria-labelledby={`${name}-dialog-title`}
      onClose={_onBackdropClick}
      onEntered={_onEntered}
      {...nest}>
      <>{children}</>
    </MuiDialog>
  );
}

Dialog.propTypes = {
  name: PropTypes.string,
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  onEntered: PropTypes.func
};

Dialog.defaultProps = {
  name: 'default',
  open: false,
  fullScreen: true,
  onBackdropClick: () => {},
  onEntered: () => {}
};

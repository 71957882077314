import TableLoadMore from '@/@crema/core/DataTable/TableLoadMore';
import NoDataFound from '@/@crema/core/NoDataFound';
import ListSkeletonMobile from '@/@crema/core/Skeleton/ListSkeletonMobile';
import { logd } from '@/@crema/utility/Logging';
import { Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';

const ListTransactionMobile = ({ loading, data, renderItem, hasMore, onLoadMore }) => {
  const { formatMessage } = useIntl();
  logd('inspect.SectionListTransactionMobile.sections', data);

  return (
    <>
      {data.length > 0 ? (
        <div>
          {data.map((item, index, arr) => {
            const isLastedItem = index < arr.length - 1;
            return (
              <Fragment key={item.id}>
                {renderItem({ item })}
                {isLastedItem && <Divider />}
              </Fragment>
            );
          })}
        </div>
      ) : (
        !loading && <NoDataFound message={formatMessage({ id: 'common.noTransactionHistory' })} />
      )}

      {!hasMore && loading && <ListSkeletonMobile />}
      <TableLoadMore canLoadMore={hasMore} isLoadMore={loading} loadMore={onLoadMore} />
    </>
  );
};

ListTransactionMobile.propTypes = {
  onClickItem: PropTypes.func
};

ListTransactionMobile.defaultProps = {
  onClickItem: () => {}
};

export default ListTransactionMobile;

import { DataTable } from '@/@crema';
import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import { useSelectedTransaction, useTransactionGame } from '@/redux/reducers/Transaction';
import { useIntl } from 'react-intl';
import DialogDetailsGame from '../../components/DialogDetails/DialogDetailsGame';
// import DrawerDetail from '../../components/DrawerDetail';
import { useCallback } from 'react';
import TransactionListItemGame from '../../components/ListItem/TransactionListItemGame';
import ListTransactionMobile from '../../components/ListTransactionMobile';
import { configure, TRANSACTION, useGetData, useSelectedItem } from '../../configure/';

const COLUMNS = ['roundId', 'gameName', 'betAmount', 'payout', 'betTime', 'settleTime'];
const COLUMNS_TABLE = COLUMNS.map((key) => configure.fields[key]);

const ListGame = () => {
  const isSM = useBreakPointDown('sm');
  const transactionData = useTransactionGame();
  const { data, hasMore, loading, page, perPage } = transactionData;
  const { onLoadMore } = useGetData(TRANSACTION.gameHistory);
  const { data: selectedItem } = useSelectedTransaction();
  const {
    isSelected,
    onSelectItem: handleSelectItem,
    onUnselectItem: handleUnselectItem
  } = useSelectedItem();
  const { formatMessage } = useIntl();

  const renderItem = useCallback(
    ({ item }) => <TransactionListItemGame item={item} onClick={handleSelectItem} />,
    [handleSelectItem]
  );

  return (
    <>
      {isSM ? (
        <ListTransactionMobile
          hasMore={hasMore}
          loading={loading}
          data={data}
          renderItem={renderItem}
          onLoadMore={onLoadMore}
        />
      ) : (
        <DataTable
          loading={loading && page === 0}
          data={data}
          columns={COLUMNS_TABLE}
          page={page}
          count={0}
          rowsPerPage={perPage}
          // onRowClick={onSelectItem}
          messageNoData={formatMessage({ id: 'common.noTransactionHistory' })}
          isPaging={false}
          canLoadMore={hasMore}
          isLoadMore={loading && page !== 0}
          loadMore={onLoadMore}
        />
      )}

      <DialogDetailsGame
        open={isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={handleUnselectItem}
        fields={COLUMNS}
      />

      {/* <DrawerDetail
        title={formatMessage({ id: 'transactionDetail.header.game' })}
        fields={COLUMNS}
        open={!isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={handleUnselectItem}
      /> */}
    </>
  );
};

export default ListGame;

import { Dialog, Icon } from '@/@crema';
import { onToggleAgentSignup } from '@/redux/actions';
import { usePopupAgentSignup } from '@/redux/reducers/App';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import AffiliateSignup from './AffiliateSignup';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      margin: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(4)
      },
      '&.MuiDialog-paperFullScreen': {
        margin: 0,
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingTop: 'env(safe-area-inset-top, 0)'
      }
    },
    '& .MuiPaper-root': {
      overflowX: 'hidden'
    }
  },
  content: {
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(8),
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(4),
      right: theme.spacing(6)
    }
  }
}));

const CloseButton = ({ onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const closeMsg = formatMessage({ id: 'common.close' });

  return (
    <IconButton
      aria-label={closeMsg}
      size="small"
      className={clsx(classes.closeButton)}
      onClick={onClick}>
      <Icon name="close" />
    </IconButton>
  );
};

const Affiliate = () => {
  const classes = useStyles();
  const open = usePopupAgentSignup();
  const dispatch = useDispatch();

  const handleToggle = useCallback(() => {
    dispatch(onToggleAgentSignup());
    window?.history?.replaceState({ ...window.history.state, old: null }, '', '/');
  }, [dispatch]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      fullScreen={true}
      onBackdropClick={handleToggle}
      disableBackdropClick={handleToggle}
      className={classes.dialog}
      PaperProps={{
        elevation: 0
      }}
      disableEnforceFocus>
      <Box className={classes.content}>
        <CloseButton onClick={handleToggle} />
        <AffiliateSignup handleToggle={handleToggle} />
      </Box>
    </Dialog>
  );
};
export default Affiliate;

import NumberFormat from 'react-number-format';

const Amount = ({ value, prefix, ...nest }) => {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={prefix}
      decimalScale={2}
      fixedDecimalScale
      {...nest}
    />
  );
};

export default Amount;

import cookie from 'js-cookie';

export const setCookie = (key, value, expired = 1, path = '/') => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: expired,
      path: path
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1
    });
  }
};

export const getCookie = (key, headers) => {
  return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, headers);
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, headers) => {
  if (!headers || !headers.cookie) {
    return undefined;
  }

  const rawCookie = headers.cookie.split(';').find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const getAllCookies = (key, headers) => {
  return process.browser ? getAllCookiesFromBrowser(key) : getAllCookiesFromServer(key, headers);
};

const getAllCookiesFromBrowser = () => {
  return cookie.get();
};

const getAllCookiesFromServer = (headers) => {
  const list = {};
  const cookieHeader = headers?.cookie;
  if (!cookieHeader) return list;

  cookieHeader.split(`;`).forEach(function (cookie) {
    let [name, ...rest] = cookie.split(`=`);
    name = name?.trim();
    if (!name) return;
    const value = rest.join(`=`).trim();
    if (!value) return;
    list[name] = decodeURIComponent(value);
  });

  return list;
};

import { withStyles } from '@material-ui/core';

const GlobalDialog = withStyles(
  (theme) => ({
    '@global': {
      '.MuiDialog-root': {
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.background.overlay
        },
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.background.dialog
        }
      }
    }
  }),
  { name: 'GlobalDialog' }
)(() => null);

export default GlobalDialog;

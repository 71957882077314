import {
  KYC_GET_CITY,
  KYC_GET_COUNTRY,
  KYC_GET_DOCUMENTS_SUCCESS,
  KYC_GET_ERROR,
  KYC_GET_START,
  KYC_GET_STATE,
  KYC_GET_SUCCESS,
  KYC_RESET,
  KYC_SET_STEP
} from '@/shared/constants/ActionTypes';
import { KYC_STEP } from '@/shared/constants/AppEnums';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  loading: false,
  documents: [],
  data: null,
  step: KYC_STEP.personalRegistration,
  error: false,
  country: [],
  states: [],
  cities: []
};

export const useKyc = () => {
  return useSelector(({ kyc }) => kyc);
};

export const useCountries = () => {
  return useSelector(({ kyc }) => kyc.country);
};
export const useStates = () => {
  return useSelector(({ kyc }) => kyc.states);
};
export const useCities = () => {
  return useSelector(({ kyc }) => kyc.cities);
};

export const KycProcessReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case KYC_SET_STEP:
      return {
        ...state,
        step: action.payload
      };
    case KYC_GET_START:
      return {
        ...INIT_STATE,
        loading: true
      };
    case KYC_GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case KYC_GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload
      };
    case KYC_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case KYC_RESET:
      return INIT_STATE;
    case KYC_GET_COUNTRY:
      return {
        ...state,
        country: action.payload || []
      };
    case KYC_GET_STATE:
      return {
        ...state,
        states: action.payload || []
      };
    case KYC_GET_CITY:
      return {
        ...state,
        cities: action.payload || []
      };
    default:
      return state;
  }
};

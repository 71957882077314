const { makeStyles, fade } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  transactionItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing(4)}px 16px`,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  name: {
    flex: 3,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginRight: theme.spacing(2)
  },
  amount: {
    flex: 1,
    textAlign: 'right'
  },
  date: {
    color: fade(theme.palette.text.primary, 0.5)
  }
}));

export default useStyles;

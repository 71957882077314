import { AnalyticsService } from '@/@crema';
import {
  addBank,
  getBanks,
  getWithdrawBanks,
  submitWithdrawal
} from '@/@crema/services/apis/withdrawal';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { groupBy, isValidArray } from '@/@crema/utility/utils';
import { onCheckSystemStatus, onGetWallets } from '@/redux/actions';
import {
  WITHDRAWAL_BANKS_GET_ERROR,
  WITHDRAWAL_BANKS_GET_START,
  WITHDRAWAL_BANKS_GET_SUCCESS,
  WITHDRAWAL_RESET,
  WITHDRAWAL_RESET_BANK_TRANSFER,
  WITHDRAWAL_SET_BANKS,
  WITHDRAWAL_SET_FORM_STATUS,
  WITHDRAWAL_SET_PLAYER_BANKS_ERROR,
  WITHDRAWAL_SET_PLAYER_BANKS_START,
  WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS,
  WITHDRAWAL_SET_SELECTED,
  WITHDRAWAL_SET_SELECTED_PLAYER_BANK,
  WITHDRAWAL_SET_SUBMIT_DATA
} from '@/shared/constants/ActionTypes';
import { FORM_STATUS, WITHDRAW_BY } from '@/shared/constants/AppEnums';
import { ZERO_BALANCE } from '@/shared/constants/WalletConst';
import get from 'lodash/get';
import Router from 'next/router';
import { batch } from 'react-redux';
import { fetchError, handleErrors, showSuccess } from './Common';

export const onSubmitWithdrawal = (withdraw_by) => (params, actions) => {
  return async (dispatch) => {
    try {
      const data = Object.assign({}, params, { withdraw_by });
      const res = await submitWithdrawal(data);

      dispatch({ type: WITHDRAWAL_SET_SUBMIT_DATA, payload: res.data });
      dispatch({ type: WITHDRAWAL_SET_FORM_STATUS, payload: FORM_STATUS.SUCCESS });

      dispatch(onGetWallets());

      if (res.data) {
        AnalyticsService.withdrawal_init('account_page', res.data.currency_code, res.data.amount);
      }
    } catch (error) {
      const resErrorVariables = get(error, 'data.error_message_variables', '');
      const resError = get(error, 'data.error', '');
      const code = get(error, 'data.code', '');
      const message = get(error, 'data.message', '');

      if (error.status === 403) {
        actions.setFieldError('password', <IntlMessages id={code} />);
        return;
      }

      if (error.status === 400 && resError === 'invalid_withdraw_amount') {
        actions.setFieldError(
          'amount',
          <IntlMessages
            id="validation.withdraw.limit"
            values={{ max: resErrorVariables.max, min: resErrorVariables.min }}
          />
        );
        return;
      }
      if (error.status === 400 && resError === 'balance_insufficient') {
        dispatch(fetchError(resError));
        return;
      }
      if (error.status === 400 && resError === 'un_verify_email') {
        dispatch(fetchError(resError));
        return;
      }
      if (error.status === 400 && message === 'Your country not allow withdraw.') {
        dispatch(fetchError(message));
        return;
      }
      dispatch(handleErrors(error));
    }
  };
};

export const onSubmitWithdrawalCrypto = onSubmitWithdrawal(WITHDRAW_BY.CRYPTO);
export const onSubmitWithdrawalCreditCard = onSubmitWithdrawal(WITHDRAW_BY.CREDIT_CARD);

export const onGetPlayerBanks = (withdraw_bank_id, shouldIgnore) => {
  return async (dispatch) => {
    try {
      const res = await getBanks(withdraw_bank_id);

      if (shouldIgnore()) return;
      if (isValidArray(res?.data?.data)) {
        dispatch({ type: WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS, payload: res?.data?.data });
      }
      dispatch({ type: WITHDRAWAL_SET_FORM_STATUS, payload: FORM_STATUS.READY });
    } catch (error) {
      if (shouldIgnore()) return;
      dispatch(handleErrors(error));
      dispatch({ type: WITHDRAWAL_SET_FORM_STATUS, payload: FORM_STATUS.ERROR });
    }
  };
};

export const onGetPlayerBankOfBankTransfer = (withdraw_bank_id, preselect, shouldIgnore) => {
  return async (dispatch) => {
    try {
      dispatch({ type: WITHDRAWAL_SET_PLAYER_BANKS_START });
      const res = await getBanks(withdraw_bank_id);

      if (shouldIgnore()) return;
      const types = [];
      const playerBanks = res?.data?.data ?? [];
      types.push({ type: WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS, payload: playerBanks });

      if (preselect && isValidArray(playerBanks)) {
        types.push({
          type: WITHDRAWAL_SET_SELECTED_PLAYER_BANK,
          payload: playerBanks[0]
        });
      }
      batch(() => types.forEach(dispatch));
    } catch (error) {
      if (shouldIgnore()) return;
      dispatch(handleErrors(error));
      dispatch({ type: WITHDRAWAL_SET_PLAYER_BANKS_ERROR });
    }
  };
};

const groupWithdrawBanks = (banks) => {
  let group = [],
    list = [];
  if (banks && Array.isArray(banks)) {
    list = banks;
    banks = banks.filter((b) => b?.cms_property);
    const paymentGroupByCode = groupBy(banks, (i) => i.cms_property?.group?.code);
    group = Object.keys(paymentGroupByCode)
      .map((key) => {
        const result = {
          name: '',
          code: '',
          order: 0,
          logo: '',
          description: '',
          short_description: '',
          badge_label: '',
          data: paymentGroupByCode[key],
          hide_selection_on_single_option: false
        };
        if (key === 'undefined') {
          result.name = 'Other';
          result.code = 'other';
          result.order = banks.length;
          result.logo = '';
          result.description = '';
          result.short_description = '';
        } else {
          const foundObj = banks.find((i) => i.cms_property?.group?.code === key);
          if (foundObj) {
            result.name = foundObj.cms_property?.group?.name;
            result.code = foundObj.cms_property?.group?.code;
            result.logo = foundObj.cms_property?.group?.logo;
            result.description = foundObj.cms_property?.group?.description;
            result.short_description = foundObj.cms_property?.group?.short_description;
            result.hide_selection_on_single_option =
              !!foundObj.cms_property?.group?.hide_selection_on_single_option;
            try {
              result.order = parseFloat(foundObj.cms_property?.group.order);
            } catch (err) {
              result.order = banks.length - 1;
            }
            result.badge_label = foundObj.cms_property?.group?.badge_label;
          }
        }
        return result;
      })
      .sort((a, b) => a.order - b.order);
  }
  return { group, list };
};

export const onGetWithdrawBanks = (groupCode = '') => {
  return async (dispatch, getState) => {
    try {
      const { wallets } = getState();
      dispatch({ type: WITHDRAWAL_BANKS_GET_START });
      const [res] = await Promise.all([getWithdrawBanks(), dispatch(onCheckSystemStatus())]);

      let banks = [];
      if (isValidArray(wallets?.data) && isValidArray(res?.data?.data)) {
        banks = res?.data?.data.map((item) => {
          const found = wallets?.data.find((w) => w?.currency?.code === item?.currency?.code);
          if (found) {
            item = {
              ...item,
              balance: found?.balance
            };
          }
          return item;
        });
      }

      const types = [];
      let { list, group } = groupWithdrawBanks(banks);

      if (groupCode) {
        let formStatus = FORM_STATUS.READY;
        const findGroupCode = (group) => group.find((g) => g?.code === groupCode);
        const foundGroup = findGroupCode(group);

        // set selected
        if (foundGroup) {
          if (groupCode === 'crypto') {
            types.push(onSetWithdrawBanks(foundGroup?.data));
          } else {
            types.push({ type: WITHDRAWAL_SET_SELECTED, payload: foundGroup?.data?.[0] });
          }
        }
        // groupCode is not found
        else {
          formStatus = FORM_STATUS.NEW;
          Router.push('/account/withdraw/');
        }
        types.push({ type: WITHDRAWAL_SET_FORM_STATUS, payload: formStatus });
      }

      types.push({ type: WITHDRAWAL_BANKS_GET_SUCCESS, payload: { list, group } });
      batch(() => types.forEach(dispatch));
    } catch (error) {
      dispatch(handleErrors(error));
      dispatch({ type: WITHDRAWAL_BANKS_GET_ERROR });
    }
  };
};

export const onSetWithdrawBanks = (banks) => {
  return (dispatch, getState) => {
    const { withdrawal, wallets } = getState();
    const _banks = banks || withdrawal?.banks;
    logd('onSetWithdrawBanks', { withdrawal, banks, wallets, _banks });

    const mapNewBank = (wallet) => {
      const found = _banks.find((b) => b?.currency?.code === wallet?.currency?.code);
      if (found) {
        return Object.assign({}, found, wallet);
      }
      return null;
    };

    if (isValidArray(wallets?.data) && isValidArray(_banks)) {
      // get non-zero balances
      let newBanks = wallets.data.reduce((acc, wallet) => {
        if (wallet.balance !== ZERO_BALANCE) {
          const newItem = mapNewBank(wallet);
          newItem && acc.push(newItem);
        }
        return acc;
      }, []);

      // fallback to active crypto wallet
      if (!newBanks.length) {
        const wallet = wallets.data.find((w) => w.activated === 1);
        if (wallet) {
          const newItem = mapNewBank(wallet);
          newItem && newBanks.push(newItem);
        }
      }

      dispatch(onSetWithdrawSelectedBank(newBanks));

      dispatch({ type: WITHDRAWAL_SET_BANKS, payload: newBanks });
    }
  };
};

export const onSetWithdrawSelectedBank = (banks, id) => {
  return async (dispatch) => {
    let selectedBank = null;
    if (isValidArray(banks)) {
      if (id) {
        selectedBank = banks.find((i) => String(i.id) === String(id));
      } else {
        selectedBank = banks.find((i) => i.activated === 1);
      }

      if (!selectedBank) {
        selectedBank = banks[0];
      }

      if (isValidArray(selectedBank?.blockchain_networks)) {
        selectedBank.blockchain_network = selectedBank.blockchain_networks[0];
      }
    }
    dispatch({ type: WITHDRAWAL_SET_SELECTED, payload: selectedBank });
  };
};

export const onSetSelectedBank = (bank) => {
  return (dispatch) => {
    dispatch({ type: WITHDRAWAL_SET_SELECTED, payload: bank });
  };
};

export const onResetWithdraw = () => {
  return (dispatch) => {
    dispatch({ type: WITHDRAWAL_RESET });
  };
};

export const onResetBankTransfer = () => {
  return (dispatch) => {
    dispatch({ type: WITHDRAWAL_RESET_BANK_TRANSFER });
  };
};

export const onAddBank = (data, actions) => {
  return async (dispatch, getState) => {
    try {
      const { selected, playerBanks } = getState().withdrawal;
      data.withdraw_bank_id = selected?.id;
      data.withdraw_by = selected?.type;
      const res = await addBank(data);
      batch(() => {
        dispatch(showSuccess('account.withdraw.addBankSuccessfully'));
        dispatch({
          type: WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS,
          payload: [res?.data, ...playerBanks]
        });
        dispatch({
          type: WITHDRAWAL_SET_SELECTED_PLAYER_BANK,
          payload: res?.data
        });
      });
      return true;
    } catch (error) {
      dispatch(handleErrors(error, actions));
      return false;
    }
  };
};

import { isValidString } from '@/@crema/utility/utils';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Form as FormWrapper, Formik, FormikConfig } from 'formik';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    padding: theme.spacing(8),
    '& .MuiFormControl-marginNormal': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6)
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4)
    }
  },
  formHeader: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      '& h4': {
        ...theme.typography.h5,
        fontWeight: theme.typography.fontWeightBold
      }
    }
  }
}));

/**
 *
 * @param {FormikConfig} props
 */
export default function Form({ className, id, children, title, description, ...formikProps }) {
  const classes = useStyles();
  return (
    <Formik {...formikProps}>
      <FormWrapper
        id={id}
        className={clsx(classes.formRoot, className, 'form-wrapper')}
        noValidate
        autoComplete="off">
        <>
          {isValidString(title) && (
            <Box className={classes.formHeader}>
              <Typography variant="h4" className="bold uppercase form-title" gutterBottom>
                {title}
              </Typography>
              {isValidString(description) && (
                <Typography variant="body2" className="form-description">
                  {description}
                </Typography>
              )}
            </Box>
          )}

          {children}
        </>
      </FormWrapper>
    </Formik>
  );
}

Form.defaultProps = {
  className: undefined,
  id: '',
  title: '',
  description: ''
};

Form.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

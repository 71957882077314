/* eslint-disable no-empty */
import { logi } from '@/@crema/utility/Logging';
import { getClientIp } from './ip';

const TAG = 'REQUEST_TRACKING';

export const requestTracking = (key, ctx, Component) => {
  try {
    const tag = `[${TAG}][${String(key).toUpperCase()}]_${
      Component?.displayName || Component?.name || ''
    }`;
    logi(
      `${tag}`,
      getClientIp(ctx?.req),
      ctx?.req?.method,
      ctx?.req?.url,
      ctx?.res?.statusCode,
      ctx?.pathname,
      ctx?.asPath,
      ctx?.locale,
      ctx?.query
    );
  } catch (error) {}
};

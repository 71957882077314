import { isNA, isValidFunction } from '@/@crema/utility/utils';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { configure } from '../../configure/';
import useStyles from './TransactionInformationDrawer.style';

const TransactionInformationDrawer = ({ data, fields }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const renderRow = (key) => {
    const { name, label, renderContent } = configure.fields[key];
    let value = data?.[name];
    if (isNA(value)) {
      value = '-';
    }

    if (!label) {
      return (
        <div key={key} className={classes.row}>
          {isValidFunction(renderContent) ? renderContent(value, data) : value}
        </div>
      );
    }

    return (
      <Typography
        key={key}
        variant="body2"
        component="div"
        className={clsx('semibold', classes.row, classes.rowLabel)}>
        <div className={classes.left}>{formatMessage({ id: label })}</div>
        <div className={classes.right}>
          {isValidFunction(renderContent) ? renderContent(value, data) : value}
        </div>
      </Typography>
    );
  };

  if (!data) return null;

  return (fields || []).map(renderRow);
};

TransactionInformationDrawer.propTypes = {
  data: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.string)
};

TransactionInformationDrawer.defaultProps = {
  data: {},
  fields: []
};

export default memo(TransactionInformationDrawer);

import { getCMSName, isValidObject } from '@/@crema/utility/utils';
import { Collapse, Divider, ListItem, Typography, fade, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { configure } from '../../configure/';
import { CellCurrencySports } from '../../configure/renderBetSelection';
import { CellMarketSports } from '../../configure/renderBetType';
import renderStatusSportsbook from '../../configure/renderStatusSportsbook';
import { CellTextCopy } from '../../configure/renderTextCopy';
import CellDetailSport from '../ExpandedRow/CellDetailSport';

const useStyles = makeStyles((theme) => ({
  transactionListItemSportsbookGame: {
    '& .text-copy-transaction-list-expand-detail .cell-text': {
      color: fade(theme.palette.text.primary, 0.5)
    }
  },
  transactionItemGame: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,

    '& .transactionItemGame-textDesc': {
      color: fade(theme.palette.text.primary, 0.5)
    },
    '& .transactionItemGame-descItem1': {
      flexShrink: 1,
      flexGrow: 2,
      marginRight: theme.spacing(2),
      lineHeight: 1.66
    },
    '& .transactionItemGame-descItem2': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      flexShrink: 0,
      flexGrow: 1
    },
    '& .transactionItemGame-descItem2Inner': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    '& .transactionItemGame-oneLine': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden'
    },
    '& .transactionItemGame-head': {
      marginBottom: theme.spacing(2),
      '& div': {
        lineHeight: '1'
      }
    },
    '& .transactionItemGame-body': {
      gridArea: 'body',
      display: 'flex',
      justifyContent: 'space-between',
      lineHeight: '1.1'
    }
  },
  image: {
    width: 48
  }
}));

const parseFn = (item) => {
  return {
    name: configure.fields.gameName.renderContent(null, item),
    amount: configure.fields.amountWithPrefix.renderContent(null, item),
    date: configure.fields.betTime.renderContent(null, item),
    src: item?.game?.cms_property?.mobile_image?.path ?? '',
    alt: getCMSName(item?.game),
    gameProvider: configure.fields.providerName.renderContent(null, item),
    gameType: configure.fields.gameType.renderContent(null, item),
    betAmount: configure.fields.betAmount.renderContent(null, item),
    payout: configure.fields.payout.renderContent(null, item)
  };
};

const TransactionListItemSportsbookGame = ({ item, onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [open, setopen] = useState(false);

  const { game = {}, round_id, sportbook_bet_detail = {} } = item;
  const gameTypeName = game?.game_type?.name;
  const { detail = [] } = sportbook_bet_detail;
  const { name, date, gameProvider, betAmount, payout } = parseFn(item);

  const handleClick = useCallback(() => {
    setopen((open) => !open);
    // allow ripple effect happens ahead
    setTimeout(() => {
      onClick(item);
    }, 150);
  }, [item, onClick]);

  const contentCollapseCpm = useMemo(
    () => (
      <>
        <div>
          <CellTextCopy
            variant="caption"
            className="text-copy-transaction-list-expand-detail"
            text={round_id}
          />
        </div>
        <div className="flex column gap-12">
          {detail.map((detail, i) => (
            <CellDetailSport
              className="flex column"
              key={i}
              detail={detail}
              gameTypeName={gameTypeName}
            />
          ))}
        </div>
      </>
    ),
    [detail, gameTypeName, round_id]
  );

  const itemsCpm = useMemo(() => {
    if (!isValidObject(item)) return null;
    return (
      <>
        <div className={classes.transactionItemGame}>
          <div className="transactionItemGame-head">
            <Typography
              variant="subtitle2"
              component={'div'}
              className="transactionItemGame-oneLine">
              {name}
            </Typography>
          </div>
          <Typography variant="caption" component={'div'} className="transactionItemGame-body">
            <div className="transactionItemGame-descItem1">
              <div className="align-center">
                <span className="transactionItemGame-textDesc">{date}</span>
              </div>
              <div className="align-center force-inline">
                <span className="transactionItemGame-textDesc">{`${gameProvider} `}</span>
                <CellCurrencySports value={item} />
              </div>
              <div className="flex align-center line-height-1">
                {/* <span className='transactionItemGame-textDesc'>{date}</span> */}
                <CellMarketSports value={item} />
              </div>
            </div>
            <div className="transactionItemGame-descItem2">
              <div className="transactionItemGame-descItem2Inner">
                {renderStatusSportsbook(undefined, item)}
                <div className="transactionItemGame-oneLine">
                  <span className="transactionItemGame-textDesc">
                    {formatMessage({ id: 'transaction.game.bet' })}
                  </span>
                  &nbsp;
                  {betAmount}
                </div>
                <div className="transactionItemGame-oneLine">
                  <span className="transactionItemGame-textDesc">
                    {formatMessage({ id: 'transaction.game.payout' })}
                  </span>
                  &nbsp;
                  {payout}
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </>
    );
  }, [
    betAmount,
    classes.transactionItemGame,
    date,
    formatMessage,
    gameProvider,
    item,
    name,
    payout
  ]);

  return (
    <div className={classes.transactionListItemSportsbookGame}>
      <ListItem button onClick={handleClick}>
        {itemsCpm}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className={clsx('p-4 pt-0', open && 'open-expand-cell')}>{contentCollapseCpm}</div>
      </Collapse>
      <Divider />
    </div>
  );
};

TransactionListItemSportsbookGame.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

TransactionListItemSportsbookGame.defaultProps = {
  onClick: () => {}
};

export default TransactionListItemSportsbookGame;

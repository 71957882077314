import { logd } from '@/@crema/utility/Logging';
import { routerPush } from '@/@crema/utility/Route';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { APP_CONFIG } from '../constants/AppConfig';

const GetDepositId = () => {
  const router = useRouter();
  const { transaction_id } = router.query || {};
  const transaction_id_ref = useRef();

  logd('GetDepositId.router', router);

  useEffect(() => {
    try {
      if (typeof window === 'undefined') {
        return;
      }

      if (window.name.includes('tag-assistant-debug-window')) return;

      if (transaction_id_ref.current) {
        return;
      }

      if (transaction_id) {
        // child iframe
        if (window.parent) {
          window.parent.postMessage({ transaction_id }, APP_CONFIG.siteUrl);
          if (!window.closed) {
            window.close();
          }
        }
        // child window
        if (window.opener) {
          window.opener.postMessage({ transaction_id }, APP_CONFIG.siteUrl);
          if (!window.closed) {
            window.close();
          }
        }
        transaction_id_ref.current = transaction_id;
      }
    } catch (error) {
      console.error(error);
    }
  }, [transaction_id]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const getMessageFromIframe = (e) => {
      try {
        if (!e || !e?.origin || e?.origin !== APP_CONFIG.siteUrl) return;

        if (e?.data?.transaction_id) {
          if (router.query.transaction_id) {
            return router.push(router.asPath);
          } else {
            return routerPush('/account/deposit/', { transaction_id: e?.data?.transaction_id });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener('message', getMessageFromIframe, false);

    return () => {
      window.removeEventListener('message', getMessageFromIframe, false);
    };
  }, [router]);

  return null;
};

export default GetDepositId;

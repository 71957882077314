import {
  countUnRead,
  getNotifications,
  markAllRead,
  markAsRead
} from '@/@crema/services/apis/notifications';
import { handleErrors } from '@/redux/actions';
import {
  NOTIFICATION_COUNT_UN_READ,
  NOTIFICATION_GET_ERROR,
  NOTIFICATION_GET_START,
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_LOAD_MORE_ERROR,
  NOTIFICATION_LOAD_MORE_START,
  NOTIFICATION_LOAD_MORE_SUCCESS,
  NOTIFICATION_MARK_AS_READ,
  NOTIFICATION_RESET
} from '@/shared/constants/ActionTypes';
import { getFeatureToggle } from '@/shared/utils/FeatureToggle';

export const onLoadMoreNotifications = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATION_LOAD_MORE_START
      });
      dispatch(onGetNotifications(true));
    } catch (error) {
      dispatch({
        type: NOTIFICATION_LOAD_MORE_ERROR
      });
    }
  };
};

export const onGetNotifications = (isLoadMore = false) => {
  return async (dispatch, getState) => {
    const { app, notifications } = getState();
    const systemSetting = getFeatureToggle(app?.systemSetting);
    if (!systemSetting.notification.enabled) {
      return;
    }
    try {
      dispatch({ type: NOTIFICATION_GET_START });
      let params = {};
      if (isLoadMore) {
        const { paging } = notifications;
        params = {
          page: isLoadMore ? paging?.current_page + 1 : paging?.current_page,
          per_page: paging.per_page
        };
      }

      const res = await getNotifications(params);

      if (res.data) {
        dispatch({
          type: isLoadMore ? NOTIFICATION_LOAD_MORE_SUCCESS : NOTIFICATION_GET_SUCCESS,
          payload: res.data
        });
      }

      return res.data;
    } catch (error) {
      dispatch({ type: NOTIFICATION_GET_ERROR });
      return error;
    }
  };
};

export const onMarkAsReadNotification = (id) => {
  return async (dispatch) => {
    try {
      await markAsRead(id);

      dispatch({ type: NOTIFICATION_MARK_AS_READ, payload: id });
      dispatch(onCountNotification());
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onMarkAllReadNotification = () => {
  return async (dispatch) => {
    try {
      await markAllRead();
      dispatch(onGetNotifications());
      dispatch(onCountNotification());
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onCountNotification = () => {
  return async (dispatch, getState) => {
    const { app } = getState();
    const systemSetting = app?.systemSetting;
    if (!systemSetting?.enable_notification) {
      return;
    }
    try {
      const res = await countUnRead();
      dispatch({ type: NOTIFICATION_COUNT_UN_READ, payload: res.data });
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onResetNotificationsData = () => {
  return (dispatch) => {
    dispatch({ type: NOTIFICATION_RESET });
  };
};

import NumberFormat from 'react-number-format';

export default function AmountCrypto({ prefix, suffix, value, ...nest }) {
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={prefix}
      suffix={suffix}
      //   decimalScale={2}
      fixedDecimalScale
      {...nest}
    />
  );
}

import { isValidString } from '@/@crema/utility/utils';
import { COLORS } from '@/shared/constants/ColorSets';
import {
  Box,
  CircularProgress,
  fade,
  FormControl,
  FormLabel,
  InputAdornment,
  makeStyles,
  withStyles
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '../../Button/IconButton';
import Icon from '../../Icon';

const MuiTextField = withStyles(
  (theme) => {
    return {
      root: {
        '& .MuiFormHelperText-contained': {
          marginLeft: 0
        },
        '& .MuiOutlinedInput-input': {
          '&:-webkit-autofill': {
            '-webkit-text-fill-color': theme.palette.text.primary,
            '-webkit-background-clip': 'text',
            '-webkit-box-shadow': 'unset'
          }
        },
        '& .MuiInputBase-root.Mui-disabled': {
          color: fade(theme.palette.text.primary, 0.15)
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: fade(theme.palette.text.primary, 0.08)
        },
        '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot, & .MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiOutlinedInput-marginDense':
          {
            paddingTop: 0,
            paddingBottom: 0,
            '& .MuiAutocomplete-input': {
              height: 40,
              paddingTop: 0,
              paddingBottom: 0
            }
          }
      }
    };
  },
  { name: 'BaseTextField' }
)(TextField);

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiFormLabel-root': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        ...theme.typography.body2,
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold
      }
    }
  },
  select: {},
  loading: {
    '& #loading': {
      width: 16,
      height: 16
    }
  },
  currency: {
    backgroundColor: theme.palette.primary.main,
    color: COLORS.background1,
    width: 20,
    height: 20,
    borderRadius: '50%',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '20px'
  }
}));

const BaseTextField = React.forwardRef(
  (
    {
      name,
      label,
      margin = 'normal',
      select = false,
      disabled = false,
      error = false,
      type = 'text',
      variant = 'outlined',
      value,
      loading = false,
      helperText,
      currency,
      ...nest
    },
    ref
  ) => {
    const classes = useStyles();
    const isPassword = type === 'password';
    const [showPassword, setShowPassword] = useState(!isPassword);
    const { formatMessage } = useIntl();

    let msgHelperText = helperText;
    if (helperText && isValidString(helperText)) {
      msgHelperText = formatMessage({ id: helperText });
    }

    const handleToggleShowPassword = () => {
      setShowPassword((value) => !value);
    };

    const TogglePasswordButton = useMemo(() => {
      if (isPassword) {
        return (
          <IconButton
            size="small"
            edge="end"
            aria-label="toggle password"
            disabled={disabled}
            onClick={handleToggleShowPassword}>
            {showPassword ? <Icon name="eye" /> : <Icon name="eye-off" />}
          </IconButton>
        );
      }

      return null;
    }, [disabled, isPassword, showPassword]);

    const Currency = useMemo(() => {
      if (currency) {
        return <Box className={classes.currency}>{currency}</Box>;
      }

      return null;
    }, [classes.currency, currency]);

    const endAdornment = useMemo(() => {
      if (loading) {
        return (
          <InputAdornment position="end">
            <CircularProgress color="primary" size={16} />
          </InputAdornment>
        );
      }
      if (TogglePasswordButton) {
        return <InputAdornment position="end">{TogglePasswordButton}</InputAdornment>;
      }
      if (Currency) {
        return <InputAdornment position="end">{Currency}</InputAdornment>;
      }
      return null;
    }, [Currency, TogglePasswordButton, loading]);

    return (
      <FormControl
        className={classes.formControl}
        fullWidth
        margin={margin}
        error={error}
        disabled={disabled}>
        {label && (
          <FormLabel htmlFor={name} className="bold">
            {label}
          </FormLabel>
        )}

        <MuiTextField
          inputRef={ref}
          name={name}
          fullWidth
          variant={variant}
          select={select}
          error={error}
          disabled={disabled}
          autoComplete="off"
          type={showPassword ? 'text' : 'password'}
          value={value}
          margin="none"
          size="small"
          InputProps={{ endAdornment }}
          helperText={msgHelperText}
          {...nest}
        />
      </FormControl>
    );
  }
);

BaseTextField.displayName = 'BaseTextField';

export default BaseTextField;

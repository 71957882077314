import { isValidString } from '@/@crema/utility/utils';
import { useFavicon } from '@/redux/reducers/App';
import Head from 'next/head';
import PropTypes from 'prop-types';
import HeadMeta from './HeadMeta';

const CremaPage = ({ children, title, description, image, data, hasSpacing = false }) => {
  const faviconImg = useFavicon();

  return (
    <>
      <HeadMeta title={title} description={description} image={image} data={data} />
      <Head>{isValidString(faviconImg) && <link rel="icon" href={faviconImg} />}</Head>
      {children}
    </>
  );
};

CremaPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  data: PropTypes.array
};

CremaPage.defaultProps = {
  data: []
};

export default CremaPage;

import { setCookie } from '@/@crema/utility/Session';
import { COOKIES } from '@/shared/constants/CookieConst';
import router from 'next/router';

export default function changeLocale(newLocale) {
  try {
    setCookie(COOKIES.next_locale, newLocale);
    setTimeout(() => {
      let splitAsPath = router.asPath.split('?')[0];

      // prevent to show signup again
      if (splitAsPath === '/signup/') {
        splitAsPath = '/';
      }

      // router.replace(router.pathname, splitAsPath, {
      //   locale: newLocale,
      //   scroll: true,
      //   shallow: false
      // });
      // setTimeout(() => {
      //   store.dispatch(onGetSetting());
      // }, 1000);

      window.location.href = splitAsPath;
    }, 1000);
  } catch (error) {
    console.error(error);
  }
}

import { withStyles } from '@material-ui/core';

const GlobalContainer = withStyles(
  (theme) => ({
    '@global': {
      '.MuiContainer-root': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6)
        }
      }
    }
  }),
  { name: 'GlobalContainer' }
)(() => null);

export default GlobalContainer;

import {
  AUTH_FETCH_KIOSKS,
  AUTH_FETCH_PROFILE_START,
  AUTH_FETCH_PROFILE_SUCCESS,
  AUTH_FORGOT_PASSWORD_RESET,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_GET_BANKS,
  AUTH_RESET,
  AUTH_SET_REQUIRE_OTP,
  AUTH_SET_USER,
  AUTH_SET_USERINFO
} from '@/shared/constants/ActionTypes';
import { DEFAULT_CURRENCY, DEFAULT_PREFIX } from '@/shared/constants/AppEnums';
import React from 'react';
import { useSelector } from 'react-redux';

export const FORGOT_PASSWORD_STATUS = {
  NONE: 'NONE',
  SEND_SUCCESS: 'SEND_SUCCESS',
  SEND_FAILED: 'SEND_FAILED'
};

const FORGOT_PASSWORD_DEFAULT_STATE = {
  status: FORGOT_PASSWORD_STATUS.NONE,
  formData: null,
  message: null
};

const INIT_STATE = {
  user: null,
  isLoggedIn: false,
  fetchProfileProcessing: true,
  forgotPasswordData: FORGOT_PASSWORD_DEFAULT_STATE,
  banks: [],
  kiosks: [],
  requireOTP: false,
  userInfo: {}
};

export const useAuthUser = () => {
  return useSelector(({ auth }) => auth.user);
};

export const useAuthUserId = () => {
  return useSelector(({ auth }) => auth.user?.id);
};

export const useTotalDepositNumber = () => {
  return useSelector(({ auth }) => auth.user?.total_deposit_number);
};

export const useCurrencySign = () => {
  return useSelector(({ auth }) => auth?.user?.currency?.sign || DEFAULT_PREFIX);
};

export const useCurrencySymbol = () => {
  return useSelector(({ auth }) => auth?.user?.currency?.symbol || DEFAULT_PREFIX);
};

export const useCurrencyCode = () => {
  return useSelector(({ auth }) => auth?.user?.currency?.code || DEFAULT_CURRENCY);
};

export const useCurrency = () => {
  return useSelector(({ auth }) => auth?.user?.currency);
};

export const useAuthForgotPasswordData = () => {
  return useSelector(({ auth }) => auth.forgotPasswordData);
};

export const useAuthBanks = () => {
  return useSelector(({ auth }) => auth.banks);
};

export const useRequireOTP = () => {
  return useSelector(({ auth }) => auth.requireOTP);
};

export const useUserInfo = () => {
  return useSelector(({ auth }) => auth.userInfo);
};

export const useFetchProfileProcessing = () => {
  return useSelector(({ auth }) => auth.fetchProfileProcessing);
};

export const useIsLoggedIn = () => {
  return useSelector(({ auth }) => auth.isLoggedIn);
};

export const useAccountProfileCompleted = () => {
  return useSelector(({ auth }) => auth.user?.account_profile_completed);
};

export const isLoggedIn = React.createRef(false);

export const JWTAuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_SET_USER:
      isLoggedIn.current = true;
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload
      };
    case AUTH_FETCH_PROFILE_START:
      return {
        ...state,
        fetchProfileProcessing: true
      };
    case AUTH_FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        fetchProfileProcessing: false
      };
    case AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordData: action.payload
      };
    case AUTH_GET_BANKS:
      return {
        ...state,
        banks: action.payload
      };
    case AUTH_RESET:
      return INIT_STATE;
    case AUTH_FETCH_KIOSKS:
      return {
        ...state,
        kiosks: action.payload
      };
    case AUTH_SET_REQUIRE_OTP:
      return {
        ...state,
        requireOTP: action.payload
      };
    case AUTH_SET_USERINFO:
      return {
        ...state,
        userInfo: action.payload
      };
    case AUTH_FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotPasswordData: FORGOT_PASSWORD_DEFAULT_STATE
      };
    default:
      return state;
  }
};

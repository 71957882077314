export const FIATS = [
  { code: 'USD', name: 'USD', icon: '/flag/english.svg', enable: true },
  { code: 'THB', name: 'THB', icon: '/flag/thailand.svg', enable: true },
  { code: 'EUR', name: 'EUR', icon: '/flag/europe.svg', enable: true },
  { code: 'MYR', name: 'MYR', icon: '/flag/malaysia.svg', enable: true },
  { code: 'CNY', name: 'CNY', icon: '/flag/china.svg', enable: true },
  { code: 'JPY', name: 'JPY', icon: '/flag/japan.svg', enable: true },
  { code: 'KRW', name: 'KRW', icon: '/flag/south-korea.svg', enable: true },
  { code: 'INR', name: 'INR', icon: '/flag/india.svg', enable: true },
  { code: 'IDR', name: 'IDR', icon: '/flag/indonesia.svg', enable: true }
];

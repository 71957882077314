import {
  NOTIFICATION_COUNT_UN_READ,
  NOTIFICATION_GET_ERROR,
  NOTIFICATION_GET_START,
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_LOAD_MORE_ERROR,
  NOTIFICATION_LOAD_MORE_START,
  NOTIFICATION_LOAD_MORE_SUCCESS,
  NOTIFICATION_MARK_AS_READ,
  NOTIFICATION_RESET
} from '@/shared/constants/ActionTypes';
import { PAGING_DEFAULT } from '@/shared/constants/PagingConst';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  data: null,
  loading: true,
  total_unread: 0,
  paging: {
    current_page: 1,
    last_page: 0,
    per_page: PAGING_DEFAULT.perPage,
    total: 0
  },
  isLoadMore: false
};

const updateItem = (id, arr) => {
  if (id && arr.length) {
    let data = arr;
    const index = findIndex(arr, { id: id });
    const item = find(arr, { id: id });
    data.splice(index, 1, { ...item, is_read: 1 });
    return data;
  }
  return arr;
};

export const useNotificationsLoading = () => {
  return useSelector(({ notifications }) => notifications?.loading);
};

export const useNotificationsData = () => {
  return useSelector(({ notifications }) => notifications?.data);
};

export const useCountNotifications = () => {
  return useSelector(({ notifications }) => notifications?.total_unread);
};

export const useNotificationsPaging = () => {
  return useSelector(({ notifications }) => notifications?.paging);
};

export const useNotificationsIsLoadMore = () => {
  return useSelector(({ notifications }) => notifications?.isLoadMore);
};

export const NotificationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_GET_START:
      return {
        ...state,
        loading: true
      };
    case NOTIFICATION_GET_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        paging: {
          current_page: action.payload?.current_page,
          last_page: action.payload?.last_page,
          per_page: action.payload?.per_page,
          total: action.payload?.total
        },
        loading: false
      };
    case NOTIFICATION_GET_ERROR:
      return {
        ...state,
        isLoadMore: false,
        loading: false
      };
    case NOTIFICATION_MARK_AS_READ:
      return {
        ...state,
        data: [...updateItem(action.payload, state.data)]
      };
    case NOTIFICATION_COUNT_UN_READ:
      return {
        ...state,
        total_unread: action.payload?.total_unread || 0
      };
    case NOTIFICATION_LOAD_MORE_START:
      return {
        ...state,
        isLoadMore: true
      };
    case NOTIFICATION_LOAD_MORE_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...(action?.payload?.data || [])],
        paging: {
          current_page: action.payload?.current_page,
          last_page: action.payload?.last_page,
          per_page: action.payload?.per_page,
          total: action.payload?.total
        },
        isLoadMore: false
      };
    case NOTIFICATION_LOAD_MORE_ERROR:
      return {
        ...state,
        isLoadMore: false
      };
    case NOTIFICATION_RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

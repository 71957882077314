import {
  cancelTransactionDeposit,
  cancelTransactionWithdraw,
  getDepositHistories,
  getGameHistories,
  getGameSportsbookHistories,
  getTransactionStatus,
  getWithdrawalHistories
} from '@/@crema/services/apis/transaction';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { routerPush } from '@/@crema/utility/Route';
import { SLUG_TYPE } from '@/modules/Account/Transactions/TransactionList';
import { TRANSACTION } from '@/modules/Account/Transactions/configure/';
import { SPORTS_NAME_TO_CODE } from '@/modules/Account/Transactions/configure/renderStatusSportsbook';
import {
  CLEAR_SELECTED_TRANSACTION,
  TRANSACTION_CANCEL_DEPOSIT_ERROR,
  TRANSACTION_CANCEL_DEPOSIT_START,
  TRANSACTION_CANCEL_WITHDRAW_ERROR,
  TRANSACTION_CANCEL_WITHDRAW_START,
  TRANSACTION_GET_DEPOSIT_ERROR,
  TRANSACTION_GET_DEPOSIT_START,
  TRANSACTION_GET_DEPOSIT_SUCCESS,
  TRANSACTION_GET_GAME_ERROR,
  TRANSACTION_GET_GAME_SPORTSBOOK_ERROR,
  TRANSACTION_GET_GAME_SPORTSBOOK_START,
  TRANSACTION_GET_GAME_SPORTSBOOK_SUCCESS,
  TRANSACTION_GET_GAME_START,
  TRANSACTION_GET_GAME_SUCCESS,
  TRANSACTION_GET_STATUS_ERROR,
  TRANSACTION_GET_STATUS_START,
  TRANSACTION_GET_STATUS_SUCCESS,
  TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS,
  TRANSACTION_GET_WITHDRAW_ERROR,
  TRANSACTION_GET_WITHDRAW_START,
  TRANSACTION_GET_WITHDRAW_SUCCESS,
  TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE,
  TRANSACTION_LOADING_CHECK_SPORTSBOOK_START,
  TRANSACTION_RESET_DEPOSIT,
  TRANSACTION_RESET_GAME,
  TRANSACTION_RESET_GAME_SPORTSBOOK,
  TRANSACTION_RESET_WITHDRAW,
  TRANSACTION_SET_DEPOSIT,
  TRANSACTION_SET_DEPOSIT_SUCCESS,
  TRANSACTION_SET_FILTER_SPORT_HISTORIES,
  TRANSACTION_SET_SHOW_SPORTSBOOK_TAB,
  TRANSACTION_SET_WITHDRAW
} from '@/shared/constants/ActionTypes';
import { DEFAULT_FILTER_SPORT_HISTORY, PRODUCT_TYPE } from '@/shared/constants/Transaction';
import get from 'lodash/get';
import Router from 'next/router';
import { batch } from 'react-redux';
import { handleErrors } from './Common';

export const onGetTransactionList = (type) => {
  return (dispatch) => {
    switch (type) {
      case TRANSACTION.depositHistory:
        return dispatch(onGetDepositHistoriesList());
      case TRANSACTION.withdrawalHistory:
        return dispatch(onGetWithdrawHistoriesList());
      case TRANSACTION.gameHistory:
        return dispatch(onGetGameHistoriesList());
      case TRANSACTION.sportHistory:
        return dispatch(onGetGameSportHistoriesList());
    }
  };
};

export const onResetTransactionList = (type) => {
  return (dispatch) => {
    switch (type) {
      case TRANSACTION.depositHistory:
        dispatch(onResetDepositHistoriesList());
        break;
      case TRANSACTION.withdrawalHistory:
        dispatch(onResetWithdrawHistoriesList());
        break;
      case TRANSACTION.gameHistory:
        dispatch(onResetGameHistoriesList());
        break;
      case TRANSACTION.sportHistory:
        dispatch(onResetGameSportHistoriesList());
        break;
    }
  };
};

export const onCancelTransaction = (type) => {
  return (dispatch) => {
    switch (type) {
      case TRANSACTION.depositHistory:
        return dispatch(onCancelTransactionDeposit());
      case TRANSACTION.withdrawalHistory:
        return dispatch(onCancelTransactionWithdraw());
    }
  };
};

// END COMMON

export const onGetDepositHistoriesList = () => {
  return async (dispatch, getState) => {
    dispatch({ type: TRANSACTION_GET_DEPOSIT_START });
    try {
      const { transaction } = getState();
      const { depositHistory } = transaction;
      const query = {
        paging: 'simplePaginate',
        per_page: depositHistory.perPage,
        page: depositHistory.page + 1
      };
      logd('inspect.deposit.query', query);
      const { data } = await getDepositHistories(query);
      dispatch({ type: TRANSACTION_GET_DEPOSIT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: TRANSACTION_GET_DEPOSIT_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

export const onResetDepositHistoriesList = () => (dispatch) =>
  dispatch({ type: TRANSACTION_RESET_DEPOSIT });

export const onSetSelectedTransaction = (item) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS, payload: { ...item } });
  };
};

export const onClearSelectedTransaction = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_SELECTED_TRANSACTION });
  };
};

export const onCancelTransactionDeposit = () => {
  return async (dispatch, getState) => {
    try {
      const { depositHistory, selectedTransaction } = getState().transaction;
      dispatch({ type: TRANSACTION_CANCEL_DEPOSIT_START });
      const res = await cancelTransactionDeposit(selectedTransaction.data.id);
      batch(() => {
        const types = [
          {
            type: TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS,
            payload: res.data
          },
          // Update canceled deposit item to lists
          {
            type: TRANSACTION_SET_DEPOSIT,
            payload: getTransactionListFromItem(depositHistory.data, res.data)
          }
        ];
        types.forEach(dispatch);
      });
      return true;
    } catch (error) {
      dispatch({ type: TRANSACTION_CANCEL_DEPOSIT_ERROR });
      dispatch(handleErrors(error));
      return false;
    }
  };
};

// END DEPOSIT

export const onGetWithdrawHistoriesList = () => {
  return async (dispatch, getState) => {
    dispatch({ type: TRANSACTION_GET_WITHDRAW_START });
    try {
      const { transaction } = getState();
      const { withdrawalHistory } = transaction;
      const query = {
        paging: 'simplePaginate',
        per_page: withdrawalHistory.perPage,
        page: withdrawalHistory.page + 1
      };
      const { data } = await getWithdrawalHistories(query);
      dispatch({ type: TRANSACTION_GET_WITHDRAW_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: TRANSACTION_GET_WITHDRAW_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

export const onResetWithdrawHistoriesList = () => (dispatch) =>
  dispatch({ type: TRANSACTION_RESET_WITHDRAW });

export const onCancelTransactionWithdraw = () => {
  return async (dispatch, getState) => {
    try {
      const { withdrawalHistory, selectedTransaction } = getState().transaction;
      dispatch({ type: TRANSACTION_CANCEL_WITHDRAW_START });
      const res = await cancelTransactionWithdraw(selectedTransaction.data.id);
      batch(() => {
        const types = [
          {
            type: TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS,
            payload: res.data
          },
          // Update canceled deposit item to lists
          {
            type: TRANSACTION_SET_WITHDRAW,
            payload: getTransactionListFromItem(withdrawalHistory.data, res.data)
          }
        ];
        types.forEach(dispatch);
      });
      return true;
    } catch (error) {
      dispatch({ type: TRANSACTION_CANCEL_WITHDRAW_ERROR });
      dispatch(handleErrors(error));
      return false;
    }
  };
};

// END WITHDRAW

export const onGetGameHistoriesList = () => {
  return async (dispatch, getState) => {
    dispatch({ type: TRANSACTION_GET_GAME_START });
    try {
      const { transaction } = getState();
      const { gameHistory } = transaction;
      const query = {
        exclude_sportsbook: 0,
        paging: 'simplePaginate',
        per_page: gameHistory.perPage,
        order_by: 'bet_time',
        sort_by: 'desc',
        product_types: [PRODUCT_TYPE.SPORTS, PRODUCT_TYPE.SLOTS, PRODUCT_TYPE.LIVE_CASINO],
        page: gameHistory.page + 1
      };
      const { data } = await getGameHistories(query);
      dispatch({ type: TRANSACTION_GET_GAME_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: TRANSACTION_GET_GAME_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

export const onResetGameHistoriesList = () => (dispatch) =>
  dispatch({ type: TRANSACTION_RESET_GAME });

// END GAME

export const onCheckShowGameSportHistories = () => {
  return async (dispatch, getState) => {
    const { query = {} } = Router;
    const { type } = query;
    let result = false;

    if (getState()?.transaction?.showSportsbookTab !== undefined) {
      dispatch({ type: TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE });
      return;
    }

    try {
      dispatch({ type: TRANSACTION_LOADING_CHECK_SPORTSBOOK_START });
      const response = await getGameSportsbookHistories({ page: 1, per_page: 4 });
      result = get(response, 'data.data.length', 0) > 0;
    } catch (error) {
      console.log('onGetGameSportHistories', error);
    }

    // navigate to fisrt tab when no data in sportsbook transaction
    if (!result && type === SLUG_TYPE.sportsbook) {
      dispatch({ type: TRANSACTION_SET_SHOW_SPORTSBOOK_TAB, payload: result });
      // don't dispatch TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE in here
      // because need to show loading
      // it will stop loading in if (getState().transaction.showSportsbookTab !== undefined) {
      routerPush('/account/transactions/deposit');
      return;
    }

    batch(() => {
      dispatch({ type: TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE });
      dispatch({ type: TRANSACTION_SET_SHOW_SPORTSBOOK_TAB, payload: result });
    });
  };
};

export const onGetGameSportHistoriesList = () => {
  return async (dispatch, getState) => {
    dispatch({ type: TRANSACTION_GET_GAME_SPORTSBOOK_START });
    try {
      const { transaction } = getState();
      const { gameHistorySport } = transaction;
      const { perPage, page, filters = {} } = gameHistorySport || {};
      const { end_time, is_settled, start_time } = filters;

      const queryResults =
        (is_settled || DEFAULT_FILTER_SPORT_HISTORY.is_settled) === 1
          ? [
              SPORTS_NAME_TO_CODE.Won,
              SPORTS_NAME_TO_CODE.Lost,
              SPORTS_NAME_TO_CODE.Draw,
              SPORTS_NAME_TO_CODE.Cash_out,
              SPORTS_NAME_TO_CODE.Canceled,
              SPORTS_NAME_TO_CODE.Partial_cashout,
              SPORTS_NAME_TO_CODE.Void,
              SPORTS_NAME_TO_CODE.Half_won,
              SPORTS_NAME_TO_CODE.Half_lose
            ]
          : SPORTS_NAME_TO_CODE.Pending;

      let query = {
        paging: 'simplePaginate',
        order_by: 'bet_time',
        sort_by: 'desc',
        'product_types[]': PRODUCT_TYPE.SPORTS,
        page: page + 1,
        per_page: perPage,
        results: queryResults,
        from: start_time || DEFAULT_FILTER_SPORT_HISTORY.start_time,
        to: end_time || DEFAULT_FILTER_SPORT_HISTORY.end_time
      };

      const { data } = await getGameSportsbookHistories(query);
      dispatch({ type: TRANSACTION_GET_GAME_SPORTSBOOK_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: TRANSACTION_GET_GAME_SPORTSBOOK_ERROR });
      dispatch(handleErrors(error));
    }
  };
};
export const onResetGameSportHistoriesList = () => (dispatch) =>
  dispatch({ type: TRANSACTION_RESET_GAME_SPORTSBOOK });

export const onSetFilterSportHistory = (params) => {
  return (dispatch) => {
    batch(() => {
      dispatch({ type: TRANSACTION_SET_FILTER_SPORT_HISTORIES, payload: params });
      dispatch(onGetGameSportHistoriesList());
    });
  };
};

// END GAME

export const onGetTransactionStatus = (transactionId) => {
  return async (dispatch) => {
    try {
      logd('onGetTransactionStatus.transactionId', transactionId);
      dispatch({ type: TRANSACTION_GET_STATUS_START });
      const res = await getTransactionStatus(transactionId);
      logd('onGetTransactionStatus.res', res);
      dispatch(onGetTransactionStatusSuccess(res.data));
    } catch (error) {
      logd('onGetTransactionStatus.error', error);
      if (error?.data?.error) {
        dispatch(onGetTransactionStatusError(error.data));
      } else {
        dispatch(
          onGetTransactionStatusError({
            error: 'error.title',
            message: <IntlMessages id="error.systemError.description" />
          })
        );
      }
    }
  };
};

export const onGetTransactionStatusSuccess = (data) => {
  return (dispatch) => {
    dispatch({ type: TRANSACTION_GET_STATUS_SUCCESS, payload: data });
  };
};

export const onGetTransactionStatusError = (error) => {
  return (dispatch) => {
    dispatch({ type: TRANSACTION_GET_STATUS_ERROR, payload: error });
  };
};

const getTransactionListFromItem = (transactionList, transactionItem) => {
  const index = transactionList.findIndex((n) => n.id === transactionItem.id);
  let newList;
  if (index > -1) {
    const found = transactionList[index];
    newList = [...transactionList];
    newList[index] = {
      ...found,
      status: transactionItem.status,
      status_name: transactionItem.status_name,
      updated_at: transactionItem.updated_at
    };
  }
  return newList || transactionList;
};

export const setDepositSuccess = (isSuccess = false) => {
  return (dispatch) => {
    dispatch({ type: TRANSACTION_SET_DEPOSIT_SUCCESS, payload: isSuccess });
  };
};

export const PAGING_DEFAULT = {
  page: 1,
  perPage: 24,
  dataTablePerPage: 10
};

export const PAGING_DEFAULT_STATE = {
  page: PAGING_DEFAULT.page,
  lastPage: 0,
  total: 0,
  data: [],
  refreshing: false,
  loading: false,
  sortBy: '',
  orderBy: '',
  filters: {},
  error: null
};

export const FREE_GAME_PER_PAGE = 100;

import { useCurrency } from '@/redux/reducers/Auth';
import { fade, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AmountCrypto from './AmountCrypto';

const useStyles = makeStyles((theme) => ({
  currency: {
    color: fade(theme.palette.text.primary, 0.6),
    marginLeft: 6
  }
}));

const AmountCurrencyCode = ({ value = '', currencyCode = '', showPrefix = false }) => {
  const classes = useStyles();
  const currency = useCurrency();

  return (
    <>
      <AmountCrypto value={value} prefix={showPrefix ? currency?.symbol : showPrefix} />
      {currencyCode && (
        <Typography className={clsx('medium', classes.currency)} variant="caption">
          {currencyCode}
        </Typography>
      )}
    </>
  );
};

export default AmountCurrencyCode;

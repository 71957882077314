import { COLORS } from '@/shared/constants/ColorSets';
import { Typography } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    textAlign: 'center',
    minWidth: 91,
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(2)}px`
  },
  primary: {
    backgroundColor: fade(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.main
  },
  warning: {
    background: fade(COLORS.orange, 0.2),
    color: COLORS.orange
  },
  error: {
    backgroundColor: fade(COLORS.red, 0.2),
    color: COLORS.red
  },
  pending: {
    backgroundColor: fade(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.main
  },
  success: {
    background: fade(COLORS.green, 0.2),
    color: COLORS.green
  },
  default: {
    color: COLORS.base80,
    backgroundColor: COLORS.base0
  },
  productEsports: {
    backgroundColor: fade(COLORS.productEsports, 0.2),
    color: COLORS.productEsports
  },
  ghost: {
    color: COLORS.text,
    border: `1px solid ${COLORS.text}`,
    backgroundColor: 'transparent'
  },
  statusClaimed: {
    backgroundColor: fade(COLORS.warning, 0.2),
    color: COLORS.warning
  },
  jackpots: {
    backgroundColor: fade(COLORS.wwSecondary, 0.2),
    color: COLORS.wwSecondary
  },
  active: {
    backgroundColor: fade(COLORS.success, 0.2),
    color: COLORS.success
  },

  cashout: {
    background: fade(COLORS.yellow, 0.2),
    color: COLORS.yellow
  },
  round: {
    borderRadius: theme.shape.borderRadius / 2
  },
  circle: {
    borderRadius: '50%',
    padding: 32
  }
}));

/**
 * Status badge
 * @param {String} variant: primary | warning | error | success | pending
 * @param {String} type: round | circle
 * @params {*} children
 * @params {*} className
 * @returns React.node
 */

function Status({ variant, type, children, className }) {
  const classes = useStyles();
  return (
    <Typography
      variant="caption"
      component="div"
      className={clsx(classes.root, classes[variant], classes[type], className)}>
      {children}
    </Typography>
  );
}

Status.propTypes = {
  variant: PropTypes.oneOf(['primary', 'warning', 'error', 'pending', 'success']),
  type: PropTypes.oneOf(['round', 'circle']),
  className: PropTypes.any
};
Status.defaultProps = {
  variant: 'primary',
  type: 'round',
  className: ''
};

export default React.memo(Status);

import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const getPaymentOptions = (query = {}) => {
  return API_SERVICE.get(getApiEndpoint('payment-options'), query);
};

export const submitDeposit = (data) => {
  return API_SERVICE.post(getApiEndpoint('deposits'), data);
};

export const getAmountFromVoucher = (payment_options_id, voucher) => {
  return API_SERVICE.get(
    getApiEndpoint(`payment-options/${payment_options_id}/voucher/${voucher}`)
  );
};

export const getPaymentOptionAddress = (id, network) => {
  return API_SERVICE.get(
    getApiEndpoint(`payment-options/${id}/crypto/information?network=${network}`)
  );
};

export const getAddressInfoRate = (payment_options_id) => {
  return API_SERVICE.get(getApiEndpoint(`payment-options/${payment_options_id}/crypto/rate`));
};

export const getDepositPromotionList = (query = {}) => {
  return API_SERVICE.get(getApiEndpoint('deposits/promotions'), query);
};

export const getDepositPromotionDetail = (voucher_code) => {
  return API_SERVICE.get(getApiEndpoint(`promotions/${voucher_code}/find-by-promotion-code`));
};

export const getListCreditCard = (paymentOptionId) => {
  return API_SERVICE.get(getApiEndpoint(`payment-options/${paymentOptionId}/banks`));
};

import { logd } from '@/@crema/utility/Logging';
import * as SentryNext from '@sentry/nextjs';

/**
 * Updates user context information for future events.
 *
 * @param {SentryNext.User} user User context object to be set in the current context. Pass `null` to unset the user.
 */
function setUser(user) {
  try {
    SentryNext.setUser(user);
  } catch (error) {
    logd('Sentry.setUser.error', error);
  }
}

/**
 * Captures an exception event and sends it to Sentry.
 *
 * @param {SentryNext.Exception} exception An exception-like object.
 * @param {import('@sentry/types').CaptureContext} captureContext Additional scope data to apply to exception event.
 * @returns The generated eventId.
 */
function captureException(exception, captureContext) {
  try {
    SentryNext.captureException(exception, captureContext);
  } catch (error) {
    logd('Sentry.captureException.error', error);
  }
}

/**
 * Captures a message event and sends it to Sentry.
 *
 * @param {String} message The message to send to Sentry.
 * @param {CaptureContext | Severity | SeverityLevel} captureContext Define the level of the message.
 * @returns The generated eventId.
 */
function captureMessage(message, captureContext) {
  try {
    SentryNext.captureMessage(message, captureContext);
  } catch (error) {
    logd('Sentry.captureMessage.error', error);
  }
}

const Sentry = {
  setUser,
  captureException,
  captureMessage
};

export default Sentry;

import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loading: {
    width: '20px',
    height: '20px',
    boxSizing: 'border-box',
    border: 'solid 3px transparent',
    borderRadius: '50%',
    WebkitAnimation: 'spin 700ms linear infinite',
    animation: 'spin 700ms linear infinite',
    '&.default': {
      borderTopColor: theme.palette.text.primary,
      borderLeftColor: theme.palette.text.primary
    },
    '&.primary': {
      borderTopColor: theme.palette.primary.main,
      borderLeftColor: theme.palette.primary.main
    },
    '&.secondary': {
      borderTopColor: theme.palette.secondary.main,
      borderLeftColor: theme.palette.secondary.main
    }
  }
}));

const Loading = ({ color }) => {
  const classes = useStyles();
  return <Box className={clsx(classes.loading, color)} />;
};

Loading.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'secondary'])
};

Loading.defaultProps = {
  color: 'primary'
};

export default Loading;

export const TRANSACTIONS_SPORTS_STATUS = {
  1: 'primary',
  2: 'success',
  3: 'error',
  4: 'warning',
  5: 'cashout',
  6: 'cancel',
  7: 'cashout'
};

export const DEFAULT_FILTER_SPORT_HISTORY = {
  is_settled: 0,
  start_time: '',
  end_time: ''
};

export const PRODUCT_TYPE = {
  SPORTS: 1,
  LIVE_CASINO: 2,
  SLOTS: 3,
  VIRTUAL_SPORTS: 4,
  FISH_GAMES: 5
};

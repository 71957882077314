import { fade, withStyles } from '@material-ui/core';

const GlobalButton = withStyles(
  (theme) => {
    const outlined = (color) => {
      return {
        color: theme.palette[color].main,
        border: '1px solid '.concat(fade(theme.palette[color].main, 0.5)),
        '&:hover': {
          border: '1px solid '.concat(theme.palette[color].main),
          backgroundColor: fade(theme.palette[color].main, theme.palette.action.hoverOpacity),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent'
          }
        },
        '&.Mui-disabled': {
          border: '1px solid '.concat(theme.palette.action.disabled)
        }
      };
    };
    const contained = (color) => {
      return {
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        '&:hover': {
          backgroundColor: theme.palette[color].dark,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: theme.palette[color].main
          }
        }
      };
    };
    const text = (color) => {
      return {
        color: theme.palette[color].main,
        '&:hover': {
          backgroundColor: fade(theme.palette[color].main, theme.palette.action.hoverOpacity),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent'
          }
        }
      };
    };
    return {
      '@global': {
        '.MuiButton-root': {
          /**
           * Mui button text
           */
          /**
           * <Button variant="text" className="MuiButton-textSuccess">Text</Button>
           */
          '&.MuiButton-textSuccess': {
            ...text('success')
          },
          /**
           * <Button variant="text" className="MuiButton-textWarning">Text</Button>
           */
          '&.MuiButton-textWarning': {
            ...text('warning')
          },
          /**
           * <Button variant="text" className="MuiButton-textError">Text</Button>
           */
          '&.MuiButton-textError': {
            ...text('error')
          },
          /**
           * <Button variant="text" className="MuiButton-textInfo">Text</Button>
           */
          '&.MuiButton-textInfo': {
            ...text('info')
          },

          /**
           * Mui button contained
           */
          /**
           * <Button variant="contained" className="MuiButton-containedSuccess">Contained</Button>
           */
          '&.MuiButton-containedSuccess': {
            ...contained('success')
          },
          /**
           * <Button variant="contained" className="MuiButton-containedWarning">Contained</Button>
           */
          '&.MuiButton-containedWarning': {
            ...contained('warning')
          },
          /**
           * <Button variant="contained" className="MuiButton-containedError">Contained</Button>
           */
          '&.MuiButton-containedError': {
            ...contained('error')
          },
          /**
           * <Button variant="contained" className="MuiButton-containedInfo">Contained</Button>
           */
          '&.MuiButton-containedInfo': {
            ...contained('info')
          },

          /**
           * Mui button outlined
           */
          /**
           * <Button variant="outlined" className="MuiButton-outlinedSuccess">Outlined</Button>
           */
          '&.MuiButton-outlinedSuccess': {
            ...outlined('success')
          },
          /**
           * <Button variant="outlined" className="MuiButton-outlinedWarning">Outlined</Button>
           */
          '&.MuiButton-outlinedWarning': {
            ...outlined('warning')
          },
          /**
           * <Button variant="outlined" className="MuiButton-outlinedError">Outlined</Button>
           */
          '&.MuiButton-outlinedError': {
            ...outlined('error')
          },
          /**
           * <Button variant="outlined" className="MuiButton-outlinedInfo">Outlined</Button>
           */
          '&.MuiButton-outlinedInfo': {
            ...outlined('info')
          }
        }
      }
    };
  },
  { name: 'GlobalButton' }
)(() => null);

export default GlobalButton;

import { API_SERVICE } from '../..';
import { getApiEndpoint } from '../../Helper';

export const getWallets = () => {
  return API_SERVICE.get(getApiEndpoint('wallets'));
};

export const activeWallet = (currencyCode) => {
  return API_SERVICE.put(getApiEndpoint(`wallets/${currencyCode}/activate`));
};

import Box from '@material-ui/core/Box';
import NoSsr from '@material-ui/core/NoSsr';
import Loading from '../Loading';

const Loader = ({ color, isLoading, pastDelay, timedOut, retry, ...nest }) => {
  return (
    <NoSsr>
      <Box
        height="100%"
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={1299}
        {...nest}>
        <Loading color={color} />
      </Box>
    </NoSsr>
  );
};

export default Loader;

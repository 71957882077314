import { AnalyticsService } from '@/@crema';
import {
  getDepositPromotionDetail,
  getDepositPromotionList,
  getPaymentOptionAddress,
  getPaymentOptions,
  submitDeposit
} from '@/@crema/services/apis/payment';
import { logd } from '@/@crema/utility/Logging';
import { routerPush } from '@/@crema/utility/Route';
import {
  getCMSName,
  getMonth,
  getYear,
  hasOwnProperty,
  isValidArray,
  isValidString
} from '@/@crema/utility/utils';
import {
  PAYMENT_GET_ADDRESS_ERROR,
  PAYMENT_GET_ADDRESS_START,
  PAYMENT_GET_ADDRESS_SUCCESS,
  PAYMENT_GET_ERROR,
  PAYMENT_GET_START,
  PAYMENT_GET_SUCCESS,
  PAYMENT_RESET,
  PAYMENT_SET_DATA,
  PAYMENT_SET_NETWORK,
  PAYMENT_SET_PENDING_DEPOSIT,
  PAYMENT_SET_SELECTED_CURRENCY,
  PAYMENT_SET_SELECTED_PAYMENT
} from '@/shared/constants/ActionTypes';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { FeatureConst } from '@/shared/constants/Feature';
import { captureExceptionFeature } from '@/shared/lib/sentry/captureExceptionFeature';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { getDefaultPayment } from '../reducers/Payment';
import { getListBonusRecommendation } from './Bonus';
import { handleErrors } from './Common';
export const parseVoucherData = (voucher = {}, code) => {
  const bonus_award = voucher?.bonus_award || {};
  return {
    voucher_img: get(voucher, 'cms_property.mobile_image.path', ''),
    name: getCMSName(voucher), // get(voucher, 'cms_property.name', '') || voucher?.name || '',
    expire_at: voucher?.end_at || '',
    bonus_award:
      !isEmpty(voucher) && !isEmpty(bonus_award)
        ? {
            ...bonus_award,
            currencies: get(voucher, `bonus_award.currencies.${code}`, 0)
          }
        : {},
    promotion_code: voucher?.promotion_code || '',
    awarding_condition: {
      min: voucher ? get(voucher, `awarding_condition.${code}.min`, 0) : 0,
      max: voucher ? get(voucher, `awarding_condition.${code}.max`, 0) : 0
    },
    id: voucher?.id || '',
    wagering_requirement: get(voucher, 'wagering_requirement', {}),
    frequency: get(voucher, 'recurrence_limit', {})
  };
};

export const onGetPaymentOptions = () => {
  return async (dispatch) => {
    try {
      let paymentList = [],
        paymentGroup = [];

      dispatch({ type: PAYMENT_GET_START });
      const res = await getPaymentOptions();

      logd('onGetPaymentOptions.res', res?.data);

      if (isValidArray(res?.data?.data)) {
        paymentList = res?.data?.data
          .filter((i) => !!i.cms_property?.group?.code)
          .map((item) => {
            item.name = getCMSName(item);
            return item;
          });
      }

      if (isValidArray(paymentList)) {
        const paymentGroupByCode = groupBy(paymentList, (i) => i.cms_property?.group?.code);
        paymentGroup = Object.keys(paymentGroupByCode)
          .map((key) => {
            const result = {
              name: '',
              code: '',
              order: 0,
              logo: '',
              description: '',
              short_description: '',
              badge_label: '',
              data: paymentGroupByCode[key],
              require_complete_account_profile: false
            };
            const foundObj = paymentList.find((i) => i.cms_property?.group?.code === key);
            if (foundObj) {
              result.name = foundObj.cms_property?.group?.name || '';
              result.code = foundObj.cms_property?.group?.code || '';
              result.logo = foundObj.cms_property?.group?.logo || '';
              result.description = foundObj.cms_property?.group?.description || '';
              result.short_description = foundObj.cms_property?.group?.short_description || '';
              result.secondary_logo = foundObj.cms_property?.group?.option_icons || '';
              result.amount_buttons = foundObj.cms_property?.amount_buttons || [];
              try {
                result.order = parseFloat(foundObj.cms_property?.group.order);
              } catch (err) {
                result.order = paymentList.length - 1;
              }
              result.badge_label = foundObj.cms_property?.group?.badge_label;
              result.require_complete_account_profile =
                foundObj.cms_property?.group?.require_complete_account_profile;
            }
            return result;
          })
          .sort((a, b) => a.order - b.order);
      }

      dispatch({
        type: PAYMENT_SET_DATA,
        payload: { list: paymentList, group: paymentGroup }
      });

      dispatch(onSetSelectedCurrency());

      dispatch({ type: PAYMENT_GET_SUCCESS });
    } catch (error) {
      dispatch({ type: PAYMENT_GET_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

export const getSelectedCurrency = (paymentList, wallets, id) => {
  let selectedCurrency;

  if (id) {
    // get selected payment by id
    selectedCurrency = paymentList.find((i) => String(i.id) === String(id));
  } else {
    // get selected payment by default - BTC | from wallet
    selectedCurrency = getDefaultPayment(paymentList, wallets);
  }

  if (selectedCurrency) {
    // set network as first of blockchain networks
    if (isValidArray(selectedCurrency.blockchain_networks)) {
      selectedCurrency.network = selectedCurrency.blockchain_networks[0]?.network_code || '';
    }
    if (selectedCurrency.currency_code && isValidArray(wallets)) {
      const found = wallets.find((item) => item?.currency?.code === selectedCurrency.currency_code);
      if (found) {
        selectedCurrency = {
          ...found,
          ...selectedCurrency
        };
      }
    }
  } else {
    selectedCurrency = {};
  }

  return selectedCurrency;
};

export const onSetSelectedCurrency = (id, isNewCurrency = false) => {
  return async (dispatch, getState) => {
    const state = getState();
    const paymentList = get(state, 'payment.data.list') || [];
    const wallets = get(state, 'wallets.data') || [];
    const selectedCurrency = getSelectedCurrency(paymentList, wallets, id);

    await dispatch({ type: PAYMENT_SET_SELECTED_CURRENCY, payload: selectedCurrency });

    // get payment address by id & network
    dispatch(onGetPaymentOptionAddress());

    if (isNewCurrency) {
      // Change currency not call onDeactivateAllBonus
      // if (activatedRecommend) {
      //   const key = activatedRecommend.key;
      //   dispatch(onDeactivateAllBonus(key, false));
      // }
      const params = {
        currency_code: selectedCurrency?.currency_code,
        claimable_in_next_deposit: true
      };
      dispatch(getListBonusRecommendation(params, isNewCurrency));
    }
  };
};

export const onSelectedPayment = (item = null) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT_SET_SELECTED_PAYMENT, payload: item });
  };
};

export const onSetPaymentNetWork = (network) => {
  return (dispatch) => {
    dispatch({ type: PAYMENT_SET_NETWORK, payload: network });
    dispatch(onGetPaymentOptionAddress());
  };
};

function getRedirectUrl() {
  try {
    const origin = window.location.origin || APP_CONFIG.siteUrl;
    return `${origin}/account/deposit?transaction_id=:transaction_id`;
  } catch (error) {
    console.error(error);
    return `${APP_CONFIG.siteUrl}/account/deposit?transaction_id=:transaction_id`;
  }
}

/**
 * get deposit payload from form data
 * @param {Object} data
 * @returns {Object} params
 */
function getDepositPayload(data) {
  const params = {};

  const redirect_url = getRedirectUrl();

  Object.assign(params, {
    ...data,
    redirect_urls: {
      payment_pending: redirect_url,
      payment_success: redirect_url,
      payment_failure: redirect_url,
      payment_cancel: redirect_url
    }
  });

  if (hasOwnProperty(data, 'card_number') || hasOwnProperty(data, 'account_id')) {
    const { expiry_date, cvv, card_number, account_id } = data;

    const expiry_month = getMonth(expiry_date);
    const expiry_year = getYear(expiry_date);

    Object.assign(params, {
      cvv: encryptData(cvv)
    });

    if (account_id) {
      // select card
      Object.assign(params, {
        account_id
      });
    } else {
      // new card
      Object.assign(params, {
        card_number: encryptData(card_number),
        expiry_month,
        expiry_year
      });
    }
  }

  if (params && params.expiry_date) {
    delete params.expiry_date;
  }

  return params;
}

export const onSubmitDeposit = (data, actions) => {
  return async (dispatch, getState) => {
    let params;
    try {
      const {
        payment: { selectedPayment }
      } = getState();
      params = getDepositPayload(data);
      logd('DEPOSIT.onSubmitDeposit.data', data);

      AnalyticsService.purchase_processing(
        'account_page',
        selectedPayment?.code,
        params.currency_code,
        params.amount
      );

      const res = await submitDeposit(params);

      logd('DEPOSIT.onSubmitDeposit.data', res.data);

      if (res.data) {
        const { id, provider_response_meta } = res.data;
        if (!provider_response_meta.redirect_output) {
          routerPush(`/account/deposit/`, { transaction_id: id });
        } else {
          dispatch({ type: PAYMENT_SET_PENDING_DEPOSIT, payload: res.data });
        }
      }

      if (actions && typeof actions.resetForm === 'function') {
        actions.resetForm();
      }

      return {
        success: true,
        data: res.data,
        error: null
      };
    } catch (error) {
      logd('DEPOSIT.onSubmitDeposit.error', error);

      captureExceptionFeature(params, error, FeatureConst.deposit);

      dispatch(handleErrors(error, actions));

      return {
        success: false,
        data: null,
        error
      };
    }
  };
};

export const onResetPayment = () => {
  return (dispatch) => {
    dispatch({ type: PAYMENT_RESET });
  };
};

export const onGetDepositPromotionList = () => {
  return async () => {
    try {
      const res = await getDepositPromotionList();
      if (res?.data?.data) return res.data.data;
      return [];
    } catch (error) {
      return [];
    }
  };
};

export const onGetDepositPromotionDetail = (voucher_code) => {
  return async () => {
    try {
      const res = await getDepositPromotionDetail(voucher_code);
      return res;
    } catch (error) {
      return error;
    }
  };
};

export const onGetPaymentOptionAddress = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const id = get(state, 'payment.selectedCurrency.id', null);
      const network = get(state, 'payment.selectedCurrency.network', null);
      const paymentAddress = get(state, 'payment.address.data', {});

      if (!id || !network) {
        return;
      }

      const _id = String(id);

      if (paymentAddress[_id] && paymentAddress[_id][network]) {
        return;
      }
      dispatch({ type: PAYMENT_GET_ADDRESS_START });

      const res = await getPaymentOptionAddress(_id, network);
      if (res.data && isValidString(res.data.address)) {
        set(paymentAddress, `${_id}.${network}`, res.data);
      }
      dispatch({ type: PAYMENT_GET_ADDRESS_SUCCESS, payload: paymentAddress });
      return paymentAddress[_id][network];
    } catch (error) {
      dispatch({ type: PAYMENT_GET_ADDRESS_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

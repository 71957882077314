import { darken, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(4)
  },
  rowLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  left: {
    marginRight: theme.spacing(2),
    color: darken(theme.palette.text.primary, 0.5)
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'end',
    textAlign: 'right',
    wordBreak: 'break-all'
  }
}));

export default useStyles;

import { isValidArray } from '@/@crema/utility/utils';
import {
  APP_FETCH_ERROR,
  APP_FETCH_START,
  APP_FETCH_SUCCESS,
  APP_PUSHER_SUBSCRIBED,
  APP_SET_AFFILIATE_URL,
  APP_SET_CMS_SETTINGS,
  APP_SET_LOCALIZATION,
  APP_SET_MENU,
  APP_SET_REQUIRE_PLAYER_SIGNIN_CAPTCHA,
  APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA,
  APP_SET_SYSTEM_SETTINGS,
  APP_TOGGLE_AGENT_SIGN_UP,
  APP_TOGGLE_HIDE_ZERO_BALANCES,
  APP_TOGGLE_NAV,
  APP_TOGGLE_SELECT_IN_FIAT,
  APP_TOGGLE_VIEW_IN_FIAT
} from '@/shared/constants/ActionTypes';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { LANGUAGES, LOCALES, MIN_AGE } from '@/shared/constants/AppEnums';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { getFeatureToggle } from '@/shared/utils/FeatureToggle';
import { useSelector } from 'react-redux';

const INITIAL_STATE = {
  navCollapsed: false,
  systemSetting: null,
  loading: false,
  menus: [],
  cmsSettings: null,
  hideZeroBalances: false,
  viewInFiat: false,
  openViewSelectFiat: false,
  localization: null,
  pusherSubscribed: false
};

export const AppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_FETCH_START:
      return {
        ...state,
        loading: true
      };
    case APP_FETCH_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case APP_FETCH_ERROR:
      return {
        ...state,
        loading: false
      };
    case APP_SET_MENU:
      return {
        ...state,
        menus: action.payload
      };
    case APP_TOGGLE_NAV:
      localStorage.setItem(LOCAL_STORAGE.nav_collapsed, String(!state.navCollapsed));
      return {
        ...state,
        navCollapsed: !state.navCollapsed
      };
    case APP_TOGGLE_HIDE_ZERO_BALANCES:
      localStorage.setItem(LOCAL_STORAGE.hide_zero_balances, String(!state.hideZeroBalances));
      return {
        ...state,
        hideZeroBalances: !state.hideZeroBalances
      };
    case APP_TOGGLE_VIEW_IN_FIAT:
      localStorage.setItem(LOCAL_STORAGE.view_in_fiat, String(!state.viewInFiat));
      return {
        ...state,
        viewInFiat: !state.viewInFiat
      };
    case APP_SET_CMS_SETTINGS:
      return {
        ...state,
        cmsSettings: action.payload
      };
    case APP_SET_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSetting: action.payload
      };
    case APP_SET_REQUIRE_PLAYER_SIGNIN_CAPTCHA:
      return {
        ...state,
        systemSetting: {
          ...state.systemSetting,
          players: {
            ...state.systemSetting.players,
            require_player_signin_captcha: true
          }
        }
      };
    case APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA:
      return {
        ...state,
        systemSetting: {
          ...state.systemSetting,
          players: {
            ...state.systemSetting.players,
            require_player_signup_captcha: true
          }
        }
      };
    case APP_SET_LOCALIZATION:
      return {
        ...state,
        localization: action.payload
      };
    case APP_PUSHER_SUBSCRIBED:
      return {
        ...state,
        pusherSubscribed: action.payload
      };
    case APP_TOGGLE_SELECT_IN_FIAT:
      return {
        ...state,
        openViewSelectFiat: !state.openViewSelectFiat
      };
    case APP_SET_AFFILIATE_URL:
      return {
        ...state,
        affiliateUrls: action.payload
      };
    case APP_TOGGLE_AGENT_SIGN_UP:
      return {
        ...state,
        popupAgentSignup: !state.popupAgentSignup
      };
    default:
      return state;
  }
};

export const useSystemSetting = () => {
  return useSelector(({ app }) => app.systemSetting);
};

export const useEnableNotification = () => {
  return useSelector(({ app }) => !!app.systemSetting?.enable_notification);
};

export const usePlayerKyc = () => {
  const playerKyc = useSelector(({ app }) => app.systemSetting?.players?.player_kyc);
  return typeof playerKyc === 'boolean' ? playerKyc : false;
};

export const usePlayerKycOptions = () => {
  return useSelector(({ app }) => app.systemSetting?.players?.require_kyc_options);
};

export const usePlayerVip = () => {
  const playerVip = useSelector(({ app }) => app.systemSetting?.players?.player_vip);
  return typeof playerVip === 'boolean' ? playerVip : false;
};

export const useAppLoading = () => {
  return useSelector(({ app }) => app.loading);
};

export const useNavCollapsed = () => {
  return useSelector(({ app }) => app.navCollapsed);
};

export const useHideZeroBalances = () => {
  return useSelector(({ app }) => app.hideZeroBalances);
};

export const useViewInFiat = () => {
  return useSelector(({ app }) => app.viewInFiat);
};

export const useCmsSettings = () => {
  return useSelector(({ app }) => app.cmsSettings);
};

export const useFooter = () => {
  const cmsSettings = useCmsSettings();
  return {
    footer_link: cmsSettings?.footer_link || [],
    footer_section: cmsSettings?.footer_section || []
  };
};

export const useLogo = () => {
  const cmsSettings = useCmsSettings();
  return {
    logo_small: cmsSettings?.logo_small?.path || APP_CONFIG.logo.small,
    logo_large: cmsSettings?.logo_large?.path || APP_CONFIG.logo.large
  };
};

export const useSignupBanner = () => {
  const cmsSettings = useCmsSettings();
  const signup_banner = cmsSettings?.signup_banner?.path || '/images/signup.jpg';
  const signup_banner_mobile = cmsSettings?.signup_banner_mobile?.path || '';
  const signup_banner_tablet = cmsSettings?.signup_banner_tablet?.path || '';
  return {
    signup_banner,
    signup_banner_mobile,
    signup_banner_tablet
  };
};

export const useLoginBanner = () => {
  const cmsSettings = useCmsSettings();
  const login_banner = cmsSettings?.signin_banner?.path || '/images/login.jpg';
  const login_banner_mobile = cmsSettings?.signin_banner_mobile?.path || '';
  const login_banner_tablet = cmsSettings?.signin_banner_tablet?.path || '';
  return {
    login_banner,
    login_banner_mobile,
    login_banner_tablet
  };
};

export const useLanguages = () => {
  let languages = LANGUAGES;
  const systemLanguages = useSelector(({ app }) => app.systemSetting?.languages);
  if (isValidArray(systemLanguages)) {
    languages = systemLanguages
      .filter((i) => i.enable)
      .map((item) => {
        item.flag = LANGUAGES.find((language) => language.code === item.code)?.flag;
        return item;
      });
  }
  return languages;
};

export const useDefaultLanguage = () => {
  const languages = useLanguages();
  let defaultLanguage = LOCALES.EN;
  if (isValidArray(languages)) {
    defaultLanguage = languages.find((item) => item.is_default)?.code;
  }
  if (!defaultLanguage && isValidArray(languages)) {
    defaultLanguage = languages[0]?.code;
  }
  return defaultLanguage;
};

export const useMenus = (typeMenu) => {
  return useSelector(({ app }) => app.menus)?.filter((item) => item.type === typeMenu) || [];
};

export const useEnablePlayerEmailVerification = () => {
  const enableOnlySendToVerifiedEmail = useSelector(
    ({ app }) => !!app?.systemSetting?.players?.enable_player_email_verification
  );
  return enableOnlySendToVerifiedEmail;
};

export const useRequireEmailVerificationWithDrawal = () => {
  const requireEmailVerificationWithDrawal = useSelector(
    ({ app }) => app?.systemSetting?.players?.require_email_verification_options?.withdraw
  );
  return requireEmailVerificationWithDrawal;
};

export const useAgeRestriction = () => {
  return useSelector(({ app }) => app?.systemSetting?.age_restriction || MIN_AGE);
};

export const useEnableFavoriteGame = () => {
  return useSelector(
    ({ app, auth }) => !!app.systemSetting?.players?.enable_player_favorite_game && auth?.isLoggedIn
  );
};

export const useFeatureToggle = () => {
  const systemSetting = useSelector(({ app }) => app?.systemSetting);
  return getFeatureToggle(systemSetting);
};

export const usePusherAppKey = () => {
  return useSelector(({ app }) => app?.systemSetting?.broadcast_provider?.app_key);
};

export const useEnableCashback = () => {
  return useSelector(({ app }) => !!app?.systemSetting?.players?.enable_player_vip_progress);
};

export const useCaptchaKey = () => {
  return useSelector(({ app }) => {
    // return '1x00000000000000000000AA'; // Always passes visible
    // return '2x00000000000000000000AB'; // Always blocks visible
    // return '1x00000000000000000000BB'; // Always passes invisible
    // return '2x00000000000000000000BB'; // Always blocks invisible
    // return '3x00000000000000000000FF'; // Forces an interactive challenge	visible
    return app?.systemSetting?.captcha_provider?.site_key;
  });
};

export const usePusherSubscribed = () => {
  return useSelector(({ app }) => app.pusherSubscribed);
};

export const useOpenViewSelectFiat = () => {
  return useSelector(({ app }) => app.openViewSelectFiat);
};

export const useCmsSettingsHtmlHead = () => {
  return useSelector(({ app }) => app?.cmsSettings?.html_head);
};

export const useCmsSettingsHtmlBody = () => {
  return useSelector(({ app }) => app?.cmsSettings?.html_body);
};

export const useCurrencies = () => {
  return useSelector(({ app }) => app?.systemSetting?.agent_usable_currencies);
};

export const useAffiliateUrl = () => {
  return useSelector(({ app }) => app?.affiliateUrls);
};

export const usePopupAgentSignup = () => {
  return useSelector(({ app }) => app.popupAgentSignup);
};

export const useFavicon = () => {
  return useSelector(({ app }) => app.cmsSettings?.favicon?.path || APP_CONFIG.icons.favicon);
};

const MEASUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID;

export const COOKIES = {
  // next locale
  next_locale: 'NEXT_LOCALE',

  // user token
  jwt_auth: 'jwt_auth',

  uuid: 'uuid',
  username: 'username',

  // store agent code from coming url
  agent_code: 'agent_code',
  payload: 'payload',
  theme_mode: 'theme_mode',
  prefers_color_scheme: 'prefers_color_scheme',
  currency: 'currency',
  crm_session_id: 'crm_session_id',

  // GA
  _ga: '_ga',
  _ga_measurement_id: MEASUREMENT_ID,

  query_strings: 'query_strings',
  affiliate_id: 'affiliate_id',

  // Fast track
  tf: 'tf'
};

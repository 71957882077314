import { isValidString } from '@/@crema/utility/utils';

function isNotNull(value) {
  return value != null;
}

export function isIP(value) {
  const regex = {
    ipv4: /^(?:(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/,
    ipv6: /^((?=.*::)(?!.*::.+::)(::)?([\dA-F]{1,4}:(:|\b)|){5}|([\dA-F]{1,4}:){6})((([\dA-F]{1,4}((?!\3)::|:\b|$))|(?!\2\3)){2}|(((2[0-4]|1\d|[1-9])?\d|25[0-5])\.?\b){4})$/i
  };
  return (isNotNull(value) && regex.ipv4.test(value)) || regex.ipv6.test(value);
}

/**
 * Parse x-forwarded-for headers.
 *
 * @param {string} value - The value to be parsed.
 * @return {string|null} First known IP address, if any.
 */
function getClientIpFromXForwardedFor(value) {
  if (!isNotNull(value)) {
    return null;
  }

  if (!isValidString(value)) {
    throw new TypeError(`Expected a string, got "${typeof value}"`);
  }

  // x-forwarded-for may return multiple IP addresses in the format:
  // "client IP, proxy 1 IP, proxy 2 IP"
  // Therefore, the right-most IP address is the IP address of the most recent proxy
  // and the left-most IP address is the IP address of the originating client.
  // source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For
  // Azure Web App's also adds a port for some reason, so we'll only use the first part (the IP)
  const forwardedIps = value.split(',').map((e) => {
    const ip = e.trim();
    if (ip.includes(':')) {
      const splitted = ip.split(':');
      // make sure we only use this if it's ipv4 (ip:port)
      if (splitted.length === 2) {
        return splitted[0];
      }
    }
    return ip;
  });

  // Sometimes IP addresses in this header can be 'unknown' (http://stackoverflow.com/a/11285650).
  // Therefore taking the right-most IP address that is not unknown
  // A Squid configuration directive can also set the value to "unknown" (http://www.squid-cache.org/Doc/config/forwarded_for/)
  for (let i = 0; i < forwardedIps.length; i++) {
    if (isIP(forwardedIps[i])) {
      return forwardedIps[i];
    }
  }

  // If no value in the split list is an ip, return null
  return null;
}

export function getClientIp(req) {
  try {
    // Server is probably behind a proxy.
    if (req.headers) {
      // Standard headers used by Amazon EC2, Heroku, and others.
      if (isIP(req.headers['x-client-ip'])) {
        return req.headers['x-client-ip'];
      }

      // Load-balancers (AWS ELB) or proxies.
      const xForwardedFor = getClientIpFromXForwardedFor(req.headers['x-forwarded-for']);
      if (isIP(xForwardedFor)) {
        return xForwardedFor;
      }

      // Cloudflare.
      // @see https://support.cloudflare.com/hc/en-us/articles/200170986-How-does-Cloudflare-handle-HTTP-Request-headers-
      // CF-Connecting-IP - applied to every request to the origin.
      if (isIP(req.headers['cf-connecting-ip'])) {
        return req.headers['cf-connecting-ip'];
      }

      // Fastly and Firebase hosting header (When forwared to cloud function)
      if (isIP(req.headers['fastly-client-ip'])) {
        return req.headers['fastly-client-ip'];
      }

      // Akamai and Cloudflare: True-Client-IP.
      if (isIP(req.headers['true-client-ip'])) {
        return req.headers['true-client-ip'];
      }

      // Default nginx proxy/fcgi; alternative to x-forwarded-for, used by some proxies.
      if (isIP(req.headers['x-real-ip'])) {
        return req.headers['x-real-ip'];
      }

      // (Rackspace LB and Riverbed's Stingray)
      // http://www.rackspace.com/knowledge_center/article/controlling-access-to-linux-cloud-sites-based-on-the-client-ip-address
      // https://splash.riverbed.com/docs/DOC-1926
      if (isIP(req.headers['x-cluster-client-ip'])) {
        return req.headers['x-cluster-client-ip'];
      }

      if (isIP(req.headers['x-forwarded'])) {
        return req.headers['x-forwarded'];
      }

      if (isIP(req.headers['forwarded-for'])) {
        return req.headers['forwarded-for'];
      }

      if (isIP(req.headers.forwarded)) {
        return req.headers.forwarded;
      }

      // Google Cloud App Engine
      // https://cloud.google.com/appengine/docs/standard/go/reference/request-response-headers

      if (isIP(req.headers['x-appengine-user-ip'])) {
        return req.headers['x-appengine-user-ip'];
      }
    }

    // Remote address checks.
    // Deprecated
    if (isNotNull(req.connection)) {
      if (isIP(req.connection.remoteAddress)) {
        return req.connection.remoteAddress;
      }
      if (isNotNull(req.connection.socket) && isIP(req.connection.socket.remoteAddress)) {
        return req.connection.socket.remoteAddress;
      }
    }

    if (isNotNull(req.socket) && isIP(req.socket.remoteAddress)) {
      return req.socket.remoteAddress;
    }

    if (isNotNull(req.info) && isIP(req.info.remoteAddress)) {
      return req.info.remoteAddress;
    }

    // AWS Api Gateway + Lambda
    if (
      isNotNull(req.requestContext) &&
      isNotNull(req.requestContext.identity) &&
      isIP(req.requestContext.identity.sourceIp)
    ) {
      return req.requestContext.identity.sourceIp;
    }

    // Cloudflare fallback
    // https://blog.cloudflare.com/eliminating-the-last-reasons-to-not-enable-ipv6/#introducingpseudoipv4
    if (req.headers) {
      if (isIP(req.headers['Cf-Pseudo-IPv4'])) {
        return req.headers['Cf-Pseudo-IPv4'];
      }
    }

    // Fastify https://www.fastify.io/docs/latest/Reference/Request/
    if (isNotNull(req.raw)) {
      return getClientIp(req.raw);
    }

    return null;
  } catch (error) {
    return null;
  }
}

import { API_SERVICE } from '@/@crema/services';
import { getSetting } from '@/@crema/services/apis/app';
import { logd, logServer } from '@/@crema/utility/Logging';
import { getQueryParams } from '@/@crema/utility/Route';
import { getCookie, removeCookie, setCookie } from '@/@crema/utility/Session';
import {
  getDocumentReferrer,
  getQueryStrings,
  isValidArray,
  isValidString
} from '@/@crema/utility/utils';
import {
  APP_FETCH_ERROR,
  APP_FETCH_START,
  APP_FETCH_SUCCESS,
  APP_PUSHER_SUBSCRIBED,
  APP_SET_AFFILIATE_URL,
  APP_SET_CMS_SETTINGS,
  APP_SET_MENU,
  APP_SET_SYSTEM_SETTINGS,
  APP_TOGGLE_AGENT_SIGN_UP,
  APP_TOGGLE_HIDE_ZERO_BALANCES,
  APP_TOGGLE_NAV,
  APP_TOGGLE_SELECT_IN_FIAT,
  APP_TOGGLE_VIEW_IN_FIAT,
  AUTH_SET_USER
} from '@/shared/constants/ActionTypes';
import { COOKIES } from '@/shared/constants/CookieConst';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { STATUS_CODES } from '@/shared/constants/StatusCodes';
import Router from 'next/router';
import { onGetProfile, onSignOut, setJWTAuth } from './Auth';
import { toggleBonusRecommend } from './Bonus';
import { handleErrors } from './Common';

export const getQueryUrlParamsByKey = (key, query) => {
  const _query = query || getQueryParams(Router.locales) || {};

  if ((key === 'aff' || key === 'raf') && _query?.aff && _query?.raf) {
    localStorage.removeItem(LOCAL_STORAGE.raf_code);
    removeCookie(COOKIES.agent_code);
    return '';
  }

  return _query?.[key];
};

export const handleControlAffAndRafCode = (key, value, query) => {
  const isRafCode = key === LOCAL_STORAGE.raf_code;
  const isAffCode = key === COOKIES.agent_code;

  if (query?.aff && query?.raf) {
    localStorage.removeItem(key);
    removeCookie(COOKIES.agent_code);
    return;
  }

  if (!isValidString(value)) {
    isRafCode && localStorage.removeItem(LOCAL_STORAGE.raf_code);
    isAffCode && removeCookie(COOKIES.agent_code);
    return;
  }

  if (isAffCode) {
    localStorage.removeItem(LOCAL_STORAGE.raf_code);
    setCookie(COOKIES.agent_code, value);
  }
  if (isRafCode) {
    removeCookie(COOKIES.agent_code);
    localStorage.setItem(LOCAL_STORAGE.raf_code, value);
  }
};

export const onStartUp = (router) => {
  return async (dispatch, getState) => {
    try {
      const { app } = getState();
      const { jwt_auth, ...routerQuery } = router.query;

      API_SERVICE.handleRequest(undefined, router);

      dispatch({ type: APP_FETCH_START });

      if (isValidString(jwt_auth)) {
        setJWTAuth(jwt_auth);
      }

      // remove jwt_auth query param
      if (jwt_auth || jwt_auth === '') {
        router.replace({
          query: { ...routerQuery }
        });
      }

      const token = getCookie(COOKIES.jwt_auth);
      if (token) {
        // update isLoggedIn
        dispatch({
          type: AUTH_SET_USER,
          payload: null
        });

        if (app.systemSetting && app.systemSetting.country_restricted) {
          dispatch(onSignOut());
          return;
        }
      }

      const navCollapsed = localStorage.getItem(LOCAL_STORAGE.nav_collapsed);
      const hideZeroBalances = localStorage.getItem(LOCAL_STORAGE.hide_zero_balances);
      const viewInFiat = localStorage.getItem(LOCAL_STORAGE.view_in_fiat);
      const switchBonus = localStorage.getItem(LOCAL_STORAGE.switch_bonus);
      if (switchBonus === 'true') {
        dispatch(toggleBonusRecommend());
      }

      if (typeof navCollapsed === 'string') {
        if (navCollapsed === 'true') {
          dispatch(toggleNavCollapsed());
        }
      }

      if (typeof hideZeroBalances === 'string') {
        if (hideZeroBalances === 'true') {
          dispatch(toggleHideZeroBalances());
        }
      }

      if (typeof viewInFiat === 'string') {
        if (viewInFiat === 'true') {
          dispatch(toggleViewInFiat());
        }
      }

      if (token) {
        dispatch(onGetProfile(true, false, true, true));
      } else {
        // handle agent code, payload
        const queries = [
          [COOKIES.agent_code, router.query.aff],
          [COOKIES.payload, router.query.payload]
        ];
        queries.forEach(([key, value]) => {
          if (key === COOKIES.agent_code && isValidString(value)) {
            handleControlAffAndRafCode(COOKIES[key], value, router.query);
            return;
          }
          if (isValidString(value)) {
            setCookie(COOKIES[key], value);
          }
        });

        // handle query strings
        const [firstQueryStrings] = getQueryStrings();
        if (firstQueryStrings?.value && !firstQueryStrings.isFromCookie) {
          setCookie(COOKIES.query_strings, firstQueryStrings?.value, 30);
        }

        // handle referrer code
        const [firstReferrer] = getDocumentReferrer();
        if (firstReferrer?.value && !firstReferrer.isLocalStorage) {
          localStorage.setItem(LOCAL_STORAGE.document_referrer, firstReferrer.value);
        }
      }

      if (router.query?.overlay === 'affiliate-registration') {
        dispatch(onToggleAgentSignup());
      }

      if (
        window &&
        window.location &&
        window.location.pathname.startsWith(`/${router.defaultLocale}/`)
      ) {
        router.replace(router.pathname, router.asPath, { locale: router.locale });
      }

      dispatch({ type: APP_FETCH_SUCCESS });
    } catch (error) {
      dispatch(handleErrors(error));
      dispatch({ type: APP_FETCH_ERROR });
    }
  };
};

export const toggleNavCollapsed = () => {
  return (dispatch) => dispatch({ type: APP_TOGGLE_NAV });
};

export const toggleHideZeroBalances = () => {
  return (dispatch) => dispatch({ type: APP_TOGGLE_HIDE_ZERO_BALANCES });
};

export const toggleViewInFiat = () => {
  return (dispatch) => dispatch({ type: APP_TOGGLE_VIEW_IN_FIAT });
};

export const onGetSetting = (ctx) => {
  return async (dispatch) => {
    try {
      const res = await getSetting();

      if (res.data) {
        if (res.data.cms) {
          dispatch(onGetCMSSettings(res.data.cms.setting));
          dispatch(onGetMenu(res.data.cms.menu, res.data.system));
        }

        if (res.data.system) {
          dispatch({
            type: APP_SET_SYSTEM_SETTINGS,
            payload: res.data.system
          });
        }
      }

      // update isLoggedIn at server side
      if (ctx?.req) {
        const jwt_auth = getCookie(COOKIES.jwt_auth, ctx?.req?.headers);
        if (jwt_auth) {
          dispatch({
            type: AUTH_SET_USER,
            payload: null
          });
        }
      }
      const AFFILIATE_URL = res.data.cms?.setting?.advanced_config?.affiliateUrl || '';
      const payload = {
        affiliateUrl: AFFILIATE_URL,
        affiliateUrlSignIn: `${AFFILIATE_URL}/signin`,
        affiliateUrlForgotPw: `${AFFILIATE_URL}/forgot-password`,
        affiliateUrlSignInSuccess: `${AFFILIATE_URL}/?token=`
      };
      dispatch({
        type: APP_SET_AFFILIATE_URL,
        payload
      });

      return res.data;
    } catch (error) {
      logServer('onGetSystemSetting.error', { status: error.status, data: error.data });
      return {
        statusCode: STATUS_CODES.SERVER_ERROR
      };
    }
  };
};

export const onGetCMSSettings = (data) => {
  return (dispatch) => {
    if (data) {
      dispatch({ type: APP_SET_CMS_SETTINGS, payload: data });
    }
  };
};

export const onGetMenu = (menu, systemSetting) => {
  return (dispatch) => {
    try {
      const kycEnabled = !!systemSetting?.players?.player_kyc;
      const favGameEnabled = !!systemSetting?.players?.enable_player_favorite_game;
      const cashbackEnabled = !!systemSetting?.players?.enable_player_vip_progress;

      if (menu && menu.data && isValidArray(menu.data)) {
        let sortedData = menu.data.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }
          return 0;
        });

        const newMenu = sortedData.map((item) => {
          if (isValidArray(item.items)) {
            if (!kycEnabled) {
              item.items = item.items.filter((subItem) => subItem.value.code !== 'kyc');
            }
            if (!favGameEnabled) {
              item.items = item.items.filter((subItem) => subItem.value.code !== 'favorite-games');
            }
            if (!cashbackEnabled) {
              item.items = item.items.filter((subItem) => subItem.value.code !== 'cashback');
            }
          }
          return item;
        });

        dispatch({ type: APP_SET_MENU, payload: newMenu });
      }
    } catch (error) {
      logd('onGetMenu.error', error);
    }
  };
};

export const setPusherSubscribed = (subscribe) => {
  return (dispatch) => dispatch({ type: APP_PUSHER_SUBSCRIBED, payload: subscribe });
};

export const toggleViewSelectFiat = () => {
  return (dispatch) => dispatch({ type: APP_TOGGLE_SELECT_IN_FIAT });
};

export const onToggleAgentSignup = () => {
  return (dispatch) => dispatch({ type: APP_TOGGLE_AGENT_SIGN_UP });
};

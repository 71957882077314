import { isValidString } from '@/@crema/utility/utils';
import MuiIcon, { IconProps } from '@material-ui/core/Icon';
import clsx from 'clsx';
import PropTypes from 'prop-types';

/**
 *
 * @param {IconProps} props
 */
export default function Icon({ name, className = '', color, fontSize, style = {} }) {
  if (!isValidString(name)) {
    return null;
  }

  let classNames = clsx(`icon icon-${name}`, className);
  return <MuiIcon color={color} fontSize={fontSize} className={classNames} style={style} />;
}

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string
};

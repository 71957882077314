export const CryptoIcons = {
  $PAC: {
    code: '$PAC',
    name: 'PACcoin',
    icon: '/assets/icons/crypto/$pac.png'
  },
  '0XBTC': {
    code: '0XBTC',
    name: '0xBitcoin',
    icon: '/assets/icons/crypto/0xbtc.png'
  },
  '1INCH': {
    code: '1INCH',
    name: '1inch Network',
    icon: '/assets/icons/crypto/1inch.png'
  },
  '2GIVE': {
    code: '2GIVE',
    name: '2Give',
    icon: '/assets/icons/crypto/2give.png'
  },
  AAVE: {
    code: 'AAVE',
    name: 'AAVE',
    icon: '/assets/icons/crypto/aave.png'
  },
  ABT: {
    code: 'ABT',
    name: 'Arcblock',
    icon: '/assets/icons/crypto/abt.png'
  },
  ACT: {
    code: 'ACT',
    name: 'Achain',
    icon: '/assets/icons/crypto/act.png'
  },
  ACTN: {
    code: 'ACTN',
    name: 'Action Coin',
    icon: '/assets/icons/crypto/actn.png'
  },
  ADA: {
    code: 'ADA',
    name: 'Cardano',
    icon: '/assets/icons/crypto/ada.png'
  },
  ADD: {
    code: 'ADD',
    name: 'ADD Token',
    icon: '/assets/icons/crypto/add.png'
  },
  ADX: {
    code: 'ADX',
    name: 'AdEx',
    icon: '/assets/icons/crypto/adx.png'
  },
  AE: {
    code: 'AE',
    name: 'Aeternity',
    icon: '/assets/icons/crypto/ae.png'
  },
  AEON: {
    code: 'AEON',
    name: 'Aeon',
    icon: '/assets/icons/crypto/aeon.png'
  },
  AEUR: {
    code: 'AEUR',
    name: 'Augmint Euro Token',
    icon: '/assets/icons/crypto/aeur.png'
  },
  AGI: {
    code: 'AGI',
    name: 'SingularityNET',
    icon: '/assets/icons/crypto/agi.png'
  },
  AGRS: {
    code: 'AGRS',
    name: 'Agoras Tauchain',
    icon: '/assets/icons/crypto/agrs.png'
  },
  AION: {
    code: 'AION',
    name: 'Aion',
    icon: '/assets/icons/crypto/aion.png'
  },
  ALGO: {
    code: 'ALGO',
    name: 'Algorand',
    icon: '/assets/icons/crypto/algo.png'
  },
  AMB: {
    code: 'AMB',
    name: 'Ambrosus',
    icon: '/assets/icons/crypto/amb.png'
  },
  AMP: {
    code: 'AMP',
    name: 'HyperSpace (Synereo)',
    icon: '/assets/icons/crypto/amp.png'
  },
  AMPL: {
    code: 'AMPL',
    name: 'Ampleforth',
    icon: '/assets/icons/crypto/ampl.png'
  },
  ANKR: {
    code: 'ANKR',
    name: 'Ankr Network',
    icon: '/assets/icons/crypto/ankr.png'
  },
  ANT: {
    code: 'ANT',
    name: 'Aragon',
    icon: '/assets/icons/crypto/ant.png'
  },
  APE: {
    code: 'APE',
    name: 'ApeCoin',
    icon: '/assets/icons/crypto/ape.png'
  },
  APPC: {
    code: 'APPC',
    name: 'AppCoins',
    icon: '/assets/icons/crypto/appc.png'
  },
  ARDR: {
    code: 'ARDR',
    name: 'Ardor',
    icon: '/assets/icons/crypto/ardr.png'
  },
  ARG: {
    code: 'ARG',
    name: 'Argentum',
    icon: '/assets/icons/crypto/arg.png'
  },
  ARK: {
    code: 'ARK',
    name: 'Ark',
    icon: '/assets/icons/crypto/ark.png'
  },
  ARN: {
    code: 'ARN',
    name: 'Aeron',
    icon: '/assets/icons/crypto/arn.png'
  },
  ARNX: {
    code: 'ARNX',
    name: 'Aeron',
    icon: '/assets/icons/crypto/arnx.png'
  },
  ARY: {
    code: 'ARY',
    name: 'Block Array',
    icon: '/assets/icons/crypto/ary.png'
  },
  AST: {
    code: 'AST',
    name: 'AirSwap',
    icon: '/assets/icons/crypto/ast.png'
  },
  ATLAS: {
    code: 'ATLAS',
    name: 'Star Atlas',
    icon: '/assets/icons/crypto/atlas.png'
  },
  ATM: {
    code: 'ATM',
    name: 'ATMChain',
    icon: '/assets/icons/crypto/atm.png'
  },
  ATOM: {
    code: 'ATOM',
    name: 'Cosmos',
    icon: '/assets/icons/crypto/atom.png'
  },
  AUDR: {
    code: 'AUDR',
    name: 'AUDRamp',
    icon: '/assets/icons/crypto/audr.png'
  },
  AURY: {
    code: 'AURY',
    name: 'Aurory',
    icon: '/assets/icons/crypto/aury.png'
  },
  AUTO: {
    code: 'AUTO',
    name: 'Cube',
    icon: '/assets/icons/crypto/auto.png'
  },
  AVAX: {
    code: 'AVAX',
    name: 'Avalanche',
    icon: '/assets/icons/crypto/avax.png'
  },
  AYWA: {
    code: 'AYWA',
    name: 'Aywa',
    icon: '/assets/icons/crypto/aywa.png'
  },
  BAB: {
    code: 'BAB',
    name: 'Bitcoin Cash ABC',
    icon: '/assets/icons/crypto/bab.png'
  },
  BAL: {
    code: 'BAL',
    name: 'Balancer',
    icon: '/assets/icons/crypto/bal.png'
  },
  BAND: {
    code: 'BAND',
    name: 'Band Protocol',
    icon: '/assets/icons/crypto/band.png'
  },
  BAT: {
    code: 'BAT',
    name: 'Basic Attention Token',
    icon: '/assets/icons/crypto/bat.png'
  },
  BAY: {
    code: 'BAY',
    name: 'BitBay',
    icon: '/assets/icons/crypto/bay.png'
  },
  BCBC: {
    code: 'BCBC',
    name: 'BCBC',
    icon: '/assets/icons/crypto/bcbc.png'
  },
  BCC: {
    code: 'BCC',
    name: 'BCC',
    icon: '/assets/icons/crypto/bcc.png'
  },
  BCD: {
    code: 'BCD',
    name: 'Bitcoin Diamond',
    icon: '/assets/icons/crypto/bcd.png'
  },
  BCH: {
    code: 'BCH',
    name: 'Bitcoin Cash',
    icon: '/assets/icons/crypto/bch.png'
  },
  BCIO: {
    code: 'BCIO',
    name: 'Blockchain.io',
    icon: '/assets/icons/crypto/bcio.png'
  },
  BCN: {
    code: 'BCN',
    name: 'Bytecoin',
    icon: '/assets/icons/crypto/bcn.png'
  },
  BCO: {
    code: 'BCO',
    name: 'BananaCoin',
    icon: '/assets/icons/crypto/bco.png'
  },
  BCPT: {
    code: 'BCPT',
    name: 'BlockMason Credit Protocol',
    icon: '/assets/icons/crypto/bcpt.png'
  },
  BDL: {
    code: 'BDL',
    name: 'Bitdeal',
    icon: '/assets/icons/crypto/bdl.png'
  },
  BEAM: {
    code: 'BEAM',
    name: 'Beam',
    icon: '/assets/icons/crypto/beam.png'
  },
  BELA: {
    code: 'BELA',
    name: 'Belacoin',
    icon: '/assets/icons/crypto/bela.png'
  },
  BIX: {
    code: 'BIX',
    name: 'Bibox Token',
    icon: '/assets/icons/crypto/bix.png'
  },
  BLCN: {
    code: 'BLCN',
    name: 'BLCN',
    icon: '/assets/icons/crypto/blcn.png'
  },
  BLK: {
    code: 'BLK',
    name: 'BlackCoin',
    icon: '/assets/icons/crypto/blk.png'
  },
  BLOCK: {
    code: 'BLOCK',
    name: 'Blocknet',
    icon: '/assets/icons/crypto/block.png'
  },
  BLZ: {
    code: 'BLZ',
    name: 'Blazecoin',
    icon: '/assets/icons/crypto/blz.png'
  },
  BNB: {
    code: 'BNB',
    name: 'Binance Coin',
    icon: '/assets/icons/crypto/bnb.png'
  },
  BNT: {
    code: 'BNT',
    name: 'Bancor Network Token',
    icon: '/assets/icons/crypto/bnt.png'
  },
  BNTY: {
    code: 'BNTY',
    name: 'Bounty0x',
    icon: '/assets/icons/crypto/bnty.png'
  },
  BOOTY: {
    code: 'BOOTY',
    name: 'Booty',
    icon: '/assets/icons/crypto/booty.png'
  },
  BOS: {
    code: 'BOS',
    name: 'BOScoin',
    icon: '/assets/icons/crypto/bos.png'
  },
  BPT: {
    code: 'BPT',
    name: 'Blockport',
    icon: '/assets/icons/crypto/bpt.png'
  },
  BQ: {
    code: 'BQ',
    name: 'bitqy',
    icon: '/assets/icons/crypto/bq.png'
  },
  BRD: {
    code: 'BRD',
    name: 'Bread',
    icon: '/assets/icons/crypto/brd.png'
  },
  BSD: {
    code: 'BSD',
    name: 'BitSend',
    icon: '/assets/icons/crypto/bsd.png'
  },
  BSV: {
    code: 'BSV',
    name: 'BitcoinSV',
    icon: '/assets/icons/crypto/bsv.png'
  },
  BTC: {
    code: 'BTC',
    name: 'Bitcoin',
    icon: '/assets/icons/crypto/btc.png'
  },
  BTCD: {
    code: 'BTCD',
    name: 'BitcoinDark',
    icon: '/assets/icons/crypto/btcd.png'
  },
  BTCH: {
    code: 'BTCH',
    name: 'Bitcoin Hush',
    icon: '/assets/icons/crypto/btch.png'
  },
  BTCP: {
    code: 'BTCP',
    name: 'Bitcoin Private',
    icon: '/assets/icons/crypto/btcp.png'
  },
  BTCZ: {
    code: 'BTCZ',
    name: 'BitcoinZ',
    icon: '/assets/icons/crypto/btcz.png'
  },
  BTDX: {
    code: 'BTDX',
    name: 'Bitcloud',
    icon: '/assets/icons/crypto/btdx.png'
  },
  BTG: {
    code: 'BTG',
    name: 'Bitcoin Gold',
    icon: '/assets/icons/crypto/btg.png'
  },
  BTM: {
    code: 'BTM',
    name: 'Bytom',
    icon: '/assets/icons/crypto/btm.png'
  },
  BTS: {
    code: 'BTS',
    name: 'BitShares',
    icon: '/assets/icons/crypto/bts.png'
  },
  BTT: {
    code: 'BTT',
    name: 'BitTorrent',
    icon: '/assets/icons/crypto/btt.png'
  },
  BTX: {
    code: 'BTX',
    name: 'Bitcore',
    icon: '/assets/icons/crypto/btx.png'
  },
  BURST: {
    code: 'BURST',
    name: 'Burst',
    icon: '/assets/icons/crypto/burst.png'
  },
  BUSD: {
    code: 'BUSD',
    name: 'BUSD',
    icon: '/assets/icons/crypto/busd.png'
  },
  BZE: {
    code: 'BZE',
    name: 'BZEdge',
    icon: '/assets/icons/crypto/bze.png'
  },
  CALL: {
    code: 'CALL',
    name: 'Capital',
    icon: '/assets/icons/crypto/call.png'
  },
  CC: {
    code: 'CC',
    name: 'CoinCollect',
    icon: '/assets/icons/crypto/cc.png'
  },
  CDN: {
    code: 'CDN',
    name: 'Canada eCoin',
    icon: '/assets/icons/crypto/cdn.png'
  },
  CDT: {
    code: 'CDT',
    name: 'Blox',
    icon: '/assets/icons/crypto/cdt.png'
  },
  CHAIN: {
    code: 'CHAIN',
    name: 'Chainmakers',
    icon: '/assets/icons/crypto/chain.png'
  },
  CHAT: {
    code: 'CHAT',
    name: 'ChatCoin',
    icon: '/assets/icons/crypto/chat.png'
  },
  CHIPS: {
    code: 'CHIPS',
    name: 'CHIPS',
    icon: '/assets/icons/crypto/chips.png'
  },
  CHSB: {
    code: 'CHSB',
    name: 'SwissBorg',
    icon: '/assets/icons/crypto/chsb.png'
  },
  CHZ: {
    code: 'CHZ',
    name: 'Chiliz',
    icon: '/assets/icons/crypto/chz.png'
  },
  CIX: {
    code: 'CIX',
    name: 'Cryptonetix',
    icon: '/assets/icons/crypto/cix.png'
  },
  CLAM: {
    code: 'CLAM',
    name: 'Clams',
    icon: '/assets/icons/crypto/clam.png'
  },
  CLOAK: {
    code: 'CLOAK',
    name: 'Cloakcoin',
    icon: '/assets/icons/crypto/cloak.png'
  },
  CMM: {
    code: 'CMM',
    name: 'Commercium',
    icon: '/assets/icons/crypto/cmm.png'
  },
  CMT: {
    code: 'CMT',
    name: 'Comet',
    icon: '/assets/icons/crypto/cmt.png'
  },
  CND: {
    code: 'CND',
    name: 'Cindicator',
    icon: '/assets/icons/crypto/cnd.png'
  },
  CNX: {
    code: 'CNX',
    name: 'Cryptonex',
    icon: '/assets/icons/crypto/cnx.png'
  },
  CNY: {
    code: 'CNY',
    name: 'CNY',
    icon: '/assets/icons/crypto/cny.png'
  },
  COB: {
    code: 'COB',
    name: 'Cobinhood',
    icon: '/assets/icons/crypto/cob.png'
  },
  COLX: {
    code: 'COLX',
    name: 'ColossusXT',
    icon: '/assets/icons/crypto/colx.png'
  },
  COMP: {
    code: 'COMP',
    name: 'Compound',
    icon: '/assets/icons/crypto/comp.png'
  },
  COQUI: {
    code: 'COQUI',
    name: 'COQUI Cash',
    icon: '/assets/icons/crypto/coqui.png'
  },
  CRED: {
    code: 'CRED',
    name: 'Verify',
    icon: '/assets/icons/crypto/cred.png'
  },
  CRPT: {
    code: 'CRPT',
    name: 'Crypterium',
    icon: '/assets/icons/crypto/crpt.png'
  },
  CRV: {
    code: 'CRV',
    name: 'Curve DAO Token',
    icon: '/assets/icons/crypto/crv.png'
  },
  CRW: {
    code: 'CRW',
    name: 'Crown',
    icon: '/assets/icons/crypto/crw.png'
  },
  CS: {
    code: 'CS',
    name: 'CREDITS',
    icon: '/assets/icons/crypto/cs.png'
  },
  CTR: {
    code: 'CTR',
    name: 'Centra',
    icon: '/assets/icons/crypto/ctr.png'
  },
  CTXC: {
    code: 'CTXC',
    name: 'Cortex',
    icon: '/assets/icons/crypto/ctxc.png'
  },
  CVC: {
    code: 'CVC',
    name: 'Civic',
    icon: '/assets/icons/crypto/cvc.png'
  },
  D: {
    code: 'D',
    name: 'Denarius',
    icon: '/assets/icons/crypto/d.png'
  },
  DAI: {
    code: 'DAI',
    name: 'Dai',
    icon: '/assets/icons/crypto/dai.png'
  },
  DASH: {
    code: 'DASH',
    name: 'Dash',
    icon: '/assets/icons/crypto/dash.png'
  },
  DAT: {
    code: 'DAT',
    name: 'Datum',
    icon: '/assets/icons/crypto/dat.png'
  },
  DATA: {
    code: 'DATA',
    name: 'Streamr DATAcoin',
    icon: '/assets/icons/crypto/data.png'
  },
  DBC: {
    code: 'DBC',
    name: 'DeepBrain Chain',
    icon: '/assets/icons/crypto/dbc.png'
  },
  DCN: {
    code: 'DCN',
    name: 'Dentacoin',
    icon: '/assets/icons/crypto/dcn.png'
  },
  DCR: {
    code: 'DCR',
    name: 'Decred',
    icon: '/assets/icons/crypto/dcr.png'
  },
  DEEZ: {
    code: 'DEEZ',
    name: 'DeezNuts',
    icon: '/assets/icons/crypto/deez.png'
  },
  DENT: {
    code: 'DENT',
    name: 'Dent',
    icon: '/assets/icons/crypto/dent.png'
  },
  DEW: {
    code: 'DEW',
    name: 'DEW',
    icon: '/assets/icons/crypto/dew.png'
  },
  DGB: {
    code: 'DGB',
    name: 'DigiByte',
    icon: '/assets/icons/crypto/dgb.png'
  },
  DGD: {
    code: 'DGD',
    name: 'DigixDAO',
    icon: '/assets/icons/crypto/dgd.png'
  },
  DLT: {
    code: 'DLT',
    name: 'Agrello',
    icon: '/assets/icons/crypto/dlt.png'
  },
  DNT: {
    code: 'DNT',
    name: 'district0x',
    icon: '/assets/icons/crypto/dnt.png'
  },
  DOCK: {
    code: 'DOCK',
    name: 'Dock',
    icon: '/assets/icons/crypto/dock.png'
  },
  DOGE: {
    code: 'DOGE',
    name: 'Dogecoin',
    icon: '/assets/icons/crypto/doge.png'
  },
  DOT: {
    code: 'DOT',
    name: 'Polkadot',
    icon: '/assets/icons/crypto/dot.png'
  },
  DRGN: {
    code: 'DRGN',
    name: 'Dragonchain',
    icon: '/assets/icons/crypto/drgn.png'
  },
  DROP: {
    code: 'DROP',
    name: 'Dropil',
    icon: '/assets/icons/crypto/drop.png'
  },
  DTA: {
    code: 'DTA',
    name: 'DATA',
    icon: '/assets/icons/crypto/dta.png'
  },
  DTH: {
    code: 'DTH',
    name: 'Dether',
    icon: '/assets/icons/crypto/dth.png'
  },
  DTR: {
    code: 'DTR',
    name: 'Dynamic Trading Rights',
    icon: '/assets/icons/crypto/dtr.png'
  },
  EBST: {
    code: 'EBST',
    name: 'eBoost',
    icon: '/assets/icons/crypto/ebst.png'
  },
  ECA: {
    code: 'ECA',
    name: 'Electra',
    icon: '/assets/icons/crypto/eca.png'
  },
  EDG: {
    code: 'EDG',
    name: 'Edgeless',
    icon: '/assets/icons/crypto/edg.png'
  },
  EDO: {
    code: 'EDO',
    name: 'Eidoo',
    icon: '/assets/icons/crypto/edo.png'
  },
  EDOGE: {
    code: 'EDOGE',
    name: 'EtherDoge',
    icon: '/assets/icons/crypto/edoge.png'
  },
  ELA: {
    code: 'ELA',
    name: 'Elastos',
    icon: '/assets/icons/crypto/ela.png'
  },
  ELEC: {
    code: 'ELEC',
    name: 'Electrify.Asia',
    icon: '/assets/icons/crypto/elec.png'
  },
  ELF: {
    code: 'ELF',
    name: 'aelf',
    icon: '/assets/icons/crypto/elf.png'
  },
  ELIX: {
    code: 'ELIX',
    name: 'Elixir',
    icon: '/assets/icons/crypto/elix.png'
  },
  ELLA: {
    code: 'ELLA',
    name: 'Ellaism',
    icon: '/assets/icons/crypto/ella.png'
  },
  EMB: {
    code: 'EMB',
    name: 'Emblem',
    icon: '/assets/icons/crypto/emb.png'
  },
  EMC: {
    code: 'EMC',
    name: 'EmerCoin',
    icon: '/assets/icons/crypto/emc.png'
  },
  EMC2: {
    code: 'EMC2',
    name: 'Einsteinium',
    icon: '/assets/icons/crypto/emc2.png'
  },
  ENG: {
    code: 'ENG',
    name: 'Enigma',
    icon: '/assets/icons/crypto/eng.png'
  },
  ENJ: {
    code: 'ENJ',
    name: 'Enjin Coin',
    icon: '/assets/icons/crypto/enj.png'
  },
  ENTRP: {
    code: 'ENTRP',
    name: 'Hut34 Entropy',
    icon: '/assets/icons/crypto/entrp.png'
  },
  EON: {
    code: 'EON',
    name: 'EOS Network',
    icon: '/assets/icons/crypto/eon.png'
  },
  EOP: {
    code: 'EOP',
    name: 'EOP',
    icon: '/assets/icons/crypto/eop.png'
  },
  EOS: {
    code: 'EOS',
    name: 'EOS',
    icon: '/assets/icons/crypto/eos.png'
  },
  EQLI: {
    code: 'EQLI',
    name: 'Equaliser',
    icon: '/assets/icons/crypto/eqli.png'
  },
  EQUA: {
    code: 'EQUA',
    name: 'EQUA',
    icon: '/assets/icons/crypto/equa.png'
  },
  ETC: {
    code: 'ETC',
    name: 'Ethereum Classic',
    icon: '/assets/icons/crypto/etc.png'
  },
  ETH: {
    code: 'ETH',
    name: 'Ethereum',
    icon: '/assets/icons/crypto/eth.png'
  },
  ETHOS: {
    code: 'ETHOS',
    name: 'Ethos',
    icon: '/assets/icons/crypto/ethos.png'
  },
  ETN: {
    code: 'ETN',
    name: 'Electroneum',
    icon: '/assets/icons/crypto/etn.png'
  },
  ETP: {
    code: 'ETP',
    name: 'Metaverse ETP',
    icon: '/assets/icons/crypto/etp.png'
  },
  EUR: {
    code: 'EUR',
    name: 'EUR',
    icon: '/assets/icons/crypto/eur.png'
  },
  EVX: {
    code: 'EVX',
    name: 'Everex',
    icon: '/assets/icons/crypto/evx.png'
  },
  EXMO: {
    code: 'EXMO',
    name: 'EXMO',
    icon: '/assets/icons/crypto/exmo.png'
  },
  EXP: {
    code: 'EXP',
    name: 'Expanse',
    icon: '/assets/icons/crypto/exp.png'
  },
  FAIR: {
    code: 'FAIR',
    name: 'Faircoin',
    icon: '/assets/icons/crypto/fair.png'
  },
  FCT: {
    code: 'FCT',
    name: 'Factom',
    icon: '/assets/icons/crypto/fct.png'
  },
  FIDA: {
    code: 'FIDA',
    name: 'Bonfida',
    icon: '/assets/icons/crypto/fida.png'
  },
  FIL: {
    code: 'FIL',
    name: 'Filecoin [IOU]',
    icon: '/assets/icons/crypto/fil.png'
  },
  FJC: {
    code: 'FJC',
    name: 'Fujicoin',
    icon: '/assets/icons/crypto/fjc.png'
  },
  FLDC: {
    code: 'FLDC',
    name: 'Foldingcoin',
    icon: '/assets/icons/crypto/fldc.png'
  },
  FLO: {
    code: 'FLO',
    name: 'FLO',
    icon: '/assets/icons/crypto/flo.png'
  },
  FLUX: {
    code: 'FLUX',
    name: 'Flux',
    icon: '/assets/icons/crypto/flux.png'
  },
  FSN: {
    code: 'FSN',
    name: 'FUSION',
    icon: '/assets/icons/crypto/fsn.png'
  },
  FTC: {
    code: 'FTC',
    name: 'Feathercoin',
    icon: '/assets/icons/crypto/ftc.png'
  },
  FUEL: {
    code: 'FUEL',
    name: 'Etherparty',
    icon: '/assets/icons/crypto/fuel.png'
  },
  FUN: {
    code: 'FUN',
    name: 'FunFair',
    icon: '/assets/icons/crypto/fun.png'
  },
  GAME: {
    code: 'GAME',
    name: 'GameCredits',
    icon: '/assets/icons/crypto/game.png'
  },
  GAS: {
    code: 'GAS',
    name: 'Gas',
    icon: '/assets/icons/crypto/gas.png'
  },
  GBP: {
    code: 'GBP',
    name: 'GBP',
    icon: '/assets/icons/crypto/gbp.png'
  },
  GBX: {
    code: 'GBX',
    name: 'Globitex',
    icon: '/assets/icons/crypto/gbx.png'
  },
  GBYTE: {
    code: 'GBYTE',
    name: 'Obyte',
    icon: '/assets/icons/crypto/gbyte.png'
  },
  GENERIC: {
    code: 'GENERIC',
    name: 'GENERIC',
    icon: '/assets/icons/crypto/generic.png'
  },
  GIN: {
    code: 'GIN',
    name: 'GINcoin',
    icon: '/assets/icons/crypto/gin.png'
  },
  GLXT: {
    code: 'GLXT',
    name: 'GLX Token',
    icon: '/assets/icons/crypto/glxt.png'
  },
  GMR: {
    code: 'GMR',
    name: 'Gimmer',
    icon: '/assets/icons/crypto/gmr.png'
  },
  GMT: {
    code: 'GMT',
    name: 'STEPN',
    icon: '/assets/icons/crypto/gmt.png'
  },
  GNO: {
    code: 'GNO',
    name: 'Gnosis',
    icon: '/assets/icons/crypto/gno.png'
  },
  GNT: {
    code: 'GNT',
    name: 'Golem',
    icon: '/assets/icons/crypto/gnt.png'
  },
  GOLD: {
    code: 'GOLD',
    name: 'Dragonereum Gold',
    icon: '/assets/icons/crypto/gold.png'
  },
  GRC: {
    code: 'GRC',
    name: 'Gridcoin',
    icon: '/assets/icons/crypto/grc.png'
  },
  GRIN: {
    code: 'GRIN',
    name: 'Grin',
    icon: '/assets/icons/crypto/grin.png'
  },
  GRS: {
    code: 'GRS',
    name: 'Groestlcoin',
    icon: '/assets/icons/crypto/grs.png'
  },
  GRT: {
    code: 'GRT',
    name: 'The Graph',
    icon: '/assets/icons/crypto/grt.png'
  },
  GSC: {
    code: 'GSC',
    name: 'Global Social Chain',
    icon: '/assets/icons/crypto/gsc.png'
  },
  GTO: {
    code: 'GTO',
    name: 'Gifto',
    icon: '/assets/icons/crypto/gto.png'
  },
  GUP: {
    code: 'GUP',
    name: 'Guppy',
    icon: '/assets/icons/crypto/gup.png'
  },
  GUSD: {
    code: 'GUSD',
    name: 'Gemini dollar',
    icon: '/assets/icons/crypto/gusd.png'
  },
  GVT: {
    code: 'GVT',
    name: 'Genesis Vision',
    icon: '/assets/icons/crypto/gvt.png'
  },
  GXS: {
    code: 'GXS',
    name: 'GXChain',
    icon: '/assets/icons/crypto/gxs.png'
  },
  GZR: {
    code: 'GZR',
    name: 'Gizer',
    icon: '/assets/icons/crypto/gzr.png'
  },
  HIGHT: {
    code: 'HIGHT',
    name: 'Highcoin',
    icon: '/assets/icons/crypto/hight.png'
  },
  HNS: {
    code: 'HNS',
    name: 'Handshake',
    icon: '/assets/icons/crypto/hns.png'
  },
  HODL: {
    code: 'HODL',
    name: 'HOdlcoin',
    icon: '/assets/icons/crypto/hodl.png'
  },
  HOT: {
    code: 'HOT',
    name: 'Holo',
    icon: '/assets/icons/crypto/hot.png'
  },
  HPB: {
    code: 'HPB',
    name: 'High Performance Blockchain',
    icon: '/assets/icons/crypto/hpb.png'
  },
  HSR: {
    code: 'HSR',
    name: 'HShare',
    icon: '/assets/icons/crypto/hsr.png'
  },
  HT: {
    code: 'HT',
    name: 'HOTTO',
    icon: '/assets/icons/crypto/ht.png'
  },
  HTML: {
    code: 'HTML',
    name: 'HTMLCOIN',
    icon: '/assets/icons/crypto/html.png'
  },
  HUC: {
    code: 'HUC',
    name: 'Huntercoin',
    icon: '/assets/icons/crypto/huc.png'
  },
  HUSD: {
    code: 'HUSD',
    name: 'HUSD',
    icon: '/assets/icons/crypto/husd.png'
  },
  HUSH: {
    code: 'HUSH',
    name: 'Hush',
    icon: '/assets/icons/crypto/hush.png'
  },
  ICN: {
    code: 'ICN',
    name: 'Iconomi',
    icon: '/assets/icons/crypto/icn.png'
  },
  ICP: {
    code: 'ICP',
    name: 'Internet Computer',
    icon: '/assets/icons/crypto/icp.png'
  },
  ICX: {
    code: 'ICX',
    name: 'ICON',
    icon: '/assets/icons/crypto/icx.png'
  },
  IGNIS: {
    code: 'IGNIS',
    name: 'Ignis',
    icon: '/assets/icons/crypto/ignis.png'
  },
  ILK: {
    code: 'ILK',
    name: 'Inlock Token',
    icon: '/assets/icons/crypto/ilk.png'
  },
  INK: {
    code: 'INK',
    name: 'Ink',
    icon: '/assets/icons/crypto/ink.png'
  },
  INS: {
    code: 'INS',
    name: 'Insolar',
    icon: '/assets/icons/crypto/ins.png'
  },
  ION: {
    code: 'ION',
    name: 'ION',
    icon: '/assets/icons/crypto/ion.png'
  },
  IOP: {
    code: 'IOP',
    name: 'Internet of People',
    icon: '/assets/icons/crypto/iop.png'
  },
  IOST: {
    code: 'IOST',
    name: 'IOStoken',
    icon: '/assets/icons/crypto/iost.png'
  },
  IOTX: {
    code: 'IOTX',
    name: 'IoTeX',
    icon: '/assets/icons/crypto/iotx.png'
  },
  IQ: {
    code: 'IQ',
    name: 'Everipedia',
    icon: '/assets/icons/crypto/iq.png'
  },
  ITC: {
    code: 'ITC',
    name: 'IoT Chain',
    icon: '/assets/icons/crypto/itc.png'
  },
  JNT: {
    code: 'JNT',
    name: 'Jibrel Network',
    icon: '/assets/icons/crypto/jnt.png'
  },
  JPY: {
    code: 'JPY',
    name: 'JPY',
    icon: '/assets/icons/crypto/jpy.png'
  },
  KCS: {
    code: 'KCS',
    name: 'KuCoin Shares',
    icon: '/assets/icons/crypto/kcs.png'
  },
  KIN: {
    code: 'KIN',
    name: 'Kin',
    icon: '/assets/icons/crypto/kin.png'
  },
  KLOWN: {
    code: 'KLOWN',
    name: 'Ether Clown',
    icon: '/assets/icons/crypto/klown.png'
  },
  KMD: {
    code: 'KMD',
    name: 'Komodo',
    icon: '/assets/icons/crypto/kmd.png'
  },
  KNC: {
    code: 'KNC',
    name: 'Kyber Network',
    icon: '/assets/icons/crypto/knc.png'
  },
  KRB: {
    code: 'KRB',
    name: 'Karbo',
    icon: '/assets/icons/crypto/krb.png'
  },
  KSM: {
    code: 'KSM',
    name: 'Kusama',
    icon: '/assets/icons/crypto/ksm.png'
  },
  LBC: {
    code: 'LBC',
    name: 'LBRY Credits',
    icon: '/assets/icons/crypto/lbc.png'
  },
  LEND: {
    code: 'LEND',
    name: 'ETHLend',
    icon: '/assets/icons/crypto/lend.png'
  },
  LEO: {
    code: 'LEO',
    name: 'Unus Sed LEO',
    icon: '/assets/icons/crypto/leo.png'
  },
  LINK: {
    code: 'LINK',
    name: 'ChainLink',
    icon: '/assets/icons/crypto/link.png'
  },
  LKK: {
    code: 'LKK',
    name: 'Lykke',
    icon: '/assets/icons/crypto/lkk.png'
  },
  LOOM: {
    code: 'LOOM',
    name: 'Loom Network',
    icon: '/assets/icons/crypto/loom.png'
  },
  LPT: {
    code: 'LPT',
    name: 'Livepeer Token',
    icon: '/assets/icons/crypto/lpt.png'
  },
  LRC: {
    code: 'LRC',
    name: 'Loopring',
    icon: '/assets/icons/crypto/lrc.png'
  },
  LSK: {
    code: 'LSK',
    name: 'Lisk',
    icon: '/assets/icons/crypto/lsk.png'
  },
  LTC: {
    code: 'LTC',
    name: 'Litecoin',
    icon: '/assets/icons/crypto/ltc.png'
  },
  LUN: {
    code: 'LUN',
    name: 'Lunyr',
    icon: '/assets/icons/crypto/lun.png'
  },
  MAID: {
    code: 'MAID',
    name: 'MaidSafeCoin',
    icon: '/assets/icons/crypto/maid.png'
  },
  MANA: {
    code: 'MANA',
    name: 'Decentraland',
    icon: '/assets/icons/crypto/mana.png'
  },
  MATIC: {
    code: 'MATIC',
    name: 'Polygon',
    icon: '/assets/icons/crypto/matic.png'
  },
  MAX: {
    code: 'MAX',
    name: 'MAX Token',
    icon: '/assets/icons/crypto/max.png'
  },
  MCAP: {
    code: 'MCAP',
    name: 'MCAP',
    icon: '/assets/icons/crypto/mcap.png'
  },
  MCO: {
    code: 'MCO',
    name: 'Crypto.com',
    icon: '/assets/icons/crypto/mco.png'
  },
  MDA: {
    code: 'MDA',
    name: 'Moeda Loyalty Points',
    icon: '/assets/icons/crypto/mda.png'
  },
  MDS: {
    code: 'MDS',
    name: 'MediShares',
    icon: '/assets/icons/crypto/mds.png'
  },
  MED: {
    code: 'MED',
    name: 'Medibloc',
    icon: '/assets/icons/crypto/med.png'
  },
  MEETONE: {
    code: 'MEETONE',
    name: 'MEET.ONE',
    icon: '/assets/icons/crypto/meetone.png'
  },
  MFT: {
    code: 'MFT',
    name: 'Mainframe',
    icon: '/assets/icons/crypto/mft.png'
  },
  MIOTA: {
    code: 'MIOTA',
    name: 'IOTA',
    icon: '/assets/icons/crypto/miota.png'
  },
  MITH: {
    code: 'MITH',
    name: 'Mithril',
    icon: '/assets/icons/crypto/mith.png'
  },
  MKR: {
    code: 'MKR',
    name: 'Maker',
    icon: '/assets/icons/crypto/mkr.png'
  },
  MLN: {
    code: 'MLN',
    name: 'Melon',
    icon: '/assets/icons/crypto/mln.png'
  },
  MNX: {
    code: 'MNX',
    name: 'MinexCoin',
    icon: '/assets/icons/crypto/mnx.png'
  },
  MNZ: {
    code: 'MNZ',
    name: 'MNZ',
    icon: '/assets/icons/crypto/mnz.png'
  },
  MOAC: {
    code: 'MOAC',
    name: 'MOAC',
    icon: '/assets/icons/crypto/moac.png'
  },
  MOD: {
    code: 'MOD',
    name: 'Modum',
    icon: '/assets/icons/crypto/mod.png'
  },
  MONA: {
    code: 'MONA',
    name: 'MonaCoin',
    icon: '/assets/icons/crypto/mona.png'
  },
  MSR: {
    code: 'MSR',
    name: 'Masari',
    icon: '/assets/icons/crypto/msr.png'
  },
  MTH: {
    code: 'MTH',
    name: 'Monetha',
    icon: '/assets/icons/crypto/mth.png'
  },
  MTL: {
    code: 'MTL',
    name: 'Metal',
    icon: '/assets/icons/crypto/mtl.png'
  },
  MUSIC: {
    code: 'MUSIC',
    name: 'Musicoin',
    icon: '/assets/icons/crypto/music.png'
  },
  MZC: {
    code: 'MZC',
    name: 'MAZA',
    icon: '/assets/icons/crypto/mzc.png'
  },
  NANO: {
    code: 'NANO',
    name: 'Nano',
    icon: '/assets/icons/crypto/nano.png'
  },
  NAS: {
    code: 'NAS',
    name: 'Nebulas',
    icon: '/assets/icons/crypto/nas.png'
  },
  NAV: {
    code: 'NAV',
    name: 'NavCoin',
    icon: '/assets/icons/crypto/nav.png'
  },
  NCASH: {
    code: 'NCASH',
    name: 'Nucleus Vision',
    icon: '/assets/icons/crypto/ncash.png'
  },
  NDZ: {
    code: 'NDZ',
    name: 'NDZ',
    icon: '/assets/icons/crypto/ndz.png'
  },
  NEBL: {
    code: 'NEBL',
    name: 'Neblio',
    icon: '/assets/icons/crypto/nebl.png'
  },
  NEO: {
    code: 'NEO',
    name: 'NEO',
    icon: '/assets/icons/crypto/neo.png'
  },
  NEOS: {
    code: 'NEOS',
    name: 'Neoscoin',
    icon: '/assets/icons/crypto/neos.png'
  },
  NEU: {
    code: 'NEU',
    name: 'Neumark',
    icon: '/assets/icons/crypto/neu.png'
  },
  NEXO: {
    code: 'NEXO',
    name: 'Nexo',
    icon: '/assets/icons/crypto/nexo.png'
  },
  NGC: {
    code: 'NGC',
    name: 'NAGA',
    icon: '/assets/icons/crypto/ngc.png'
  },
  NIO: {
    code: 'NIO',
    name: 'Autonio',
    icon: '/assets/icons/crypto/nio.png'
  },
  NKN: {
    code: 'NKN',
    name: 'NKN',
    icon: '/assets/icons/crypto/nkn.png'
  },
  NLC2: {
    code: 'NLC2',
    name: 'NoLimitCoin',
    icon: '/assets/icons/crypto/nlc2.png'
  },
  NLG: {
    code: 'NLG',
    name: 'Gulden',
    icon: '/assets/icons/crypto/nlg.png'
  },
  NMC: {
    code: 'NMC',
    name: 'Namecoin',
    icon: '/assets/icons/crypto/nmc.png'
  },
  NMR: {
    code: 'NMR',
    name: 'Numeraire',
    icon: '/assets/icons/crypto/nmr.png'
  },
  NPXS: {
    code: 'NPXS',
    name: 'Pundi X',
    icon: '/assets/icons/crypto/npxs.png'
  },
  NTBC: {
    code: 'NTBC',
    name: 'Note Blockchain',
    icon: '/assets/icons/crypto/ntbc.png'
  },
  NULS: {
    code: 'NULS',
    name: 'Nuls',
    icon: '/assets/icons/crypto/nuls.png'
  },
  NXS: {
    code: 'NXS',
    name: 'Nexus',
    icon: '/assets/icons/crypto/nxs.png'
  },
  NXT: {
    code: 'NXT',
    name: 'NXT',
    icon: '/assets/icons/crypto/nxt.png'
  },
  NZD: {
    code: 'NZD',
    name: 'NZD',
    icon: '/assets/icons/crypto/nzd.png'
  },
  OAX: {
    code: 'OAX',
    name: 'OpenANX',
    icon: '/assets/icons/crypto/oax.png'
  },
  OK: {
    code: 'OK',
    name: 'OKCash',
    icon: '/assets/icons/crypto/ok.png'
  },
  OMG: {
    code: 'OMG',
    name: 'OMG Network',
    icon: '/assets/icons/crypto/omg.png'
  },
  OMNI: {
    code: 'OMNI',
    name: 'Omni',
    icon: '/assets/icons/crypto/omni.png'
  },
  ONE: {
    code: 'ONE',
    name: 'Harmony',
    icon: '/assets/icons/crypto/one.png'
  },
  ONG: {
    code: 'ONG',
    name: 'SoMee.Social',
    icon: '/assets/icons/crypto/ong.png'
  },
  ONT: {
    code: 'ONT',
    name: 'Ontology',
    icon: '/assets/icons/crypto/ont.png'
  },
  OOT: {
    code: 'OOT',
    name: 'Utrum',
    icon: '/assets/icons/crypto/oot.png'
  },
  OST: {
    code: 'OST',
    name: 'OST',
    icon: '/assets/icons/crypto/ost.png'
  },
  OX: {
    code: 'OX',
    name: 'OX Fina',
    icon: '/assets/icons/crypto/ox.png'
  },
  OXT: {
    code: 'OXT',
    name: 'Orchid',
    icon: '/assets/icons/crypto/oxt.png'
  },
  OXY: {
    code: 'OXY',
    name: 'Oxygen',
    icon: '/assets/icons/crypto/oxy.png'
  },
  PART: {
    code: 'PART',
    name: 'Particl',
    icon: '/assets/icons/crypto/part.png'
  },
  PASC: {
    code: 'PASC',
    name: 'Pascalcoin',
    icon: '/assets/icons/crypto/pasc.png'
  },
  PASL: {
    code: 'PASL',
    name: 'Pascal Lite',
    icon: '/assets/icons/crypto/pasl.png'
  },
  PAX: {
    code: 'PAX',
    name: 'PAX Token',
    icon: '/assets/icons/crypto/pax.png'
  },
  PAXG: {
    code: 'PAXG',
    name: 'PAX Gold',
    icon: '/assets/icons/crypto/paxg.png'
  },
  PAY: {
    code: 'PAY',
    name: 'TenX',
    icon: '/assets/icons/crypto/pay.png'
  },
  PAYX: {
    code: 'PAYX',
    name: 'Paypex',
    icon: '/assets/icons/crypto/payx.png'
  },
  PINK: {
    code: 'PINK',
    name: 'Pinkcoin',
    icon: '/assets/icons/crypto/pink.png'
  },
  PIRL: {
    code: 'PIRL',
    name: 'Pirl',
    icon: '/assets/icons/crypto/pirl.png'
  },
  PIVX: {
    code: 'PIVX',
    name: 'PIVX',
    icon: '/assets/icons/crypto/pivx.png'
  },
  PLR: {
    code: 'PLR',
    name: 'Pillar',
    icon: '/assets/icons/crypto/plr.png'
  },
  POA: {
    code: 'POA',
    name: 'POA Network',
    icon: '/assets/icons/crypto/poa.png'
  },
  POE: {
    code: 'POE',
    name: 'Po.et',
    icon: '/assets/icons/crypto/poe.png'
  },
  POLIS: {
    code: 'POLIS',
    name: 'Polis',
    icon: '/assets/icons/crypto/polis.png'
  },
  POLY: {
    code: 'POLY',
    name: 'Polymath Network',
    icon: '/assets/icons/crypto/poly.png'
  },
  POT: {
    code: 'POT',
    name: 'Potcoin',
    icon: '/assets/icons/crypto/pot.png'
  },
  POWR: {
    code: 'POWR',
    name: 'Power Ledger',
    icon: '/assets/icons/crypto/powr.png'
  },
  PPC: {
    code: 'PPC',
    name: 'Peercoin',
    icon: '/assets/icons/crypto/ppc.png'
  },
  PPP: {
    code: 'PPP',
    name: 'PayPie',
    icon: '/assets/icons/crypto/ppp.png'
  },
  PPT: {
    code: 'PPT',
    name: 'Populous',
    icon: '/assets/icons/crypto/ppt.png'
  },
  PRE: {
    code: 'PRE',
    name: 'Presearch',
    icon: '/assets/icons/crypto/pre.png'
  },
  PRL: {
    code: 'PRL',
    name: 'Oyster',
    icon: '/assets/icons/crypto/prl.png'
  },
  PUNGO: {
    code: 'PUNGO',
    name: 'Pungo Token',
    icon: '/assets/icons/crypto/pungo.png'
  },
  PURA: {
    code: 'PURA',
    name: 'Pura',
    icon: '/assets/icons/crypto/pura.png'
  },
  QASH: {
    code: 'QASH',
    name: 'QASH',
    icon: '/assets/icons/crypto/qash.png'
  },
  QIWI: {
    code: 'QIWI',
    name: 'QIWI',
    icon: '/assets/icons/crypto/qiwi.png'
  },
  QLC: {
    code: 'QLC',
    name: 'QLC Chain',
    icon: '/assets/icons/crypto/qlc.png'
  },
  QNT: {
    code: 'QNT',
    name: 'Quant',
    icon: '/assets/icons/crypto/qnt.png'
  },
  QRL: {
    code: 'QRL',
    name: 'Quantum Resistant Ledger',
    icon: '/assets/icons/crypto/qrl.png'
  },
  QSP: {
    code: 'QSP',
    name: 'Quantstamp',
    icon: '/assets/icons/crypto/qsp.png'
  },
  QTUM: {
    code: 'QTUM',
    name: 'Qtum',
    icon: '/assets/icons/crypto/qtum.png'
  },
  R: {
    code: 'R',
    name: 'Revain',
    icon: '/assets/icons/crypto/r.png'
  },
  RADS: {
    code: 'RADS',
    name: 'Radium',
    icon: '/assets/icons/crypto/rads.png'
  },
  RAP: {
    code: 'RAP',
    name: 'Rapture',
    icon: '/assets/icons/crypto/rap.png'
  },
  RAY: {
    code: 'RAY',
    name: 'Raydium',
    icon: '/assets/icons/crypto/ray.png'
  },
  RCN: {
    code: 'RCN',
    name: 'Rcoin',
    icon: '/assets/icons/crypto/rcn.png'
  },
  RDD: {
    code: 'RDD',
    name: 'Reddcoin',
    icon: '/assets/icons/crypto/rdd.png'
  },
  RDN: {
    code: 'RDN',
    name: 'Raiden Network Token',
    icon: '/assets/icons/crypto/rdn.png'
  },
  REN: {
    code: 'REN',
    name: 'Ren',
    icon: '/assets/icons/crypto/ren.png'
  },
  REP: {
    code: 'REP',
    name: 'Augur',
    icon: '/assets/icons/crypto/rep.png'
  },
  REPV2: {
    code: 'REPV2',
    name: 'Augur',
    icon: '/assets/icons/crypto/repv2.png'
  },
  REQ: {
    code: 'REQ',
    name: 'Request',
    icon: '/assets/icons/crypto/req.png'
  },
  RHOC: {
    code: 'RHOC',
    name: 'RChain',
    icon: '/assets/icons/crypto/rhoc.png'
  },
  RIC: {
    code: 'RIC',
    name: 'Riecoin',
    icon: '/assets/icons/crypto/ric.png'
  },
  RISE: {
    code: 'RISE',
    name: 'Rise',
    icon: '/assets/icons/crypto/rise.png'
  },
  RLC: {
    code: 'RLC',
    name: 'iExec RLC',
    icon: '/assets/icons/crypto/rlc.png'
  },
  RPX: {
    code: 'RPX',
    name: 'RPX',
    icon: '/assets/icons/crypto/rpx.png'
  },
  RUB: {
    code: 'RUB',
    name: 'RUB',
    icon: '/assets/icons/crypto/rub.png'
  },
  RVN: {
    code: 'RVN',
    name: 'Ravencoin',
    icon: '/assets/icons/crypto/rvn.png'
  },
  RYO: {
    code: 'RYO',
    name: 'Ryo Currency',
    icon: '/assets/icons/crypto/ryo.png'
  },
  SAFE: {
    code: 'SAFE',
    name: 'Safe',
    icon: '/assets/icons/crypto/safe.png'
  },
  SAFEMOON: {
    code: 'SAFEMOON',
    name: 'SafeMoon',
    icon: '/assets/icons/crypto/safemoon.png'
  },
  SAI: {
    code: 'SAI',
    name: 'Single Collateral DAI',
    icon: '/assets/icons/crypto/sai.png'
  },
  SALT: {
    code: 'SALT',
    name: 'SALT',
    icon: '/assets/icons/crypto/salt.png'
  },
  SAN: {
    code: 'SAN',
    name: 'Santiment Network Token',
    icon: '/assets/icons/crypto/san.png'
  },
  SAND: {
    code: 'SAND',
    name: 'The Sandbox',
    icon: '/assets/icons/crypto/sand.png'
  },
  SBD: {
    code: 'SBD',
    name: 'Steem Dollars',
    icon: '/assets/icons/crypto/sbd.png'
  },
  SBERBANK: {
    code: 'SBERBANK',
    name: 'SBERBANK',
    icon: '/assets/icons/crypto/sberbank.png'
  },
  SC: {
    code: 'SC',
    name: 'Siacoin',
    icon: '/assets/icons/crypto/sc.png'
  },
  SER: {
    code: 'SER',
    name: 'Serum',
    icon: '/assets/icons/crypto/ser.png'
  },
  SHIFT: {
    code: 'SHIFT',
    name: 'Shift',
    icon: '/assets/icons/crypto/shift.png'
  },
  SIB: {
    code: 'SIB',
    name: 'SIBCoin',
    icon: '/assets/icons/crypto/sib.png'
  },
  SIN: {
    code: 'SIN',
    name: 'SINOVATE',
    icon: '/assets/icons/crypto/sin.png'
  },
  SKL: {
    code: 'SKL',
    name: 'SKALE Network',
    icon: '/assets/icons/crypto/skl.png'
  },
  SKY: {
    code: 'SKY',
    name: 'Skycoin',
    icon: '/assets/icons/crypto/sky.png'
  },
  SLR: {
    code: 'SLR',
    name: 'Solarcoin',
    icon: '/assets/icons/crypto/slr.png'
  },
  SLS: {
    code: 'SLS',
    name: 'SaluS',
    icon: '/assets/icons/crypto/sls.png'
  },
  SMART: {
    code: 'SMART',
    name: 'SmartCash',
    icon: '/assets/icons/crypto/smart.png'
  },
  SNGLS: {
    code: 'SNGLS',
    name: 'SingularDTV',
    icon: '/assets/icons/crypto/sngls.png'
  },
  SNM: {
    code: 'SNM',
    name: 'SONM',
    icon: '/assets/icons/crypto/snm.png'
  },
  SNT: {
    code: 'SNT',
    name: 'Status',
    icon: '/assets/icons/crypto/snt.png'
  },
  SNX: {
    code: 'SNX',
    name: 'Synthetix',
    icon: '/assets/icons/crypto/snx.png'
  },
  SOC: {
    code: 'SOC',
    name: 'All Sports',
    icon: '/assets/icons/crypto/soc.png'
  },
  SOL: {
    code: 'SOL',
    name: 'Solana',
    icon: '/assets/icons/crypto/sol.png'
  },
  SPACEHBIT: {
    code: 'SPACEHBIT',
    name: 'HashBit Blockchain',
    icon: '/assets/icons/crypto/spacehbit.png'
  },
  SPANK: {
    code: 'SPANK',
    name: 'SpankChain',
    icon: '/assets/icons/crypto/spank.png'
  },
  SPHTX: {
    code: 'SPHTX',
    name: 'SophiaTX',
    icon: '/assets/icons/crypto/sphtx.png'
  },
  SRN: {
    code: 'SRN',
    name: 'Sirin Labs Token',
    icon: '/assets/icons/crypto/srn.png'
  },
  STAK: {
    code: 'STAK',
    name: 'STRAKS',
    icon: '/assets/icons/crypto/stak.png'
  },
  START: {
    code: 'START',
    name: 'Startcoin',
    icon: '/assets/icons/crypto/start.png'
  },
  STEEM: {
    code: 'STEEM',
    name: 'Steem',
    icon: '/assets/icons/crypto/steem.png'
  },
  STORJ: {
    code: 'STORJ',
    name: 'Storj',
    icon: '/assets/icons/crypto/storj.png'
  },
  STORM: {
    code: 'STORM',
    name: 'Storm',
    icon: '/assets/icons/crypto/storm.png'
  },
  STOX: {
    code: 'STOX',
    name: 'Stox',
    icon: '/assets/icons/crypto/stox.png'
  },
  STQ: {
    code: 'STQ',
    name: 'Storiqa',
    icon: '/assets/icons/crypto/stq.png'
  },
  STRAT: {
    code: 'STRAT',
    name: 'Stratis',
    icon: '/assets/icons/crypto/strat.png'
  },
  STX: {
    code: 'STX',
    name: 'Stacks',
    icon: '/assets/icons/crypto/stx.png'
  },
  SUB: {
    code: 'SUB',
    name: 'Substratum',
    icon: '/assets/icons/crypto/sub.png'
  },
  SUMO: {
    code: 'SUMO',
    name: 'Sumokoin',
    icon: '/assets/icons/crypto/sumo.png'
  },
  SUSHI: {
    code: 'SUSHI',
    name: 'SushiSwap',
    icon: '/assets/icons/crypto/sushi.png'
  },
  SYS: {
    code: 'SYS',
    name: 'Syscoin',
    icon: '/assets/icons/crypto/sys.png'
  },
  TAAS: {
    code: 'TAAS',
    name: 'TaaS',
    icon: '/assets/icons/crypto/taas.png'
  },
  TAU: {
    code: 'TAU',
    name: 'Lamden',
    icon: '/assets/icons/crypto/tau.png'
  },
  TBX: {
    code: 'TBX',
    name: 'Tokenbox',
    icon: '/assets/icons/crypto/tbx.png'
  },
  TEL: {
    code: 'TEL',
    name: 'Telcoin',
    icon: '/assets/icons/crypto/tel.png'
  },
  TEN: {
    code: 'TEN',
    name: 'Tokenomy',
    icon: '/assets/icons/crypto/ten.png'
  },
  TERN: {
    code: 'TERN',
    name: 'Ternio',
    icon: '/assets/icons/crypto/tern.png'
  },
  TGCH: {
    code: 'TGCH',
    name: 'TrueGalaxyCash',
    icon: '/assets/icons/crypto/tgch.png'
  },
  THETA: {
    code: 'THETA',
    name: 'Theta Network',
    icon: '/assets/icons/crypto/theta.png'
  },
  TIX: {
    code: 'TIX',
    name: 'Blocktix',
    icon: '/assets/icons/crypto/tix.png'
  },
  TKN: {
    code: 'TKN',
    name: 'TokenCard',
    icon: '/assets/icons/crypto/tkn.png'
  },
  TKS: {
    code: 'TKS',
    name: 'Tokes Platform',
    icon: '/assets/icons/crypto/tks.png'
  },
  TNB: {
    code: 'TNB',
    name: 'Time New Bank',
    icon: '/assets/icons/crypto/tnb.png'
  },
  TNC: {
    code: 'TNC',
    name: 'Trinity Network Credit',
    icon: '/assets/icons/crypto/tnc.png'
  },
  TNT: {
    code: 'TNT',
    name: 'Tierion',
    icon: '/assets/icons/crypto/tnt.png'
  },
  TOMO: {
    code: 'TOMO',
    name: 'TomoChain',
    icon: '/assets/icons/crypto/tomo.png'
  },
  TPAY: {
    code: 'TPAY',
    name: 'TokenPay',
    icon: '/assets/icons/crypto/tpay.png'
  },
  TRIG: {
    code: 'TRIG',
    name: 'Triggers',
    icon: '/assets/icons/crypto/trig.png'
  },
  TRTL: {
    code: 'TRTL',
    name: 'TurtleCoin',
    icon: '/assets/icons/crypto/trtl.png'
  },
  TRX: {
    code: 'TRX',
    name: 'TRON',
    icon: '/assets/icons/crypto/trx.png'
  },
  TUSD: {
    code: 'TUSD',
    name: 'TrueUSD',
    icon: '/assets/icons/crypto/tusd.png'
  },
  TZC: {
    code: 'TZC',
    name: 'TrezarCoin',
    icon: '/assets/icons/crypto/tzc.png'
  },
  UBQ: {
    code: 'UBQ',
    name: 'Ubiq',
    icon: '/assets/icons/crypto/ubq.png'
  },
  UMA: {
    code: 'UMA',
    name: 'UMA',
    icon: '/assets/icons/crypto/uma.png'
  },
  UNI: {
    code: 'UNI',
    name: 'Uniswap',
    icon: '/assets/icons/crypto/uni.png'
  },
  UNITY: {
    code: 'UNITY',
    name: 'SuperNET',
    icon: '/assets/icons/crypto/unity.png'
  },
  USD: {
    code: 'USD',
    name: 'USD',
    icon: '/assets/icons/crypto/usd.png'
  },
  USDC: {
    code: 'USDC',
    name: 'USD Coin',
    icon: '/assets/icons/crypto/usdc.png'
  },
  USDT: {
    code: 'USDT',
    name: 'Tether',
    icon: '/assets/icons/crypto/usdt.png'
  },
  UTK: {
    code: 'UTK',
    name: 'UTRUST',
    icon: '/assets/icons/crypto/utk.png'
  },
  VERI: {
    code: 'VERI',
    name: 'Veritaseum',
    icon: '/assets/icons/crypto/veri.png'
  },
  VET: {
    code: 'VET',
    name: 'VeChain',
    icon: '/assets/icons/crypto/vet.png'
  },
  VIA: {
    code: 'VIA',
    name: 'Viacoin',
    icon: '/assets/icons/crypto/via.png'
  },
  VIB: {
    code: 'VIB',
    name: 'Viberate',
    icon: '/assets/icons/crypto/vib.png'
  },
  VIBE: {
    code: 'VIBE',
    name: 'VIBE',
    icon: '/assets/icons/crypto/vibe.png'
  },
  VIVO: {
    code: 'VIVO',
    name: 'VIVO',
    icon: '/assets/icons/crypto/vivo.png'
  },
  VRC: {
    code: 'VRC',
    name: 'VeriCoin',
    icon: '/assets/icons/crypto/vrc.png'
  },
  VRSC: {
    code: 'VRSC',
    name: 'VerusCoin',
    icon: '/assets/icons/crypto/vrsc.png'
  },
  VTC: {
    code: 'VTC',
    name: 'Vertcoin',
    icon: '/assets/icons/crypto/vtc.png'
  },
  VTHO: {
    code: 'VTHO',
    name: 'VeThor Token',
    icon: '/assets/icons/crypto/vtho.png'
  },
  WABI: {
    code: 'WABI',
    name: 'Tael',
    icon: '/assets/icons/crypto/wabi.png'
  },
  WAN: {
    code: 'WAN',
    name: 'Wanchain',
    icon: '/assets/icons/crypto/wan.png'
  },
  WAVES: {
    code: 'WAVES',
    name: 'Waves',
    icon: '/assets/icons/crypto/waves.png'
  },
  WAX: {
    code: 'WAX',
    name: 'WAX',
    icon: '/assets/icons/crypto/wax.png'
  },
  WBTC: {
    code: 'WBTC',
    name: 'Wrapped Bitcoin',
    icon: '/assets/icons/crypto/wbtc.png'
  },
  WGR: {
    code: 'WGR',
    name: 'Wagerr',
    icon: '/assets/icons/crypto/wgr.png'
  },
  WICC: {
    code: 'WICC',
    name: 'WaykiChain',
    icon: '/assets/icons/crypto/wicc.png'
  },
  WINGS: {
    code: 'WINGS',
    name: 'Wings',
    icon: '/assets/icons/crypto/wings.png'
  },
  WPR: {
    code: 'WPR',
    name: 'WePower',
    icon: '/assets/icons/crypto/wpr.png'
  },
  WTC: {
    code: 'WTC',
    name: 'Waltonchain',
    icon: '/assets/icons/crypto/wtc.png'
  },
  X: {
    code: 'X',
    name: 'GLX Equity Token',
    icon: '/assets/icons/crypto/x.png'
  },
  XAS: {
    code: 'XAS',
    name: 'Asch',
    icon: '/assets/icons/crypto/xas.png'
  },
  XBC: {
    code: 'XBC',
    name: 'Bitcoin Plus',
    icon: '/assets/icons/crypto/xbc.png'
  },
  XBP: {
    code: 'XBP',
    name: 'BlitzPredict',
    icon: '/assets/icons/crypto/xbp.png'
  },
  XBY: {
    code: 'XBY',
    name: 'XtraBYtes',
    icon: '/assets/icons/crypto/xby.png'
  },
  XCP: {
    code: 'XCP',
    name: 'Counterparty',
    icon: '/assets/icons/crypto/xcp.png'
  },
  XDN: {
    code: 'XDN',
    name: 'DigitalNote',
    icon: '/assets/icons/crypto/xdn.png'
  },
  XEM: {
    code: 'XEM',
    name: 'NEM',
    icon: '/assets/icons/crypto/xem.png'
  },
  XIN: {
    code: 'XIN',
    name: 'Infinity Economics',
    icon: '/assets/icons/crypto/xin.png'
  },
  XLM: {
    code: 'XLM',
    name: 'Stellar',
    icon: '/assets/icons/crypto/xlm.png'
  },
  XMCC: {
    code: 'XMCC',
    name: 'Monoeci',
    icon: '/assets/icons/crypto/xmcc.png'
  },
  XMG: {
    code: 'XMG',
    name: 'Magi',
    icon: '/assets/icons/crypto/xmg.png'
  },
  XMO: {
    code: 'XMO',
    name: 'Monero Original',
    icon: '/assets/icons/crypto/xmo.png'
  },
  XMR: {
    code: 'XMR',
    name: 'Monero',
    icon: '/assets/icons/crypto/xmr.png'
  },
  XMY: {
    code: 'XMY',
    name: 'Myriad',
    icon: '/assets/icons/crypto/xmy.png'
  },
  XP: {
    code: 'XP',
    name: 'XP',
    icon: '/assets/icons/crypto/xp.png'
  },
  XPA: {
    code: 'XPA',
    name: 'XPA',
    icon: '/assets/icons/crypto/xpa.png'
  },
  XPM: {
    code: 'XPM',
    name: 'Primecoin',
    icon: '/assets/icons/crypto/xpm.png'
  },
  XPR: {
    code: 'XPR',
    name: 'Proton',
    icon: '/assets/icons/crypto/xpr.png'
  },
  XRP: {
    code: 'XRP',
    name: 'XRP',
    icon: '/assets/icons/crypto/xrp.png'
  },
  XSG: {
    code: 'XSG',
    name: 'SnowGem',
    icon: '/assets/icons/crypto/xsg.png'
  },
  XTZ: {
    code: 'XTZ',
    name: 'Tezos',
    icon: '/assets/icons/crypto/xtz.png'
  },
  XUC: {
    code: 'XUC',
    name: 'Exchange Union',
    icon: '/assets/icons/crypto/xuc.png'
  },
  XVC: {
    code: 'XVC',
    name: 'Vcash',
    icon: '/assets/icons/crypto/xvc.png'
  },
  XVG: {
    code: 'XVG',
    name: 'Verge',
    icon: '/assets/icons/crypto/xvg.png'
  },
  XZC: {
    code: 'XZC',
    name: 'Zcoin',
    icon: '/assets/icons/crypto/xzc.png'
  },
  YFI: {
    code: 'YFI',
    name: 'yearn.finance',
    icon: '/assets/icons/crypto/yfi.png'
  },
  YOYOW: {
    code: 'YOYOW',
    name: 'YOYOW',
    icon: '/assets/icons/crypto/yoyow.png'
  },
  ZCL: {
    code: 'ZCL',
    name: 'Zclassic',
    icon: '/assets/icons/crypto/zcl.png'
  },
  ZEC: {
    code: 'ZEC',
    name: 'Zcash',
    icon: '/assets/icons/crypto/zec.png'
  },
  ZEL: {
    code: 'ZEL',
    name: 'ZelCash',
    icon: '/assets/icons/crypto/zel.png'
  },
  ZEN: {
    code: 'ZEN',
    name: 'Horizen',
    icon: '/assets/icons/crypto/zen.png'
  },
  ZEST: {
    code: 'ZEST',
    name: 'Zest',
    icon: '/assets/icons/crypto/zest.png'
  },
  ZIL: {
    code: 'ZIL',
    name: 'Zilliqa',
    icon: '/assets/icons/crypto/zil.png'
  },
  ZILLA: {
    code: 'ZILLA',
    name: 'Chainzilla',
    icon: '/assets/icons/crypto/zilla.png'
  },
  ZRX: {
    code: 'ZRX',
    name: '0x',
    icon: '/assets/icons/crypto/zrx.png'
  }
};

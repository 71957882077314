import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { closeAllPopup, updateQueryPopup } from '@/@crema/utility/Route';
import { isValidFunction, isValidObject, isValidString } from '@/@crema/utility/utils';
import {
  COMMON_FETCH_ERROR,
  COMMON_FETCH_START,
  COMMON_FETCH_SUCCESS,
  COMMON_HIDE_BOTTOM_SHEET,
  COMMON_HIDE_MESSAGE,
  COMMON_HIDE_POPUP,
  COMMON_SHOW_BOTTOM_SHEET,
  COMMON_SHOW_MESSAGE,
  COMMON_SHOW_POPUP
} from '@/shared/constants/ActionTypes';
import get from 'lodash/get';
import { onSignOut } from './Auth';

export const fetchStart = () => {
  return (dispatch) => dispatch({ type: COMMON_FETCH_START });
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({ type: COMMON_FETCH_SUCCESS });
};

export const fetchError = (error, values) => {
  return (dispatch) => dispatch({ type: COMMON_FETCH_ERROR, payload: { error, values } });
};

export const handleErrors = (response, actions, fieldError = null) => {
  return (dispatch) => {
    logd('handleErrors', response, actions, fieldError);
    let values = {};
    if (response && response.status) {
      let error = get(response, 'data.message', 'error.systemError.description');
      // client error
      if (response.status >= 400 && response.status < 500) {
        // fill form error
        if (response.status === 400 || response.status === 422) {
          const resErrors = get(response, 'data.errors');
          if (isValidObject(resErrors)) {
            if (isValidFunction(actions?.setFieldError)) {
              Object.keys(resErrors).forEach((key) => {
                if (resErrors[key] && isValidString(resErrors[key][0])) {
                  const errorMessage = resErrors[key][0];
                  actions.setFieldError(key, <IntlMessages id={errorMessage} />);
                }
              });
              return;
            }

            Object.keys(resErrors).forEach((key) => {
              if (resErrors[key] && isValidString(resErrors[key][0])) {
                const errorMessage = resErrors[key][0];
                error = errorMessage;
              }
            });
          } else {
            error = get(response, 'data.error');
            const error_message_variables = get(response, 'data.error_message_variables');
            if (isValidObject(error_message_variables)) {
              values = Object.keys(error_message_variables).reduce((prev, key) => {
                if (!error_message_variables[key]?.name)
                  return { [key]: error_message_variables[key] };
                error += '.' + key;
                return { ...prev, [key]: error_message_variables[key]?.name };
              }, {});
            }
          }
        } else if (response.status === 401) {
          onSignOut();
          // prevent show toast error message
          return;
        } else {
          error = error !== '' ? error : 'error.systemError.description';
        }
      }
      // server error
      else if (response.status >= 500) {
        // handle server error
        error = 'error.systemError.description';
      }
      return dispatch(fetchError(error, values));
    }
  };
};

export const showSuccess = (message) => {
  return (dispatch) =>
    dispatch({ type: COMMON_SHOW_MESSAGE, payload: { type: 'success', message } });
};

export const showInfo = (message) => {
  return (dispatch) => dispatch({ type: COMMON_SHOW_MESSAGE, payload: { type: 'info', message } });
};

export const showWarning = (message) => {
  return (dispatch) =>
    dispatch({ type: COMMON_SHOW_MESSAGE, payload: { type: 'warning', message } });
};

export const showError = (message) => {
  return (dispatch) => dispatch({ type: COMMON_SHOW_MESSAGE, payload: { type: 'error', message } });
};

export const hideMessage = () => {
  return (dispatch) => dispatch({ type: COMMON_HIDE_MESSAGE });
};

export const showPopup = (popup, query, shouldUpdateQuery = true) => {
  return (dispatch) => {
    dispatch({ type: COMMON_SHOW_POPUP, payload: popup });

    if (shouldUpdateQuery) {
      updateQueryPopup({
        overlay: popup,
        ...query
      });
    }
  };
};

export const hidePopup = (shouldCloseAllPopup = false) => {
  return (dispatch) => {
    dispatch({ type: COMMON_HIDE_POPUP });
    shouldCloseAllPopup && closeAllPopup();
  };
};

export const showBottomSheet = () => {
  return (dispatch) => dispatch({ type: COMMON_SHOW_BOTTOM_SHEET });
};

export const hideBottomSheet = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_HIDE_BOTTOM_SHEET });
  };
};

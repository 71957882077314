import {
  getCanonicalUrl,
  isValidArray,
  isValidString,
  isValidUrl,
  mergeByProperty
} from '@/@crema/utility/utils';
import { useLanguages } from '@/redux/reducers/App';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { LOCALES } from '@/shared/constants/AppEnums';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import HEAD_META_DEFAULT from './HeadMetaDefault.json';

const useHrefLangs = (siteUrl, asPath) => {
  const languages = useLanguages();

  const hrefLangs = languages.map((item) => {
    let href = item.is_default ? `${siteUrl}${asPath}` : `${siteUrl}/${item.code}${asPath}`;
    let hrefLang = item.code === LOCALES.NZ ? LOCALES.ENNZ : item.code;
    return {
      key: item.code,
      rel: 'alternate',
      hrefLang,
      href
    };
  });

  const defaultLang = languages.find((item) => item.is_default);
  if (defaultLang) {
    hrefLangs.push({
      key: 'x-default',
      rel: 'alternate',
      hrefLang: 'x-default',
      href: `${siteUrl}${asPath}`
    });
  }
  return hrefLangs;
};

export default function HeadMeta({ data, title, description, image }) {
  const {
    siteUrl,
    defaultTitle,
    defaultDescription,
    defaultImage,
    defaultSep,
    siteName,
    keywords
  } = APP_CONFIG;
  const router = useRouter();

  const theTitle = isValidString(title)
    ? (title + defaultSep + defaultTitle)?.substring(0, 60)
    : defaultTitle;

  const theDescription = isValidString(description)
    ? description?.substring(0, 155)
    : defaultDescription;

  const theImage = isValidString(image) ? `${siteUrl}${image}` : defaultImage;

  const asPath = router ? router.asPath : '';
  const langs = useHrefLangs(siteUrl, asPath);

  const canonicalUrl = getCanonicalUrl(router);

  const getDefaultTags = () => {
    return HEAD_META_DEFAULT.map((item) => {
      switch (item.key) {
        case 'title':
        case 'name':
        case 'og:title':
        case 'twitter:title':
          return {
            ...item,
            content: theTitle
          };
        case 'description':
        case 'og:description':
        case 'twitter:description':
          return {
            ...item,
            content: theDescription
          };
        case 'og:url':
        case 'twitter:url':
          return {
            ...item,
            content: canonicalUrl
          };
        case 'og:image':
        case 'twitter:image':
          return {
            ...item,
            content: theImage
          };
        case 'og:site_name':
          return {
            ...item,
            content: siteName
          };
        case 'twitter:creator':
          return {
            ...item,
            content: `@${siteName}`
          };
        case 'canonical':
          return {
            ...item,
            href: canonicalUrl
          };
        default:
          return {
            ...item,
            content: item.content ? item.content : ''
          };
      }
    });
  };

  const getTitle = () => {
    if (Array.isArray(data)) {
      const titleObj = data.find((item) => item.htag && item.htag === 'title');
      if (titleObj && titleObj.content) {
        return titleObj.content;
      }
      return theTitle;
    }
    return theTitle;
  };

  const renderTags = () => {
    const defaultTags = getDefaultTags();
    const _data = isValidArray(data) ? data : [];

    const tags = mergeByProperty(_data, defaultTags, 'key');

    return tags.map((item) => {
      const key = item.key;
      if (item.key !== 'title') {
        const HtmlTag = item.htag ? item.htag : 'meta';

        // delete unused obj
        delete item.htag;
        delete item.type;
        if (!item.property) {
          delete item.property;
        }
        if (!item.name) {
          delete item.name;
        }

        // check & encode url
        if (isValidUrl(item.content)) {
          item.content = encodeURI(item.content);
        }

        return <HtmlTag key={key} {...item} />;
      }
    });
  };

  const renderLanguages = () => {
    return langs.map((item) => (
      <link key={item.key} rel={item.rel} hrefLang={item.hrefLang} href={item.href} />
    ));
  };

  return (
    <Head>
      <title>{getTitle()}</title>
      {renderLanguages()}
      {renderTags()}
    </Head>
  );
}

HeadMeta.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

HeadMeta.defaultProps = {
  data: [],
  title: '',
  description: '',
  image: ''
};

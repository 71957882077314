import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const getCMSPromotions = (query) => {
  return API_SERVICE.get(getApiEndpoint(`promotions`), { params: query });
};

export const getCMSPromotionsDetail = (id) => {
  return API_SERVICE.get(getApiEndpoint(`promotions/${id}`));
};

export const claimPromotion = (id) => {
  return API_SERVICE.put(getApiEndpoint(`bonuses/${id}/claim`));
};

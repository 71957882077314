import { getPlayerVipProgress } from '@/@crema/services/apis/auth';
import {
  CASHBACK_GET_ERROR,
  CASHBACK_GET_START,
  CASHBACK_GET_SUCCESS
} from '@/shared/constants/ActionTypes';
import { handleErrors } from './Common';

export const onGetCashbackProgress = () => {
  return async (dispatch, getState) => {
    const { app } = getState();
    if (!app?.systemSetting?.players?.enable_player_vip_progress) {
      return;
    }
    try {
      dispatch({ type: CASHBACK_GET_START });
      const res = await getPlayerVipProgress();
      if (res.data) {
        dispatch({ type: CASHBACK_GET_SUCCESS, payload: res.data });
      }
      return res.data;
    } catch (error) {
      dispatch({ type: CASHBACK_GET_ERROR });
      dispatch(handleErrors(error));
      return error;
    }
  };
};

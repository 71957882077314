import { getLaunchDemoGameHref, getLaunchGameHref } from '@/@crema/utility/Route';
import { formatDatetime, get, getCMSName, isValidArray, uniqueId } from '@/@crema/utility/utils';
import { APP_CONFIG } from '../constants/AppConfig';

export const getCMSGame = (data) => {
  const id = get(data, 'id') || get(data, 'game_id') || 0;
  const name = getCMSName(data);
  const name_slug = get(data, 'cms_property.name_slug') || get(data, 'name_slug') || '';
  const game_type = get(data, 'game_type.cms_property.name') || get(data, 'game_type.name') || '';
  const provider_name =
    get(data, 'provider_name') ||
    get(data, 'game_provider.cms_property.name') ||
    get(data, 'game_provider.name') ||
    '';
  const product_type = get(data, 'game_type.product_type') || '';
  const product_type_name = get(data, 'game_type.product_type_name') || '';
  const code = get(data, 'code', uniqueId());
  const maintenance = get(data, 'maintenance') || get(data, 'game_provider.maintenance') || false;
  const has_trial = get(data, 'has_trial', false);
  const provider = get(data, 'provider_code') || get(data, 'game_provider.code') || '';
  const title = `${name} | ${game_type} | ${provider_name} | ${APP_CONFIG.siteName}`;
  const description = `Play ${name} online ${game_type} by ${provider_name} on ${APP_CONFIG.siteName}. Get a big bonus on your first deposit. Sign up and play now.`;
  const alt = name || APP_CONFIG.siteName;
  const desktop_image =
    get(data, 'cms_property.desktop_image.path') || get(data, 'desktop_image.path') || '';
  const tablet_image =
    get(data, 'cms_property.tablet_image.path') || get(data, 'tablet_image.path') || '';
  const mobile_image =
    get(data, 'cms_property.mobile_image.path') || get(data, 'mobile_image.path') || '';
  const head_meta = get(data, 'cms_property.head_meta', []);

  const description_seo = get(data, 'cms_property.description') || '';

  const slug = get(data, 'game_slug') || get(data, 'slug') || '';
  const game_launch_endpoint = get(data, 'game_launch_endpoint', '');
  const game_launch_endpoint_trial = game_launch_endpoint ? `${game_launch_endpoint}?trial=1` : '';

  const url = getLaunchGameHref({ slug });
  const demo_url = getLaunchDemoGameHref({ slug });
  const country_restricted = !!get(data, 'country_restricted');
  const advance_info = get(data, 'cms_property.advance_info', {});

  const displayName = [advance_info?.game_name_prefix, name, advance_info?.game_name_suffix]
    .join(' ')
    .trim();

  return {
    id,
    name,
    displayName,
    name_slug,
    provider,
    provider_name,
    game_type,
    product_type,
    product_type_name,
    code,
    desktop_image,
    tablet_image,
    mobile_image,
    has_trial,
    maintenance,
    head_meta,
    title,
    description,
    alt,
    url,
    demo_url,
    game_url: '',
    game_demo_url: '',
    description_seo,
    slug,
    game_launch_endpoint,
    game_launch_endpoint_trial,
    country_restricted
  };
};

export const getCMSGameVendor = (data) => {
  const code = get(data, 'code', '');
  return {
    id: get(data, '_id', uniqueId()),
    name: getCMSName(data),
    code: code,
    icon: get(data, 'icon.path', ''),
    slug: get(data, 'slug', ''),
    href: data?.code ? `/casino?providers=${data.code}` : '#'
  };
};

export const getCMSPromotion = (data) => {
  const id = get(data, 'id') || 0;
  const code = get(data, 'code', '');
  const key = get(data, 'key', '');
  const name = getCMSName(data);
  const name_slug = get(data, 'cms_property.name_slug') || get(data, 'name_slug') || code;
  const short_description =
    get(data, 'cms_property.short_description') || get(data, 'short_description', '');
  const description = get(data, 'cms_property.description') || get(data, 'description', '');
  const title = `${name} | ${short_description} | ${APP_CONFIG.siteName}`;
  const alt = `${name} - ${short_description}`;
  const desktop_image =
    get(data, 'cms_property.desktop_image.path', '') || get(data, 'desktop_image.path');
  const tablet_image =
    get(data, 'cms_property.tablet_image.path', '') || get(data, 'tablet_image.path');
  const mobile_image =
    get(data, 'cms_property.mobile_image.path', '') || get(data, 'mobile_image.path');
  const desktop_image_detail =
    get(data, 'cms_property.desktop_image_detail.path', '') ||
    get(data, 'desktop_image_detail.path');
  const tablet_image_detail =
    get(data, 'cms_property.tablet_image_detail.path', '') || get(data, 'tablet_image_detail.path');
  const mobile_image_detail =
    get(data, 'cms_property.mobile_image_detail.path', '') || get(data, 'mobile_image_detail.path');

  const arr_img_recommend = get(data, 'cms_property.images', []);

  const image_recommend_bonus =
    arr_img_recommend.length > 0 &&
    arr_img_recommend.find((i) => i?.value?.code === 'small')?.value?.image?.path;
  const game_product_type = get(data, 'game_product_type.0.name', '');
  const color_title = get(data, 'cms_property.type', '');

  const url = `/promotions/${name_slug}`;
  const cta = get(data, 'cta', null);
  return {
    id,
    code,
    key,
    name,
    name_slug,
    short_description,
    description,
    desktop_image,
    tablet_image,
    mobile_image,
    desktop_image_detail,
    tablet_image_detail,
    mobile_image_detail,
    cta,
    url,
    title,
    alt,
    image_recommend_bonus,
    game_product_type,
    color_title
  };
};

export const getCMSBonusItem = (data) => {
  const bonusId = get(data, 'id', '');
  const code = get(data, 'bonus.code', '');
  const bonusAmount = get(data, 'bonus_amount', 0);
  const name = getCMSName(data?.bonus);
  const expire = formatDatetime(data?.expire_at);
  const currentAmount = get(data, 'current_wagering_amount', 0);
  const requirementAmount = get(data, 'wagering_requirement_amount', '');
  const currency = get(data, 'currency.code', '');
  const progress_percent = get(data, 'progress_percent', 0);
  const status = get(data, 'status', '');
  const statusName = get(data, 'status_name', '');
  const totalFreeRounds = get(data, 'game_provider_bonus_info.total_free_rounds', 0);
  const bonusType = get(data, 'game_provider_bonus_info.bonus_type', 'free_rounds');

  const images = get(data, 'bonus.cms_property.images', []);
  const imgUrl =
    isValidArray(images) && images.find((i) => i?.value?.code === 'small')?.value?.image?.path;

  return {
    bonusId,
    code,
    name,
    imgUrl,
    bonusAmount,
    expire,
    currentAmount,
    requirementAmount,
    currency,
    progress_percent,
    status,
    statusName,
    totalFreeRounds,
    bonusType
  };
};

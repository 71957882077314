import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drawerRoot: {},
  btnClose: {
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },
  drawerPaper: {
    width: 500,
    height: '100%',
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  }
}));

export default useStyles;

import { STATIC_PAGE_GET_DATA_SUCCESS } from '@/shared/constants/ActionTypes';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  data: null,
  slug: null
};

export const StaticPageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STATIC_PAGE_GET_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        slug: action.payload.slug
      };
    default:
      return state;
  }
};

export const useStaticPageData = () => {
  return useSelector(({ staticPage }) => staticPage);
};

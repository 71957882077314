export const COLORS = {
  // winwin
  productEsports: '#0BABDF',
  success: '#21A556',
  warning: '#BDAE20',
  wwSecondary: '#EDAC2D',
  base80: '#d4d4d4',
  base0: '#2a2a2a',
  // end winwin

  // bld
  bldPaper: '#222224',
  // end bld

  primary: '#d0ac3a',
  secondary: '#D3D04F',
  tertiary: '#1ac693',
  blue: '#2196F3',
  green: '#66BB6A',
  red: '#FF4135',
  text: '#ffffff',
  background1: '#0E0E0E',
  background175: 'rgba(14, 14, 14, 0.75)',
  background2: '#121421',
  background375: 'rgba(25, 30, 50, 0.75)',
  orange: '#ff9f2d',
  yellow: 'rgba(245, 237, 141, 1)',
  overlay: 'rgba(0, 0, 0, 0.6)',
  paper: 'rgba(255, 255, 255, 0.08)',
  card: 'rgba(255, 255, 255, 0.08)',
  section: '#F5F8FC',
  gray60: '#95A1AC',
  gray70: '#808A93',
  gray80: '#646D74',
  gray40: '#BDC4CB',
  gray10: '#EBEDEF',
  navyBlue: '#0E0E0E',
  darkNavy: '#212121',
  darkNavy10: '#111E32',
  darkNavy20: '#13233A',
  link: '#0e4891',
  mintGreen: '#3ef3a9'
};

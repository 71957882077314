import { htmlParser } from '@/@crema/utility/utils';
import { useCmsSettingsHtmlBody, useCmsSettingsHtmlHead } from '@/redux/reducers/App';
import Head from 'next/head';

export default function GlobalCustomHtml() {
  const head = useCmsSettingsHtmlHead();
  const body = useCmsSettingsHtmlBody();

  const renderHtmlHead = () => {
    if (!head) return null;
    const parsedHtmlHead = htmlParser(head);
    return <Head>{parsedHtmlHead}</Head>;
  };

  const renderHtmlBody = () => {
    if (!body) return null;
    return <div id="__next-custom-html" dangerouslySetInnerHTML={{ __html: body }} />;
  };

  return (
    <>
      {renderHtmlHead()}
      {renderHtmlBody()}
    </>
  );
}

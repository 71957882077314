import Status from '@/@crema/core/Status';
import { DEPOSIT_STATUS } from '@/shared/constants/AppEnums';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const StatusDeposit = ({ status, statusName }) => {
  const { formatMessage } = useIntl();
  const mapColorAndName = (code) => {
    switch (code) {
      case DEPOSIT_STATUS.Declined:
      case DEPOSIT_STATUS.Canceled:
        return ['error', formatMessage({ id: 'transaction.deposit.canceled' })];
      case DEPOSIT_STATUS.Pending:
        return ['pending', formatMessage({ id: 'transaction.status.pending' })];
      case DEPOSIT_STATUS.Approved:
        return ['success', formatMessage({ id: 'transaction.status.approved' })];
      case DEPOSIT_STATUS.Failed:
        return ['error', formatMessage({ id: 'transaction.status.failed' })];
      default:
        return [];
    }
  };
  const [variant, name = statusName] = mapColorAndName(status);
  return (
    <Status variant={variant} type="round">
      {name}
    </Status>
  );
};

StatusDeposit.propTypes = {
  status: PropTypes.number.isRequired,
  statusName: PropTypes.string.isRequired
};

export default StatusDeposit;

import { APP_CONFIG } from './AppConfig';

export const TimeConst = {
  walletInterval: Number(APP_CONFIG.NEXT_PUBLIC_WALLET_INTERVAL) || 30000, // 30s
  systemStatusInterval: Number(APP_CONFIG.NEXT_PUBLIC_SYSTEM_STATUS_INTERVAL) || 60000, // 60s
  openPopupDelay: 1500,
  apiTimeout: Number(APP_CONFIG.NEXT_PUBLIC_API_TIMEOUT) || 60000, // 60s
  gameFilterDebounce: 500,
  onlinePaymentRedirect: 5 // 5s
};

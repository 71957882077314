import { DEFAULT_CONFIG } from '@/modules/Account/Deposit/PaymentForms/PaymentDynamicForm/defaultConfig';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { isServer } from '@/shared/constants/AppConst';
import {
  ASPECT_RATIO,
  BANNER,
  DEFAULT_CALLING_CODE,
  GAME_FILTER,
  GAME_FILTER_FE,
  LOCALES
} from '@/shared/constants/AppEnums';
import { COOKIES } from '@/shared/constants/CookieConst';
import { CryptoIcons } from '@/shared/constants/CryptoIcons';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { PAGING_DEFAULT_STATE } from '@/shared/constants/PagingConst';
import { STATUS_CODES } from '@/shared/constants/StatusCodes';
import HTMLReactParser from 'html-react-parser';
import get from 'lodash/get';
import { logd } from '../Logging';
import { getLaunchGameLatestBetsHref } from '../Route';
import { getCookie } from '../Session';
import { isValidArray } from './utils-array';
import { hasOwnProperty, isEmpty, isValidObject } from './utils-object';
import { isValidString } from './utils-string';
import { parseUserAgent } from './utils-user-agent';

export function getCMSName(obj, locale) {
  if (!obj) return '';
  if (obj.cms_property && obj.cms_property.name) {
    return obj.cms_property[`name_${locale}`] || obj.cms_property.name;
  }
  return obj?.name;
}

export function getCMSDescription(obj) {
  if (!obj) return '';
  if (obj.cms_property && obj.cms_property.description) {
    return obj.cms_property.description;
  }
  return obj?.description;
}

export function getCMSImage(obj) {
  if (!obj) return '';
  if (obj.cms_property && obj.cms_property.icon) {
    return obj.cms_property.icon?.path;
  }
  return '';
}

export function getFullName(user) {
  if (!user) return 'Guest';
  return `${user?.first_name ? user?.first_name + ' ' : ''}${
    user?.middle_name ? user?.middle_name + ' ' : ''
  }${user?.last_name ? user?.last_name + ' ' : ''}`;
}

export function isLoggedIn(ctx) {
  const token = getCookie(COOKIES.jwt_auth, ctx.req?.headers);
  return typeof token === 'string';
}

// export function getAgentCodes(queryRouter) {
//   const codes = [
//     [typeof queryRouter.aff === 'string' && queryRouter.aff, { code: queryRouter.aff }],
//     [getCookie(COOKIES.agent_code), { code: getCookie(COOKIES.agent_code), isFromCookie: true }]
//   ];
//   return codes.filter(([isKeep]) => isKeep).map(([_, code]) => code);
// }

export function getPayload(queryRouter) {
  return (
    (typeof queryRouter.payload === 'string' && queryRouter.payload) || getCookie(COOKIES.payload)
  );
}

export function getQueryStrings() {
  const qsURL = window.location.search.replace(/^\?/, '');
  const qsCookie = getCookie(COOKIES.query_strings);
  const params = [
    [qsURL, { value: qsURL }],
    [qsCookie, { value: qsCookie, isFromCookie: true }]
  ];
  return params.filter(([isKeep]) => isKeep).map(([_, queryStrings]) => queryStrings);
}

export function getDocumentReferrer() {
  const referrer = document.referrer;
  const referrerLocalStorage = localStorage.getItem(LOCAL_STORAGE.document_referrer);
  const referrers = [
    [referrer, { value: referrer }],
    [referrerLocalStorage, { value: referrerLocalStorage, isLocalStorage: true }]
  ];
  return referrers.filter(([isKeep]) => isKeep).map(([_, referrer]) => referrer);
}

export function propsAreEqual(prev, next) {
  try {
    return JSON.stringify(prev) === JSON.stringify(next);
  } catch (error) {
    return false;
  }
}

export function formatCmsHtml(html = '', isLoggedIn = false) {
  if (!isValidString(html)) {
    return '';
  }

  if (isLoggedIn) {
    html = html.replace(/actionGroup/g, 'actionGroup hidden');
  }

  // map class btn-cta with MuiButton
  html = html.replace(/btn-cta /g, 'btn-cta MuiButtonBase-root MuiButton-root ');

  // variant text
  html = html.replace(/btn-cta-text-default/g, 'btn-cta-text-default MuiButton-text');
  html = html.replace(
    /btn-cta-text-primary/g,
    'btn-cta-text-primary MuiButton-text MuiButton-textPrimary'
  );
  html = html.replace(
    /btn-cta-text-secondary/g,
    'btn-cta-text-secondary MuiButton-text MuiButton-textSecondary'
  );

  // variant contained
  html = html.replace(
    /btn-cta-contained-default/g,
    'btn-cta-contained-default MuiButton-contained'
  );
  html = html.replace(
    /btn-cta-contained-primary/g,
    'btn-cta-contained-primary MuiButton-contained MuiButton-containedPrimary'
  );
  html = html.replace(
    /btn-cta-contained-secondary/g,
    'btn-cta-contained-secondary MuiButton-contained MuiButton-containedSecondary'
  );

  // variant outlined
  html = html.replace(/btn-cta-outlined-default/g, 'btn-cta-outlined-default MuiButton-outlined');
  html = html.replace(
    /btn-cta-outlined-primary/g,
    'btn-cta-outlined-primary MuiButton-outlined MuiButton-outlinedPrimary'
  );
  html = html.replace(
    /btn-cta-outlined-secondary/g,
    'btn-cta-outlined-secondary MuiButton-outlined MuiButton-outlinedSecondary'
  );

  return html;
}

export function getImageFullUrl(imagePath) {
  try {
    if (typeof imagePath !== 'string' || imagePath === '') {
      if (
        imagePath &&
        (imagePath.desktop_image || imagePath.tablet_image || imagePath.mobile_image)
      ) {
        if (isServer) {
          return (
            imagePath.desktop_image?.path ||
            imagePath.tablet_image?.path ||
            imagePath.mobile_image?.path
          );
        }
        const ua = parseUserAgent();
        if (ua) {
          if (ua.isTablet) {
            return imagePath.tablet_image?.path;
          }

          if (ua.isDesktop) {
            return imagePath.desktop_image?.path;
          }

          if (ua.isMobile) {
            return imagePath.mobile_image?.path;
          }

          return (
            imagePath.desktop_image?.path ||
            imagePath.tablet_image?.path ||
            imagePath.mobile_image?.path
          );
        }
      }
      return '';
    }

    const regexUrl = /(http(s?)):\/\//i;
    const regexBase64 = /base64/i;

    if (regexUrl.test(imagePath) || regexBase64.test(imagePath)) {
      return imagePath;
    }

    if (isValidString(imagePath) && imagePath.startsWith('/')) {
      return imagePath;
    }

    return '';
  } catch (error) {
    return '';
  }
}

export function getPagingObj(params) {
  try {
    logd('getPagingObj.params', params);

    const page = get(params, 'page', PAGING_DEFAULT_STATE.page);
    const loading = get(params, 'loading', PAGING_DEFAULT_STATE.loading);
    const lastPage = get(params, 'lastPage', PAGING_DEFAULT_STATE.lastPage);
    const isLoadMore = loading && lastPage > 0;

    const result = {
      page,
      lastPage,
      loading,
      isLoadMore,
      total: get(params, 'total', PAGING_DEFAULT_STATE.total),
      data: get(params, 'data', PAGING_DEFAULT_STATE.data),
      refreshing: get(params, 'refreshing', PAGING_DEFAULT_STATE.refreshing),
      sortBy: get(params, 'sortBy', PAGING_DEFAULT_STATE.sortBy),
      orderBy: get(params, 'orderBy', PAGING_DEFAULT_STATE.orderBy),
      filters: get(params, 'filters', PAGING_DEFAULT_STATE.filters),
      error: get(params, 'error', PAGING_DEFAULT_STATE.error),
      canLoadMore: page < lastPage
    };

    logd('getPagingObj.result', result);
    return result;
  } catch (error) {
    logd('getPagingObj.error', error);
    return PAGING_DEFAULT_STATE;
  }
}

export function isValidPagingData(data) {
  return (
    hasOwnProperty(data, 'current_page') &&
    hasOwnProperty(data, 'last_page') &&
    hasOwnProperty(data, 'data')
  );
}

export function parseQueryToGameFilterObj(query) {
  const filters = {};
  Object.keys(GAME_FILTER_FE).forEach((key) => {
    const value = query[GAME_FILTER_FE[key]];
    if (value && value !== 'all') {
      if (Array.isArray(value)) {
        filters[GAME_FILTER_FE[key]] = value;
      }
      if (isValidString(value)) {
        filters[GAME_FILTER_FE[key]] = [value];
      }
    }
  });
  return filters;
}

export const isSuccess = (res) => {
  return res && res.status === STATUS_CODES.SUCCESS;
};

export function getCryptoIcon(code) {
  return (CryptoIcons[code] ? CryptoIcons[code]['icon'] : '') || '';
}

export function getCryptoData(code) {
  return CryptoIcons[code];
}

/**
 * converted: {
  balance: '0.32',
  currency: {
    key: 'USD',
    name: 'US Dollar',
    code: 'USD',
    symbol: '$',
    decimals: 2,
    type: 1,
    type_name: 'Fiat Money',
    is_default: 1
  },
  currency_code: 'USD'
}
 * @param {Object} data 
 */
export function getConvertedBalance(data) {
  if (data && data.converted && data.converted.currency) {
    return `${data.converted.currency.symbol}${data.converted.balance}`;
  }

  return '';
}

export function getActiveWallets(wallets) {
  return (wallets || []).find((i) => i?.activated === 1);
}

export function getPageSlug(data) {
  return data?.code || data?.name_slug || '';
}

export function getOriginalTitle(str) {
  if (!isValidString(str)) {
    return '';
  }
  return str.replace(/<primary>/g, '').replace(/<\/primary>/g, '');
}

export function getSlug(str) {
  if (!isValidString(str)) {
    return '';
  }
  return str
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '_')
    .replace(/__/g, '_')
    .toLowerCase();
}

export function getAvatarCharacter(str) {
  if (!isValidString(str)) {
    return '';
  }

  return str.charAt(0);
}

export function getAllBalance(item = {}) {
  if (Object.length === 0) return {};
  const code = item?.currency?.code || '';
  const name = item?.currency?.name || '';
  const symbol = item?.currency?.symbol || '';
  const symbol_converted = item?.converted?.currency?.symbol || '';

  const main_amount_converted = item?.converted?.balance || '';
  const main_amount_bonus_locked_converted = item?.converted?.total_bonus_locked_balance || '';

  const withdraw_amount_converted = item?.converted?.total_withdraw_balance || '';
  const bonus_amount_converted = item?.converted?.total_bonus_balance || '';
  const total_amount_converted = item?.converted?.total_balance || '';

  const main_amount = item?.balance || '';
  const main_amount_bonus_locked = item?.total_bonus_locked_balance || '';
  const withdraw_amount = item?.total_withdraw_balance || '';
  const bonus_amount = item?.total_bonus_balance || '';
  const total_amount = item?.total_balance || '';

  return {
    code,
    name,
    symbol,
    symbol_converted,
    main_amount_converted,
    main_amount_bonus_locked_converted,
    withdraw_amount_converted,
    bonus_amount_converted,
    total_amount_converted,
    main_amount,
    main_amount_bonus_locked,
    withdraw_amount,
    bonus_amount,
    total_amount
  };
}

export function buildUrlSplash(url) {
  if (typeof url === 'string' && url !== '' && url !== '/') {
    if (!url.startsWith('/')) {
      url = `/${url}`;
    }

    if (!url.endsWith('/') && !url.includes('?')) {
      url = `${url}/`;
    }

    return url;
  }

  return '';
}

export function getCMSDeposit(data) {
  // we will get data from group or first payment option in group
  const group_data = data;
  const payment_option_data = data?.data?.[0];

  const name = getCMSName(group_data) || getCMSName(payment_option_data) || '';
  const logo =
    get(group_data, 'logo.path') || get(payment_option_data, 'cms_property.logo.path') || '';
  const secondary_logo = get(group_data, 'secondary_logo') || '';
  const code = get(group_data, 'code', '') || get(payment_option_data, 'cms_property.code', '');
  const credit_card_encryption_key_url = get(
    payment_option_data,
    'behavior.provider_params.credit_card_encryption_key_url',
    ''
  );

  const description =
    get(group_data, 'description') || get(payment_option_data, 'cms_property.description') || '';

  const min = get(payment_option_data, 'min_amount', 0);
  const max = get(payment_option_data, 'max_amount', 0);

  let form_config = get(payment_option_data, 'behavior.form_config', null);

  if (!form_config || !form_config?.components) {
    form_config = DEFAULT_CONFIG;
  }

  form_config.components = form_config.components
    .filter((i) => i.type && i.key)
    .map((i) => {
      if (i.type === 'amount') {
        i.min = min;
        i.max = max;
      }

      return i;
    });

  return {
    // group data
    code,
    logo,
    name,
    description,
    badge_label: get(group_data, 'badge_label', ''),
    require_complete_account_profile: get(group_data, 'require_complete_account_profile', false),
    // payment option data
    min,
    max,
    payment_option_id: get(payment_option_data, 'id', null),
    form_type: get(payment_option_data, 'behavior.form_type', 'crypto'),
    currency_code: get(payment_option_data, 'currency_code', ''),
    credit_card_encryption_key_url,
    payment_type: get(payment_option_data, 'type', null),
    form_config,
    secondary_logo,
    amount_buttons: get(payment_option_data, 'cms_property.amount_buttons', [])
  };
}

export function getFallbackRedirect(settingCMS, path) {
  const createFallback = (to, redirection_code) => ({
    to: buildUrlSplash(to),
    redirection_code: redirection_code ?? 301
  });

  let fb = createFallback();

  try {
    const fallback = get(settingCMS, 'route_config.fallback_redirection');

    if (!isEmpty(fallback) && path) {
      path = buildUrlSplash(path);

      const src = Object.keys(fallback).find((src) => {
        src = buildUrlSplash(src);

        if (src === path) {
          return true;
        }

        return false;
      });

      if (src) {
        let destination = fallback[src] || '';
        if (destination) {
          // destination is object to manually set status code
          if (isValidObject(destination)) {
            const { to, redirection_code } = destination;
            fb = createFallback(to, redirection_code);
          }
          // destination is string with default status code
          else {
            fb = createFallback(destination);
          }
        }
      }
    }
    return fb;
  } catch (error) {
    return fb;
  }
}

export function limit(val, max) {
  if (val?.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val?.length === 2) {
    if (Number(val) === 0) {
      val = '01';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

export const getRedirectOutputObj = (data) => {
  return {
    url: get(data, 'url', ''),
    html: get(data, 'html', ''),
    width: get(data, 'width', 600),
    height: get(data, 'height', 600),
    method: get(data, 'method', ''),
    sizeUnit: get(data, 'sizeUnit', 'px'),
    container: get(data, 'container', ''),
    parameters: get(data, 'parameters', {})
  };
};

export function getPhoneNumber(obj) {
  if (obj && isValidString(obj.phone_number)) {
    let calling_code = isValidString(obj.calling_code.phone)
      ? obj.calling_code
      : DEFAULT_CALLING_CODE;
    return `+${calling_code.phone}${obj.phone_number}`;
  }
  return '';
}

export function generateSiteMap(siteUrl, url, locales, defaultLocale, freq, lastmod, priority) {
  let generateXHTMLLink = [];
  let generateContent = [];

  if (isValidArray(locales)) {
    const generatePriority = priority ? `<priority>${priority}</priority>` : '';

    generateXHTMLLink = locales
      .map((locale) => {
        const href = locale === defaultLocale ? `${siteUrl}/` : `${siteUrl}/${locale}/`;
        const hrefLang = locale === LOCALES.NZ ? LOCALES.ENNZ : locale;
        let urlLocale = href;

        if (url) {
          urlLocale = `${href}${url}/`;
        }

        return `<xhtml:link rel="alternate" hreflang="${hrefLang}" href="${urlLocale}" />`;
      })
      .join('\n');

    generateContent = locales
      .map((locale) => {
        const urlLocale = locale === defaultLocale ? `${siteUrl}/` : `${siteUrl}/${locale}/`;
        let urlLoc = urlLocale;
        let hrefDefault = `${siteUrl}/`;

        if (url) {
          urlLoc = `${urlLoc}${url}/`;
          hrefDefault = `${hrefDefault}${url}/`;
        }

        return `<url>
                  <loc>${urlLoc}</loc>
                  ${generateXHTMLLink}
                  <changefreq>${freq}</changefreq>
                  <lastmod>${lastmod}</lastmod>
                  ${generatePriority}
                </url>`;
      })
      .join('\n');
  }

  return generateContent;
}

export function getGameFilterObject(filters) {
  if (isValidArray(filters)) {
    return {
      [GAME_FILTER.productTypes]: filters
        .filter((i) => i._link === 'game_product_type')
        .map((i) => i.code),
      [GAME_FILTER.gameProviders]: filters
        .filter((i) => i._link === 'game_vendor')
        .map((i) => i.code),
      [GAME_FILTER.gameTypes]: filters.filter((i) => i._link === 'game_type').map((i) => i.code),
      [GAME_FILTER.gameFeatures]: filters
        .filter((i) => i._link === 'game_featured')
        .map((i) => i.code)
    };
  }
  return {
    [GAME_FILTER.productTypes]: [],
    [GAME_FILTER.gameProviders]: [],
    [GAME_FILTER.gameTypes]: [],
    [GAME_FILTER.gameFeatures]: []
  };
}

export function getTf(query) {
  return query?.tf || getCookie(COOKIES.tf);
}

export function getImagesCarousel(images) {
  if (isValidArray(images)) {
    const desktop_image = isValidArray(images)
      ? images.find((i) => i?.value?.code === ASPECT_RATIO['4-1'])?.value?.image
      : null;
    const mobile_image = isValidArray(images)
      ? images.find((i) => i?.value?.code === ASPECT_RATIO['1-1'])?.value?.image
      : null;

    const coins_desktop = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.coins_desktop)?.value?.image
      : null;
    const coins_mobile = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.coins_mobile)?.value?.image
      : null;

    const coins_blur_1_desktop = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.coins_blur_1_desktop)?.value?.image
      : null;
    const coins_blur_2_desktop = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.coins_blur_2_desktop)?.value?.image
      : null;
    const coins_blur_1_mobile = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.coins_blur_1_mobile)?.value?.image
      : null;
    const coins_blur_2_mobile = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.coins_blur_2_mobile)?.value?.image
      : null;

    const player_desktop = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.player_desktop)?.value?.image
      : null;
    const player_mobile = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.player_mobile)?.value?.image
      : null;
    const flag_desktop = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.flag_desktop)?.value?.image
      : null;
    const flag_mobile = isValidArray(images)
      ? images.find((i) => i?.value?.code === BANNER.flag_mobile)?.value?.image
      : null;
    return {
      desktop_image,
      mobile_image,
      playerAnimation: {
        coins_desktop,
        coins_mobile,
        coins_blur_1_desktop,
        coins_blur_2_desktop,
        coins_blur_1_mobile,
        coins_blur_2_mobile,
        player_desktop,
        player_mobile,
        flag_desktop,
        flag_mobile
      }
    };
  }
  return null;
}

export const getPureAsPath = (asPath) => {
  let path = '';
  if (asPath) {
    const _pathSliceLength = Math.min.apply(Math, [
      asPath.indexOf('?') > 0 ? asPath.indexOf('?') : asPath.length,
      asPath.indexOf('#') > 0 ? asPath.indexOf('#') : asPath.length
    ]);
    path = asPath.substring(0, _pathSliceLength);
  }
  return path;
};

/**
 *
 * @param {import('next/router').NextRouter} router
 */
export const getCanonicalUrl = (router) => {
  let CANONICAL_DOMAIN = APP_CONFIG.siteUrl;
  try {
    let asPath =
      router.locale === router.defaultLocale ? router.asPath : `/${router.locale}${router.asPath}`;
    const getAsPath = getPureAsPath(asPath);
    return CANONICAL_DOMAIN + getAsPath;
  } catch (error) {
    return CANONICAL_DOMAIN;
  }
};

/* try excute function by condition
 * @param {Function} comparison
 * @param {Function} callback
 * @param {Number} timerStep
 * @param {Number} maxTime
 */

export const poller = (comparison, callback, fail, timerStep = 250, maxTime = 5000) => {
  // why setTimeout instead of setInterval
  // https://stackoverflow.com/questions/8682622/using-setinterval-to-do-simplistic-continuous-polling
  let currTime = 0;
  const checkCondition = () => {
    // `comparison` is a function so the condition is always up-to-date
    if (comparison() === true) {
      callback && callback();
    } else if (currTime <= maxTime) {
      currTime += timerStep;
      setTimeout(checkCondition, timerStep);
    } else {
      fail && fail();
    }
  };
  checkCondition(); // calling the function
};

export const isFingerprintSuccess = () => {
  for (let i = (window?.dataLayer?.length || 0) - 1; 0 <= i; i--) {
    const element = window?.dataLayer[i];

    try {
      if (element?.value?.event === 'FingerprintJSPro.identified') return true;
    } catch (error) {
      //
    }
  }

  return false;
};

export function getSiteUrl(ctx) {
  let site_url;

  if (ctx?.req) {
    if (ctx?.req?.headers?.host || ctx?.req?.headers?.['x-forwarded-host']) {
      let scheme = 'https';
      let host = ctx?.req?.headers?.host || ctx?.req?.headers?.['x-forwarded-host'];

      if (ctx?.req?.headers?.['x-forwarded-proto']) {
        scheme = ctx?.req?.headers?.['x-forwarded-proto'];
      }

      if (host === 'localhost:3000') {
        scheme = 'http';
      }

      site_url = `${scheme}://${host}`;
    } else {
      site_url = process.env.NEXT_PUBLIC_SITE_URL;
    }
  } else {
    site_url = window.location.origin;
  }
  return site_url;
}

export function getGameSitemapUrl(ctx) {
  const siteUrl = getSiteUrl(ctx);
  const { query, locale, defaultLocale } = ctx || {};

  let href = '';
  let asPath = getPureAsPath(ctx?.asPath);

  if (locale !== defaultLocale) {
    if (!asPath.startsWith(`/${locale}`)) {
      asPath = `/${locale}${asPath}`;
    }
  }

  if (!asPath.endsWith('/')) {
    asPath = `${asPath}/`;
  }

  if (query?.types) {
    href = `${siteUrl}${asPath}sitemap/`;
  } else {
    href = `${siteUrl}${asPath}all/sitemap/`;
  }

  return href;
}

// number !== [-1, 0, 1, '-1', '0', '1', 'A', NaN, Infinite];
export const formatBetMulti = (number) => {
  let num = '-';
  try {
    if (number && !isNaN(number) && isFinite(number) && number >= 0) {
      num = `${Number(number).toFixed(2)}x`;
    }
    return num;
  } catch (error) {
    logd('Error formatBetMulti', error);
    return num;
  }
};

export const getLatestBetData = (data, locale) => {
  let newData = [];
  if (isValidArray(data)) {
    newData = data.map((item) => {
      const game_name = getCMSName(item?.game);
      const game_image =
        get(item, 'game.cms_property.desktop_image.path') ||
        get(item, 'game.cms_property.tablet_image.path') ||
        get(item, 'game.cms_property.mobile_image.path');
      const game_item = get(item, 'game.cms_property');
      const game_url = getLaunchGameLatestBetsHref(game_item);
      const username = get(item, 'username', '');
      const vip_name = getCMSName(item?.vip, locale);
      const vip_color = get(item, 'vip.cms_property.color');
      const wager = get(item, 'formatted_bet_amount', '');
      const payout = get(item, 'formatted_payout_amount', '');
      const multiplier = get(item, 'multiplier', '');
      const format_multiplier = formatBetMulti(multiplier);
      const currency = get(item, 'base_currency', '');
      const is_high_roller = get(item, 'is_high_roller', false);
      const is_big_wins = get(item, 'is_big_wins', false);

      return {
        game_name,
        game_image,
        game_url,
        username,
        vip_name,
        vip_color,
        wager,
        payout,
        multiplier,
        format_multiplier,
        currency,
        is_high_roller,
        is_big_wins
      };
    });
  }
  return newData;
};

/**
 * Converts HTML string to JSX element(s).
 *
 * @param html - HTML string.
 * @param {import('html-react-parser').HTMLReactParserOptions} options
 * @returns - JSX element(s), empty array, or string.
 */
export function htmlParser(html, options) {
  return HTMLReactParser(html, options);
}

import { jssPreset, StylesProvider as MuiStylesProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import PropTypes from 'prop-types';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins] });

const StylesProvider = (props) => {
  return <MuiStylesProvider jss={jss}>{props.children}</MuiStylesProvider>;
};
export default StylesProvider;

StylesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

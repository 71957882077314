import { logd } from '@/@crema/utility/Logging';
import { DATE_FORMAT_DOB, DATE_FORMAT_MASK } from '@/shared/constants/AppEnums';
import { COLORS } from '@/shared/constants/ColorSets';
import { fade, makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { FastField, getIn } from 'formik';
import { useState } from 'react';
import BaseTextField from '../TextField';

const useStyles = makeStyles((theme) => ({
  textInput: {
    '& .MuiIconButton-root': {
      color: fade(theme.palette.text.primary, 0.7),
      '&.Mui-disabled': {
        color: fade(theme.palette.text.primary, 0.08)
      }
    }
  },
  wrapperDateRange: {
    // borderRadius: theme.shape.borderRadiusLarge,
    '.MuiPickersPopper-paper &': {
      backgroundColor: COLORS.bldPaper
    },
    '& .MuiPickersBasePicker-pickerView': {
      '& .MuiPickersCalendarHeader-root': {
        '& .MuiPickersCalendarHeader-monthTitleContainer': {
          alignItems: 'center'
        }
      }
    },
    '& .MuiPickersDay-root': {
      backgroundColor: 'transparent',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main
      }
    },
    '& .MuiPickersArrowSwitcher-iconButton': {
      backgroundColor: 'transparent'
    },
    '&.MuiIconButton-edgeEnd': {
      marginRight: 0
    }
  }
}));

const BaseDateTimePicker = ({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue },
  helperText,
  className,
  margin,
  ...nest
}) => {
  const [value, setValue] = useState(field.value ? field.value : '');
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const onChange = (value) => {
    setValue(value);
    setFieldValue(field.name, value);
  };

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onBlur = (e) => {
    try {
      if (e.persist) {
        e.persist();
      }
      setFieldValue(field.name, value);
      setTimeout(() => fieldOnBlur(e ?? {}), 100);
    } catch (error) {
      logd(`TextField.onBlur.${field.name}.error`, error);
    }
  };

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <DatePicker
      {...field}
      {...nest}
      open={isOpen}
      disabled={disabled ?? isSubmitting}
      value={value}
      onChange={onChange}
      onOpen={onOpen}
      onClose={onClose}
      onBlur={onBlur}
      inputFormat={DATE_FORMAT_DOB}
      openTo="date"
      className={classes.wrapperDateRange}
      // disableMaskedInput
      allowSameDateSelection
      mask={DATE_FORMAT_MASK}
      renderInput={(props) => (
        <BaseTextField
          {...props}
          margin={margin}
          className={clsx(classes.textInput, isOpen ? '' : className)}
          variant="outlined"
          fullWidth
          error={showError}
          helperText={showError ? fieldError : helperText}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
    />
  );
};

const shouldUpdate = (prevProps, nextProps) => {
  return (
    prevProps.disabled !== nextProps.disabled ||
    prevProps.name !== nextProps.name ||
    getIn(prevProps.formik.values, nextProps.name) !==
      getIn(nextProps.formik.values, nextProps.name) ||
    getIn(prevProps.formik.errors, nextProps.name) !==
      getIn(nextProps.formik.errors, nextProps.name) ||
    getIn(prevProps.formik.touched, nextProps.name) !==
      getIn(nextProps.formik.touched, nextProps.name) ||
    Object.keys(nextProps).length !== Object.keys(prevProps).length ||
    prevProps.formik.isSubmitting !== nextProps.formik.isSubmitting
  );
};

const DateTimePicker = (props) => {
  return (
    <FastField {...props} component={BaseDateTimePicker} shouldUpdate={shouldUpdate}></FastField>
  );
};

export default DateTimePicker;

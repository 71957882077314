function log(...optionalParams) {
  console.log(...optionalParams);
}

function noop() {}

// only log at client
export const logd = process.env.NODE_ENV === 'development' ? log : noop;

// only log at ssr
export const logServer = process.env.NODE_ENV === 'development' ? log : noop;

// be careful use this function - log anywhere
export const logi = process.env.NODE_ENV === 'development' ? log : noop;

// log some error
export const loge = process.env.NODE_ENV === 'development' ? log : noop;

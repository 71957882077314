import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

export default function TableHead({ classes, columns, sortOrder, onSort }) {
  const { formatMessage } = useIntl();
  return (
    <MuiTableHead>
      <TableRow>
        {columns.map((column, columnIndex) => {
          const { label, id, align, alignHeader, width, colSpan = 1 } = column || {};
          return (
            <TableCell
              colSpan={colSpan}
              key={id || columnIndex}
              align={alignHeader || align}
              width={width}>
              {label && formatMessage({ id: label })}
            </TableCell>
          );
        })}
      </TableRow>
    </MuiTableHead>
  );
}

TableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  sortOrder: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired
};

import { ANALYTICS_EVENT } from '@/shared/constants/Analytics';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { logd } from '../utility/Logging';
import { dispatchGlobalEvent, get } from '../utility/utils';

const TAG = 'Analytics';

function getLastPlayedGame(price) {
  let last_played_game_obj = {
    item_id: 'no_game',
    item_name: 'No Game',
    item_brand: '',
    item_category: '',
    quantity: 1,
    price
  };
  const last_played_game = localStorage.getItem(LOCAL_STORAGE.last_played_game);
  if (last_played_game) {
    try {
      last_played_game_obj = JSON.parse(last_played_game);
      last_played_game_obj.price = price;
    } catch (err) {
      logd(`${TAG}.begin_checkout.parse.err`, err);
    }
  }

  return last_played_game_obj;
}

const AnalyticsService = {
  set_crm_session_id: () => {
    try {
      logd(`${TAG}.set_crm_session_id`);

      dispatchGlobalEvent({ event: ANALYTICS_EVENT.custom.set_crm_session_id });
    } catch (error) {
      logd(`${TAG}.set_crm_session_id`, error);
    }
  },
  login: (method = 'email') => {
    try {
      dispatchGlobalEvent({ event: ANALYTICS_EVENT.recommended.login, method });
    } catch (error) {
      logd(`${TAG}.login.error`, error);
    }
  },
  signup: (method = 'email') => {
    try {
      logd(`${TAG}.signup`, method);
      dispatchGlobalEvent({ event: ANALYTICS_EVENT.recommended.sign_up, method });
    } catch (error) {
      logd(`${TAG}.signup.error`, error);
    }
  },
  logout: () => {
    try {
      logd(`${TAG}.logout`);
      dispatchGlobalEvent({ event: ANALYTICS_EVENT.recommended.logout });
    } catch (error) {
      logd(`${TAG}.logout.error`, error);
    }
  },
  view_promotion: (page_type, items) => {
    try {
      logd(`${TAG}.view_promotion`, { page_type, items });
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.view_promotion,
        page_type,
        ecommerce: { items }
      });
    } catch (error) {
      logd(`${TAG}.view_promotion.error`, error);
    }
  },
  select_promotion: (data) => {
    try {
      logd(`${TAG}.select_promotion`, data);
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.select_promotion,
        ecommerce: { items: [data] }
      });
    } catch (error) {
      logd(`${TAG}.select_promotion.error`, error);
    }
  },
  view_item_list: (items) => {
    try {
      logd(`${TAG}.view_item_list`, items);
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.view_item_list,
        ecommerce: { items }
      });
    } catch (error) {
      logd(`${TAG}.view_item_list.error`, error);
    }
  },
  select_item: (data) => {
    try {
      logd(`${TAG}.select_item`, data);
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.select_item,
        ecommerce: { items: [data] }
      });
    } catch (error) {
      logd(`${TAG}.select_item.error`, error);
    }
  },
  view_item: (page_type, data) => {
    try {
      logd(`${TAG}.view_item`, data);
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.view_item,
        page_type,
        ecommerce: { items: [data] }
      });
    } catch (error) {
      logd(`${TAG}.view_item.error`, error);
    }
  },
  add_to_wishlist: (data) => {
    try {
      logd(`${TAG}.add_to_wishlist`, data);
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.add_to_wishlist,
        ecommerce: { items: [data] }
      });
    } catch (error) {
      logd(`${TAG}.add_to_wishlist.error`, error);
    }
  },
  begin_checkout: (page_type = 'account_page') => {
    try {
      logd(`${TAG}.begin_checkout`);

      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.ecommerce.begin_checkout,
        page_type,
        ecommerce: { items: [getLastPlayedGame()] }
      });
    } catch (error) {
      logd(`${TAG}.begin_checkout.error`, error);
    }
  },
  add_payment_info: (page_type = 'account_page', payment_type, currency) => {
    try {
      logd(`${TAG}.add_payment_info`, { page_type, payment_type, currency });

      if (currency && payment_type) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.ecommerce.add_payment_info,
          page_type,
          ecommerce: {
            payment_type,
            currency,
            items: [getLastPlayedGame()]
          }
        });
      }
    } catch (error) {
      logd(`${TAG}.add_payment_info.error`, error);
    }
  },
  purchase: (currency, transaction_id, value, deposit_type, payment_type) => {
    try {
      logd(`${TAG}.purchase`, { currency, transaction_id, value });
      if (currency && transaction_id && value && payment_type) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.ecommerce.purchase,
          deposit_type,
          payment_type,
          ecommerce: {
            currency,
            transaction_id,
            value,
            items: [getLastPlayedGame(value)]
          }
        });
      }
    } catch (error) {
      logd(`${TAG}.purchase.error`, error);
    }
  },
  purchase_processing: (page_type = 'account_page', payment_type, currency, value) => {
    try {
      logd(`${TAG}.purchase_processing`, { page_type, payment_type, currency, value });

      if (currency && payment_type) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.ecommerce.purchase_processing,
          page_type,
          ecommerce: {
            payment_type,
            currency,
            value,
            items: [getLastPlayedGame()]
          }
        });
      }
    } catch (error) {
      logd(`${TAG}.purchase_processing.error`, error);
    }
  },
  purchase_pending: (currency, transaction_id, value, deposit_type, payment_type) => {
    try {
      logd(`${TAG}.purchase_pending`, { currency, transaction_id, value });
      if (currency && transaction_id && value && payment_type) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.ecommerce.purchase_pending,
          deposit_type,
          payment_type,
          ecommerce: {
            currency,
            transaction_id,
            value,
            items: [getLastPlayedGame(value)]
          }
        });
      }
    } catch (error) {
      logd(`${TAG}.purchase_pending.error`, error);
    }
  },
  purchase_failure: (page_type = 'account_page', payment_type, currency, value, error) => {
    try {
      logd(`${TAG}.purchase_failure`, { page_type, payment_type, currency, value });

      if (currency && payment_type) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.ecommerce.purchase_failure,
          page_type,
          error,
          payment_type,
          ecommerce: {
            payment_type,
            currency,
            value,
            items: [getLastPlayedGame()]
          }
        });
      }
    } catch (error) {
      logd(`${TAG}.purchase_processing.error`, error);
    }
  },
  withdrawal_init: (page_type = 'account_page', currency, value) => {
    try {
      logd(`${TAG}.withdrawal_init`, { page_type, currency, value });
      if (currency && value) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.custom.withdrawal_init,
          page_type,
          currency,
          value
        });
      }
    } catch (error) {
      logd(`${TAG}.withdrawal_init.error`, error);
    }
  },
  // can not track
  withdrawal: (currency, value) => {
    try {
      logd(`${TAG}.withdrawal`, { currency, value });
      if (currency && value) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.custom.withdrawal,
          currency,
          value
        });
      }
    } catch (error) {
      logd(`${TAG}.withdrawal.error`, error);
    }
  },
  search: (search_term) => {
    try {
      logd(`${TAG}.search`, { search_term });
      if (search_term) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.recommended.search,
          search_term
        });
      }
    } catch (error) {
      logd(`${TAG}.withdrawal.error`, error);
    }
  },
  update_profile: (data) => {
    try {
      logd(`${TAG}.update_profile`, { data });
      if (data) {
        dispatchGlobalEvent({
          event: ANALYTICS_EVENT.custom.update_profile,
          username: get(data, 'username', ''),
          first_name: get(data, 'first_name', ''),
          middle_name: get(data, 'middle_name', ''),
          last_name: get(data, 'last_name', ''),
          phone_number: get(data, 'phone_number', ''),
          email: get(data, 'email', ''),
          country_code: get(data, 'country.iso2', ''),
          language_code: get(data, 'language.code', '')
        });
      }
    } catch (error) {
      logd(`${TAG}.update_profile.error`, error);
    }
  },
  fingerprint: () => {
    try {
      logd(`${TAG}.fingerprint`);
      dispatchGlobalEvent({
        event: ANALYTICS_EVENT.custom.fingerprint
      });
    } catch (error) {
      logd(`${TAG}.fingerprint.error`, error);
    }
  }
};

export default AnalyticsService;

import { fade, withStyles } from '@material-ui/core';

const GlobalTabs = withStyles(
  (theme) => ({
    '@global': {
      '.MuiTabs-root': {
        minHeight: 0,
        backgroundColor: fade(theme.palette.text.primary, 0.08),
        borderRadius: theme.shape.borderRadius,
        padding: 2
      },
      '.MuiTab-root': {
        minHeight: 0,
        borderRadius: theme.shape.borderRadius,
        textTransform: 'capitalize',
        padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
        fontWeight: 600
      },
      '.MuiTab-wrapper': {
        lineHeight: 1
      },
      '.MuiTabs-indicator': {
        display: 'none'
      }
    }
  }),
  { name: 'GlobalTabs' }
)(() => null);

export default GlobalTabs;

import { LEADERBOARD_GET_DATA } from '@/shared/constants/ActionTypes';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  data: []
};

export const useLeaderboardData = () => {
  return useSelector(({ leaderboard }) => leaderboard.data);
};

export const LeaderboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEADERBOARD_GET_DATA:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};

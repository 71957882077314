import {
  COMMON_FETCH_ERROR,
  COMMON_FETCH_START,
  COMMON_FETCH_SUCCESS,
  COMMON_HIDE_BOTTOM_SHEET,
  COMMON_HIDE_MESSAGE,
  COMMON_HIDE_POPUP,
  COMMON_SHOW_BOTTOM_SHEET,
  COMMON_SHOW_MESSAGE,
  COMMON_SHOW_POPUP
} from '@/shared/constants/ActionTypes';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  loading: false,
  values: {},
  message: '',
  type: '',
  popup: '',
  bottomSheet: false
};

export const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMMON_FETCH_START: {
      return { ...state, loading: true };
    }
    case COMMON_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case COMMON_SHOW_MESSAGE: {
      return {
        ...state,
        type: action.payload.type,
        values: action.payload.values,
        message: action.payload.message
      };
    }
    case COMMON_HIDE_MESSAGE: {
      return {
        ...state,
        type: '',
        values: {},
        message: ''
      };
    }
    case COMMON_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        type: 'error',
        values: action.payload.values,
        message: action.payload.error
      };
    }
    case COMMON_SHOW_POPUP: {
      return {
        ...state,
        popup: action.payload
      };
    }
    case COMMON_HIDE_POPUP: {
      return {
        ...state,
        popup: ''
      };
    }
    case COMMON_SHOW_BOTTOM_SHEET: {
      return {
        ...state,
        bottomSheet: true
      };
    }
    case COMMON_HIDE_BOTTOM_SHEET: {
      return {
        ...state,
        bottomSheet: false
      };
    }
    default:
      return state;
  }
};

export const useFetchStart = () => {
  return useSelector(({ common }) => common.loading);
};

export const usePopup = () => {
  return useSelector(({ common }) => common.popup);
};

export const useBottomSheet = () => {
  return useSelector(({ common }) => common.bottomSheet);
};

export const useMessage = () => {
  return useSelector(({ common }) => ({
    type: common.type,
    values: common.values,
    message: common.message
  }));
};

export const usePlayerImages = () => {
  return useSelector(({ common }) => common.playerImages);
};

import NotAvailable from '@/@crema/core/DataTable/NotAvailable';

export function CellMarketSports({ value }) {
  const { detail = [], is_combo_bet } = value?.sportbook_bet_detail || {};
  const isSingle = !is_combo_bet;
  const market = detail[0]?.market;
  if (isSingle && market) return market;
  return <NotAvailable />;
}

export default function renderBetType(_, item) {
  return <CellMarketSports value={item} />;
}

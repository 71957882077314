export const LOCAL_STORAGE = {
  nav_collapsed: 'nav_collapsed',
  pending_deposit: 'dp',
  document_referrer: 'document_referrer',
  out_of_money: 'out_of_money',
  hide_zero_balances: 'toggle_hide_zero_balances',
  view_in_fiat: 'toggle_view_in_fiat',
  last_played_game: 'last_played_game',
  raf_code: 'raf_code',
  switch_bonus: 'switch_bonus'
};

import AppLocale from '@/shared/localization';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

const LocaleProvider = (props) => {
  const { router } = props;
  const { defaultLocale } = router;
  const currentAppLocale = AppLocale[router?.locale] || AppLocale.en;

  // const handleError = (err) => {
  //   try {
  //     if (isDev) {
  //       console.error(err);
  //     }
  //     Sentry.captureException(err);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
      //onError={handleError}
    >
      {props.children}
    </IntlProvider>
  );
};

export default LocaleProvider;

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired
};

import { Icon } from '@/@crema';
import { DATE_FORMAT, DATE_FORMAT_DOB } from '@/shared/constants/AppEnums';
import { COLORS } from '@/shared/constants/ColorSets';
import { IconButton, makeStyles } from '@material-ui/core';
import { DateRangePicker } from '@material-ui/pickers';
import { useCallback, useMemo, useRef } from 'react';

const useStyles = makeStyles(() => ({
  dateRangePicker: {
    backgroundColor: COLORS.bldPaper
  }
}));

export default function FilterDateRangePicker({
  values = [null, null],
  onChangeValueDate,
  isForcePickerOpen,
  setIsOpen,
  onAccept
}) {
  const classes = useStyles();

  const setIsOpenRef = useRef(setIsOpen);
  setIsOpenRef.current = setIsOpen;

  const handleClose = useCallback(
    () => typeof setIsOpenRef.current === 'function' && setIsOpenRef.current(false),
    []
  );

  const handleError = useCallback(
    ([startReason, endReason], value) => {
      const [startTime, endTime] = value;

      if (
        startReason === 'invalidRange' &&
        endReason === 'invalidRange' &&
        startTime?.format(DATE_FORMAT_DOB) === endTime?.format(DATE_FORMAT_DOB)
      ) {
        onAccept(value);
        handleClose();
      }
    },
    [handleClose, onAccept]
  );

  const handleToggle = useCallback(
    () => typeof setIsOpenRef.current === 'function' && setIsOpenRef.current((isOpen) => !isOpen),
    []
  );

  const iconActionCpm = useMemo(
    () => (
      <IconButton key="date-range" aria-label="date-range" color="inherit" onClick={handleToggle}>
        <Icon name="date-range" className={classes.iconRight} />
      </IconButton>
    ),
    [classes.iconRight, handleToggle]
  );

  return (
    <DateRangePicker
      className={classes.dateRangePicker}
      onError={handleError}
      onAccept={onAccept}
      onClose={handleClose}
      disableFuture
      open={isForcePickerOpen}
      format={DATE_FORMAT}
      value={values}
      onChange={onChangeValueDate}
      calendars={1}
      renderInput={() => iconActionCpm}
    />
  );
}

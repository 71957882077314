import {
  getCities,
  getCountry,
  getKycDocument,
  getSignUpDocuments,
  getStates,
  resubmitKycRequest,
  submitKycRequest
} from '@/@crema/services/apis/profile';
import { logd } from '@/@crema/utility/Logging';
import { isValidArray } from '@/@crema/utility/utils';
import { handleErrors } from '@/redux/actions';
import {
  KYC_GET_CITY,
  KYC_GET_COUNTRY,
  KYC_GET_DOCUMENTS_SUCCESS,
  KYC_GET_ERROR,
  KYC_GET_START,
  KYC_GET_STATE,
  KYC_GET_SUCCESS,
  KYC_RESET,
  KYC_SET_STEP
} from '@/shared/constants/ActionTypes';
import { KYC_STATUS, KYC_STEP } from '@/shared/constants/AppEnums';
import get from 'lodash/get';

const getStepByStatus = (status) => {
  switch (status) {
    case KYC_STATUS.NEW:
      return KYC_STEP.identification;
    case KYC_STATUS.PENDING:
    case KYC_STATUS.APPROVED:
    case KYC_STATUS.DECLINED:
    case KYC_STATUS.REQUESTED_RESUBMISSION:
    case KYC_STATUS.RESUBMISSION_COMPLETE:
      return KYC_STEP.confirmation;
    default:
      return KYC_STEP.identification;
  }
};

export const onGetKycDocuments = () => {
  return async (dispatch) => {
    let data = [];
    try {
      const res = await getSignUpDocuments();
      data = res.data;
    } catch (error) {
      dispatch(handleErrors(error));
    }
    dispatch({ type: KYC_GET_DOCUMENTS_SUCCESS, payload: data });
  };
};

export const onGetKycDocument = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: KYC_GET_START });

      await dispatch(onGetKycDocuments());
      const res = await getKycDocument();

      const step = getStepByStatus(res.data?.status);
      dispatch(onChangeStep(step));
      dispatch({ type: KYC_GET_SUCCESS, payload: res.data?.status !== null ? res.data : null });
    } catch (error) {
      dispatch({ type: KYC_GET_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

export const onGetCountryList = () => {
  return async (dispatch) => {
    try {
      const res = await getCountry();
      if (isValidArray(res.data)) {
        dispatch({ type: KYC_GET_COUNTRY, payload: res.data });
      }
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onGetStateList = (id) => {
  return async (dispatch) => {
    try {
      const res = await getStates(id);
      if (isValidArray(res.data)) {
        dispatch({ type: KYC_GET_STATE, payload: res.data });
      }
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onGetCityList = (id) => {
  return async (dispatch) => {
    try {
      const res = await getCities(id);
      if (isValidArray(res.data)) {
        dispatch({ type: KYC_GET_CITY, payload: res.data });
      }
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onSubmitKycRequest = (data, actions, resubmission = false) => {
  return async (dispatch, getState) => {
    try {
      logd('onSubmitKycRequest.data', data);
      let res;

      if (resubmission) {
        res = await resubmitKycRequest(data);
      } else {
        res = await submitKycRequest(data);
      }

      logd('onSubmitKycRequest.res', res);
      if (res && res.status === 200) {
        const step = getStepByStatus(res.data?.status);
        dispatch(onChangeStep(step));
        dispatch({ type: KYC_GET_SUCCESS, payload: res.data });
        // AnalyticsService.logKycSubmitSuccess(data);
      }
    } catch (error) {
      // AnalyticsService.logKycSubmitFailed(data, error);
      if (error.status === 422) {
        if (actions && actions.setFieldError) {
          const resError = get(error, 'data.errors');
          const message = get(error, 'data.message');
          if (typeof resError === 'object') {
            let errors = [];
            try {
              Object.keys(resError).forEach((key, index) => {
                let error = resError[key][0];
                const fileName = data['documents[]'][index]['name'];
                error = error.replace(`documents.${index}`, fileName);
                errors.push(error);
                errors.push(<br />);
              });
            } catch (error) {
              logd('error', error);
              errors = [message];
            }
            logd('errors', errors);
            errors.length > 1 && actions.setFieldError('document', errors);
            return;
          }
        }
      } else {
        dispatch(handleErrors(error, actions));
      }
    }
  };
};

export const onChangeStep = (step) => {
  return async (dispatch) => {
    dispatch({ type: KYC_SET_STEP, payload: step });
  };
};

export const onResetKyc = () => {
  return async (dispatch) => {
    dispatch({ type: KYC_RESET });
  };
};

import { COLORS } from './ColorSets';

const defaultSep = ' | ';
const siteName = process.env.NEXT_PUBLIC_SITE_NAME;
const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || '1.0.0';
const isDev = process.env.NODE_ENV === 'development';

export const APP_CONFIG = {
  NEXT_PUBLIC_API_HOST: process.env.NEXT_PUBLIC_API_HOST || '',
  NEXT_PUBLIC_IMAGE_HOST: process.env.NEXT_PUBLIC_IMAGE_HOST || '',
  NEXT_PUBLIC_WALLET_INTERVAL: process.env.NEXT_PUBLIC_WALLET_INTERVAL || '',
  NEXT_PUBLIC_SYSTEM_STATUS_INTERVAL: process.env.NEXT_PUBLIC_SYSTEM_STATUS_INTERVAL || '',
  NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL || '',
  NEXT_PUBLIC_API_TIMEOUT: process.env.NEXT_PUBLIC_API_TIMEOUT || '',
  NEXT_PUBLIC_SITE_NAME: siteName,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV || '',
  NEXT_PUBLIC_APP_BUILD_TIME: process.env.NEXT_PUBLIC_APP_BUILD_TIME || '',
  NEXT_PUBLIC_APP_BUILD_TIMESTAMP: process.env.NEXT_PUBLIC_APP_BUILD_TIMESTAMP || '',
  NEXT_PUBLIC_APP_NAME: process.env.NEXT_PUBLIC_APP_NAME || '',
  NEXT_PUBLIC_APP_VERSION: appVersion,
  siteName,
  siteUrl: isDev ? 'http://localhost:3000' : process.env.NEXT_PUBLIC_SITE_URL,
  defaultTitle: `${siteName}${defaultSep}Live Casino, Cricket Betting, Lottery & Slots`,
  defaultAlt: siteName,
  defaultDescription: `${siteName} is the best and most secure online casino, sports betting, and cricket betting app that began with the government granting legal rights.`,
  defaultSep,
  defaultImage: 'https://cdn-cms.bigw.io/promotions/bigwins-play-now.png',
  theme_color: COLORS.primary,
  background_color: COLORS.background2,
  logo: {
    large: '/assets/logo/logo-full.png',
    small: '/assets/logo/logo.png'
  },
  icons: {
    'apple-icon-180': '/assets/icons/apple-icon-180.png',
    'mstile-icon-128': '/assets/icons/mstile-icon-128.png',
    'mstile-icon-270': '/assets/icons/mstile-icon-270.png',
    'mstile-icon-558': '/assets/icons/mstile-icon-558.png',
    'mstile-icon-558-270': '/assets/icons/mstile-icon-558-270.png',
    favicon: '/favicon.ico?v=1.0'
  }
};

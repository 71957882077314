import { ConfirmDialog, DataTable } from '@/@crema';
import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import { logd } from '@/@crema/utility/Logging';
import { useSelectedTransaction, useTransactionWithdraw } from '@/redux/reducers/Transaction';
import { Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import DialogDetailsWithdraw from '../../components/DialogDetails/DialogDetailsWithdraw';
import DrawerDetail from '../../components/DrawerDetail';
import ExpandedRow from '../../components/ExpandedRow';
import TransactionListItem from '../../components/ListItem/TransactionListItem';
import ListTransactionMobile from '../../components/ListTransactionMobile';
import {
  TRANSACTION,
  configure,
  useCancelSelectedItemDialog,
  useGetData,
  useSelectedItem
} from '../../configure/';
import TransactionProvider from '../ListContext';

const FIELDS_DETAIL = [
  'uuid',
  'amount',
  'withdrawBy',
  'address',
  'blockchainNetwork',
  'statusWithdraw',
  'tag',
  'memo',
  'txID',
  'createdAt',
  'cancelWithdraw'
];
const FIELDS_DETAIL_DRAWER = [
  'uuid',
  'amount',
  'withdrawBy',
  'address',
  'blockchainNetwork',
  'statusWithdraw',
  'tag',
  'memo',
  'txID',
  'createdAt',
  'cancelWithdrawDrawer'
];
const COLUMNS = ['uuid', 'amount', 'statusWithdraw', 'createdAt', 'actionWithdraw'];
const COLUMNS_TABLE = COLUMNS.map((key) => configure.fields[key]);

const ListWithdraw = () => {
  const isSM = useBreakPointDown('sm');
  const { formatMessage } = useIntl();
  const transactionData = useTransactionWithdraw();
  logd('inspect.transaction.List.transactionWithdrawData', transactionData);
  const { data, hasMore, loading, page, perPage } = transactionData;
  const { onLoadMore } = useGetData(TRANSACTION.withdrawalHistory);
  const { data: selectedItem } = useSelectedTransaction();
  const { isSelected, onSelectItem, onUnselectItem } = useSelectedItem();

  const {
    openDialogConfirmCancel,
    onOpenDialogConfirmCancel,
    onCloseDialogConfirmCancel,
    onCancel
  } = useCancelSelectedItemDialog(TRANSACTION.withdrawalHistory);

  const handleCancel = useCallback(() => {
    onCancel(onUnselectItem);
  }, [onCancel, onUnselectItem]);

  const renderItem = useCallback(
    ({ item }) => <TransactionListItem variant="withdraw" item={item} onClick={onSelectItem} />,
    [onSelectItem]
  );

  const renderExpandedRow = (row, expanded, toggleExpanded) => {
    return (
      <ExpandedRow
        row={row}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        onClick={onOpenDialogConfirmCancel}
      />
    );
  };

  return (
    <TransactionProvider onUnselectItem={onUnselectItem}>
      {isSM ? (
        <ListTransactionMobile
          hasMore={hasMore}
          loading={loading}
          data={data}
          renderItem={renderItem}
          onLoadMore={onLoadMore}
        />
      ) : (
        <DataTable
          loading={loading && page === 0}
          data={data}
          columns={COLUMNS_TABLE}
          page={page}
          count={0}
          rowsPerPage={perPage}
          onRowClick={onSelectItem}
          messageNoData={formatMessage({ id: 'common.noTransactionHistory' })}
          isPaging={false}
          canLoadMore={hasMore}
          isLoadMore={loading && page !== 0}
          loadMore={onLoadMore}
          onActionClick={onOpenDialogConfirmCancel}
          renderExpandedRow={renderExpandedRow}
        />
      )}

      <DialogDetailsWithdraw
        open={isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={onUnselectItem}
        fields={FIELDS_DETAIL}
      />

      <DrawerDetail
        title={formatMessage({ id: 'transactionDetail.header.withdrawDetail' })}
        fields={FIELDS_DETAIL_DRAWER}
        open={!isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={onUnselectItem}
      />

      <ConfirmDialog
        open={selectedItem !== null && openDialogConfirmCancel}
        onClose={onCloseDialogConfirmCancel}
        onClickYes={handleCancel}
        onClickCancel={onCloseDialogConfirmCancel}
        title={formatMessage({
          id: 'account.transaction.cancelTransaction.title'
        })}
        content={
          <Typography color="primary" component="span" variant="h5">
            {formatMessage({
              id: 'account.transaction.cancelTransaction.description'
            })}
          </Typography>
        }
      />
    </TransactionProvider>
  );
};

export default ListWithdraw;

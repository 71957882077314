import { isValidFunction } from '@/@crema/utility/utils';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import Loader from '../Loader';
import NoDataFound from '../NoDataFound';
import { ExpandableTableRow } from './ExpandableTableRow';
import TableHead from './TableHead';
import TableLoadMore from './TableLoadMore';
import TablePagination from './TablePagination';

export const EMPTY_FUNC = () => {};

const useStyles = makeStyles((theme) => ({
  dataTableRoot: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: 24,
    position: 'relative',
    boxShadow: 'none',
    borderRadius: theme.shape.borderRadius,
    '&.havePointer': {
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '&:hover': {
            cursor: 'pointer'
          }
        }
      }
    }
  },
  dataTableContainer: {
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    '&.small': {
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '&:hover': {
            cursor: 'pointer'
          },
          '& .cell-text': theme.typography.caption,
          '& .MuiTableCell-body': theme.typography.caption
        }
      }
    }
  },
  empty: {
    minHeight: 588,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  loadingWrapper: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    flex: '1 1 0%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.1)'
  },
  header: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'rgba(255, 255, 255, 0.75)'
  },
  cell: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left'
  }
}));

function DataTable({
  size,
  title,
  columns,
  data,
  loading,
  count,
  page,
  rowsPerPage,
  sortBy,
  orderBy,
  onSort,
  onRowClick,
  changePage,
  messageNoData,
  renderExpandedRow,
  isPaging = true,
  canLoadMore,
  isLoadMore,
  loadMore,
  onActionClick
}) {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = useState({
    name: orderBy,
    direction: sortBy
  });

  const havePointer = useMemo(() => {
    return isValidFunction(onRowClick) && onRowClick !== EMPTY_FUNC;
  }, [onRowClick]);

  const handleSort = useCallback(
    (index) => {
      let newOrder = 'asc';
      let sequenceOrder = ['asc', 'desc', 'none'];
      if (columns[index].name === sortOrder.name) {
        let pos = sequenceOrder.indexOf(sortOrder.direction);
        if (pos !== -1) {
          pos++;
          if (pos >= sequenceOrder.length) pos = 0;
          newOrder = sequenceOrder[pos];
        }
      }
      const newSortOrder = {
        name: columns[index].name,
        direction: newOrder
      };
      setSortOrder(newSortOrder);
      onSort({
        orderBy: newOrder === 'none' ? '' : columns[index].name,
        sortBy: newOrder === 'none' ? '' : newOrder
      });
    },
    [columns, onSort, sortOrder.direction, sortOrder.name]
  );

  const titleCpm = useMemo(
    () =>
      title && (
        <>
          <Box py={2} px={{ xs: 4, sm: 6 }}>
            <Typography variant="subtitle1" className="bold">
              {title}
            </Typography>
          </Box>
          <Divider />
        </>
      ),
    [title]
  );

  return (
    <div className={classes.dataTableRoot}>
      <Paper
        className={clsx(classes.paper, {
          [classes.empty]: loading || data.length <= 0,
          havePointer
        })}>
        {loading && (
          <div className={classes.loadingWrapper}>
            <Loader />
          </div>
        )}
        {titleCpm}
        <div className={clsx(classes.dataTableContainer, size)}>
          {data.length > 0 ? (
            <TableContainer>
              <Table className={classes.table} aria-label="collapsible table">
                <TableHead
                  classes={classes}
                  columns={columns}
                  sortOrder={sortOrder}
                  onSort={handleSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {data.map((row, rowIndex) => (
                    <ExpandableTableRow
                      columns={columns}
                      key={row?.id}
                      row={row}
                      rowIndex={rowIndex}
                      renderExpandedRow={renderExpandedRow}
                      onRowClick={onRowClick}
                      onActionClick={onActionClick}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : loading ? null : (
            <NoDataFound message={messageNoData} />
          )}
        </div>
      </Paper>
      {data.length > 0 && isPaging && (
        <TablePagination
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
          loading={loading}
        />
      )}
      {data.length > 0 && !isPaging && (
        <TableLoadMore canLoadMore={canLoadMore} isLoadMore={isLoadMore} loadMore={loadMore} />
      )}
    </div>
  );
}

DataTable.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  sortBy: PropTypes.oneOf(['none', 'asc', 'desc']),
  orderBy: PropTypes.string,
  count: PropTypes.number,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onSort: PropTypes.func,
  onRowClick: PropTypes.func,
  changePage: PropTypes.func,
  isPaging: PropTypes.bool
};

DataTable.defaultProps = {
  size: 'small',
  sortBy: 'none',
  orderBy: '',
  data: [],
  rowsPerPage: 0,
  onSort: EMPTY_FUNC,
  onRowClick: EMPTY_FUNC,
  changePage: EMPTY_FUNC,
  isPaging: true
};

export default memo(DataTable);

import {
  DATE_FORMAT,
  DATE_FORMAT_MMMDYYYY,
  DATE_TIME_MMMDYYYY_HHMM,
  DATE_TIME_YYMMDD_HHMMSS
} from '@/shared/constants/AppEnums';
import dayjs from 'dayjs';
import * as isTodayDayjs from 'dayjs/plugin/isToday';
import * as isYesterdayDayjs from 'dayjs/plugin/isYesterday';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { limit } from './utils-common';
import { isValidString } from './utils-string';

dayjs.extend(isTodayDayjs);
dayjs.extend(isYesterdayDayjs);
dayjs.extend(timezone);
dayjs.extend(utc);

export function formatDatetime(str, pattern = DATE_TIME_MMMDYYYY_HHMM) {
  try {
    if (!str) {
      return '';
    }
    const timeZone = dayjs.tz.guess(); // Get local timezone => "Asia/Saigon"
    return dayjs.utc(str).tz(timeZone).format(pattern);
  } catch (error) {
    return str;
  }
}

export function validateBirthday(date, minAge) {
  try {
    if (!date) {
      return false;
    }
    return dayjs().diff(dayjs(date), 'years') >= minAge;
  } catch (error) {
    return false;
  }
}

export function dateToString(date) {
  const dateStr = dayjs(date);
  if (dateStr.isValid()) {
    return dateStr.format(DATE_FORMAT);
  }
  return dateStr.isValid() ? dateStr.format(DATE_FORMAT) : '';
}

export function unixTimestampToDateString(timestamp) {
  const date = dayjs.unix(timestamp);
  if (date.isValid()) {
    return date.format(DATE_FORMAT_MMMDYYYY);
  }
  return '';
}

export function isToday(date) {
  const d = dayjs(date);
  if (d.isValid()) {
    return d.isToday();
  }
  return false;
}

export function isYesterday(date) {
  const d = dayjs(date);
  if (d.isValid()) {
    return d.isYesterday();
  }
  return false;
}

export function getStartOfDay(str) {
  const date = dayjs(str);
  if (date.isValid()) {
    return date.startOf('date').format(DATE_TIME_YYMMDD_HHMMSS);
  }
  return '';
}

export function getEndOfDay(str) {
  const date = dayjs(str);
  if (date.isValid()) {
    return date.endOf('date').format(DATE_TIME_YYMMDD_HHMMSS);
  }
  return '';
}

/**
 * Returns a cloned Day.js object with a specified amount of time added.
 * ```
 * dayjs().add(7, 'day')// => Dayjs
 * ```
 * Units are case insensitive, and support plural and short forms.
 *
 * Docs: https://day.js.org/docs/en/manipulate/add
 * @param {number} value
 * @param {dayjs.ManipulateType} unit
 * @returns {dayjs.Dayjs} date
 */
export const getFutureDate = (value = 8, unit = 'h') => dayjs().add(value, unit).toDate();

export const getMonth = (str) => {
  if (!isValidString(str)) {
    return '';
  }
  return limit(str.substring(0, 2), '12');
};

export const getYear = (str) => {
  if (!isValidString(str)) {
    return '';
  }
  return str.substring(2, 4);
};

export const formatExpiry = (val) => {
  let month = getMonth(val);
  let year = getYear(val);
  return month + (year.length ? '/' + year : '');
};

export const getTimeZoneName = () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone) {
      return timezone;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Get TimeZone Name', error);
    return null;
  }
};

import asyncComponent from '@/@crema/utility/asyncComponent';

const Lottie = asyncComponent(() => import('./Lottie'), false);

export default function LottiePlayer({ path = null }) {
  if (!path) {
    return null;
  }

  return <Lottie path={path} />;
}

import { TRANSACTION_TYPES } from '@/modules/Account/Transactions/configure';
import {
  CLEAR_SELECTED_TRANSACTION,
  TRANSACTION_CANCEL_DEPOSIT_ERROR,
  TRANSACTION_CANCEL_DEPOSIT_START,
  TRANSACTION_CANCEL_DEPOSIT_SUCCESS,
  TRANSACTION_CANCEL_WITHDRAW_ERROR,
  TRANSACTION_CANCEL_WITHDRAW_START,
  TRANSACTION_CANCEL_WITHDRAW_SUCCESS,
  TRANSACTION_GET_DEPOSIT_ERROR,
  TRANSACTION_GET_DEPOSIT_START,
  TRANSACTION_GET_DEPOSIT_SUCCESS,
  TRANSACTION_GET_GAME_ERROR,
  TRANSACTION_GET_GAME_SPORTSBOOK_ERROR,
  TRANSACTION_GET_GAME_SPORTSBOOK_START,
  TRANSACTION_GET_GAME_SPORTSBOOK_SUCCESS,
  TRANSACTION_GET_GAME_START,
  TRANSACTION_GET_GAME_SUCCESS,
  TRANSACTION_GET_STATUS_ERROR,
  TRANSACTION_GET_STATUS_START,
  TRANSACTION_GET_STATUS_SUCCESS,
  TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS,
  TRANSACTION_GET_WITHDRAW_ERROR,
  TRANSACTION_GET_WITHDRAW_START,
  TRANSACTION_GET_WITHDRAW_SUCCESS,
  TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE,
  TRANSACTION_LOADING_CHECK_SPORTSBOOK_START,
  TRANSACTION_RESET,
  TRANSACTION_RESET_DEPOSIT,
  TRANSACTION_RESET_GAME,
  TRANSACTION_RESET_GAME_SPORTSBOOK,
  TRANSACTION_RESET_STATUS,
  TRANSACTION_RESET_WITHDRAW,
  TRANSACTION_SET_DEPOSIT,
  TRANSACTION_SET_DEPOSIT_SUCCESS,
  TRANSACTION_SET_FILTER_SPORT_HISTORIES,
  TRANSACTION_SET_SHOW_SPORTSBOOK_TAB,
  TRANSACTION_SET_WITHDRAW
} from '@/shared/constants/ActionTypes';
import { PAGING_DEFAULT } from '@/shared/constants/PagingConst';
import { DEFAULT_FILTER_SPORT_HISTORY } from '@/shared/constants/Transaction';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

const INIT_DATA = {
  page: 0,
  data: [],
  loading: false,
  perPage: PAGING_DEFAULT.dataTablePerPage,
  hasMore: false
};

const INIT_DATA_SPORT = {
  ...INIT_DATA,
  filters: DEFAULT_FILTER_SPORT_HISTORY
};

const INIT_SELECTED_TRANSACTION = {
  data: null
};

const INIT_STATE = {
  showSportsbookTab: undefined,
  loadingCheckShowSportsbookTab: false,

  depositHistory: INIT_DATA,
  withdrawalHistory: INIT_DATA,
  gameHistory: INIT_DATA,
  gameHistorySport: INIT_DATA_SPORT,
  status: null,
  error: null,
  loading: false,
  selectedTransaction: INIT_SELECTED_TRANSACTION,
  isDepositSuccess: false
};

export const TRANSACTION = {
  depositHistory: 'depositHistory',
  withdrawalHistory: 'withdrawalHistory',
  gameHistory: 'gameHistory'
};

export const useTransactionDeposit = () => {
  return useSelector((state) => state.transaction.depositHistory);
};

export const useTransactionWithdraw = () => {
  return useSelector((state) => state.transaction.withdrawalHistory);
};

export const useTransactionGame = () => {
  return useSelector((state) => state.transaction.gameHistory);
};

export const useTransactionGameSport = () => {
  return useSelector((state) => state?.transaction?.gameHistorySport);
};

export const useTransactionGameSportTabs = () => {
  return useSelector((state) => {
    const showTransactionGameSport =
      get(state, 'transaction.showSportsbookTab', false) ||
      get(state, 'transaction.gameHistorySport.data.length', 0) > 0;

    const result = [...TRANSACTION_TYPES];

    if (!showTransactionGameSport) result.pop();

    return result;
  });
};

export const useLoadingCheckShowSportsbookTab = () => {
  return useSelector(({ transaction }) => transaction.loadingCheckShowSportsbookTab);
};

export const useTransactionStatus = () => {
  return useSelector(({ transaction }) => transaction.status);
};

export const useTransactionError = () => {
  return useSelector(({ transaction }) => transaction.error);
};

export const useTransactionLoading = () => {
  return useSelector(({ transaction }) => transaction.loading);
};

export const useSelectedTransaction = () => {
  return useSelector(({ transaction }) => transaction.selectedTransaction);
};

export const useDepositSuccess = () => {
  return useSelector(({ transaction }) => transaction.isDepositSuccess);
};

export const TransactionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSACTION_RESET:
      return {
        ...state,
        ...INIT_STATE
      };

    case TRANSACTION_SET_SHOW_SPORTSBOOK_TAB:
      return {
        ...state,
        showSportsbookTab: action.payload
      };
    case TRANSACTION_LOADING_CHECK_SPORTSBOOK_START:
      return {
        ...state,
        loadingCheckShowSportsbookTab: true
      };
    case TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE:
      return {
        ...state,
        loadingCheckShowSportsbookTab: false
      };
    case TRANSACTION_GET_DEPOSIT_START:
      return {
        ...state,
        depositHistory: {
          ...state.depositHistory,
          loading: true
        }
      };
    case TRANSACTION_GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        depositHistory: {
          ...state.depositHistory,
          data:
            action.payload?.current_page === 1
              ? action.payload.data
              : [...state.depositHistory.data, ...action.payload.data],
          page: action.payload?.current_page,
          loading: false,
          hasMore: action.payload?.has_more
        }
      };
    case TRANSACTION_GET_DEPOSIT_ERROR:
      return {
        ...state,
        depositHistory: {
          ...state.depositHistory,
          loading: false
        }
      };
    case TRANSACTION_SET_DEPOSIT:
      return {
        ...state,
        depositHistory: {
          ...state.depositHistory,
          data: action.payload
        }
      };
    case TRANSACTION_RESET_DEPOSIT:
      return {
        ...state,
        depositHistory: INIT_DATA
      };
    case TRANSACTION_CANCEL_DEPOSIT_START:
      return { ...state, loading: true };
    case TRANSACTION_CANCEL_DEPOSIT_SUCCESS:
      return { ...state, loading: false, isOpenDialog: false };
    case TRANSACTION_CANCEL_DEPOSIT_ERROR:
      return { ...state, loading: false };

    // END DEPOSIT

    case TRANSACTION_GET_WITHDRAW_START:
      return {
        ...state,
        withdrawalHistory: {
          ...state.withdrawalHistory,
          loading: true
        }
      };
    case TRANSACTION_GET_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawalHistory: {
          ...state.withdrawalHistory,
          data:
            action.payload?.current_page === 1
              ? action.payload.data
              : [...state.withdrawalHistory.data, ...action.payload.data],
          hasMore: action.payload?.has_more,
          page: action.payload.current_page,
          loading: false
        }
      };
    case TRANSACTION_GET_WITHDRAW_ERROR:
      return {
        ...state,
        withdrawalHistory: {
          ...state.withdrawalHistory,
          loading: false
        }
      };
    case TRANSACTION_SET_WITHDRAW:
      return {
        ...state,
        withdrawalHistory: {
          ...state.withdrawalHistory,
          data: action.payload
        }
      };
    case TRANSACTION_RESET_WITHDRAW:
      return {
        ...state,
        withdrawalHistory: INIT_DATA
      };
    case TRANSACTION_CANCEL_WITHDRAW_START:
      return { ...state, loading: true };
    case TRANSACTION_CANCEL_WITHDRAW_SUCCESS:
      return { ...state, loading: false, isOpenDialog: false };
    case TRANSACTION_CANCEL_WITHDRAW_ERROR:
      return { ...state, loading: false };

    // END WITHDRAW

    case TRANSACTION_GET_GAME_START:
      return {
        ...state,
        gameHistory: {
          ...state.gameHistory,
          loading: true
        }
      };
    case TRANSACTION_GET_GAME_SUCCESS:
      return {
        ...state,
        gameHistory: {
          ...state.gameHistory,
          data:
            action.payload?.current_page === 1
              ? action.payload.data
              : [...state.gameHistory.data, ...action.payload.data],
          page: action.payload.current_page,
          loading: false,
          hasMore: action.payload?.has_more
        }
      };
    case TRANSACTION_GET_GAME_ERROR:
      return {
        ...state,
        gameHistory: {
          ...state.gameHistory,
          loading: false
        }
      };
    case TRANSACTION_RESET_GAME:
      return {
        ...state,
        gameHistory: INIT_DATA
      };

    // END GAME

    case TRANSACTION_GET_GAME_SPORTSBOOK_START:
      return {
        ...state,
        gameHistorySport: {
          ...state.gameHistorySport,
          loading: true
        }
      };
    case TRANSACTION_GET_GAME_SPORTSBOOK_SUCCESS:
      return {
        ...state,
        gameHistorySport: {
          ...state.gameHistorySport,
          data:
            action.payload?.current_page === 1
              ? action.payload.data
              : [...state.gameHistorySport.data, ...action.payload.data],
          page: action.payload.current_page,
          loading: false,
          hasMore: action.payload?.has_more
        }
      };
    case TRANSACTION_GET_GAME_SPORTSBOOK_ERROR:
      return {
        ...state,
        gameHistorySport: {
          ...state.gameHistorySport,
          loading: false
        }
      };

    case TRANSACTION_SET_FILTER_SPORT_HISTORIES:
      return {
        ...state,
        gameHistorySport: {
          ...INIT_DATA_SPORT,
          filters: {
            ...state.gameHistorySport.filters,
            ...action.payload
          },
          page: 0
        }
      };
    case TRANSACTION_RESET_GAME_SPORTSBOOK:
      return {
        ...state,
        gameHistorySport: INIT_DATA_SPORT
      };
    // END GAME SPORT

    case TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedTransaction: {
          data: action.payload
        },
        error: null
      };
    case CLEAR_SELECTED_TRANSACTION:
      return {
        ...state,
        loading: false,
        selectedTransaction: INIT_SELECTED_TRANSACTION,
        error: null
      };

    // END SELECTED

    case TRANSACTION_GET_STATUS_START:
      return {
        ...state,
        loading: true,
        status: null
      };
    case TRANSACTION_GET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload
      };
    case TRANSACTION_GET_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        status: null,
        error: action.payload
      };
    case TRANSACTION_RESET_STATUS:
      return {
        ...state,
        loading: false,
        status: null,
        error: null
      };

    // END STATUS

    case TRANSACTION_SET_DEPOSIT_SUCCESS:
      return {
        ...state,
        isDepositSuccess: action.payload
      };

    default:
      return state;
  }
};

import { withStyles } from '@material-ui/core';

const GlobalRadio = withStyles(
  (theme) => ({
    '@global': {}
  }),
  { name: 'GlobalRadio' }
)(() => null);

export default GlobalRadio;

import { Field, getIn } from 'formik';
import BaseAutocomplete from '../../Inputs/Autocomplete';
import BaseTextField from '../../Inputs/TextField';

const _BaseAutocomplete = ({
  label,
  required,
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, setFieldValue, errors, touched },
  helperText,
  onChangeSelect,
  ...nest
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  const onChange = (_event, value) => {
    setFieldValue(field.name, value);
    typeof onChangeSelect === 'function' && onChangeSelect(field.name, value, setFieldValue);
  };

  const onBlur = (event) => {
    fieldOnBlur(event ?? field.name);
  };

  return (
    <BaseAutocomplete
      {...field}
      {...nest}
      disabled={disabled ?? isSubmitting}
      loading={isSubmitting}
      onBlur={onBlur}
      onChange={onChange}
      fullWidth
      renderInput={(params) => {
        return (
          <BaseTextField
            {...params}
            label={label}
            required={required}
            error={showError}
            helperText={showError ? fieldError : helperText}
          />
        );
      }}
    />
  );
};

const Autocomplete = (props) => {
  return <Field {...props} component={_BaseAutocomplete}></Field>;
};

export default Autocomplete;

import { getCMSName, getPagingObj, isNumber, isValidArray } from '@/@crema/utility/utils';
import {
  GAME_GET_COUPON_ERROR,
  GAME_GET_COUPON_START,
  GAME_GET_COUPON_SUCCESS,
  GAME_GET_DETAIL_ERROR,
  GAME_GET_DETAIL_START,
  GAME_GET_DETAIL_SUCCESS,
  GAME_GET_LAUNCH_GAME_DEMO_URL_ERROR,
  GAME_GET_LAUNCH_GAME_URL_ERROR,
  GAME_LIST_GET_ERROR,
  GAME_LIST_GET_START,
  GAME_LIST_GET_SUCCESS,
  GAME_LIST_REFRESH,
  GAME_LIST_RESET,
  GAME_LIST_SET_FILTERS,
  GAME_RESET_DETAIL,
  GAME_SEARCH_ERROR,
  GAME_SEARCH_START,
  GAME_SEARCH_SUCCESS,
  GAME_SET_DEFAULT_FILTERS_CONFIG,
  GAME_SET_FILTERS_CONFIG,
  GAME_SET_PLAY_MODE,
  GAME_SET_SEARCH_RECOMMENDED,
  GAME_SET_SLUG,
  GET_FAVORITE_GAMES_ASSETS_ERROR,
  GET_FAVORITE_GAMES_ASSETS_START,
  GET_FAVORITE_GAMES_ASSETS_SUCCESS,
  GET_FAVORITE_GAMES_ERROR,
  GET_FAVORITE_GAMES_START,
  GET_FAVORITE_GAMES_SUCCESS,
  RESET_FAVORITE_GAME,
  RESET_FAVORITE_GAME_ASSETS,
  SET_FAVORITE_GAME_ERROR,
  SET_FAVORITE_GAME_START,
  SET_FAVORITE_GAME_SUCCESS,
  SHOW_GAME_DETAIL_FOR_PLAY,
  UPDATE_FAVORITE_GAMES_SUCCESS
} from '@/shared/constants/ActionTypes';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { GAME_FILTER } from '@/shared/constants/AppEnums';
import { PLAY_MODE } from '@/shared/constants/GameConst';
import { PAGING_DEFAULT, PAGING_DEFAULT_STATE } from '@/shared/constants/PagingConst';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { useSelector } from 'react-redux';

/**
 * parse game detail data
 * @param game as Object
 * @returns {Object}
 * name
 * provider
 * code
 * desktop_image
 * tablet_image
 * mobile_image
 * game_url
 * has_trial
 * maintenance
 */
export const parseGameDetailData = (game = {}) => {
  const name = getCMSName(game);
  const game_type = getCMSName(game?.game_type);
  const provider_name = get(game, 'game_provider.name', '');
  const code = get(game, 'code', '') || get(game, 'game.code', '');
  const title = `${name} | Online ${game_type} | ${APP_CONFIG.siteName}`;
  const description = `Play ${name} online ${game_type} by ${provider_name} on ${APP_CONFIG.siteName}. A fully licensed online casino. Get a big bonus on your first deposit. Sign up and play now.`;
  const alt = name || APP_CONFIG.siteName;

  return {
    id: get(game, 'game.id', '') || get(game, 'game_id', '') || get(game, 'id', uniqueId()),
    name,
    favorited: game?.favorited || false,
    provider: get(game, 'game_provider.code', '') || get(game, 'game.game_provider.code', ''),
    providerName: get(game, 'game_provider.name', '') || get(game, 'game.game_provider.name', ''),
    provider_name,
    code,
    desktop_image:
      get(game, 'cms_property.desktop_image.path', '') ||
      get(game, 'game.cms_property.desktop_image.path', ''),
    tablet_image:
      get(game, 'cms_property.tablet_image.path', '') ||
      get(game, 'game.cms_property.tablet_image.path', ''),
    mobile_image:
      get(game, 'cms_property.mobile_image.path', '') ||
      get(game, 'game.cms_property.mobile_image.path', ''),
    game_url: '',
    game_demo_url: '',
    has_trial: !!game?.has_trial,
    maintenance: get(game, 'game_provider.maintenance', false),
    head_meta: get(game, 'cms_property.head_meta', []),
    title,
    description,
    alt,
    expire_at: get(game, 'expire_at', ''),
    claimable: get(game, 'claimable', false)
  };
};

const FAVORITE_GAME_DEFAULT_STATE = {
  loading: false,
  data: [],
  total: 0
};

const FAVORITE_GAME_ASSET_DEFAULT_STATE = {
  page: 0,
  perPage: PAGING_DEFAULT.perPage,
  lastPage: 0,
  total: 0,
  data: [],
  loading: false,
  sortBy: '',
  orderBy: '',
  filters: [],
  error: null
};

export const initialGameState = {
  recommendedGames: [],
  searchResults: [],
  searching: false,
  loading: false,
  gameDetail: null,
  gameDetailError: null,
  launchGameError: null,
  launchDemoGameError: null,
  isGameLoading: false,
  playMode: PLAY_MODE.SELECT,
  loadingCoupon: false,
  // for game list
  list: PAGING_DEFAULT_STATE,
  slug: '',
  favoriteGames: FAVORITE_GAME_DEFAULT_STATE,
  favoriteGameAssets: FAVORITE_GAME_ASSET_DEFAULT_STATE
};

export const useGame = () => {
  return useSelector(({ game }) => game);
};

export const useGameLoadingCoupon = () => {
  return useSelector(({ game }) => game?.loadingCoupon);
};

export const useGameLoading = () => {
  return useSelector(({ game }) => game.isGameLoading);
};

export const useGameProductTypes = () => {
  return useSelector(({ game }) => game[GAME_FILTER.productTypes]);
};

export const useGamePaging = () => {
  return useSelector(({ game }) => game.paging);
};

export const useGameIsLoadMore = () => {
  return useSelector(({ game }) => {
    const isLoadMore = game?.isGameLoading && game?.data?.length > 0;
    return typeof isLoadMore === 'boolean' ? isLoadMore : false;
  });
};

export const useRecommendedGames = () => {
  return useSelector(({ game }) => game.recommendedGames);
};

export const useGameSearching = () => {
  return useSelector(({ game }) => game.searching);
};

export const useGameSearchResults = () => {
  return useSelector(({ game }) => game.searchResults);
};

export const useGameList = () => {
  const list = useSelector(({ game }) => game.list);
  return getPagingObj(list);
};

// Favorite Games
export const useFavoriteGameList = () => {
  const list = useSelector(({ game }) => game.favoriteGameAssets);
  return getPagingObj(list);
};

export const useFavoriteGames = () => {
  const favoriteGames = useSelector(({ game }) => game.favoriteGames?.data || []);
  return favoriteGames;
};

export const useTotalFavoriteGames = () => {
  return useSelector(({ game }) => {
    return isNumber(game?.favoriteGames?.total) ? game?.favoriteGames?.total : 0;
  });
};

export const useGameSlug = () => {
  const slug = useSelector(({ game }) => game.slug);
  return slug;
};

export const GameReducer = (state = initialGameState, action) => {
  switch (action.type) {
    case SHOW_GAME_DETAIL_FOR_PLAY:
      return {
        ...state,
        gameDetailError: null,
        launchGameError: null,
        launchDemoGameError: null,
        playMode: PLAY_MODE.SELECT
      };
    case GAME_GET_DETAIL_START:
      return {
        ...state,
        launchGameError: null,
        launchDemoGameError: null,
        loading: true
      };
    case GAME_GET_DETAIL_SUCCESS:
      return {
        ...state,
        gameDetail: action.payload,
        loading: false
      };
    case GAME_GET_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        gameDetail: null,
        gameDetailError: action.payload
      };
    case GAME_GET_LAUNCH_GAME_URL_ERROR:
      return {
        ...state,
        loading: false,
        launchGameError: action.payload
      };
    case GAME_GET_LAUNCH_GAME_DEMO_URL_ERROR:
      return {
        ...state,
        loading: false,
        launchDemoGameError: action.payload
      };

    case GAME_RESET_DETAIL:
      return {
        ...state,
        gameDetail: null,
        loading: false,
        launchGameError: null,
        launchDemoGameError: null
      };

    case GAME_SET_SEARCH_RECOMMENDED:
      return {
        ...state,
        recommendedGames: action.payload
      };

    case GAME_SEARCH_START:
      return {
        ...state,
        searching: true
      };

    case GAME_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
        searching: false
      };
    case GAME_SEARCH_ERROR:
      return {
        ...state,
        searching: false
      };
    case GAME_SET_PLAY_MODE:
      return {
        ...state,
        playMode: action.payload
      };
    case GAME_GET_COUPON_START:
      return {
        ...state,
        loadingCoupon: true
      };
    case GAME_GET_COUPON_SUCCESS:
      return {
        ...state,
        loadingCoupon: false
      };
    case GAME_GET_COUPON_ERROR:
      return {
        ...state,
        loadingCoupon: false
      };

    // GAME LIST
    case GAME_LIST_REFRESH:
      return {
        ...state,
        list: {
          ...PAGING_DEFAULT_STATE,
          refreshing: true,
          data: state.list.data
        }
      };

    case GAME_LIST_GET_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null
        }
      };

    case GAME_LIST_GET_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          page: action.payload.current_page,
          lastPage: action.payload.last_page,
          total: action.payload.total,
          data:
            action.payload.current_page === 1
              ? action.payload.data
              : [...state.list.data, ...action.payload.data],
          refreshing: false,
          loading: false
        }
      };

    case GAME_LIST_GET_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          refreshing: false,
          loading: false,
          error: action.payload
        }
      };

    case GAME_LIST_SET_FILTERS:
      return {
        ...state,
        list: {
          ...PAGING_DEFAULT_STATE,
          filters: action.payload
        }
      };

    case GAME_LIST_RESET:
      return {
        ...state,
        list: PAGING_DEFAULT_STATE,
        filtersConfig: [],
        defaultFiltersConfig: []
      };
    case GAME_SET_FILTERS_CONFIG:
      return {
        ...state,
        filtersConfig: action.payload || []
      };
    case GAME_SET_DEFAULT_FILTERS_CONFIG:
      return {
        ...state,
        defaultFiltersConfig: action.payload || []
      };
    case GAME_SET_SLUG:
      return {
        ...state,
        slug: action.payload
      };
    // END GAME LIST

    // Favorites Games
    case GET_FAVORITE_GAMES_ASSETS_START:
      return {
        ...state,
        favoriteGameAssets: {
          ...state.favoriteGameAssets,
          loading: true
        }
      };

    case GET_FAVORITE_GAMES_ASSETS_SUCCESS:
      return {
        ...state,
        favoriteGameAssets: {
          ...state.favoriteGameAssets,
          page: action.payload.current_page,
          lastPage: action.payload.last_page,
          total: action.payload.total,
          data:
            action.payload.current_page === 1
              ? action.payload.data
              : [...state.favoriteGameAssets.data, ...action.payload.data],
          refreshing: false,
          loading: false
        }
      };

    case GET_FAVORITE_GAMES_ASSETS_ERROR:
      return {
        ...state,
        favoriteGameAssets: {
          ...state.favoriteGameAssets,
          refreshing: false,
          loading: false,
          error: action.payload
        }
      };

    case GET_FAVORITE_GAMES_START:
      return {
        ...state,
        favoriteGames: {
          ...state.favoriteGames,
          loading: true
        }
      };

    case GET_FAVORITE_GAMES_SUCCESS:
      return {
        ...state,
        favoriteGames: {
          data: isValidArray(action.payload) ? action.payload : [],
          total: isValidArray(action.payload) ? action.payload.length : 0,
          loading: false
        }
      };

    case UPDATE_FAVORITE_GAMES_SUCCESS:
      return {
        ...state,
        favoriteGames: {
          data: action.payload
        }
      };

    case GET_FAVORITE_GAMES_ERROR:
      return {
        ...state,
        favoriteGames: {
          ...state.favoriteGames,
          loading: false
        }
      };

    case RESET_FAVORITE_GAME:
      return {
        ...state,
        favoriteGames: FAVORITE_GAME_DEFAULT_STATE
      };

    case RESET_FAVORITE_GAME_ASSETS:
      return {
        ...state,
        favoriteGameAssets: FAVORITE_GAME_ASSET_DEFAULT_STATE
      };

    case SET_FAVORITE_GAME_START:
      return {
        ...state,
        favoriteGames: {
          ...state.favoriteGames,
          loading: true
        }
      };

    case SET_FAVORITE_GAME_SUCCESS:
      return {
        ...state,
        favoriteGames: {
          ...state.favoriteGames
        }
      };

    case SET_FAVORITE_GAME_ERROR:
      return {
        ...state,
        favoriteGames: {
          ...state.favoriteGames,
          loading: false
        }
      };
    default:
      return state;
  }
};

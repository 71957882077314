import MuiTheme from '@/shared/theme/MuiTheme';
import DayjsUtils from '@date-io/dayjs';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import mediaQuery from 'css-mediaquery';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';

const mobileSsrMatchMedia = (query) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: '0px'
  })
});

const desktopSsrMatchMedia = (query) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: '1280px'
  })
});

const ThemeProvider = ({ ua, children, locale }) => {
  const ssrMatchMedia = ua?.deviceType === 'mobile' ? mobileSsrMatchMedia : desktopSsrMatchMedia;
  return (
    <MuiThemeProvider
      theme={{
        ...MuiTheme,
        props: {
          ...MuiTheme.props,
          MuiUseMediaQuery: {
            ...MuiTheme.props.MuiUseMediaQuery,
            ssrMatchMedia
          }
        }
      }}>
      <LocalizationProvider dateAdapter={DayjsUtils} locale={locale}>
        {children}
      </LocalizationProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;

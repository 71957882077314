import {
  CASHBACK_GET_ERROR,
  CASHBACK_GET_START,
  CASHBACK_GET_SUCCESS
} from '@/shared/constants/ActionTypes';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  data: null,
  loading: false
};

export const useCashbackLevel = () => {
  return useSelector(({ cashback }) => cashback.data);
};

export const CashbackReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CASHBACK_GET_START:
      return {
        ...state,
        loading: true
      };
    case CASHBACK_GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case CASHBACK_GET_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

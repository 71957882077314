import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  makeStyles,
  Select as MuiSelect,
  SelectProps
} from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import Icon from '../../Icon';
import Loading from '../../Loading';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiFormLabel-root': {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        ...theme.typography.body2,
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold
      }
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.paper
    }
  },
  loading: {
    '& #loading': {
      width: 16,
      height: 16
    }
  },
  arrowForm: {
    right: 14
  }
}));

/**
 * Select
 * @param {SelectProps} props
 */
export default function Select({
  name,
  label,
  margin = 'normal',
  loading = false,
  className = '',
  disabled,
  error,
  isForm, // help align arrow of select to other inputs's adornment
  helperText,
  classes: classNames = {},
  loader = null,
  ...nest
}) {
  const classes = useStyles();
  const _disabled = loading || disabled;
  const endAdornment = useMemo(() => {
    if (loading) {
      return (
        <InputAdornment position="end">
          <Box className={classes.loading}>
            <Loading />
          </Box>
        </InputAdornment>
      );
    }

    return null;
  }, [classes, loading]);

  const IconComponent = useCallback(
    (props) => {
      if (loading) {
        return null;
      }
      return <Icon {...props} name="keyboard-arrow-down" fontSize="small" />;
    },
    [loading]
  );

  return (
    <FormControl
      className={classes.formControl}
      fullWidth
      margin={margin}
      disabled={_disabled}
      error={error}>
      {label && (
        <FormLabel htmlFor={name} className="bold">
          {label}
        </FormLabel>
      )}
      <MuiSelect
        id={name}
        name={name}
        variant="outlined"
        margin="dense"
        displayEmpty
        fullWidth
        IconComponent={IconComponent}
        {...nest}
        endAdornment={endAdornment}
        error={error}
        disabled={_disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          ...nest?.MenuProps
        }}
        className={className}
        classes={{ iconOutlined: isForm ? classes.arrowForm : null, ...classNames }}
      />
      {error ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}

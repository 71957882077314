import dynamic from 'next/dynamic';
import Loader from '../core/Loader';

function asyncComponent(importComponent, ssr = true) {
  return dynamic(importComponent, {
    ssr: ssr,
    loading: Loader
  });
}

export default asyncComponent;

import { Icon, IconButton } from '@/@crema';
import { showError, showSuccess } from '@/redux/actions';
import { Typography, makeStyles } from '@material-ui/core';
import clipboardCopy from 'clipboard-copy';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  cellTextCopy: {
    gap: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    placeContent: 'space-between'
  }
}));

export function CellTextCopy({ text, icon, messages, className, isBtnCopy, ...nest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClickCopyID = useCallback(
    (text) => async (event) => {
      try {
        event.stopPropagation();
        await clipboardCopy(text);
        dispatch(showSuccess(messages?.success || 'Copied to your clipboard'));
      } catch (error) {
        dispatch(showError(messages?.fail || 'Copied to your clipboard fail'));
      }
    },
    [dispatch, messages?.fail, messages?.success]
  );
  return (
    <Typography
      onClick={handleClickCopyID(text)}
      className={clsx(classes.cellTextCopy, 'cellTextCopy', className)}
      variant="body2"
      {...nest}>
      <span className="cell-text line-clamp-1" title={text}>
        {text}
      </span>
      {isBtnCopy ? (
        <IconButton size="extra-small">
          <Icon fontSize="small" name={icon || 'copy'} />
        </IconButton>
      ) : (
        <Icon fontSize="small" name={icon || 'copy'} />
      )}
    </Typography>
  );
}

const renderTextCopy = (value, item) => {
  return <CellTextCopy text={value} />;
};
export default renderTextCopy;

import { useTransactionGameSportTabs } from '@/redux/reducers/Transaction';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import useStyles from './index.style';

const TransactionListTab = ({ value, onChange }) => {
  const transactionTypes = useTransactionGameSportTabs();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.transactionListTabContainer}>
      <Tabs
        value={value}
        onChange={onChange}
        aria-label="transaction tabs"
        scrollButtons="off"
        variant="scrollable"
        classes={{ flexContainer: classes.flexContainer }}
        className={classes.tabs}>
        {transactionTypes.map((item, index) => {
          return (
            <Tab
              key={item.type}
              className={classes.tab}
              label={<Link href={item.href}>{formatMessage({ id: item.label })}</Link>}
              id={`transaction-tab-${index}`}
              aria-controls={`transaction-tabpanel-${index}`}></Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TransactionListTab;

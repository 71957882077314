import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const getGameAssets = (data) => {
  return API_SERVICE.get(getApiEndpoint(`game-assets`), data);
};

export const getGameDetail = (slug) => {
  return API_SERVICE.get(getApiEndpoint(`games/${slug}`));
};

export const getGameLauncherUrl = (path) => {
  return API_SERVICE.get(path);
};

export const getGameLauncherTrialUrl = (path) => {
  return API_SERVICE.get(path);
};

export const getSystemStatus = () => {
  return API_SERVICE.get(getApiEndpoint('system-status'));
};

export const onGetCouponGameApi = (method, url) => {
  switch (method) {
    case 'get':
      return API_SERVICE.get(getApiEndpoint(url));
    default:
      return;
  }
};

export const getFavoriteGameAssets = (params) => {
  return API_SERVICE.get(getApiEndpoint(`game-assets/favorites`), params);
};

export const toggleFavoriteGame = (params) => {
  return API_SERVICE.put(getApiEndpoint(`game/${params.id}/toggle-favorite`), {
    favorited: params.favorited
  });
};

export const getFavoriteGames = () => {
  return API_SERVICE.get(getApiEndpoint(`game/favorites`));
};

import { withStyles } from '@material-ui/core';

const GlobalTable = withStyles(
  (theme) => ({
    '@global': {
      '.expandedRow': {
        '& > *': {
          borderBottom: 'unset'
        }
      },

      '.MuiTableCell-head': {
        fontWeight: 700
      },

      '.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
        backgroundColor: theme.palette.background.card
      },

      '.MuiTableCell-root': {
        borderBottom: `1px solid ${theme.palette.background.card}`
      }
    }
  }),
  { name: 'GlobalTable' }
)(() => null);

export default GlobalTable;

import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const getNotifications = async (query) => {
  return API_SERVICE.get(getApiEndpoint(`notifications`), { params: query });
};

export const markAsRead = async (id) => {
  return API_SERVICE.put(getApiEndpoint(`notifications/${id}/mark-as-read`));
};

export const markAllRead = async () => {
  return API_SERVICE.put(getApiEndpoint(`notifications/mark-all-read`));
};

export const countUnRead = async () => {
  return API_SERVICE.get(getApiEndpoint(`notifications/count-un-read`));
};

import { ConfirmDialog, DataTable } from '@/@crema';
import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import { logd } from '@/@crema/utility/Logging';
import { useSelectedTransaction, useTransactionDeposit } from '@/redux/reducers/Transaction';
import { Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import DialogDetailsDeposit from '../../components/DialogDetails/DialogDetailsDeposit';
import ExpandedRow from '../../components/ExpandedRow';
import TransactionListItem from '../../components/ListItem/TransactionListItem';
import ListTransactionMobile from '../../components/ListTransactionMobile';
import {
  TRANSACTION,
  configure,
  useCancelSelectedItemDialog,
  useGetData,
  useSelectedItem
} from '../../configure/';
import TransactionProvider from '../ListContext';

const FIELDS_DETAIL = [
  'uuid',
  'amount',
  'depositBy',
  'blockchainNetwork',
  'statusDeposit',
  'createdAt',
  'instructionDetail'
];

const COLUMNS = ['uuid', 'amount', 'depositBy', 'blockchainNetwork', 'statusDeposit', 'createdAt'];

const COLUMNS_TABLE = COLUMNS.map((key) => configure.fields[key]);

const ListDeposit = () => {
  const isSM = useBreakPointDown('sm');
  const { formatMessage } = useIntl();
  const transactionData = useTransactionDeposit();
  logd('inspect.transaction.List.transactionDepositData', transactionData);
  const { data, hasMore, loading, page, perPage } = transactionData;
  const { onLoadMore } = useGetData(TRANSACTION.depositHistory);
  const { data: selectedItem } = useSelectedTransaction();
  const { isSelected, onSelectItem, onUnselectItem } = useSelectedItem();

  const {
    openDialogConfirmCancel,
    onOpenDialogConfirmCancel,
    onCloseDialogConfirmCancel,
    onCancel
  } = useCancelSelectedItemDialog(TRANSACTION.depositHistory);

  const renderItem = useCallback(
    ({ item }) => <TransactionListItem item={item} onClick={onSelectItem} />,
    [onSelectItem]
  );

  const renderExpandedRow = useCallback(
    (row, expanded, toggleExpanded) => {
      return (
        <ExpandedRow
          row={row}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          onClick={onOpenDialogConfirmCancel}
        />
      );
    },
    [onOpenDialogConfirmCancel]
  );

  return (
    <TransactionProvider onUnselectItem={onUnselectItem}>
      {isSM ? (
        <ListTransactionMobile
          hasMore={hasMore}
          loading={loading}
          data={data}
          renderItem={renderItem}
          onLoadMore={onLoadMore}
        />
      ) : (
        <DataTable
          loading={loading && page === 0}
          data={data}
          columns={COLUMNS_TABLE}
          page={page}
          count={0}
          rowsPerPage={perPage}
          messageNoData={formatMessage({ id: 'common.noTransactionHistory' })}
          isPaging={false}
          canLoadMore={hasMore}
          isLoadMore={loading && page !== 0}
          loadMore={onLoadMore}
          onActionClick={onOpenDialogConfirmCancel}
          renderExpandedRow={renderExpandedRow}
        />
      )}

      <DialogDetailsDeposit
        open={isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={onUnselectItem}
        fields={FIELDS_DETAIL}
      />

      <ConfirmDialog
        open={selectedItem !== null && openDialogConfirmCancel}
        onClose={onCloseDialogConfirmCancel}
        onClickYes={onCancel}
        onClickCancel={onCloseDialogConfirmCancel}
        title={formatMessage({
          id: 'account.transaction.cancelTransaction.title'
        })}
        content={
          <Typography color="primary" component="span" variant="h5">
            {formatMessage({
              id: 'account.transaction.cancelTransaction.description'
            })}
          </Typography>
        }
      />
    </TransactionProvider>
  );
};

export default ListDeposit;

import { RESET_ALL } from '@/shared/constants/ActionTypes';
import { isDev } from '@/shared/constants/AppConst';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import allReducers from '../reducers';

export let store;

function initStore() {
  return createStore(reducer, bindMiddleware([thunkMiddleware]));
}

const bindMiddleware = (middleware) => {
  if (isDev) {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const prevState = state;
    state = {
      ...state, // use previous state
      ...action.payload // apply delta from hydration,
    };
    if (state.transaction) {
      state.transaction = prevState.transaction;
    }
    if (state.game) {
      state.game.playMode = prevState.game.playMode;
      state.game.launchDemoGameError = prevState.game.launchDemoGameError;
      state.game.playMode = prevState.game.playMode;
      state.game.loading = prevState.game.loading;
    }
    if (state.withdrawal) {
      state.withdrawal = prevState.withdrawal;
    }
    if (state.payment) {
      state.payment.selectedPayment = prevState.payment.selectedPayment;
      state.payment.pendingDeposit = prevState.payment.pendingDeposit;
      state.payment.loading = prevState.payment.loading;
    }
  }
  if (action.type === RESET_ALL) {
    state = {
      app: state.app
    };
  }
  return allReducers(state, action);
};

const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);
  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export const wrapper = createWrapper(initializeStore, { debug: false });

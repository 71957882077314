import { getApiEndpoint, getApiEndpointAgent } from '@/@crema/services/Helper';
import { getPhoneNumber } from '@/@crema/utility/utils';
import { API_SERVICE } from '../..';

const login = (data) => {
  return API_SERVICE.post(getApiEndpoint('signin'), data);
};

const signup = (data) => {
  return API_SERVICE.post(getApiEndpoint('signup'), data);
};

const forgotPasswordByEmail = (data) => {
  return API_SERVICE.post(getApiEndpoint('forgot-password'), data);
};

const resetPassword = (data) => {
  return API_SERVICE.post(getApiEndpoint('reset-password'), data);
};

const signOut = (data) => {
  return API_SERVICE.get(getApiEndpoint('signout'), data);
};

const getKiosks = (data) => {
  return API_SERVICE.get(getApiEndpoint('kiosks'), { params: data });
};

const requestOTP = (data) => {
  const params = {
    phone_number: getPhoneNumber(data)
  };
  return API_SERVICE.post(getApiEndpoint('otp/request'), params);
};

const changePassword = (data) => {
  return API_SERVICE.put(getApiEndpoint('change-password'), data);
};

const checkValidAgentCode = (code) => {
  return API_SERVICE.get(getApiEndpoint(`agents/find-by-code/${code}`));
};

const getPlayerVipProgress = () => {
  return API_SERVICE.get(getApiEndpoint('vip-progress'));
};

const sendTracker = (params) => {
  return API_SERVICE.post(getApiEndpoint('trackers'), params);
};

const signUpAgent = (values) => {
  return API_SERVICE.post(getApiEndpointAgent(`signup`), values, {
    header: {
      Accept: 'application/json'
    }
  });
};

const requestOTPAgent = (data) => {
  const params = {
    phone_number: getPhoneNumber(data)
  };
  return API_SERVICE.post(getApiEndpointAgent('otp/request'), params);
};

const verifyEmail = () => {
  return API_SERVICE.post(getApiEndpoint('email/verify/resend'), {});
};

const verifyEmailByLink = (link) => {
  return API_SERVICE.get(link);
};

export {
  changePassword,
  checkValidAgentCode,
  forgotPasswordByEmail,
  getPlayerVipProgress,
  login,
  // getKiosks,
  requestOTP,
  requestOTPAgent,
  resetPassword,
  sendTracker,
  signOut,
  signUpAgent,
  signup,
  verifyEmail,
  verifyEmailByLink
};

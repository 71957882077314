import NextImage from '@/@crema/core/ImageOptimized/NextImage';
import { getCMSName, getCryptoIcon } from '@/@crema/utility/utils';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { renderItemWithLoadingSkeleton } from '../components/ExpandedRow/CellDetailSport';

const useStyles = makeStyles((theme) => ({
  cellCurrencyCodeImage: {
    gap: theme.spacing(2),
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    '& .next-image-16': {
      backgroundColor: 'transparent',
      minWidth: 16,
      width: 16,
      height: 16
    },
    '& .cellCurrencyCodeImage-value': {
      lineHeight: 1
    }
  }
}));

export function CellCurrencyCodeImage({
  value,
  alt,
  classNameContainer,
  imageProps,
  valueProps,
  ...nest
}) {
  const classes = useStyles();
  const { className: classNameValue } = valueProps || {};
  const src = getCryptoIcon(value?.currency_code);

  return (
    <div className={clsx(classes.cellCurrencyCodeImage, classNameContainer)} {...(nest || {})}>
      {src && (
        <NextImage
          classNameContainer="next-image-16"
          alt={alt || value}
          {...(imageProps || {})}
          src={src}
        />
      )}
      <span className={clsx('cellCurrencyCodeImage-value mx-auto line-clamp-1', classNameValue)}>
        {getCMSName(value)}
      </span>
    </div>
  );
}

const renderDepositBy = (currencyCode) => {
  return renderItemWithLoadingSkeleton(
    <CellCurrencyCodeImage value={currencyCode} />,
    currencyCode?.loading
  );
};

export default renderDepositBy;

export const RESET_ALL = 'RESET_ALL';

//COMMON
export const COMMON_FETCH_START = 'COMMON_FETCH_START';
export const COMMON_FETCH_SUCCESS = 'COMMON_FETCH_SUCCESS';
export const COMMON_FETCH_ERROR = 'COMMON_FETCH_ERROR';
export const COMMON_SHOW_MESSAGE = 'COMMON_SHOW_MESSAGE';
export const COMMON_HIDE_MESSAGE = 'COMMON_HIDE_MESSAGE';
export const COMMON_SHOW_POPUP = 'COMMON_SHOW_POPUP';
export const COMMON_HIDE_POPUP = 'COMMON_HIDE_POPUP';
export const COMMON_SHOW_BOTTOM_SHEET = 'COMMON_SHOW_BOTTOM_SHEET';
export const COMMON_HIDE_BOTTOM_SHEET = 'COMMON_HIDE_BOTTOM_SHEET';

//APP SETTING
export const APP_TOGGLE_NAV = 'APP_TOGGLE_NAV';
export const APP_FETCH_START = 'APP_FETCH_START';
export const APP_FETCH_SUCCESS = 'APP_FETCH_SUCCESS';
export const APP_FETCH_ERROR = 'APP_FETCH_ERROR';
export const APP_SET_MENU = 'APP_SET_MENU';
export const APP_SET_CMS_SETTINGS = 'APP_SET_CMS_SETTINGS';
export const APP_SET_SYSTEM_SETTINGS = 'APP_SET_SYSTEM_SETTINGS';
export const APP_TOGGLE_HIDE_ZERO_BALANCES = 'APP_TOGGLE_HIDE_ZERO_BALANCES';
export const APP_TOGGLE_VIEW_IN_FIAT = 'APP_TOGGLE_VIEW_IN_FIAT';
export const APP_SET_LOCALIZATION = 'APP_SET_LOCALIZATION';
export const APP_SET_REQUIRE_PLAYER_SIGNIN_CAPTCHA = 'APP_SET_REQUIRE_PLAYER_SIGNIN_CAPTCHA';
export const APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA = 'APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA';
export const APP_PUSHER_SUBSCRIBED = 'APP_PUSHER_SUBSCRIBED';
export const APP_TOGGLE_SELECT_IN_FIAT = 'APP_TOGGLE_SELECT_IN_FIAT';
export const APP_SET_AFFILIATE_URL = 'APP_SET_AFFILIATE_URL';
export const APP_TOGGLE_AGENT_SIGN_UP = 'APP_TOGGLE_AGENT_SIGN_UP';

//JWT_AUTH
export const AUTH_FETCH_PROFILE_START = 'AUTH_FETCH_PROFILE_START';
export const AUTH_FETCH_PROFILE_SUCCESS = 'AUTH_FETCH_PROFILE_SUCCESS';
export const AUTH_GET_BANKS = 'AUTH_GET_BANKS';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_RESET = 'AUTH_FORGOT_PASSWORD_RESET';
export const AUTH_FETCH_KIOSKS = 'AUTH_FETCH_KIOSKS';
export const AUTH_SET_REQUIRE_OTP = 'AUTH_SET_REQUIRE_OTP';
export const AUTH_SET_USERINFO = 'AUTH_SET_USERINFO';

// DEPOSIT
export const TRANSACTION_RESET = 'TRANSACTION_RESET';
export const TRANSACTION_SET_SHOW_SPORTSBOOK_TAB = 'TRANSACTION_SET_SHOW_SPORTSBOOK_TAB';
export const TRANSACTION_LOADING_CHECK_SPORTSBOOK_START =
  'TRANSACTION_LOADING_CHECK_SPORTSBOOK_START';
export const TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE =
  'TRANSACTION_LOADING_CHECK_SPORTSBOOK_DONE';

export const TRANSACTION_GET_DEPOSIT_START = 'TRANSACTION_GET_DEPOSIT_START';
export const TRANSACTION_GET_DEPOSIT_SUCCESS = 'TRANSACTION_GET_DEPOSIT_SUCCESS';
export const TRANSACTION_GET_DEPOSIT_ERROR = 'TRANSACTION_GET_DEPOSIT_ERROR';
export const TRANSACTION_RESET_DEPOSIT = 'TRANSACTION_RESET_DEPOSIT';
export const TRANSACTION_SET_DEPOSIT = 'TRANSACTION_SET_DEPOSIT';
export const TRANSACTION_CANCEL_DEPOSIT_START = 'TRANSACTION_CANCEL_DEPOSIT_START';
export const TRANSACTION_CANCEL_DEPOSIT_ERROR = 'TRANSACTION_CANCEL_DEPOSIT_ERROR';
export const TRANSACTION_CANCEL_DEPOSIT_SUCCESS = 'TRANSACTION_CANCEL_DEPOSIT_SUCCESS';
export const TRANSACTION_SET_DEPOSIT_SUCCESS = 'TRANSACTION_SET_DEPOSIT_SUCCESS';

// WITHDRAW
export const TRANSACTION_GET_WITHDRAW_START = 'TRANSACTION_GET_WITHDRAW_START';
export const TRANSACTION_GET_WITHDRAW_SUCCESS = 'TRANSACTION_GET_WITHDRAW_SUCCESS';
export const TRANSACTION_GET_WITHDRAW_ERROR = 'TRANSACTION_GET_WITHDRAW_ERROR';
export const TRANSACTION_RESET_WITHDRAW = 'TRANSACTION_RESET_WITHDRAW';
export const TRANSACTION_SET_WITHDRAW = 'TRANSACTION_SET_WITHDRAW';
export const TRANSACTION_CANCEL_WITHDRAW_START = 'TRANSACTION_CANCEL_WITHDRAW_START';
export const TRANSACTION_CANCEL_WITHDRAW_ERROR = 'TRANSACTION_CANCEL_WITHDRAW_ERROR';
export const TRANSACTION_CANCEL_WITHDRAW_SUCCESS = 'TRANSACTION_CANCEL_WITHDRAW_SUCCESS';

// GAME
export const TRANSACTION_GET_GAME_START = 'TRANSACTION_GET_GAME_START';
export const TRANSACTION_GET_GAME_SUCCESS = 'TRANSACTION_GET_GAME_SUCCESS';
export const TRANSACTION_GET_GAME_ERROR = 'TRANSACTION_GET_GAME_ERROR';
export const TRANSACTION_RESET_GAME = 'TRANSACTION_RESET_GAME';

// GAME SPORTSBOOK\
export const TRANSACTION_GET_GAME_SPORTSBOOK_START = 'TRANSACTION_GET_GAME_SPORTSBOOK_START';
export const TRANSACTION_GET_GAME_SPORTSBOOK_SUCCESS = 'TRANSACTION_GET_GAME_SPORTSBOOK_SUCCESS';
export const TRANSACTION_GET_GAME_SPORTSBOOK_ERROR = 'TRANSACTION_GET_GAME_SPORTSBOOK_ERROR';
export const TRANSACTION_RESET_GAME_SPORTSBOOK = 'TRANSACTION_RESET_GAME_SPORTSBOOK';
export const TRANSACTION_SET_FILTER_SPORT_HISTORIES = 'TRANSACTION_SET_FILTER_SPORT_HISTORIES';

// SELECTED TRANSACTION
export const TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS =
  'TRANSACTION_GET_TRANSACTION_DETAIL_SUCCESS';
export const CLEAR_SELECTED_TRANSACTION = 'CLEAR_SELECTED_TRANSACTION';

// TRANSACTION STATUS (DEPOSIT)
export const TRANSACTION_GET_STATUS_START = 'TRANSACTION_GET_STATUS_START';
export const TRANSACTION_GET_STATUS_SUCCESS = 'TRANSACTION_GET_STATUS_SUCCESS';
export const TRANSACTION_GET_STATUS_ERROR = 'TRANSACTION_GET_STATUS_ERROR';
export const TRANSACTION_RESET_STATUS = 'TRANSACTION_RESET_STATUS';
export const TRANSACTION_OPEN_DIALOG = 'TRANSACTION_OPEN_DIALOG';

//BONUS
export const BONUS_GET_PLAYER = 'BONUS_BONUS_GET_PLAYER';
export const BONUS_SET_LOADING = 'BONUS_SET_LOADING';
export const BONUS_RESET = 'BONUS_RESET';

export const BONUS_GET_REWARD_HISTORY = 'BONUS_GET_REWARD_HISTORY';
export const BONUS_LOAD_MORE_REWARD_HISTORY_START = 'BONUS_LOAD_MORE_REWARD_HISTORY_START';
export const BONUS_LOAD_MORE_REWARD_HISTORY_SUCCESS = 'BONUS_LOAD_MORE_REWARD_HISTORY_SUCCESS';
export const BONUS_LOAD_MORE_REWARD_HISTORY_ERROR = 'BONUS_LOAD_MORE_REWARD_HISTORY_ERROR';

export const BONUS_GET_PENDING_BONUS = 'BONUS_GET_PENDING_BONUS';
export const BONUS_LOAD_MORE_PENDING_BONUS_START = 'BONUS_LOAD_MORE_PENDING_BONUS_START';
export const BONUS_LOAD_MORE_PENDING_BONUS_SUCCESS = 'BONUS_LOAD_MORE_PENDING_BONUS_SUCCESS';
export const BONUS_LOAD_MORE_PENDING_BONUS_ERROR = 'BONUS_LOAD_MORE_PENDING_BONUS_ERROR';

export const BONUS_GET_RECOMMENDATION = 'BONUS_GET_RECOMMENDATION';
export const BONUS_SET_SWITCH = 'BONUS_SET_SWITCH';

// WITHDRAWAL
export const WITHDRAWAL_SET_PREVENTED = 'WITHDRAWAL_SET_PREVENTED';
export const WITHDRAWAL_SET_BANKS = 'WITHDRAWAL_SET_BANKS';
export const WITHDRAWAL_SET_FORM_STATUS = 'WITHDRAWAL_SET_FORM_STATUS';
export const WITHDRAWAL_SET_SELECTED = 'WITHDRAWAL_SET_SELECTED';
export const WITHDRAWAL_SET_SUBMIT_DATA = 'WITHDRAWAL_SET_SUBMIT_DATA';
export const WITHDRAWAL_RESET = 'WITHDRAWAL_RESET';
export const WITHDRAWAL_SET_PLAYER_BANKS_START = 'WITHDRAWAL_SET_PLAYER_BANKS_START';
export const WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS = 'WITHDRAWAL_SET_PLAYER_BANKS_SUCCESS';
export const WITHDRAWAL_SET_PLAYER_BANKS_ERROR = 'WITHDRAWAL_SET_PLAYER_BANKS_ERROR';
export const WITHDRAWAL_BANKS_GET_START = 'WITHDRAWAL_BANKS_GET_START';
export const WITHDRAWAL_BANKS_GET_SUCCESS = 'WITHDRAWAL_BANKS_GET_SUCCESS';
export const WITHDRAWAL_BANKS_GET_ERROR = 'WITHDRAWAL_BANKS_GET_ERROR';
export const WITHDRAWAL_RESET_BANK_TRANSFER = 'WITHDRAWAL_RESET_BANK_TRANSFER';
export const WITHDRAWAL_SET_SELECTED_PLAYER_BANK = 'WITHDRAWAL_SET_SELECTED_PLAYER_BANK';

export const GAME_SET_SEARCH_RECOMMENDED = 'GAME_SET_SEARCH_RECOMMENDED';
export const GAME_SEARCH_START = 'GAME_SEARCH_START';
export const GAME_SEARCH_SUCCESS = 'GAME_SEARCH_SUCCESS';
export const GAME_SEARCH_ERROR = 'GAME_SEARCH_ERROR';
export const GAME_SET_PLAY_MODE = 'GAME_SET_PLAY_MODE';

export const GAME_GET_COUPON_START = 'GAME_GET_COUPON_START';
export const GAME_GET_COUPON_SUCCESS = 'GAME_GET_COUPON_SUCCESS';
export const GAME_GET_COUPON_ERROR = 'GAME_GET_COUPON_ERROR';

// GAME LIST
export const GAME_LIST_GET_START = 'GAME_LIST_GET_START';
export const GAME_LIST_GET_SUCCESS = 'GAME_LIST_GET_SUCCESS';
export const GAME_LIST_GET_ERROR = 'GAME_LIST_GET_ERROR';
export const GAME_LIST_REFRESH = 'GAME_LIST_REFRESH';
export const GAME_LIST_SET_FILTERS = 'GAME_LIST_SET_FILTERS';
export const GAME_LIST_RESET = 'GAME_LIST_RESET';
export const GAME_SET_DEFAULT_FILTERS_CONFIG = 'GAME_SET_DEFAULT_FILTERS_CONFIG';
export const GAME_SET_FILTERS_CONFIG = 'GAME_SET_FILTERS_CONFIG';
export const GAME_SET_SLUG = 'GAME_SET_SLUG';

// LAUNCH GAME
export const SHOW_GAME_DETAIL_FOR_PLAY = 'SHOW_GAME_DETAIL_FOR_PLAY';
export const GAME_GET_DETAIL_START = 'GAME_GET_DETAIL_START';
export const GAME_GET_DETAIL_SUCCESS = 'GAME_GET_DETAIL_SUCCESS';
export const GAME_GET_DETAIL_ERROR = 'GAME_GET_DETAIL_ERROR';
export const GAME_GET_LAUNCH_GAME_URL_ERROR = 'GAME_GET_LAUNCH_GAME_URL_ERROR';
export const GAME_GET_LAUNCH_GAME_DEMO_URL_ERROR = 'GAME_GET_LAUNCH_GAME_DEMO_URL_ERROR';
export const GAME_RESET_DETAIL = 'GAME_RESET_DETAIL';

//PROMOTION
export const PROMOTION_GET_CMS = 'PROMOTION_GET_CMS';
export const PROMOTION_GET_DETAIL = 'PROMOTION_GET_DETAIL';
export const PROMOTION_GET_DETAIL_START = 'PROMOTION_GET_DETAIL_START';
export const PROMOTION_GET_DETAIL_ERROR = 'PROMOTION_GET_DETAIL_ERROR';
export const PROMOTION_LOAD_MORE_CMS_START = 'PROMOTION_LOAD_MORE_CMS_START';
export const PROMOTION_LOAD_MORE_CMS_SUCCESS = 'PROMOTION_LOAD_MORE_CMS_SUCCESS';
export const PROMOTION_LOAD_MORE_CMS_ERROR = 'PROMOTION_LOAD_MORE_CMS_ERROR';
export const PROMOTION_CLAIM_BONUS_ERROR = 'PROMOTION_CLAIM_BONUS_ERROR';
export const PROMOTION_CLAIM_BONUS_START = 'PROMOTION_CLAIM_BONUS_START';
export const PROMOTION_CLAIM_BONUS_SUCCESS = 'PROMOTION_CLAIM_BONUS_SUCCESS';
export const PROMOTION_SET_ACTIVATION = 'PROMOTION_SET_ACTIVATION';

//WALLET
export const WALLET_SET_LOADING = 'WALLET_SET_LOADING';
export const WALLET_SET_DATA = 'WALLET_SET_DATA';
export const WALLET_ACTIVE = 'WALLET_ACTIVE';
export const WALLET_SET_FIAT_LOADING = 'WALLET_SET_FIAT_LOADING';

//PAYMENT
export const PAYMENT_GET_START = 'PAYMENT_GET_START';
export const PAYMENT_GET_SUCCESS = 'PAYMENT_GET_SUCCESS';
export const PAYMENT_GET_ERROR = 'PAYMENT_GET_ERROR';
export const PAYMENT_SET_DATA = 'PAYMENT_SET_DATA';
export const PAYMENT_SET_SELECTED_CURRENCY = 'PAYMENT_SET_SELECTED_CURRENCY';
export const PAYMENT_SET_SELECTED_PAYMENT = 'PAYMENT_SET_SELECTED_PAYMENT';
export const PAYMENT_RESET = 'PAYMENT_RESET';
export const PAYMENT_GET_ADDRESS_START = 'PAYMENT_GET_ADDRESS_START';
export const PAYMENT_GET_ADDRESS_SUCCESS = 'PAYMENT_GET_ADDRESS_SUCCESS';
export const PAYMENT_GET_ADDRESS_ERROR = 'PAYMENT_GET_ADDRESS_ERROR';
export const PAYMENT_SET_NETWORK = 'PAYMENT_SET_NETWORK';
export const PAYMENT_PROFILE_GET_AREA = 'PAYMENT_PROFILE_GET_AREA';
export const PAYMENT_SET_PENDING_DEPOSIT = 'PAYMENT_SET_PENDING_DEPOSIT';
export const PAYMENT_RESET_PENDING_DEPOSIT = 'PAYMENT_RESET_PENDING_DEPOSIT';

// KYC
export const KYC_GET_START = 'KYC_GET_START';
export const KYC_GET_SUCCESS = 'KYC_GET_SUCCESS';
export const KYC_GET_ERROR = 'KYC_GET_ERROR';
export const KYC_GET_DOCUMENTS_SUCCESS = 'KYC_GET_DOCUMENTS_SUCCESS';
export const KYC_SET_STEP = 'KYC_SET_STEP';
export const KYC_RESET = 'KYC_RESET';
export const KYC_GET_COUNTRY = 'KYC_GET_COUNTRY';
export const KYC_GET_STATE = 'KYC_GET_STATE';
export const KYC_GET_CITY = 'KYC_GET_CITY';

// PAGE
export const PAGE_GET_START = 'PAGE_GET_START';
export const PAGE_GET_SUCCESS = 'PAGE_GET_SUCCESS';
export const PAGE_GET_ERROR = 'PAGE_GET_ERROR';
export const PAGE_RESET = 'PAGE_RESET';

// STATIC_PAGE
export const STATIC_PAGE_GET_DATA_SUCCESS = 'STATIC_PAGE_GET_DATA_SUCCESS';

// LEADERBOARD
export const LEADERBOARD_GET_DATA = 'LEADERBOARD_GET_DATA';

//NOTIFICATION
export const NOTIFICATION_GET_START = 'NOTIFICATION_GET_START';
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS';
export const NOTIFICATION_GET_ERROR = 'NOTIFICATION_GET_ERROR';
export const NOTIFICATION_GET_DETAIL = 'NOTIFICATION_GET_DETAIL';
export const NOTIFICATION_MARK_AS_READ = 'NOTIFICATION_MARK_AS_READ';
export const NOTIFICATION_COUNT_UN_READ = 'NOTIFICATION_COUNT_UN_READ';
export const NOTIFICATION_LOAD_MORE_START = 'NOTIFICATION_LOAD_MORE_START';
export const NOTIFICATION_LOAD_MORE_ERROR = 'NOTIFICATION_LOAD_MORE_ERROR';
export const NOTIFICATION_LOAD_MORE_SUCCESS = 'NOTIFICATION_LOAD_MORE_SUCCESS';
export const NOTIFICATION_RESET = 'NOTIFICATION_RESET';

//THEME
export const THEME_SET_MODE = 'THEME_SET_MODE';
export const THEME_SET_PREFERS_COLOR_SCHEME = 'THEME_SET_PREFERS_COLOR_SCHEME';

// FAVORITE GAMES
export const GET_FAVORITE_GAMES_ASSETS_START = 'GET_FAVORITE_GAMES_ASSETS_START';
export const GET_FAVORITE_GAMES_ASSETS_SUCCESS = 'GET_FAVORITE_GAMES_ASSETS_SUCCESS';
export const GET_FAVORITE_GAMES_ASSETS_ERROR = 'GET_FAVORITE_GAMES_ASSETS_ERROR';
export const SET_FAVORITE_GAME_START = 'SET_FAVORITE_GAME_START';
export const SET_FAVORITE_GAME_SUCCESS = 'SET_FAVORITE_GAME_SUCCESS';
export const SET_FAVORITE_GAME_ERROR = 'SET_FAVORITE_GAME_ERROR';
export const GET_FAVORITE_GAMES_START = 'GET_FAVORITE_GAMES_START';
export const GET_FAVORITE_GAMES_SUCCESS = 'GET_FAVORITE_GAMES_SUCCESS';
export const UPDATE_FAVORITE_GAMES_SUCCESS = 'UPDATE_FAVORITE_GAMES_SUCCESS';
export const GET_FAVORITE_GAMES_ERROR = 'GET_FAVORITE_GAMES_ERROR';
export const RESET_FAVORITE_GAME = 'RESET_FAVORITE_GAME';
export const RESET_FAVORITE_GAME_ASSETS = 'RESET_FAVORITE_GAME_ASSETS';
export const GAME_SHOW_DIALOG = 'GAME_SHOW_DIALOG';
export const GAME_HIDE_DIALOG = 'GAME_HIDE_DIALOG';

// CASHBACK
export const CASHBACK_GET_START = 'CASHBACK_GET_START';
export const CASHBACK_GET_SUCCESS = 'CASHBACK_GET_SUCCESS';
export const CASHBACK_GET_ERROR = 'CASHBACK_GET_ERROR';

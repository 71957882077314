import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import Link from 'next/link';
import React from 'react';

/**
 * @type {React.FC<MuiLinkProps>}
 */
const ButtonLink = React.forwardRef(
  (
    {
      className,
      href,
      hrefAs,
      children,
      prefetch = false,
      shallow = false,
      scroll = true,
      color,
      ...nest
    },
    ref
  ) => {
    return (
      <Link
        href={href || '#'}
        as={hrefAs}
        prefetch={prefetch}
        shallow={shallow}
        scroll={scroll}
        passHref>
        <MuiLink ref={ref} className={className} color={color} {...nest} underline="none">
          {children}
        </MuiLink>
      </Link>
    );
  }
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;

import { withStyles } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import Icon from '../../Icon';

const CustomAutocomplete = withStyles(
  (theme) => {
    return {
      root: {},
      inputRoot: {
        paddingTop: 0,
        paddingBottom: 0
      }
    };
  },
  { name: 'BaseAutocomplete' }
)(MuiAutocomplete);

const BaseAutocomplete = React.forwardRef(({ ...nest }, ref) => {
  return (
    <CustomAutocomplete
      ref={ref}
      autoHighlight
      {...nest}
      closeIcon={<Icon name="cancel" fontSize="small" />}
      size="small"
    />
  );
});

BaseAutocomplete.displayName = 'BaseAutocomplete';

export default BaseAutocomplete;

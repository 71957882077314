import { createMuiTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core';
import { COLORS } from '../constants/ColorSets';

const fontSize = 14; // mui default font size
const htmlFontSize = 16; // 16px is the default font-size used by browsers.
const coef = fontSize / 14;
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemiBold = 600;
const fontWeightBold = 700;

function pxToRem(size) {
  return `${(size / htmlFontSize) * coef}rem`;
}

function buildVariant(fontWeight, fontSize, lineHeight, letterSpacing, caseAllCaps) {
  const variant = {
    fontWeight,
    fontSize: pxToRem(fontSize),
    lineHeight,
    letterSpacing
  };

  if (caseAllCaps) {
    variant.textTransform = 'uppercase';
  }

  return variant;
}

const variants = {
  h1: buildVariant(fontWeightRegular, 64, 1.167, 0),
  h2: buildVariant(fontWeightRegular, 48, 1.2, 0),
  h3: buildVariant(fontWeightRegular, 32, 1.167, 0),
  h4: buildVariant(fontWeightRegular, 24, 1.235, 0),
  h5: buildVariant(fontWeightRegular, 20, 1.334, 0),
  h6: buildVariant(fontWeightRegular, 16, 1.6, 0),
  subtitle1: buildVariant(fontWeightRegular, 16, 1.75, 0),
  subtitle2: buildVariant(fontWeightRegular, 14, 1.57, 0),
  body1: buildVariant(fontWeightRegular, 16, 1.5, 0),
  body2: buildVariant(fontWeightRegular, 14, 1.43, 0),
  button: buildVariant(fontWeightRegular, 14, 1.75, 0, true),
  caption: buildVariant(fontWeightRegular, 12, 1.66, 0),
  overline: buildVariant(fontWeightRegular, 12, 2.66, 0, true)
};

/**
 * @type {ThemeOptions}
 */
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: COLORS.primary
    },
    secondary: {
      main: COLORS.secondary
    },
    background: {
      /**
       * default > paper > card
       */
      /**
       * site background
       */
      default: COLORS.background2,
      /**
       * surface on background: games, promotion, card...
       */
      paper: COLORS.paper,
      /**
       * surface on paper
       */
      card: COLORS.card,
      /**
       * modal, dialog, popup overlay...
       */
      overlay: COLORS.overlay,
      /**
       * background dialog
       */
      dialog: COLORS.background375,
      /**
       * drawer, popover blurred background
       */
      defaultBlur: COLORS.background375,

      /**
       * surface on background contrast
       */
      contrast: COLORS.section
    },
    text: {
      primary: COLORS.text,
      /**
       * text color on selected/active item
       */
      contrast: COLORS.background2
    },
    action: {
      selectedOpacity: 0.08,
      hoverOpacity: 0.04
    }
  },
  typography: {
    fontFamily: ['Prompt', 'sans-serif'].join(','),
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightSemiBold,
    fontWeightBold,
    ...variants
  },
  spacing: 4,
  shape: {
    /**
     * default border radius
     */
    borderRadius: 4,
    /**
     * bigger border radius for dialog or games...
     */
    borderRadiusLarge: 16
  },
  props: {
    MuiAvatar: {
      variant: 'rounded'
    },
    MuiAppBar: {
      color: 'transparent'
    },
    MuiButton: {
      disableElevation: true
    },
    MuiContainer: {
      disableGutters: true
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          backgroundColor: COLORS.background2
        }
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },
    MuiOutlinedInput: {
      input: {
        ...buildVariant(fontWeightSemiBold, 16, 1.5, 0)
      },
      inputMarginDense: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12
      }
    },
    MuiInputBase: {
      input: {
        height: '1.5rem'
      }
    },
    MuiButton: {
      root: {
        ...buildVariant(fontWeightBold, 16, 1.5, 0),
        padding: '8px 16px'
      },
      outlined: {
        padding: '7px 16px'
      },
      text: {
        padding: '8px 16px'
      }
    }
  }
});

export default responsiveFontSizes(theme);

import { fade, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: fade(theme.palette.text.primary, 0.5)
  }
}));

export default function NotAvailable() {
  const classes = useStyles();
  return (
    <Typography variant="h6" component="span" className={classes.root}>
      N/A
    </Typography>
  );
}

import DialogDetailsBase from './DialogDetailsBase';
import TransactionInformation from './TransactionInformation';

const DialogDetailsDeposit = ({ open, data, onClose, fields }) => {
  if (!data) {
    return null;
  }

  return (
    <DialogDetailsBase open={open} title="transactionDetail.header.deposit" onClose={onClose}>
      <TransactionInformation data={data} fields={fields} />
    </DialogDetailsBase>
  );
};

export default DialogDetailsDeposit;

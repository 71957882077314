import { withStyles } from '@material-ui/core';

const GlobalMenu = withStyles(
  (theme) => ({
    '@global': {}
  }),
  { name: 'GlobalMenu' }
)(() => null);

export default GlobalMenu;

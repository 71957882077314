import { Collapse, TableCell } from '@material-ui/core';
import { useMemo } from 'react';

import { SLUG_TYPE } from '../../TransactionList';
import SportBook from './SportBook';

export default function ExpandedRow({ colSpan = 7, row, expanded, toggleExpanded, onClick, slug }) {
  const content = useMemo(() => {
    switch (slug) {
      case SLUG_TYPE.sportsbook:
        return <SportBook row={row} />;

      default:
        return null;
    }
  }, [row, slug]);

  return (
    <TableCell padding="none" colSpan={colSpan} width="100%">
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </TableCell>
  );
}

import { activeWallet, getWallets } from '@/@crema/services/apis/wallet';
import { logd } from '@/@crema/utility/Logging';
import { isValidArray, propsAreEqual } from '@/@crema/utility/utils';
import { WALLET_ACTIVE, WALLET_SET_DATA } from '@/shared/constants/ActionTypes';
import { CURRENCY_TYPES } from '@/shared/constants/AppEnums';
import { handleErrors } from './Common';
import { onReloadGameLauncher } from './Game';
import { onSetSelectedCurrency } from './Payment';
import { onSetWithdrawBanks } from './Withdrawal';

export const onGetWallets = () => {
  return async (dispatch, getState) => {
    try {
      const { wallets } = getState();
      const prevData = wallets.data;

      let data = [];
      const res = await getWallets();
      if (isValidArray(res.data)) {
        data = res.data;
      }

      // only update when new data was changed
      if (propsAreEqual(prevData, data)) {
        return;
      }

      dispatch({ type: WALLET_SET_DATA, payload: data });
      // update selected payment as active wallet
      dispatch(onSetSelectedCurrency());

      // only set if withdraw banks has data
      dispatch(onSetWithdrawBanks());
    } catch (error) {
      logd('onGetWallets.error', error);
    }
  };
};

export const onActiveWallet = (currencyCode) => {
  return async (dispatch, getState) => {
    try {
      const { wallets } = getState() || {};
      const findCurrency = wallets.data.find((item) => item.currency.code === currencyCode);

      await activeWallet(currencyCode);
      dispatch({ type: WALLET_ACTIVE, payload: currencyCode });

      // update selected payment as active wallet
      if (findCurrency.currency.type === CURRENCY_TYPES.CRYPTO) {
        dispatch(onSetSelectedCurrency());
      }

      // get new game launcher url
      dispatch(onReloadGameLauncher());
      return true;
    } catch (error) {
      logd('onActiveWallet.error', error);
      dispatch(handleErrors(error));
      return false;
    }
  };
};

export const onUpdateWallet = (walletUpdateData) => {
  return async (dispatch, getState) => {
    try {
      logd('onUpdateWallet.data', walletUpdateData);
      const { wallets } = getState();

      if (isValidArray(wallets?.data) && walletUpdateData) {
        let data = wallets.data.map((item) => {
          if (item?.currency?.code === walletUpdateData?.currency?.code) {
            return walletUpdateData;
          }
          return item;
        });

        dispatch({ type: WALLET_SET_DATA, payload: data });
      }
    } catch (error) {
      logd('onUpdateWallet.error', error);
    }
  };
};

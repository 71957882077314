import {
  getFavoriteGameAssets,
  getFavoriteGames,
  getGameAssets,
  getGameDetail,
  getGameLauncherTrialUrl,
  getGameLauncherUrl,
  getSystemStatus,
  onGetCouponGameApi,
  toggleFavoriteGame
} from '@/@crema/services/apis/game';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { closeAllPopup, routerPush } from '@/@crema/utility/Route';
import {
  getPagingObj,
  isValidArray,
  isValidPagingData,
  parseQueryToGameFilterObj
} from '@/@crema/utility/utils';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import { AnalyticsService } from '@/@crema';
import {
  GAME_GET_COUPON_ERROR,
  GAME_GET_COUPON_START,
  GAME_GET_COUPON_SUCCESS,
  GAME_GET_DETAIL_ERROR,
  GAME_GET_DETAIL_START,
  GAME_GET_DETAIL_SUCCESS,
  GAME_GET_LAUNCH_GAME_DEMO_URL_ERROR,
  GAME_GET_LAUNCH_GAME_URL_ERROR,
  GAME_LIST_GET_ERROR,
  GAME_LIST_GET_START,
  GAME_LIST_GET_SUCCESS,
  GAME_LIST_RESET,
  GAME_LIST_SET_FILTERS,
  GAME_RESET_DETAIL,
  GAME_SEARCH_ERROR,
  GAME_SEARCH_START,
  GAME_SEARCH_SUCCESS,
  GAME_SET_PLAY_MODE,
  GAME_SET_SEARCH_RECOMMENDED,
  GAME_SET_SLUG,
  GET_FAVORITE_GAMES_ASSETS_ERROR,
  GET_FAVORITE_GAMES_ASSETS_START,
  GET_FAVORITE_GAMES_ASSETS_SUCCESS,
  GET_FAVORITE_GAMES_ERROR,
  GET_FAVORITE_GAMES_START,
  GET_FAVORITE_GAMES_SUCCESS,
  RESET_FAVORITE_GAME,
  RESET_FAVORITE_GAME_ASSETS,
  SET_FAVORITE_GAME_ERROR,
  SET_FAVORITE_GAME_START,
  SHOW_GAME_DETAIL_FOR_PLAY,
  UPDATE_FAVORITE_GAMES_SUCCESS,
  WITHDRAWAL_SET_PREVENTED
} from '@/shared/constants/ActionTypes';
import { isServer } from '@/shared/constants/AppConst';
import { GAME_FILTER_FE, GAME_FILTER_MAPPING } from '@/shared/constants/AppEnums';
import { ERROR_ACCOUNT, ERROR_GAME } from '@/shared/constants/ErrorConst';
import { PLAY_MODE } from '@/shared/constants/GameConst';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { PAGING_DEFAULT, PAGING_DEFAULT_STATE } from '@/shared/constants/PagingConst';
import { getCMSGame } from '@/shared/helpers/cms';
import router from 'next/router';
import { fetchStart, hideBottomSheet, onSignOut, showBottomSheet, showError, showSuccess } from '.';
import { fetchError, fetchSuccess, handleErrors } from './Common';
import { onGetPage } from './Page';

function isGameLauncher() {
  return ['/casino-game/[slug]', '/sportsbook/[slug]'].includes(router.pathname);
}

export function isGamePage() {
  return ['/games', '/games/[types]', '/live-casino', '/live-casino/[types]'].includes(
    router.pathname
  );
}

export const onGetGameLauncherUrl = () => {
  return async (dispatch, getState) => {
    const {
      game: { gameDetail },
      auth: { isLoggedIn }
    } = getState();

    let game = gameDetail;

    if (game && game.country_restricted) {
      return;
    }

    dispatch({ type: GAME_GET_DETAIL_START });

    logd('onGetGameLauncherUrl.game', { game, isLoggedIn });

    // call game detail api success
    // require login & has cookie
    if (isLoggedIn && game && game.game_launch_endpoint) {
      try {
        const res = await getGameLauncherUrl(game.game_launch_endpoint);
        logd('getGameLauncherUrl.res', res.data);
        if (res.data && res.data.game_url) {
          game.game_url = res.data.game_url;

          const behavior = res?.data?.behavior;
          if (behavior) {
            game.behavior = behavior;
          }

          if (!isServer) {
            try {
              const last_played_game = {
                item_id: game?.code,
                item_name: game?.name,
                item_brand: game?.provider_name,
                item_category: game?.game_type,
                item_category2: game?.product_type_name,
                quantity: 1
              };
              localStorage.setItem(
                LOCAL_STORAGE.last_played_game,
                JSON.stringify(last_played_game)
              );
            } catch (err) {
              logd(err);
            }
          }
          dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
          return true;
          // AnalyticsService.logGameLaunchSuccess(query);
        } else if (res.data.error && res.data.error.code && res.data.error.message) {
          dispatch(onGetGameLauncherUrlError(res.data.error.code, res.data.error.message));
          // AnalyticsService.logGameLaunchFailed(query, res);
        } else if (res.data.error) {
          dispatch(onGetGameLauncherUrlError('error.systemError.description', res.data.error));
          // AnalyticsService.logGameLaunchFailed(query, res);
        } else {
          dispatch(onGetGameLauncherUrlError());
          // AnalyticsService.logGameLaunchFailed(query, res);
        }
      } catch (error) {
        logd('getGameLauncherUrl.error', error);

        // AnalyticsService.logGameLaunchFailed(query, error);

        switch (error?.status) {
          case 400:
            switch (error?.data?.error) {
              case ERROR_GAME.DISABLED_SYSTEM_GAMBLING:
              case ERROR_ACCOUNT.EXCLUDED_ACCOUNT:
              case ERROR_ACCOUNT.KICKED_ACCOUNT:
                dispatch(onGetGameLauncherUrlError(error?.data?.error, error?.data?.message));
                dispatch(onHideGameDetailForPlay());
                routerPush('/');
                setTimeout(() => {
                  dispatch(handleErrors(error));
                }, 1000);
                break;
              case ERROR_GAME.UNINITIALIZED_KYC:
              case ERROR_GAME.PENDING_KYC:
                dispatch(onGetGameLauncherUrlError(error?.data?.error, error?.data?.message));
                break;
              case ERROR_GAME.DECLINED_KYC:
                dispatch(
                  onGetGameLauncherUrlError(
                    error?.data?.error,
                    <IntlMessages id="error.systemError.kycDeclined" />
                  )
                );
                break;
              default:
                dispatch(onGetGameLauncherUrlError());
            }
            break;
          case 403:
          case 404:
            dispatch(onGetGameLauncherUrlError(error?.data?.error, error?.data?.message));
            break;
          default:
            dispatch(onGetGameLauncherUrlError(error?.data?.error, error?.data?.message));
            break;
        }
      }
    }

    dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
    return false;
  };
};

export const onGetGameDemoLauncherUrl = () => {
  return async (dispatch, getState) => {
    const {
      game: { gameDetail }
    } = getState();

    let game = gameDetail;

    if (game && game.country_restricted) {
      return;
    }

    // user try to enter trial mode which the game doesnt support
    // switch to select mode
    if (game && !game.has_trial) {
      return 'has_no_demo_mode';
    }

    dispatch({ type: GAME_GET_DETAIL_START });

    const isExist = game && game.game_demo_url;

    logd('onGetGameDemoLauncherUrl.isExist', isExist);
    logd('onGetGameDemoLauncherUrl.game', game);

    if (!isExist) {
      // don't require to login
      // need enable game trial at BO
      if (game && game.has_trial && game.game_launch_endpoint_trial) {
        try {
          const res = await getGameLauncherTrialUrl(game.game_launch_endpoint_trial);
          logd('onGetGameDemoLauncherUrl.res', res);

          if (res.data && res.data.game_url) {
            game.game_demo_url = res.data.game_url;
            const behavior = res?.data?.behavior;
            if (behavior) {
              game.behavior = behavior;
            }
            dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
            return true;
          } else if (res.data.error && res.data.error.code && res.data.error.message) {
            dispatch(onGetGameLauncherDemoUrlError(res.data.error.code, res.data.error.message));
          } else if (res.data.error) {
            dispatch(
              onGetGameLauncherDemoUrlError('error.systemError.description', res.data.error)
            );
          } else {
            dispatch(
              onGetGameLauncherDemoUrlError(res?.data?.error?.code, res?.data?.error?.message)
            );
          }
        } catch (error) {
          logd('onGetGameLauncherDemoUrl.error', error);
          if (error.data && error.data.error && error.data.message) {
            dispatch(onGetGameLauncherDemoUrlError(error?.data?.error, error?.data?.message));
          } else {
            dispatch(onGetGameLauncherDemoUrlError(error?.data?.error, error?.data?.message));
          }
        }
      }
    } else {
      dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
      return true;
    }

    dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
    return false;
  };
};

export const onGetGameDetail = (query) => {
  return async (dispatch, getState) => {
    logd('onGetGameDetail.query', query);

    if (!query?.slug) {
      return;
    }

    dispatch({ type: GAME_GET_DETAIL_START });

    const {
      game: { gameDetail }
    } = getState();

    let game = gameDetail;

    // const isExist = game && game.slug === query.slug;

    // logd('onGetGameDetail.isExist', isExist);
    logd('onGetGameDetail.game', game);

    try {
      const res = await getGameDetail(query.slug);
      logd('onGetGameDetail.res', res.data);
      if (res.data) {
        game = getCMSGame(res.data);
        dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
        return true;
      }
      dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
    } catch (error) {
      logd('getGameDetail.error', error);

      dispatch(onGetGameDetailError(error?.data?.error, error?.data?.message));
    }
  };
};

export const onSetGameDetail = (game) => {
  return (dispatch) => {
    logd('onSetGameDetail.game', game);
    dispatch({ type: GAME_GET_DETAIL_SUCCESS, payload: game });
  };
};

export const onResetGameDetail = () => {
  return (dispatch) => {
    dispatch({ type: GAME_RESET_DETAIL });
  };
};

export const onGetGameDetailError = (title, message) => {
  return (dispatch) => {
    dispatch({
      type: GAME_GET_DETAIL_ERROR,
      payload: {
        title: title || 'error',
        message: message || 'error.systemError.description'
      }
    });
  };
};

export const onGetGameLauncherUrlError = (title, message) => {
  return (dispatch) => {
    dispatch({
      type: GAME_GET_LAUNCH_GAME_URL_ERROR,
      payload: {
        title: title || 'error',
        message: message || 'error.systemError.description'
      }
    });
  };
};

export const onGetGameLauncherDemoUrlError = (title, message) => {
  return (dispatch) => {
    dispatch({
      type: GAME_GET_LAUNCH_GAME_DEMO_URL_ERROR,
      payload: {
        title: title || 'error',
        message: message || 'error.systemError.description'
      }
    });
  };
};

export const onCheckSystemStatus = () => {
  return async (dispatch, getState) => {
    try {
      const res = await getSystemStatus();
      logd('onCheckSystemStatus', res.data);

      if (res?.data?.country_restricted) {
        dispatch(onSignOut());
        return;
      }

      if (res?.data?.disable_gambling) {
        if (router.pathname.indexOf('casino-game') > -1) {
          routerPush('/');
          dispatch(fetchError('game.theGamblingWasDisabledBySystem'));
        }
      }

      if (res?.data?.excluded_account) {
        if (router.pathname.indexOf('casino-game') > -1) {
          routerPush('/');
          dispatch(fetchError('auth.excludedAccount'));
        }
      }

      if (res?.data?.is_maintenance) {
        if (router.pathname !== '/maintenance') {
          routerPush('/maintenance');
        }
      } else {
        if (router.pathname === '/maintenance') {
          router.reload();
        }
      }

      if (typeof res?.data?.prevented_withdraw === 'boolean') {
        const { withdrawal } = getState();
        logd('onCheckSystemStatus.prevented', {
          prevented_withdraw: res?.data,
          withdrawal
        });
        if (res?.data?.prevented_withdraw !== withdrawal?.prevented) {
          dispatch({
            type: WITHDRAWAL_SET_PREVENTED,
            payload: res?.data?.prevented_withdraw
          });
        }
      }
    } catch (error) {
      // ignore error
      logd('onCheckSystemStatus.error', error);
      dispatch(handleErrors(error));
    }
  };
};

export const onGetRecommendedGames = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());
      const params = {
        'display_area[]': 'search_recommended',
        per_page: 10
      };
      const res = await getGameAssets({ params });
      logd('onGetGameRecommended.res', res);
      dispatch({
        type: GAME_SET_SEARCH_RECOMMENDED,
        payload: Array.isArray(res.data?.data) ? res.data?.data : []
      });
      dispatch(fetchSuccess());
    } catch (error) {
      dispatch(handleErrors(error));
      logd('onGetRecommendedGames', error);
    }
  };
};

export const onSearchGames = (value) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GAME_SEARCH_START
      });
      const params = {
        query: value,
        per_page: 10,
        order_by: 'order',
        sort_by: 'desc'
      };
      logd('onSearchGames.params', params);
      const res = await getGameAssets({ params });
      dispatch({
        type: GAME_SEARCH_SUCCESS,
        payload: Array.isArray(res.data?.data) ? res.data?.data : []
      });

      AnalyticsService.search(value);

      // AnalyticsService.logGameSearch(value);

      return res;
    } catch (error) {
      dispatch({
        type: GAME_SEARCH_ERROR
      });
      dispatch(handleErrors(error));
    }
  };
};

export const onShowGameDetailForPlay = (gameDetail) => {
  return (dispatch) => {
    dispatch({ type: SHOW_GAME_DETAIL_FOR_PLAY });
    dispatch(onSetGameDetail(gameDetail));
    dispatch(showBottomSheet());
  };
};

export const onHideGameDetailForPlay = () => {
  return (dispatch) => {
    // dispatch(onResetGameDetail());
    dispatch(hideBottomSheet());
    dispatch(onSetGamePlayMode(PLAY_MODE.SELECT));
    closeAllPopup();
  };
};

export const onSetGamePlayMode = (playMode) => {
  return (dispatch) => {
    dispatch({
      type: GAME_SET_PLAY_MODE,
      payload: playMode
    });
  };
};

export const onGetCouponGame = (method, url) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GAME_GET_COUPON_START });
      const res = await onGetCouponGameApi(method, url);
      if (res && res.status === 200) {
        if (res?.data?.status !== 200) {
          dispatch(showError(res.data.error.message));
          dispatch({ type: GAME_GET_COUPON_ERROR });
        } else {
          dispatch(showSuccess('game.coupon.success'));
          dispatch({ type: GAME_GET_COUPON_SUCCESS });
        }
      }
    } catch (error) {
      dispatch({ type: GAME_GET_COUPON_ERROR });
      dispatch(handleErrors(error));
    }
  };
};

export const onSetGameListFilters = (filters, slug) => async (dispatch, getState) => {
  try {
    const { game } = getState();
    logd('GamesPage.onSetGameListFilters', filters, slug);
    // if (JSON.stringify(game.list.filters) === JSON.stringify(filters) && game.slug === slug) {
    //   return;
    // }
    dispatch({
      type: GAME_LIST_SET_FILTERS,
      payload: filters
    });
    await dispatch(onGetGameList(slug));
    dispatch({
      type: GAME_SET_SLUG,
      payload: slug
    });
  } catch (error) {
    logd('error', error);
  }
};

export function getFinalGameFilterObject(defaultFilters, filters) {
  logd('getFinalGameFilterObject', defaultFilters, filters);
  const finalFilters = {};
  Object.keys(GAME_FILTER_FE).forEach((key) => {
    if (isValidArray(defaultFilters[GAME_FILTER_FE[key]])) {
      finalFilters[GAME_FILTER_FE[key]] = defaultFilters[GAME_FILTER_FE[key]];
    }
    if (isValidArray(filters[GAME_FILTER_FE[key]])) {
      finalFilters[GAME_FILTER_FE[key]] = filters[GAME_FILTER_FE[key]];
    }
  });
  return finalFilters;
}

export function parseDefaultFiltersConfig(data) {
  let filters = {};
  if (isValidArray(data?.game_default_filter)) {
    filters = data?.game_default_filter?.reduce((obj, item) => {
      let slug = GAME_FILTER_MAPPING[item._link];

      if (slug) {
        if (obj[slug]) {
          if (!obj[slug].includes(item.code)) {
            obj[slug].push(item.code);
          }
        } else {
          obj[slug] = [item.code];
        }
      }

      return obj;
    }, {});
  }
  return filters;
}

export const onGetGameList = (slug) => {
  return async (dispatch, getState) => {
    dispatch({ type: GAME_LIST_GET_START });
    try {
      const { game, page } = getState();

      const pagingData = getPagingObj(game?.list);
      const pageData = page?.data[slug];

      const defaultFilters = parseDefaultFiltersConfig(pageData);
      const filterObj = getFinalGameFilterObject(defaultFilters, pagingData.filters);

      logd('GamesPage.onGetGameList.filterObj', {
        pageData,
        defaultFilters,
        filterObj,
        pagingData
      });

      filterObj.per_page = PAGING_DEFAULT.perPage;
      filterObj.page =
        pagingData.lastPage === PAGING_DEFAULT_STATE.lastPage
          ? pagingData.page
          : pagingData.page + 1;

      logd('GamesPage.onGetGameList.params', filterObj);

      const res = await getGameAssets({
        params: filterObj
      });

      logd('GamesPage.onGetGameList.res', res);

      if (isValidPagingData(res.data)) {
        dispatch({
          type: GAME_LIST_GET_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({ type: GAME_LIST_GET_ERROR, payload: null });
      }
    } catch (error) {
      logd('GamesPage.onGetGameList.error', error);
      // do not handle this case
      dispatch({ type: GAME_LIST_GET_ERROR, payload: null });
    }
  };
};

export const onResetGameList = () => (dispatch) => {
  dispatch({
    type: GAME_SET_SLUG,
    payload: ''
  });
  dispatch({ type: GAME_LIST_RESET });
};

export const onReloadGameLauncher = () => (dispatch) => {
  try {
    if (isGameLauncher()) {
      dispatch(onGetGameLauncherUrl());
    }
  } catch (error) {
    logd('onReloadGameLauncher.error', error);
  }
};

export const onReloadGamePage = () => {
  return async (dispatch, getState) => {
    const _isGamePage = isGamePage();
    logd('onReloadGamePage._isGamePage', _isGamePage);
    if (_isGamePage) {
      const { game } = getState();
      const slug = game?.slug;
      logd('onReloadGamePage.slug', slug);
      if (slug) {
        await dispatch(onGetPage(slug, {}, true));

        const _query = { featured: router.query.featured, providers: router.query.providers };
        const filters = parseQueryToGameFilterObj(_query);
        await dispatch(onSetGameListFilters(filters, slug));
      }
    }
  };
};

// FavoriteGames
export const onGetFavoriteGames = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const enableFavoriteGame = get(
      state,
      'app.systemSetting.players.enable_player_favorite_game',
      false
    );

    if (!enableFavoriteGame) {
      return;
    }

    try {
      dispatch({
        type: GET_FAVORITE_GAMES_START
      });

      const res = await getFavoriteGames();

      dispatch({
        type: GET_FAVORITE_GAMES_SUCCESS,
        payload: res?.data
      });
    } catch (error) {
      dispatch({ type: GET_FAVORITE_GAMES_ERROR });
    }
  };
};

export const onGetFavoriteGameAssets = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const enableFavoriteGame = get(
      state,
      'app.systemSetting.players.enable_player_favorite_game',
      false
    );

    if (!enableFavoriteGame) {
      return;
    }

    dispatch({ type: GET_FAVORITE_GAMES_ASSETS_START });
    try {
      const pagingData = getPagingObj(state?.game?.favoriteGameAssets);

      const params = {
        per_page: PAGING_DEFAULT.perPage,
        page:
          pagingData.lastPage === PAGING_DEFAULT_STATE.lastPage
            ? pagingData.page
            : pagingData.page + 1,
        order_by: 'name',
        sort_by: 'asc'
      };
      const res = await getFavoriteGameAssets({ params });
      if (res.data) {
        dispatch({
          type: GET_FAVORITE_GAMES_ASSETS_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({ type: GET_FAVORITE_GAMES_ASSETS_ERROR, payload: null });
      }
    } catch (error) {
      dispatch({ type: GET_FAVORITE_GAMES_ASSETS_ERROR, payload: null });
    }
  };
};

export const updateArrayData = (list, gameId) => {
  const arr = list;
  return arr.map((item) => {
    const id = get(item, 'game.id', '') || get(item, 'id', uniqueId());
    if (id === gameId) return { ...item, favorited: !item.favorited };
    return item;
  });
};

export const onSetFavoriteGames = (id) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const isLoggedIn = get(state, 'auth.isLoggedIn', false);

      if (!isLoggedIn) {
        return;
      }

      const favoriteGames = get(state, 'game.favoriteGames.data', []);
      const isExist = isValidArray(favoriteGames) ? favoriteGames.includes(id) : false;

      const params = { id, favorited: !isExist };

      logd('onSetFavoriteGames', params);

      dispatch({ type: SET_FAVORITE_GAME_START });
      const res = await toggleFavoriteGame(params);
      let newArray = [];
      if (isValidArray(res?.data?.favorites)) {
        newArray = res.data.favorites;
        dispatch({
          type: UPDATE_FAVORITE_GAMES_SUCCESS,
          payload: newArray
        });
      } else {
        dispatch(onGetFavoriteGames());
      }

      return isExist;
    } catch (error) {
      logd('onSetFavoriteGames.error', error);
      dispatch({ type: SET_FAVORITE_GAME_ERROR });
      if (error?.data?.message) {
        dispatch(showError(error?.data?.message));
      } else {
        dispatch(showError('common.anErrorHasOccurred'));
      }
      return;
    }
  };
};

export const onResetFavoriteGames = () => {
  return (dispatch) => {
    dispatch({ type: RESET_FAVORITE_GAME });
  };
};

export const onResetFavoriteGameAssets = () => {
  return (dispatch) => {
    dispatch({ type: RESET_FAVORITE_GAME_ASSETS });
  };
};

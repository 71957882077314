import { withStyles } from '@material-ui/core';

const GlobalBackdrop = withStyles(
  (theme) => ({
    '@global': {
      '.MuiBackdrop-root': {
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      },
      '.MuiDialog-root': {
        '& .MuiBackdrop-root': {
          backdropFilter: 'blur(6px)'
        },
        '& .MuiDialog-paper': {
          backdropFilter: 'blur(12px)'
        }
      },
      '.MuiAppBar-root': {
        backdropFilter: 'blur(16px)'
      }
    }
  }),
  { name: 'GlobalBackdrop' }
)(() => null);

export default GlobalBackdrop;

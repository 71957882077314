import { useTransactionGameSportTabs } from '@/redux/reducers/Transaction';
import { Box } from '@material-ui/core';
import { TRANSACTION } from '../../configure/';
import ListDeposit from '../ListDeposit';
import ListGame from '../ListGame';
import ListGameSportsbook from '../ListGameSportsbook';
import ListWithdraw from '../ListWithdraw';

// const ListDeposit = asyncComponent(() => import('../ListDeposit'));
// const ListWithdraw = asyncComponent(() => import('../ListWithdraw'));
// const ListGame = asyncComponent(() => import('../ListGame'));

const TransactionListPanel = ({ value }) => {
  const transactionTypes = useTransactionGameSportTabs();

  const renderPanel = (type) => {
    switch (type) {
      case TRANSACTION.depositHistory:
        return <ListDeposit />;
      case TRANSACTION.withdrawalHistory:
        return <ListWithdraw />;
      case TRANSACTION.gameHistory:
        return <ListGame />;
      case TRANSACTION.sportHistory:
        return <ListGameSportsbook />;
      default:
        return null;
    }
  };

  return (
    <div>
      {transactionTypes.map((item, index) => {
        const hidden = value !== index;
        return (
          <Box
            key={item.type}
            role="tabpanel"
            hidden={hidden}
            id={`transaction-tabpanel-${index}`}
            position="relative">
            {hidden ? null : renderPanel(item.type)}
          </Box>
        );
      })}
    </div>
  );
};

export default TransactionListPanel;

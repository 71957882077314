import { getAllCookies, getCookie } from '@/@crema/utility/Session';
import { fetchError, onSignOut } from '@/redux/actions';
import { isLoggedIn } from '@/redux/reducers/Auth';
import { store } from '@/redux/store';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { isDev, isServer } from '@/shared/constants/AppConst';
import { LOCALES } from '@/shared/constants/AppEnums';
import { COOKIES } from '@/shared/constants/CookieConst';
import { ERROR_ACCOUNT } from '@/shared/constants/ErrorConst';
import { getClientIp } from '@/shared/helpers/ip';
import axios from 'axios';
import { logi } from '../utility/Logging';
import { getTimeZoneName, isValidString } from '../utility/utils';

const TAG = '[API_SERVICE]';
const API_HEADER_PREFIX = 'x-next';

export default class ApiService {
  constructor(baseURL, timeout) {
    this.instance = axios.create({
      baseURL: baseURL,
      timeout: timeout
    });

    // Add a request interceptor
    this.instance.interceptors.request.use(
      function (config) {
        try {
          // config.perfEnd = perfStart(TAG);

          const cookies = getAllCookies(config.headers);

          if (cookies) {
            const jwt_auth = cookies[COOKIES.jwt_auth];
            const _ga = cookies[COOKIES._ga];
            const _ga_measurement_id = cookies[COOKIES._ga_measurement_id];

            // jwt auth token
            if (isValidString(jwt_auth)) {
              config.headers.Authorization = `Bearer ${jwt_auth}`;
            }

            // GA4
            if (isValidString(_ga)) {
              config.headers['x-client-id'] = _ga.slice(6);
            }

            // GA Measurement id
            if (isValidString(_ga_measurement_id)) {
              const sessionId = _ga_measurement_id.split('.')[2];
              if (sessionId) {
                config.headers['x-client-session-id'] = sessionId;
              }
            }
          }

          delete config.headers.cookie;
          delete config.headers['x-next-cookie'];
        } catch (error) {
          // keep this log to tracking
          console.error(error);
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      function (response) {
        // response.config.perfEnd(response);
        return response;
      },
      (error) => {
        if (error.response) {
          // error.response.config.perfEnd(error.response);
          this.handleError(error.response);
          // Request made and server responded
          return Promise.reject(error.response);
        }

        // Something happened in setting up the request that triggered an Error
        // Or request has been cancelled
        return Promise.reject(error);
      }
    );
  }

  /**
   * parse request headers
   * @param {import('next').NextPageContext} context
   * @returns {Object} headers
   */
  parseHeaders(context) {
    const clientIp = getClientIp(context?.req) || '';
    const locale = getCookie(COOKIES.next_locale, context?.req?.headers) || context?.locale;
    const currency = getCookie(COOKIES.currency, context?.req?.headers) || '';
    const timezoneName = getTimeZoneName();

    const defaultHeaders = {
      /**
       * axios default config
       */
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      /**
       * custom config
       */
      [`${API_HEADER_PREFIX}-version`]: APP_CONFIG.NEXT_PUBLIC_APP_VERSION,
      [`${API_HEADER_PREFIX}-env`]: APP_CONFIG.NEXT_PUBLIC_ENV,
      [`${API_HEADER_PREFIX}-env-type`]: isServer ? 'server' : 'browser',
      [`${API_HEADER_PREFIX}-node-env`]: isDev ? 'debug' : 'release',
      cookie: context?.req?.headers?.cookie
    };

    if (clientIp) {
      defaultHeaders[`${API_HEADER_PREFIX}-client-ip`] = clientIp;
    }

    if (locale) {
      defaultHeaders[`${API_HEADER_PREFIX}-locale`] = locale;
    } else {
      defaultHeaders[`${API_HEADER_PREFIX}-locale`] = LOCALES.EN;
    }

    if (currency) {
      defaultHeaders[`${API_HEADER_PREFIX}-currency`] = currency;
    }

    if (timezoneName) {
      defaultHeaders['X-Timezone-Name'] = timezoneName;
    }

    /**
     * forwarded from cloudflare request
     */
    if (context?.req?.headers) {
      Object.keys(context.req.headers).forEach((key) => {
        defaultHeaders[`${API_HEADER_PREFIX}-${key}`] = context.req.headers[key];
      });
    }

    return defaultHeaders;
  }

  get(url, params) {
    return this.instance.get(url, params);
  }

  post(url, params) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return this.instance.post(url, params, config);
  }

  postFormData(url, params) {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
      if (Array.isArray(params[key]) && params[key]?.length > 0) {
        params[key].forEach((item) => {
          formData.append(key, item);
        });
      } else {
        if (typeof params[key] !== 'undefined' && params[key] !== null) {
          formData.append(key, params[key]);
        }
      }
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    return this.instance.post(url, formData, config);
  }

  put(url, params) {
    return this.instance.put(url, params);
  }

  /**
   * set next headers to axios headers
   * @param {import('next').NextPageContext} context
   */
  handleRequest(context) {
    try {
      this.instance.defaults.headers = this.parseHeaders(context);
    } catch (error) {
      logi('handleRequest.error', error);
    }
  }

  cancelToken() {
    return axios.CancelToken.source();
  }

  isCancel(value) {
    return axios.isCancel(value);
  }

  handleError(res) {
    if (isServer) {
      return;
    }

    switch (res?.status) {
      case 401:
        if (isLoggedIn.current) {
          store?.dispatch(onSignOut());
          switch (res?.data?.error) {
            case ERROR_ACCOUNT.EXCLUDED_ACCOUNT:
            case ERROR_ACCOUNT.DEACTIVATED_ACCOUNT:
            case ERROR_ACCOUNT.BANNED_ACCOUNT:
            case ERROR_ACCOUNT.KICKED_ACCOUNT:
              this.showError(res?.data?.error);
              break;
          }
        }
        break;
    }
  }

  showError(errorMessage) {
    if (isServer) {
      return;
    }

    if (errorMessage) {
      setTimeout(() => {
        store?.dispatch(fetchError(errorMessage));
      }, 1000);
    }
  }
}

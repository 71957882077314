import { withStyles } from '@material-ui/core';

const GlobalCustomClassName = withStyles(
  (theme) => ({
    '@global': {
      a: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '&:hover': {
          textDecoration: 'none'
        }
      },
      '.relative': {
        position: 'relative'
      },
      '.select-w-full-h-400': {
        width: 250,
        maxHeight: 400
      },
      '.montserrat400': {
        fontFamily: 'Montserrat',
        fontWeight: 400
      },
      '.montserrat500': {
        fontFamily: 'Montserrat',
        fontWeight: 500
      },
      '.montserrat600': {
        fontFamily: 'Montserrat',
        fontWeight: 600
      },
      '.montserrat700': {
        fontFamily: 'Montserrat',
        fontWeight: 700
      },
      '.montserrat800': {
        fontFamily: 'Montserrat',
        fontWeight: 800
      },
      '.montserrat900': {
        fontFamily: 'Montserrat',
        fontWeight: 900
      },
      '.mx-auto': {
        marginTop: 'auto',
        marginBottom: 'auto'
      },
      '.mt-2': {
        marginTop: theme.spacing(2)
      },
      '.p-4': {
        padding: theme.spacing(4)
      },
      '.pt-0': {
        paddingTop: 0
      },
      '.pt-2': {
        paddingTop: theme.spacing(2)
      },
      '.pt-6': {
        paddingTop: theme.spacing(6)
      }
    }
  }),
  { name: 'GlobalCustomClassName' }
)(() => null);

export default GlobalCustomClassName;

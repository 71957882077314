import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const getGameSportsbookHistories = (query) => {
  return API_SERVICE.get(getApiEndpoint('sportsbook-game-logs'), { params: query });
};

export const getGameHistories = (query) => {
  return API_SERVICE.get(getApiEndpoint('game-logs'), { params: query });
};

export const getDepositHistories = (query) => {
  return API_SERVICE.get(getApiEndpoint('deposits'), { params: query });
};

export const getWithdrawalHistories = (query) => {
  return API_SERVICE.get(getApiEndpoint('withdraws'), { params: query });
};

export const getTransactionStatus = (transactionId) => {
  return API_SERVICE.get(getApiEndpoint(`deposits/${transactionId}`));
};

export const cancelTransactionDeposit = (transactionId) => {
  return API_SERVICE.put(getApiEndpoint(`deposits/${transactionId}/cancel`));
};

export const cancelTransactionWithdraw = (transactionId) => {
  return API_SERVICE.put(getApiEndpoint(`withdraws/${transactionId}/cancel`));
};

export const getTransactionDetailDeposit = (transactionId) => {
  return API_SERVICE.get(getApiEndpoint(`deposits/${transactionId}`));
};

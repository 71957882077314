import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { Fragment } from 'react';
import Skeleton from '.';

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default function ListSkeletonMobile() {
  const classes = useStyles();
  const itemLength = 6;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {[...Array(itemLength)].map((_, index) => {
        return (
          <Fragment key={index}>
            <Box mt={6}>
              <Typography variant="subtitle2">
                <Skeleton width="50%" />
              </Typography>
            </Box>

            <Box mt={3} mb={6}>
              <Typography variant="caption">
                <Skeleton width="70%" />
              </Typography>
            </Box>

            <Divider />
          </Fragment>
        );
      })}
    </Box>
  );
}

import { makeStyles } from '@material-ui/core/styles';
import MuiTablePagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import PropTypes from 'prop-types';
import JumpToPage from './JumpToPage';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& .MuiPaginationItem-outlinedPrimary.Mui-selected': {
      border: 'none',
      background: theme.palette.primary.main,
      color: theme.palette.text.contrast,
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > *': {
        padding: '8px 0'
      }
    }
  },
  paginationItem: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'center',
    '&.MuiPaginationItem-rounded': {
      borderRadius: theme.shape.borderRadius
    },
    '&.MuiPaginationItem-outlined': {
      border: `2px solid ${theme.palette.text.secondary}`
    }
  }
}));

function TablePagination(props) {
  const classes = useStyles();
  const { count, rowsPerPage, page, loading, changePage } = props;

  const handlePageChange = useCallback(
    (_, newPage) => {
      if (newPage === page) return;
      changePage(newPage);
    },
    [changePage, page]
  );

  const renderItem = (item) => {
    return (
      <PaginationItem
        color="secondary"
        className={classes.paginationItem}
        {...item}
        disabled={item.disabled || loading}
      />
    );
  };

  return (
    <div className={classes.paginationContainer}>
      <JumpToPage
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        loading={loading}
      />
      <MuiTablePagination
        size="large"
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page}
        count={count}
        onChange={handlePageChange}
        renderItem={renderItem}
        siblingCount={0}
      />
    </div>
  );
}

TablePagination.propTypes = {
  /** Total number of table rows */
  count: PropTypes.number.isRequired,
  /** Current page index */
  page: PropTypes.number.isRequired,
  /** Total number allowed of rows per page */
  rowsPerPage: PropTypes.number.isRequired,
  /** Callback to trigger rows per page change */
  changePage: PropTypes.func.isRequired
};

export default TablePagination;

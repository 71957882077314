import {
  activateBonus,
  activePendingBonus,
  cancelBonus,
  deactivateAllBonus,
  deactivePendingBonus,
  getBonusRecommendations,
  getFreespinsGames,
  getPlayerBonus
} from '@/@crema/services/apis/bonus';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { get, isValidArray } from '@/@crema/utility/utils';
import {
  BONUS_GET_PENDING_BONUS,
  BONUS_GET_PLAYER,
  BONUS_GET_RECOMMENDATION,
  BONUS_GET_REWARD_HISTORY,
  BONUS_LOAD_MORE_PENDING_BONUS_ERROR,
  BONUS_LOAD_MORE_PENDING_BONUS_START,
  BONUS_LOAD_MORE_PENDING_BONUS_SUCCESS,
  BONUS_LOAD_MORE_REWARD_HISTORY_ERROR,
  BONUS_LOAD_MORE_REWARD_HISTORY_START,
  BONUS_LOAD_MORE_REWARD_HISTORY_SUCCESS,
  BONUS_RESET,
  BONUS_SET_LOADING,
  BONUS_SET_SWITCH,
  PROMOTION_SET_ACTIVATION
} from '@/shared/constants/ActionTypes';
import { BONUS_STATUS } from '@/shared/constants/AppEnums';
import { FREE_GAME_PER_PAGE } from '@/shared/constants/PagingConst';
import { STATUS_CODES } from '@/shared/constants/StatusCodes';
import { fetchStart, fetchSuccess, handleErrors, showError, showInfo, showSuccess } from './Common';
import { onGetWallets } from './Wallet';

export const onGetPlayerBonus = (page, isLoading = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchStart());
      if (isLoading) {
        dispatch({ type: BONUS_SET_LOADING, payload: true });
      }
      const { bonus } = getState();
      const { rewardHistory, pendingBonuses } = bonus;
      const activeQuery = {
        is_ongoing: true
      };
      const historyQuery = {
        is_ongoing: false,
        page: page || rewardHistory?.paging.page,
        per_page: rewardHistory?.paging?.per_page
      };
      const pendingQuery = {
        status: [BONUS_STATUS.Pending],
        per_page: pendingBonuses?.paging?.per_page
      };

      const [activeRes, historyRes, pendingRes] = await Promise.all([
        getPlayerBonus(activeQuery),
        getPlayerBonus(historyQuery),
        getPlayerBonus(pendingQuery)
      ]);

      dispatch({
        type: BONUS_GET_PLAYER,
        payload: {
          activeData: activeRes?.data,
          historyData: historyRes?.data,
          pendingData: pendingRes?.data
        }
      });
      dispatch(fetchSuccess());
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onGetBonuses = () => {
  return (dispatch) => {
    dispatch(onGetPlayerBonus(1, true));
  };
};

export const onGetRewardHistory = (isLoadMore) => {
  return async (dispatch, getState) => {
    try {
      const { bonus } = getState();
      const { rewardHistory } = bonus;
      const { paging } = rewardHistory;

      if (isLoadMore) {
        dispatch({
          type: BONUS_LOAD_MORE_REWARD_HISTORY_START
        });
      }

      const query = {
        is_ongoing: false,
        page: isLoadMore ? paging.current_page + 1 : paging.current_page,
        per_page: paging.per_page
      };

      const { data } = await getPlayerBonus(query);

      const ACTION_TYPE_SUCCESS = isLoadMore
        ? BONUS_LOAD_MORE_REWARD_HISTORY_SUCCESS
        : BONUS_GET_REWARD_HISTORY;
      dispatch({ type: ACTION_TYPE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: BONUS_LOAD_MORE_REWARD_HISTORY_ERROR
      });
      dispatch(handleErrors(error));
    }
  };
};

export const onGetPendingBonus = (isLoadMore) => {
  return async (dispatch, getState) => {
    try {
      const { bonus } = getState();
      const { pendingBonuses } = bonus;
      const { paging } = pendingBonuses;

      if (isLoadMore) {
        dispatch({
          type: BONUS_LOAD_MORE_PENDING_BONUS_START
        });
      }

      const query = {
        status: [BONUS_STATUS.Pending],
        page: isLoadMore ? paging.current_page + 1 : paging.current_page,
        per_page: paging.per_page
      };

      const { data } = await getPlayerBonus(query);

      const ACTION_TYPE_SUCCESS = isLoadMore
        ? BONUS_LOAD_MORE_PENDING_BONUS_SUCCESS
        : BONUS_GET_PENDING_BONUS;
      dispatch({ type: ACTION_TYPE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: BONUS_LOAD_MORE_PENDING_BONUS_ERROR
      });
      dispatch(handleErrors(error));
    }
  };
};

export const onResetBonus = () => {
  return async (dispatch) => {
    dispatch({ type: BONUS_RESET });
  };
};

export const onCancelBonus = (id) => {
  return async (dispatch) => {
    try {
      await cancelBonus(id);
      dispatch(showSuccess('bonus.dialog.cancelSuccess'));
      dispatch(onGetPlayerBonus(1, true));
      dispatch(onGetWallets());
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onActivePendingBonus = (id) => {
  return async (dispatch, getState) => {
    try {
      const res = await activePendingBonus(id);

      if (res?.status !== STATUS_CODES.SUCCESS) {
        dispatch(showInfo('bonus.available.cannotActivateBonus'));
        return;
      }

      dispatch(onGetPlayerBonus(1, true));
      dispatch(onGetWallets());
      dispatch(showSuccess('bonus.available.activatedBonus'));
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onDeactivePendingBonus = (id) => {
  return async (dispatch, getState) => {
    const { bonus } = getState();
    const { data } = bonus?.pendingBonuses || {};
    try {
      const res = await deactivePendingBonus(id);

      if (res?.status !== 204) {
        dispatch(showInfo('bonus.available.cannotDeactivateBonus'));
        return;
      }

      if (isValidArray(data)) {
        data.forEach((i) => {
          if (i.key === id) {
            i.pending_activated = false;
          }
        });
        dispatch({ type: BONUS_GET_PENDING_BONUS, payload: data });
        dispatch(showInfo('bonus.available.deactivatedBonus'));
      }
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onGetFreespinsGames = (id) => {
  return async (dispatch) => {
    try {
      const params = {
        per_page: FREE_GAME_PER_PAGE
      };
      const res = await getFreespinsGames(id, params);
      const { data } = res;

      return data;
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

/**
 *
 * @param {
 * currency_code (String): 'USDT | BTC | ADA'
 * claimable_in_next_deposit (Boolean): true
 * }
 * isNewCurrency is true when users choose a new currency on deposit form
 */
export const getListBonusRecommendation = (params, isNewCurrency = false) => {
  return async (dispatch, getState) => {
    try {
      let list = [];
      let activatedRecommend = null;
      const { bonus } = getState();
      const { recommend } = bonus || {};
      const { activatedRecommend: activatedRecommendCurrent } = recommend;
      if (isValidArray(recommend?.list) && !isNewCurrency) return;

      dispatch({ type: BONUS_SET_LOADING, payload: true });

      const { data } = await getBonusRecommendations(params);
      if (isValidArray(data?.data)) {
        list = data.data;
        // Reset activatedRecommend
        activatedRecommend = isNewCurrency ? null : list.find((item) => item.pending_activated);
        // Active bonus prev currency if list have bonus prev currency and not call api active or de active
        if (activatedRecommendCurrent) {
          const bonus = list.find((item) => item.pending_activated);
          if (bonus) activatedRecommend = bonus;
          // if (activatedRecommend) {
          //   dispatch(onActivateBonus(activatedRecommend?.id));
          // } else {
          //   // dispatch(onDeactivateAllBonus(activatedRecommendCurrent?.id, false));
          // }
        }
        if (activatedRecommend && !recommend?.switch) {
          dispatch(toggleBonusRecommend());
        }
      }
      dispatch({ type: BONUS_GET_RECOMMENDATION, payload: { list, activatedRecommend } });
      dispatch({ type: BONUS_SET_LOADING, payload: false });
    } catch (error) {
      logd('Get Error Recommendation', error);
      const { message } = error?.data || {};
      let msg = message ? message : 'error.systemError.description';
      if (msg) {
        dispatch(showError(msg));
      }
      dispatch({ type: BONUS_SET_LOADING, payload: false });
    }
  };
};

export const toggleBonusRecommend = () => {
  return (dispatch) => dispatch({ type: BONUS_SET_SWITCH });
};

export const onDeactivateAllBonus = (id, isShowMsg = true) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BONUS_SET_LOADING, payload: true });
      const state = getState();
      const list = get(state, 'bonus.recommend.list', []);
      const listData = [...list];
      // Change to de active bonus when switch
      const { status } = await deactivateAllBonus();
      if (status === STATUS_CODES.NO_CONTENT) {
        listData.forEach((item) => {
          if (item.key === id) {
            item.pending_activated = false;
          }
        });
        if (isShowMsg) {
          dispatch(showSuccess('account.bonus.deActivatedSuccessful'));
        }
        dispatch({ type: PROMOTION_SET_ACTIVATION, payload: false });
        dispatch({ type: BONUS_GET_RECOMMENDATION, payload: { list: listData } });
      }
    } catch (error) {
      dispatch({ type: BONUS_SET_LOADING, payload: false });
      const { message } = error?.data || {};
      const msg = message ? message : 'error.systemError.description';
      if (msg) {
        dispatch(showError(msg));
      }
      logd('Deactivate All Bonus Error', error);
    }
  };
};

export const onResetActivatedRecommend = () => {
  return (dispatch, getState) => {
    const { bonus } = getState();
    const { recommend } = bonus || {};
    if (recommend.activatedRecommend) {
      dispatch({ type: BONUS_GET_RECOMMENDATION, payload: { activatedRecommend: null } });
    }
  };
};

export const onActivateBonus = (id, currencyCode) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BONUS_SET_LOADING, payload: true });
      const state = getState();
      const { recommend } = state.bonus;
      const { list } = recommend || {};
      const { status, data } = await activateBonus(id, currencyCode);
      let activatedRecommend = null;
      if (status === STATUS_CODES.SUCCESS) {
        list?.forEach((item) => {
          if (item.key === data.key) {
            item.pending_activated = data.pending_activated;
          } else {
            item.pending_activated = false;
          }
        });
        activatedRecommend = list?.find((item) => item.pending_activated);
        dispatch(showSuccess('account.bonus.activatedSuccessful'));
        dispatch({ type: PROMOTION_SET_ACTIVATION, payload: data.pending_activated });
        dispatch({ type: BONUS_GET_RECOMMENDATION, payload: { list, activatedRecommend } });
      }
    } catch (error) {
      dispatch({ type: BONUS_SET_LOADING, payload: false });
      if (
        error &&
        error.data?.error === 'pending_bonus_activation_prevented_due_to_has_processing_wr' &&
        error.data?.error_message_variables?.game_product_type
      ) {
        const value = get(error, 'data.error_message_variables.game_product_type.name');
        dispatch(
          showError(
            <IntlMessages
              id="The player already have a processing bonus for game product type"
              values={{ value }}
            />
          )
        );
      } else {
        dispatch(handleErrors(error));
      }
      logd('Activate Bonus Error', error);
    }
  };
};

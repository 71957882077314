import { Icon } from '@/@crema';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Dialog from './Dialog';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(2)
  },
  close: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(5)
  },
  icoButton: {
    padding: theme.spacing(2)
  },
  closeIco: {
    fontSize: '1.1rem'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography color="primary" variant="h4" className="bold">
        {children}
      </Typography>
      {onClose ? (
        <Box className={classes.close}>
          <IconButton aria-label="close" onClick={onClose} className={classes.icoButton}>
            <Icon name="close" className={classes.closeIco} />
          </IconButton>
        </Box>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(6),
    paddingTop: theme.spacing(2)
  }
}))(MuiDialogActions);

const GoBackButton = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`
  }
}))(Button);

export default function ConfirmDialog(props) {
  const { open, title, content, onClose, onClickYes, onClickCancel } = props;
  const { formatMessage } = useIntl();

  return (
    <Dialog fullScreen={false} name="confirm" open={open} disableBackdropClick onClose={onClose}>
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <GoBackButton onClick={onClickCancel} variant="outlined">
          {formatMessage({ id: 'common.goBack' })}
        </GoBackButton>
        <Button onClick={onClickYes} variant="contained" color="primary">
          {formatMessage({ id: 'common.agree' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  onClickYes: PropTypes.func,
  onClickCancel: PropTypes.func
};

ConfirmDialog.defaultProps = {
  open: false,
  title: '',
  content: '',
  onClose: () => {},
  onClickYes: () => {},
  onClickCancel: () => {}
};

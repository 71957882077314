export const ANALYTICS_EVENT = {
  recommended: {
    login: 'login',
    sign_up: 'sign_up',
    logout: 'logout',
    search: 'search'
  },

  custom: {
    /**
     * fasttrack crm session id
     */
    set_crm_session_id: 'set_crm_session_id',

    game_play: 'game_play',
    click_cta: 'click_cta',
    favorite_game: 'favorite_game',
    top_nav: 'top_nav',
    top_nav_toggle: 'top_nav_toggle',
    submenu_toggle: 'submenu_toggle',
    footer_nav: 'footer_nav',
    live_chat: 'live_chat',
    personal_updated: 'personal_updated',
    bonus_success: 'bonus_success',

    withdrawal_init: 'withdrawal_init',
    withdrawal: 'withdrawal',

    update_profile: 'update_profile',
    fingerprint: 'fingerprint'
  },

  ecommerce: {
    view_promotion: 'view_promotion',
    select_promotion: 'select_promotion',
    view_item_list: 'view_item_list',
    select_item: 'select_item',
    view_item: 'view_item',
    add_to_wishlist: 'add_to_wishlist',
    begin_checkout: 'begin_checkout',
    add_payment_info: 'add_payment_info',
    purchase: 'purchase',
    purchase_processing: 'purchase_processing',
    purchase_failure: 'purchase_failure',
    purchase_pending: 'purchase_pending'
  }
};

export const ANALYTICS_NAVIGATION_TYPE = {
  account_nav: 'account_nav',
  bottom_tab_nav: 'bottom_tab_nav',
  footer_nav: 'footer_nav',
  top_nav: 'top_nav',
  sidebar_nav: 'sidebar_nav',
  left: 'left',
  middle: 'middle',
  right: 'right'
};

import NotAvailable from '@/@crema/core/DataTable/NotAvailable';
import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import { formatDatetime } from '@/@crema/utility/utils';
import { DATE_TIME_FORMAT, DATE_TIME_SHORT_FORMAT } from '@/shared/constants/AppEnums';
import { VALUE_NA } from '../components/DialogDetails/TransactionInformation';

export const DatetimeTransaction = ({ value }) => {
  const isMobile = useBreakPointDown('sm');

  if (!value || value === VALUE_NA) return <NotAvailable />;

  const _pattern = isMobile ? DATE_TIME_SHORT_FORMAT : DATE_TIME_FORMAT;
  return formatDatetime(value, _pattern);
};

export default function renderDateTime(value) {
  return <DatetimeTransaction value={value} />;
}

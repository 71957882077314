import enMessages from '../locales/en.json';
import { enUS } from '@material-ui/core/locale';

const EnLang = {
  messages: {
    ...enMessages
  },
  muiLocale: enUS,
  locale: 'en'
};
export default EnLang;

import {
  Autocomplete,
  ButtonLink,
  DateTimePicker,
  Form,
  Icon,
  Select,
  SubmitButton,
  TextField
} from '@/@crema';
import { VALIDATION_SCHEMA } from '@/@crema/core/Form/ValidationHelpers';
import { useBreakPointUp } from '@/@crema/hooks/useBreakPointUp';
import { requestOTPAgent } from '@/@crema/services/apis/auth';
import { formatDatetime, isValidArray } from '@/@crema/utility/utils';
import { handleErrors, onSignUpAgent } from '@/redux/actions';
import { useAffiliateUrl, useAgeRestriction, useCurrencies } from '@/redux/reducers/App';
import { DATE_FORMAT } from '@/shared/constants/AppEnums';
import { COUNTRIES } from '@/shared/constants/CountriesConst';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Typography,
  fade,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import Link from 'next/link';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  phoneGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  prefix: {
    marginRight: 10
  },
  popperPrefix: {
    background: theme.palette.background.default
  },
  popper: {
    width: '300px !important',
    height: '200px !important'
  },
  limitOldLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    '& .MuiIcon-root': {
      fontSize: '3rem',
      '&:before': {
        color: fade(theme.palette.text.primary, 0.33)
      }
    }
  }
}));

const AffiliateSignup = ({ handleToggle }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isSM = useBreakPointUp('sm');
  const age_restriction = useAgeRestriction();
  const currencies = useCurrencies();

  const msgWelcomeTo = formatMessage({ id: 'Become a partner' });
  const msgFirstName = formatMessage({ id: 'common.firstName' });
  const msgLastName = formatMessage({ id: 'common.lastName' });
  const msgEmail = formatMessage({ id: 'common.email' });
  const msgBirthday = formatMessage({ id: 'common.birthday' });
  const msgPhone = formatMessage({ id: 'common.phone' });
  const msgPassword = formatMessage({ id: 'common.password' });
  const msgConfirmPassword = formatMessage({ id: 'common.confirmPassword' });
  const msgAlreadyHaveAccount = formatMessage({ id: 'common.alreadyHaveAccount' });
  const msgLoginHere = formatMessage({ id: 'common.loginHere' });
  const msgCurrency = formatMessage({ id: 'common.currency' });
  const { affiliateUrlSignIn } = useAffiliateUrl();

  const onSubmit = useCallback(
    async (data, actions) => {
      const body = {
        ...data,
        phone_number: `+${data.calling_code.phone}${data.phone_number}`,
        birthday: formatDatetime(data.birthday, DATE_FORMAT)
      };
      dispatch(onSignUpAgent(body, actions, handleToggle));
    },
    [dispatch, handleToggle]
  );

  const sendOTP = async (params) => {
    try {
      const response = await requestOTPAgent(params);
      if (response.status === 200) return new Date().getTime() + 15 * 60 * 1000 + 2000;
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };

  const onChangeNumber = (value, form) => {
    form.setFieldValue('phone_number', value.replace(/[^\d-]+/g, ''));
  };

  const renderForm = () => {
    const initialValues = {
      code: '',
      password: '',
      confirm_password: '',
      email: '',
      currency_code: '',
      first_name: '',
      last_name: '',
      birthday: '',
      address: '',
      phone_number: '',
      otp_code: '',
      calling_code: null
    };

    const renderCurrencySelect = () => {
      if (isValidArray(currencies) && currencies.length > 2) {
        return (
          <Select
            className={classes.select}
            disabled={false}
            label={msgCurrency}
            name="currency_code"
            isForm>
            {currencies.map((item) => {
              return (
                <MenuItem key={item.code} value={item?.code}>
                  {item?.code}
                </MenuItem>
              );
            })}
          </Select>
        );
      }
      return 'No available setting currencies';
    };

    return (
      <Form
        id="signup-form"
        title={msgWelcomeTo}
        initialValues={initialValues}
        validateOnChange
        validationSchema={VALIDATION_SCHEMA.agentRegister(age_restriction)}
        onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField disabled={false} name="first_name" label={msgFirstName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField disabled={false} name="last_name" label={msgLastName} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField disabled={false} name="email" label={msgEmail} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              disabled={false}
              name="birthday"
              maxDate={new Date()}
              label={msgBirthday}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.phoneGroup}>
            <Grid item xs={5} sm={4} className={classes.prefix}>
              <Autocomplete
                name="calling_code"
                label={formatMessage({ id: 'common.phonePrefix' })}
                required
                options={COUNTRIES}
                ListboxProps={{ style: { maxHeight: 200 } }}
                getOptionLabel={(option) => `+${option.phone} ${option.code}`}
                getOptionSelected={(option, value) => {
                  return option.code === value.code;
                }}
                renderOption={(option) => (
                  <>
                    {option.label} ({option.code}) +{option.phone}
                  </>
                )}
                PaperComponent={({ children }) => (
                  <Paper className={classes.boxPaper}>{children}</Paper>
                )}
                disablePortal={isSM}
                classes={{ popper: clsx(classes.popperPrefix, { [classes.popper]: isSM }) }}
              />
            </Grid>
            <Grid item xs={7} sm={8}>
              <TextField
                disabled={false}
                name="phone_number"
                label={msgPhone}
                onChange={onChangeNumber}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderCurrencySelect()}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField disabled={false} name="password" type="password" label={msgPassword} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={false}
              name="confirm_password"
              type="password"
              label={msgConfirmPassword}
            />
          </Grid>
        </Grid>
        <SubmitButton>{formatMessage({ id: 'common.createAccount' })}</SubmitButton>
        <FormControl margin="normal" fullWidth>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box mr={2}>
              <Typography variant="body2" className="semibold">
                {msgAlreadyHaveAccount}
              </Typography>
            </Box>
            <ButtonLink
              target="_blank"
              className="semibold"
              Component={Link}
              href={affiliateUrlSignIn}
              color="primary">
              {msgLoginHere}
            </ButtonLink>
          </Box>
        </FormControl>
        <Box className={classes.limitOldLogo}>
          <Icon name="old-is-allowed" fontSize="large" />
        </Box>
      </Form>
    );
  };

  return <Box className={classes.root}>{renderForm()}</Box>;
};
export default AffiliateSignup;

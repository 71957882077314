import { DataTable } from '@/@crema';
import { useBreakPointDown } from '@/@crema/hooks/useBreakPointDown';
import { useTransactionGameSport } from '@/redux/reducers/Transaction';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SLUG_TYPE } from '../';
import ExpandedRow from '../../components/ExpandedRow';
import TransactionListItemSportsbookGame from '../../components/ListItem/TransactionListItemSportsbookGame';
import ListTransactionMobile from '../../components/ListTransactionMobile';
import { TRANSACTION, configure, useGetData } from '../../configure/';
import Filter from './Filter';

const COLUMNS = [
  'betTime',
  'betSelection',
  'betType',
  'statusSportsbook',
  'betAmount',
  'payout',
  'expandRow'
  // 'betTime',
  // 'settleTime'
];
const COLUMNS_TABLE = COLUMNS.map((key) => configure.fields[key]);

const ListGameSportsbook = () => {
  const isSM = useBreakPointDown('sm');
  const transactionData = useTransactionGameSport();
  const { data, hasMore, loading, page, perPage } = transactionData;
  const { onLoadMore } = useGetData(TRANSACTION.sportHistory);
  const { formatMessage } = useIntl();

  const renderItem = useCallback(
    ({ item }) => <TransactionListItemSportsbookGame item={item} />,
    []
  );

  const renderExpandedRow = useCallback((row, expanded, toggleExpanded) => {
    return (
      <ExpandedRow
        row={row}
        slug={SLUG_TYPE.sportsbook}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
      />
    );
  }, []);

  return (
    <div>
      <Filter />
      {isSM ? (
        <ListTransactionMobile
          hasMore={hasMore}
          loading={loading}
          data={data}
          renderItem={renderItem}
          onLoadMore={onLoadMore}
        />
      ) : (
        <DataTable
          size="small"
          loading={loading && page === 0}
          data={data}
          columns={COLUMNS_TABLE}
          page={page}
          count={0}
          rowsPerPage={perPage}
          // onRowClick={handleSelectItem}
          messageNoData={formatMessage({ id: 'common.noTransactionHistory' })}
          isPaging={false}
          canLoadMore={hasMore}
          isLoadMore={loading && page !== 0}
          loadMore={onLoadMore}
          renderExpandedRow={renderExpandedRow}
        />
      )}
    </div>
  );
  /* <DialogDetailsGame
        open={isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={handleUnselectItem}
        fields={COLUMNS}
      />
      <DrawerDetail
        title={formatMessage({ id: 'transactionDetail.header.game' })}
        fields={COLUMNS}
        open={!isSM && selectedItem !== null && isSelected}
        data={selectedItem}
        onClose={handleUnselectItem}
      /> */
};
export default ListGameSportsbook;

import { combineReducers } from 'redux';
import { AppReducer } from './App';
import { JWTAuthReducer } from './Auth';
import { BonusReducer } from './Bonus';
import { CashbackReducer } from './Cashback';
import { CommonReducer } from './Common';
import { GameReducer } from './Game';
import { KycProcessReducer } from './KycProcess';
import { LeaderboardReducer } from './Leaderboard';
import { NotificationsReducer } from './Notifications';
import { PageReducer } from './Page';
import { PaymentReducer } from './Payment';
import { PromotionReducer } from './Promotions';
import { StaticPageReducer } from './StaticPage';
import { TransactionReducer } from './Transaction';
import { WalletReducer } from './Wallets';
import { WithdrawalReducer } from './Withdrawal';

const allReducers = combineReducers({
  app: AppReducer,
  auth: JWTAuthReducer,
  bonus: BonusReducer,
  common: CommonReducer,
  game: GameReducer,
  kyc: KycProcessReducer,
  payment: PaymentReducer,
  promotion: PromotionReducer,
  transaction: TransactionReducer,
  wallets: WalletReducer,
  withdrawal: WithdrawalReducer,
  page: PageReducer,
  leaderboard: LeaderboardReducer,
  staticPage: StaticPageReducer,
  cashback: CashbackReducer,
  notifications: NotificationsReducer
});

export default allReducers;

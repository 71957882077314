import { Box } from '@material-ui/core';
import useStyles from './index.style';

const InstructionDetail = ({ html }) => {
  const classes = useStyles();

  return <Box className={classes.instruction} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default InstructionDetail;

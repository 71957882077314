import { isValidArray } from '@/@crema/utility/utils';
import {
  WALLET_ACTIVE,
  WALLET_SET_DATA,
  WALLET_SET_FIAT_LOADING,
  WALLET_SET_LOADING
} from '@/shared/constants/ActionTypes';
import { ZERO_BALANCE } from '@/shared/constants/WalletConst';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useCmsSettings, useHideZeroBalances } from './App';
import { FIATS } from './mockData';

const INIT_STATE = {
  data: [],
  isFiatLoading: true,
  loading: true
};

export const useWallets = () => {
  return useSelector(({ wallets }) => wallets.data);
};

/**
 * Get balance from wallet by type
 * @param {String} walletType
 * LOCKED: 0,
 * MAIN: 1,
 * WITHDRAW: 3,
 * SPORTS_BONUS: 5,
 * LIVE_CASINO_BONUS: 6,
 * SLOTS_BONUS: 7,
 * TOTAL: 99,
 * TOTAL_BONUS: 98
 * @returns {Number} balance
 */
export const useBalance = (walletType) => {
  try {
    const wallets = useWallets();
    const foundTotal = wallets.find((item) => item.type === walletType);
    return foundTotal ? foundTotal.balance : 0;
  } catch (error) {
    return 0;
  }
};

export const WalletReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WALLET_SET_DATA:
      return {
        ...state,
        loading: false,
        isFiatLoading: false,
        data: isValidArray(action.payload) ? action.payload : []
      };
    case WALLET_ACTIVE:
      return {
        ...state,
        loading: false,
        isFiatLoading: false,
        data: state.data.map((item) => {
          if (item?.currency?.code === action.payload) {
            item.activated = 1;
          } else {
            item.activated = 0;
          }
          return item;
        })
      };
    case WALLET_SET_FIAT_LOADING:
      return {
        ...state,
        isFiatLoading: action.payload
      };
    case WALLET_SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export const getActiveCurrencyCode = (wallets) => {
  if (isValidArray(wallets)) {
    return wallets.find((i) => i.activated === 1)?.currency?.code || '';
  }

  return '';
};

export const useFiatActive = () => {
  return useSelector(({ auth }) => auth.user?.preferred_currency);
};

export const useFiats = () => {
  let fiats = [];
  let list_fiats = useSelector(({ app }) => app.systemSetting?.preferred_currencies);

  if (isValidArray(list_fiats)) {
    fiats = list_fiats.map((item) => {
      item.icon = FIATS.find((fiat) => fiat.code === item.code)?.icon;
      return item;
    });
  }
  return fiats;
};

export const useFiatLoading = () => {
  return useSelector(({ wallets }) => wallets.isFiatLoading);
};

export const useWalletLoading = () => {
  return useSelector(({ wallets }) => wallets.loading);
};
export const usePlayerWalletsHideLockedWallet = () => {
  const cmsSettings = useCmsSettings();
  return get(cmsSettings, 'advanced_config.player_wallets_hide_locked_wallet', true);
};

export const useDisplayWallets = () => {
  const wallets = useWallets();
  const isHideZeroBalance = useHideZeroBalances();

  if (isHideZeroBalance && isValidArray(wallets)) {
    const displayWallets = wallets.filter(
      (item) => item?.total_main_bonus_balance !== ZERO_BALANCE
    );

    // if display wallets are empty then show selected wallet
    if (!displayWallets.length) {
      return [wallets.find((item) => item?.activated === 1)];
    }

    return displayWallets;
  }

  return wallets;
};

import { Loader } from '@/@crema';
import { useLoadingCheckShowSportsbookTab } from '@/redux/reducers/Transaction';
import { Box } from '@material-ui/core';
import TransactionListPanel from './TransactionListPanel';
import TransactionListTabs from './TransactionListTabs';

export const SLUG_TYPE = {
  deposit: 'deposit',
  withdrawal: 'withdrawal',
  game: 'game',
  sportsbook: 'sportsbook'
};

function TransactionList({ type }) {
  const loadingCheckShowSportsbookTab = useLoadingCheckShowSportsbookTab();

  let selectedIndex = 0;
  switch (type) {
    case SLUG_TYPE.deposit:
      selectedIndex = 0;
      break;
    case SLUG_TYPE.withdrawal:
      selectedIndex = 1;
      break;
    case SLUG_TYPE.game:
      selectedIndex = 2;
      break;
    case SLUG_TYPE.sportsbook:
      selectedIndex = 3;
      break;
  }

  if (loadingCheckShowSportsbookTab) {
    return (
      <Box position="relative" width="100%" height="100%">
        <Loader />
      </Box>
    );
  }

  return (
    <div className="transactionList">
      <TransactionListTabs value={selectedIndex} />
      <TransactionListPanel value={selectedIndex} />
    </div>
  );
}

export default TransactionList;

import { Skeleton } from '@/@crema';
import { isValidString } from '@/@crema/utility/utils';
import { Typography } from '@material-ui/core';

export const renderItemWithLoadingSkeleton = (cellElm, loading, options) => {
  if (loading) return <Skeleton variant="rect" height={20} width={80} {...options} />;
  return cellElm;
};

export default function CellDetailSport(props) {
  const { detail = {}, gameTypeName, loading, className } = props;
  const { event, home_name, away_name, odds } = detail;

  const home = isValidString(home_name) ? home_name : home_name?.name || '';
  const away = isValidString(away_name) ? away_name : away || '';

  return (
    <div key={`${event} - ${home} - ${away} - ${odds}`} className={className}>
      {renderItemWithLoadingSkeleton(
        <Typography className="text-primary-6" variant="caption">
          {gameTypeName} {' · '} {event}
        </Typography>,
        loading,
        { height: 14, width: 150 }
      )}

      {renderItemWithLoadingSkeleton(
        <Typography className="text-primary-6" variant="caption">
          {home} {' · '} {away}
          <Typography color="textPrimary" className="ml-1 white" variant="caption">
            {odds}
          </Typography>
        </Typography>,
        loading,
        { height: 14, width: 100 }
      )}
    </div>
  );
}

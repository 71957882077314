import { withStyles } from '@material-ui/core';

const blurClasses = ['.MuiDrawer-paper', '.MuiPopover-paper'].join(',');

const GlobalBlur = withStyles(
  (theme) => {
    return {
      '@global': {
        [blurClasses]: {
          backgroundColor: theme.palette.background.defaultBlur,
          backdropFilter: 'blur(10px)'
        }
      }
    };
  },
  { name: 'GlobalBlur' }
)(() => null);

export default GlobalBlur;

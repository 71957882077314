import { FastField, getIn } from 'formik';
import BaseSelect from '../../Inputs/Select';

const _Select = ({
  disabled,
  field,
  form,
  helperText,
  onChange,
  onError = () => {},
  showError = true,
  loading,
  ...nest
}) => {
  const { isSubmitting, touched, errors } = form;
  const fieldError = getIn(errors, field.name);
  const fieldTouch = getIn(touched, field.name);
  const hasError = showError && !!fieldTouch && !!fieldError;
  const _helperText = hasError ? fieldError : helperText;
  const _disabled = disabled || isSubmitting;

  const _onChange = (evt) => {
    field.onChange(evt);
    onChange?.(evt.target.value, form);
  };

  return (
    <BaseSelect
      {...field}
      {...nest}
      onChange={_onChange}
      error={hasError}
      helperText={_helperText}
      disabled={_disabled}
      loading={loading}
    />
  );
};

const shouldUpdate = (prevProps, nextProps) => {
  return (
    prevProps.children !== nextProps.children ||
    prevProps.disabled !== nextProps.disabled ||
    prevProps.loading !== nextProps.loading ||
    prevProps.type !== nextProps.type ||
    prevProps.label !== nextProps.label ||
    prevProps.name !== nextProps.name ||
    getIn(prevProps.formik.values, nextProps.name) !==
      getIn(nextProps.formik.values, nextProps.name) ||
    getIn(prevProps.formik.errors, nextProps.name) !==
      getIn(nextProps.formik.errors, nextProps.name) ||
    getIn(prevProps.formik.touched, nextProps.name) !==
      getIn(nextProps.formik.touched, nextProps.name) ||
    Object.keys(nextProps).length !== Object.keys(prevProps).length ||
    prevProps.formik.isSubmitting !== nextProps.formik.isSubmitting
  );
};

const Select = (props) => {
  return <FastField {...props} component={_Select} shouldUpdate={shouldUpdate}></FastField>;
};

export default Select;

import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const getStaticPage = async (name, query = {}) => {
  return API_SERVICE.get(getApiEndpoint(`pages/${name}`), { params: query });
};

export const getSingleton = async (name) => {
  return API_SERVICE.get(getApiEndpoint(`cms-setting/${name}`));
};

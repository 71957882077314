import DialogDetailsBase from './DialogDetailsBase';
import TransactionInformation from './TransactionInformation';

const DialogDetailsWithdraw = ({ open, data, onClose, fields }) => {
  if (!data) {
    return null;
  }

  return (
    <DialogDetailsBase
      open={open}
      title="transaction.header.withdraw"
      data={data}
      fields={fields}
      onClose={onClose}>
      <TransactionInformation data={data} fields={fields} />
    </DialogDetailsBase>
  );
};

export default DialogDetailsWithdraw;

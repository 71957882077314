import { UAParser } from 'ua-parser-js';

const BOT_UA = [
  '\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/',
  'googlebot',
  'baiduspider',
  'gurujibot',
  'yandexbot',
  'slurp',
  'msnbot',
  'bingbot',
  'facebookexternalhit',
  'linkedinbot',
  'twitterbot',
  'slackbot',
  'telegrambot',
  'applebot',
  'pingdom',
  'tumblr'
];

/**
 * Get the information of an useragent string.
 * @link https://github.com/faisalman/ua-parser-js
 * @param phrase user agent strings.
 * @returns parsed information.
 */
export function parse(phrase) {
  const result = new UAParser(phrase).getResult();

  const regex = new RegExp(`(${BOT_UA.join('|')})`, 'ig');
  const isBot = phrase ? regex.test(phrase.toLowerCase()) : false;

  const browser = result.browser.name;
  const deviceType = result.device.type || null;
  const os = result.os.name;
  const engine = result.engine.name;
  const isMobile = deviceType === 'mobile';
  const isTablet = deviceType === 'tablet';
  const isIos = os === 'iOS';

  const ua = Object.freeze({
    browser,
    deviceType,
    os,
    engine,
    isMobile,
    isTablet,
    isIos,
    source: phrase,
    deviceVendor: result.device.vendor || null,
    osVersion: parseInt(result.os.version, 10),
    browserVersion: parseFloat(result.browser.version),
    engineVersion: parseFloat(result.engine.version),
    isIphone: isMobile && isIos,
    isIpad: isTablet && isIos,
    isDesktop: !isMobile && !isTablet,
    isChrome: browser === 'Chrome',
    isFirefox: browser === 'Firefox',
    isSafari: browser === 'Safari',
    isIE: browser === 'IE',
    isEdge: browser === 'Edge',
    isOpera: browser === 'Opera',
    isMac: os === 'Mac OS',
    isChromeOS: os === 'Chromium OS',
    isWindows: os === 'Windows',
    isAndroid: os === 'Android',
    isBot: isBot
  });

  return ua;
}

export function parseUserAgent(ctx) {
  return parse(ctx?.req?.headers?.['user-agent']);
}

import { fade, Tooltip, withStyles } from '@material-ui/core';
import MuiIconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';

const CustomIconButton = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.disabled,
      '&.MuiIconButton-sizeSmall': {
        padding: 5,
        '& .MuiIcon-root': {
          fontSize: '1rem'
        }
      },
      '&.outlined': {
        color: theme.palette.text.primary,
        borderRadius: '50%',
        border: '1px solid '.concat(theme.palette.background.paper),

        '&.contrast': {
          color: theme.palette.text.contrast,
          borderRadius: '50%',
          border: '1px solid '.concat(fade(theme.palette.text.contrast, 0.12))
        }
      }
    }
  }),
  { name: 'IconButton' }
)(MuiIconButton);

/**
 * @type React.FC<IconButtonPropTypes>
 */
const IconButton = forwardRef(
  ({ title, tooltip, className, variant, contrast = false, ...rest }, ref) => {
    const { formatMessage } = useIntl();
    const msgTitle = title ? formatMessage({ id: title }) : '';
    const Button = (
      <CustomIconButton
        innerRef={ref}
        aria-label={msgTitle || 'icon button'}
        {...rest}
        className={clsx(className, variant, contrast ? 'contrast' : '')}
      />
    );

    if (tooltip && msgTitle) {
      return <Tooltip title={msgTitle}>{Button}</Tooltip>;
    }

    return Button;
  }
);

const IconButtonPropTypes = {
  ref: PropTypes.oneOf([PropTypes.shape({ current: PropTypes.any }), PropTypes.func]),
  title: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'default']),
  disableFocusRipple: PropTypes.bool,
  edge: PropTypes.oneOf(['start', 'end', false]),
  size: PropTypes.oneOf(['small', 'medium']),
  tooltip: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'contained'])
};

IconButton.propTypes = IconButtonPropTypes;

IconButton.defaultProps = {
  color: 'default',
  disableFocusRipple: false,
  edge: false,
  size: 'medium',
  tooltip: true,
  variant: 'contained'
};

IconButton.displayName = 'IconButton';

export default IconButton;

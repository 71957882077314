import { MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Select from '../Inputs/Select';
import { useCallback } from 'react';
import { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textLabel: {
    marginRight: 8,
    display: 'flex',
    alignItems: 'center'
  },
  containerSelect: {
    '& .MuiFormControl-root': {
      margin: 0
    }
  },
  select: {
    margin: 0,
    '& .MuiFilledInput-root': {
      borderRadius: 6
    }
  }
}));

function JumpToPage(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { count, page, loading, changePage } = props;

  const handlePageChange = useCallback(
    (event) => {
      changePage(parseInt(event.target.value, 10));
    },
    [changePage]
  );

  const selectCpm = useMemo(
    () => (
      <Select
        value={page}
        onChange={handlePageChange}
        className={classes.select}
        disabled={loading}>
        {[...Array(count)].map((_, index) => (
          <MenuItem className={classes.menuItem} key={index + 1} value={index + 1}>
            {index + 1}
          </MenuItem>
        ))}
      </Select>
    ),
    [classes.menuItem, classes.select, count, handlePageChange, loading, page]
  );

  if (Number.isNaN(count)) return null;

  return (
    <div className={classes.root}>
      <div className={classes.textLabel}>
        <Typography variant="body2" noWrap>
          {formatMessage({ id: 'muiTable.pagination.selectPage' })}
        </Typography>
      </div>
      <div className={classes.containerSelect}>{selectCpm}</div>
    </div>
  );
}

JumpToPage.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired
};

export default JumpToPage;

import { LocaleProvider } from '@/@crema';
import AppLayout from '@/@crema/core/AppLayout';
import { dispatchGlobalEvent, getSiteUrl } from '@/@crema/utility/utils';
import { onGetSetting, onStartUp } from '@/redux/actions/App';
import { wrapper } from '@/redux/store';
import { isDev, isProd, isServer } from '@/shared/constants/AppConst';
import GlobalStyles from '@/shared/jss/global';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../styles/index.css';
// css
import AppErrorBoundary from '@/@crema/core/AppErrorBoundary';
import { API_SERVICE } from '@/@crema/services';
import { logd } from '@/@crema/utility/Logging';
import StylesProvider from '@/@crema/utility/StylesProvider';
import ThemeProvider from '@/@crema/utility/ThemeProvider';
import asyncComponent from '@/@crema/utility/asyncComponent';
import { parseUserAgent } from '@/@crema/utility/utils/utils-user-agent';
import GetDepositId from '@/shared/components/GetDepositId';
import GlobalCustomHtml from '@/shared/components/GlobalCustomHtml';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { LOCALES } from '@/shared/constants/AppEnums';
import { requestTracking } from '@/shared/helpers/requestTracking';
import checkRedirect from '@/shared/lib/locale/redirect';
import Head from 'next/head';

if (!isServer && isProd) {
  window.next.version = undefined;
}

const unregisterServiceWorker = () => {
  if (isDev) {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }
};

const AuthListener = asyncComponent(() => import('@crema/core/AuthListener'), false);

const PusherService = asyncComponent(() => {
  return import('../shared/lib/pusher/PusherService');
}, false);

const App = (props) => {
  const { Component, pageProps, router, ua } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onStartUp(router));

    // DEV: unregister serviceWorker
    unregisterServiceWorker();

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    const setLangHTMLAttribute = () => {
      try {
        //Set lang attribute HTML
        const lang = router.locale === LOCALES.NZ ? LOCALES.ENNZ : router.locale;
        const rootElement = document.documentElement;
        if (rootElement) {
          rootElement.lang = lang;
        }
      } catch (error) {
        logd('Error setAttribute', error);
      }
    };

    const handleRouteChange = (url, { shallow }) => {
      dispatchGlobalEvent('routeChangeComplete', { url, shallow });
      setLangHTMLAttribute();
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [dispatch, router]);

  return (
    <React.Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
      </Head>

      <StylesProvider>
        <ThemeProvider ua={ua} locale={router.locale}>
          <LocaleProvider router={router}>
            <CssBaseline />
            <GlobalStyles />
            <GlobalCustomHtml />
            <GetDepositId />

            <AppErrorBoundary>
              <AppLayout router={router} pageProps={pageProps}>
                <Component {...pageProps} />
              </AppLayout>
            </AppErrorBoundary>

            {/* auth listener */}
            <AuthListener />

            {/* pusher */}
            <PusherService />
          </LocaleProvider>
        </ThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
};

App.getInitialProps = async (props) => {
  const { Component, ctx, router } = props;
  const queue = [];
  let pageProps = {};
  let systemSetting;
  const site_url = getSiteUrl(ctx);
  APP_CONFIG.siteUrl = site_url;
  if (/\/sitemap.*.xml|\/robots.txt/g.test(router.asPath) && ctx.pathname !== '/_error') return;

  // const perfEnd = perfStart('App');

  // parse current user agent
  const ua = parseUserAgent(ctx);

  try {
    // assign ctx value to axios
    API_SERVICE.handleRequest(ctx);

    if (Component.getInitialProps) {
      queue.push(Component.getInitialProps(ctx));
    } else {
      queue.push(null);
    }

    if (isServer) {
      queue.push(ctx.store.dispatch(onGetSetting(ctx)));
    }

    try {
      const [resPage, resSetting] = await Promise.all(queue);

      // ssr set status code as page status code
      if (ctx && ctx.res) {
        if (resPage && resPage.statusCode) {
          ctx.res.statusCode = resPage.statusCode;
        }

        if (resSetting && resSetting.statusCode) {
          ctx.res.statusCode = resSetting.statusCode;
        }
      }

      // error page
      if (resPage) {
        pageProps = resPage;
      }

      // error setting
      if (resSetting && resSetting.statusCode) {
        pageProps = resSetting;
      }

      // setting 200
      if (resSetting) {
        systemSetting = resSetting;
      }
    } catch (error) {
      console.error('App.getInitialProps.error', error);
    }

    // handle locale redirects
    const shouldRedirect = await checkRedirect(ctx, router, systemSetting);

    if (shouldRedirect) {
      return {};
    }

    // perfEnd();

    requestTracking('App', ctx, Component);

    return {
      pageProps: {
        ...pageProps,
        ua
      }
    };
  } catch (error) {
    console.error('App.getInitialProps.error', error, pageProps);

    // perfEnd();

    requestTracking('App', ctx, Component);

    return {
      pageProps: {
        ...pageProps,
        ua
      }
    };
  }
};

export default wrapper.withRedux(App);

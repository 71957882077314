export const POPUP_AUTH = {
  BANK_INTERNATIONAL: 'bank-intl',
  BANK_IBAN: 'bank-iban',
  WALLET: 'wallet',
  DEPOSIT: 'deposit',
  RESPONSIBLE_GAMING_NOTICE: 'responsible-gaming-notice',
  CHANGE_PASSWORD: 'change-password',
  PERSONAL_INFORMATION: 'personal-information'
};

export const POPUP_NO_AUTH = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password'
};

export const POPUP_NO_FULL_SCREEN = [POPUP_NO_AUTH.LOGIN, POPUP_AUTH.RESPONSIBLE_GAMING_NOTICE];
export const POPUP_DISABLE_BACKDROP_CLICK = [POPUP_NO_AUTH.LOGIN, POPUP_NO_AUTH.SIGNUP];
export const POPUP_NO_QUERY = [
  POPUP_AUTH.BANK_INTERNATIONAL,
  POPUP_AUTH.BANK_IBAN,
  POPUP_AUTH.WALLET,
  POPUP_AUTH.PERSONAL_INFORMATION
];

import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { withStyles } from '@material-ui/core';

const GlobalAspectRatio = withStyles(
  () => {
    const aspectRatio = Object.keys(ASPECT_RATIO).reduce((prev, key) => {
      const width = Number(key.split('-')[0]);
      const height = Number(key.split('-')[1]);
      return {
        ...prev,
        [`.aspect-ratio-${key}`]: {
          paddingTop: `calc(${height} / ${width} * 100%)`
        }
      };
    }, {});
    return {
      '@global': aspectRatio
    };
  },
  { name: 'GlobalAspectRatio' }
)(() => null);

export default GlobalAspectRatio;

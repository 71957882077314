import { showError, showInfo, showSuccess, showWarning } from '@/redux/actions';
import { store } from '@/redux/store';

const ToastService = {
  success: (message) => {
    store.dispatch(showSuccess(message));
  },
  warning: (message) => {
    store.dispatch(showWarning(message));
  },
  error: (message) => {
    store.dispatch(showError(message));
  },
  info: (message) => {
    store.dispatch(showInfo(message));
  }
};

export default ToastService;

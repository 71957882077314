import Status from '@/@crema/core/Status';
import { useIntl } from 'react-intl';

export const SPORTS_NAME_TO_CODE = {
  Pending: 1,
  Won: 2,
  Lost: 3,
  Draw: 4,
  Cash_out: 5,
  Canceled: 6,
  Partial_cashout: 7,
  Void: 8,
  Half_won: 9,
  Half_lose: 10
};

export const SPORTS_CELL_ITEM_STATUS = {
  [SPORTS_NAME_TO_CODE.Pending]: {
    label: 'account.deposit.result.pending',
    variant: 'productEsports'
  },
  [SPORTS_NAME_TO_CODE.Won]: {
    label: 'Won',
    variant: 'active'
  },
  [SPORTS_NAME_TO_CODE.Lost]: {
    label: 'Lost',
    variant: 'default'
  },
  [SPORTS_NAME_TO_CODE.Draw]: {
    label: 'Draw',
    variant: 'ghost'
  },
  [SPORTS_NAME_TO_CODE.Cash_out]: {
    label: 'Cash Out',
    variant: 'statusClaimed'
  },
  [SPORTS_NAME_TO_CODE.Canceled]: {
    label: 'account.deposit.result.declinedCanceled',
    variant: 'jackpots'
  },
  [SPORTS_NAME_TO_CODE.Partial_cashout]: {
    label: 'Partial Cashout',
    variant: 'statusClaimed'
  },
  [SPORTS_NAME_TO_CODE.Void]: {
    label: 'Void',
    variant: 'jackpots'
  },
  [SPORTS_NAME_TO_CODE.Half_won]: {
    label: 'Half Won',
    variant: 'active'
  },
  [SPORTS_NAME_TO_CODE.Half_lose]: {
    label: 'Half Lose',
    variant: 'default'
  }
};

const StatusSportsbook = ({ value }) => {
  const { formatMessage } = useIntl();
  const { bet_status } = value?.sportbook_bet_detail || {};

  if (!bet_status) return null;

  const { variant, label } = SPORTS_CELL_ITEM_STATUS[bet_status];

  return (
    <Status variant={variant} type="round">
      {formatMessage({ id: label })}
    </Status>
  );
};

const renderStatusSportsbook = (value, item) => {
  return <StatusSportsbook value={item} />;
};

export default renderStatusSportsbook;

import { getApiEndpoint } from '@/@crema/services/Helper';
import { API_SERVICE } from '../..';

export const submitWithdrawal = (data) => {
  return API_SERVICE.post(getApiEndpoint('withdraws'), data);
};

export const getWithdrawBanks = (data) => {
  return API_SERVICE.get(getApiEndpoint('withdraw-banks'), { params: data });
};

export const addBank = (data) => {
  return API_SERVICE.post(getApiEndpoint('banks'), data);
};

export const getWithdrawBy = () => {
  return API_SERVICE.get(getApiEndpoint('withdraw-by'));
};

export const getBanks = (data) => {
  return API_SERVICE.get(getApiEndpoint(`banks/?withdraw_bank_id=${data}`));
};

import { ErrorBoundary } from '@sentry/nextjs';
import Error from '../Error';

export default function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      fallback={
        <Error
          title="Ah! Something went wrong."
          description="Brace yourself till we get the error fixed. You may also refresh the page or try again later"
        />
      }>
      {children}
    </ErrorBoundary>
  );
}

import { getApiEndpoint } from '@/@crema/services/Helper';
import { PAGING_DEFAULT } from '@/shared/constants/PagingConst';
import { API_SERVICE } from '../..';

export const getPlayerBonus = (query) => {
  return API_SERVICE.get(getApiEndpoint('bonuses'), { params: query });
};

export const cancelBonus = (id) => {
  return API_SERVICE.put(getApiEndpoint(`bonuses/${id}/cancel`));
};

export const activePendingBonus = (id) => {
  return API_SERVICE.put(getApiEndpoint(`bonuses/${id}/activate-pending`));
};

export const deactivePendingBonus = (id) => {
  return API_SERVICE.post(getApiEndpoint(`pending-activation-bonuses/${id}/deactivate`));
};

export const getPendingActivationBonuses = (params) => {
  return API_SERVICE.get(getApiEndpoint('pending-activation-bonuses'), { params });
};

export const getFreespinsGames = (id, params) => {
  return API_SERVICE.get(getApiEndpoint(`bonuses/${id}/free-games`), { params });
};

export const validateReferralCode = (code) => {
  return API_SERVICE.put(getApiEndpoint('referral/validate-code'), { code });
};
export const createReferralCode = (code) => {
  return API_SERVICE.post(getApiEndpoint('referral/codes'), { code });
};

export const getReferralHistories = (params) => {
  return API_SERVICE.get(getApiEndpoint('referral/reward-histories'), {
    params: { ...params, per_page: PAGING_DEFAULT.dataTablePerPage }
  });
};

export const getReferralCode = () => {
  return API_SERVICE.get(getApiEndpoint('referral/codes'));
};
export const getSummaryReferral = () => {
  return API_SERVICE.get(getApiEndpoint('referral/summary'));
};

export const getBonusRecommendations = (query) => {
  return API_SERVICE.get(getApiEndpoint('recommendations/promotions'), { params: query });
};

export const deactivateAllBonus = () => {
  return API_SERVICE.post(getApiEndpoint(`pending-activation-bonuses/deactivate-all`));
};

export const activateBonus = (id, currency_code) => {
  return API_SERVICE.post(getApiEndpoint(`pending-activation-bonuses/${id}/activate`), {
    currency_code
  });
};

export const deActivateBonus = (id) => {
  return API_SERVICE.post(getApiEndpoint(`pending-activation-bonuses/${id}/deactivate`));
};

import { AnalyticsService } from '@/@crema';
import {
  changePassword,
  forgotPasswordByEmail,
  login,
  requestOTP,
  resetPassword,
  signOut,
  signUpAgent,
  signup,
  verifyEmail,
  verifyEmailByLink
} from '@/@crema/services/apis/auth';
import { getBanks, getProfile, updateProfile } from '@/@crema/services/apis/profile';
import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { closeAllPopup, getQueryParams, routerPush } from '@/@crema/utility/Route';
import { getCookie, removeCookie, setCookie } from '@/@crema/utility/Session';
import { getFutureDate, isFingerprintSuccess, isValidFunction } from '@/@crema/utility/utils';
import {
  handleErrors,
  onCountNotification,
  onGetFavoriteGames,
  onReloadGameLauncher,
  onReloadGamePage
} from '@/redux/actions';
import {
  APP_SET_REQUIRE_PLAYER_SIGNIN_CAPTCHA,
  APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA,
  AUTH_FETCH_PROFILE_START,
  AUTH_FETCH_PROFILE_SUCCESS,
  AUTH_FORGOT_PASSWORD_RESET,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_GET_BANKS,
  AUTH_SET_REQUIRE_OTP,
  AUTH_SET_USER,
  AUTH_SET_USERINFO,
  WALLET_SET_FIAT_LOADING
} from '@/shared/constants/ActionTypes';
import { KYC_STATUS } from '@/shared/constants/AppEnums';
import { COOKIES } from '@/shared/constants/CookieConst';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { POPUP_AUTH, POPUP_NO_AUTH } from '@/shared/constants/PopupConst';
import changeLocale from '@/shared/lib/locale/change-locale';
import Sentry from '@/shared/lib/sentry/sentry';
import { getFeatureToggle } from '@/shared/utils/FeatureToggle';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import Router from 'next/router';
import { FORGOT_PASSWORD_STATUS, isLoggedIn } from '../reducers/Auth';
import { onGetCashbackProgress } from './Cashback';
import { fetchError, fetchStart, fetchSuccess, showPopup, showSuccess } from './Common';
import { onReloadDataHome } from './Page';
import { onGetWallets } from './Wallet';

const checkFingerprintLoaded = async (interval = 100) => {
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      try {
        if (isFingerprintSuccess()) {
          clearInterval(intervalId);
          setTimeout(() => resolve(true), 2000);
        }
      } catch (error) {
        return;
      }
    }, interval);
    setTimeout(() => {
      clearInterval(intervalId);
      resolve(true);
    }, 6 * 1000);
  });
};

export const setJWTAuth = (token) => {
  setCookie(COOKIES.jwt_auth, token, getFutureDate(8, 'h'));
};

// required setCookie(COOKIES.username, data.username) after onAuthSuccess
const onAuthSuccess = (shouldRedirectAfterSignUp = false, shouldTrackingFingerprint = false) => {
  return async (dispatch) => {
    // success function need to call
    await dispatch(onReloadDataHome());
    await dispatch(onGetProfile(true, shouldRedirectAfterSignUp, true, shouldTrackingFingerprint));

    dispatch(onReloadGamePage());
  };
};

export const onSignUp = (data, actions) => {
  return async (dispatch, getState) => {
    try {
      const res = await signup(data);
      if (res.data) {
        const { token } = res.data;
        if (token) {
          setJWTAuth(token);
          removeCookie(COOKIES.agent_code);
          removeCookie(COOKIES.payload);
          removeCookie(COOKIES.query_strings);
          localStorage.removeItem(LOCAL_STORAGE.document_referrer);
          setCookie(COOKIES.username, data.username);

          await dispatch(onAuthSuccess(true, true));
          AnalyticsService.signup();
        }
      }
    } catch (error) {
      if (isValidFunction(actions?.setStatus)) {
        actions.setStatus('error');
      }

      let defaultError = get(error, 'data.message', 'error.systemError.description');
      // client error
      if (error.status >= 400 && error.status < 500) {
        // fill form error
        if (error.status === 400 || error.status === 422) {
          const resError = get(error, 'data.errors');
          if (resError.agent_code && resError.agent_code?.[0]) {
            return dispatch(fetchError(resError.agent_code?.[0]));
          }
          if (resError.captcha_token && resError.captcha_token?.[0]) {
            const { app } = getState();
            const systemSetting = getFeatureToggle(app?.systemSetting);

            if (!systemSetting.signup.captcha.enabled) {
              if (isValidFunction(actions?.setStatus)) {
                actions.setStatus('execute_after_render');
              }
              dispatch({ type: APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA });
            } else {
              dispatch(fetchError(resError.captcha_token?.[0]));
            }

            return;
          }
          if (actions.setFieldError) {
            if (typeof resError === 'object') {
              forOwn(resError, function (value, key) {
                const errorMessage = value?.[0];
                if (errorMessage) {
                  actions.setFieldError(key, errorMessage);
                }
              });
              return;
            }
          }
        } else if (error.status === 401) {
          dispatch(onSignOut());
          // prevent show toast error message
          return;
        } else {
          defaultError = defaultError !== '' ? defaultError : 'error.systemError.description';
        }
      }
      // server error
      else if (error.status >= 500) {
        // handle server error
        defaultError = 'error.systemError.description';
      }
      return dispatch(fetchError(defaultError));
    }
  };
};

export const onForgotPasswordByEmail = (data, actions) => {
  return async (dispatch) => {
    try {
      const res = await forgotPasswordByEmail(data);
      if (res && res.status === 200) {
        dispatch({
          type: AUTH_FORGOT_PASSWORD_SUCCESS,
          payload: {
            status: FORGOT_PASSWORD_STATUS.SEND_SUCCESS,
            message: res.data.message,
            formData: data
          }
        });
      }
    } catch (error) {
      logd('onForgotPasswordByEmail.error', error);
      return dispatch(handleErrors(error, actions));
    }
  };
};

export const onResetPassword = (data, actions) => {
  return async (dispatch) => {
    try {
      const res = await resetPassword(data);
      logd('onResetPassword.res', res);
      dispatch(showSuccess('common.resetPasswordSuccess'));
      closeAllPopup();
      dispatch(showPopup(POPUP_NO_AUTH.LOGIN));
    } catch (error) {
      logd('onResetPassword.error', error);
      if (error.status === 422) {
        if (actions.setFieldError) {
          const resError = get(error, 'data.errors');
          if (typeof resError === 'object' && resError.token && resError.token[0]) {
            actions.setFieldError('password', resError.token[0]);
            return;
          }
        }
      }
      actions.setFieldError('password', <IntlMessages id="error.systemError.description" />);
    }
  };
};

export const onLogin = (data, actions) => {
  return async (dispatch, getState) => {
    try {
      const res = await login(data);
      if (res.data) {
        const { token } = res.data;
        if (token) {
          setJWTAuth(token);
          setCookie(COOKIES.username, data.username);
          await dispatch(onAuthSuccess(false, true));

          AnalyticsService.login();
        }
      }
      return true;
    } catch (error) {
      logd('onLogin.error', error);

      if (isValidFunction(actions?.setStatus)) {
        actions.setStatus('error');
      }

      if (error?.status === 422) {
        if (error?.data?.errors?.otp_code) {
          dispatch(handleErrors(error, actions));
        } else if (error?.data?.errors?.captcha_token) {
          // show captcha_token error
          if (error?.data?.errors?.captcha_token?.[0]) {
            const { app } = getState();
            const systemSetting = getFeatureToggle(app?.systemSetting);

            if (!systemSetting.login.captcha.enabled) {
              if (isValidFunction(actions?.setStatus)) {
                actions.setStatus('execute_after_render');
              }
              dispatch({ type: APP_SET_REQUIRE_PLAYER_SIGNIN_CAPTCHA });
            } else {
              dispatch(fetchError(error?.data?.errors?.captcha_token?.[0]));
            }
          }
        } else {
          dispatch(fetchError('auth.invalid_credentials'));
        }
      } else if (error?.status === 401 || error?.status === 400) {
        dispatch(fetchError(error?.data?.error));
      } else {
        if (error?.data?.message) {
          dispatch(fetchError(error?.data?.message));
        }
      }
      return false;
    }
  };
};

export const onSignOut = () => {
  return async () => {
    try {
      logd('onSignOut', isLoggedIn.current);

      if (!isLoggedIn.current) return;

      isLoggedIn.current = false;

      try {
        await signOut();
      } catch (error) {
        logd('onSignOut.error', error);
      }

      // clear cookies
      removeCookie(COOKIES.jwt_auth);
      removeCookie(COOKIES.crm_session_id);
      removeCookie(COOKIES.uuid);
      removeCookie(COOKIES.username);
      removeCookie(COOKIES.affiliate_id);
      Sentry.setUser(null);

      AnalyticsService.logout();

      if (Router.pathname === '/sportsbook/[slug]') {
        return window.location.reload();
      }

      const query = getQueryParams();

      const url = query?.overlay === POPUP_AUTH.CHANGE_PASSWORD ? `/?overlay=login` : `/`;
      window.location.replace(url);
    } catch (error) {
      logd('onSignOut.error', error);
    }
  };
};

export const onUpdateProfile = (data, actions, formData) => {
  return async (dispatch) => {
    try {
      await updateProfile(data);
      dispatch(showSuccess('profile.updateProfileSuccess'));
      dispatch(onGetProfile(false, false, false));
      actions.resetForm({ values: formData });
      return true;
    } catch (error) {
      dispatch(handleErrors(error, actions));
    }
  };
};

export const onUpdateLanguage = (data) => {
  return async (dispatch) => {
    try {
      await updateProfile(data);
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

function checkLocale(res) {
  // If get locale (aka language) from profile -> switch to that locale
  const userLocale = res?.data?.language?.code;
  const cookieLocale = getCookie(COOKIES.next_locale);

  if (
    userLocale &&
    cookieLocale &&
    Router.locales.includes(userLocale) &&
    Router.locales.includes(cookieLocale) &&
    userLocale !== cookieLocale
  ) {
    return changeLocale(userLocale);
  }
}

export const onGetProfile = (
  includeWallet = false,
  shouldRedirectAfterSignUp = false,
  shouldShowResponsibleGamingNoticePopup = true,
  shouldTrackingFingerprint = false
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: AUTH_FETCH_PROFILE_START });

      if (shouldTrackingFingerprint) {
        AnalyticsService.fingerprint();
      }

      const res = await getProfile();
      if (res.data) {
        const { username, uuid, crm_session_id } = res.data;

        if (uuid) {
          setCookie(COOKIES.uuid, uuid);
          Sentry.setUser({ id: uuid });
        }

        if (crm_session_id) {
          setCookie(COOKIES.crm_session_id, crm_session_id);
        }

        if (username) {
          setCookie(COOKIES.username, username);
        }

        if (res.data?.affiliate?.cxd) {
          setCookie(COOKIES.affiliate_id, res.data?.affiliate?.cxd);
        }

        AnalyticsService.set_crm_session_id();

        AnalyticsService.update_profile(res.data);

        if (shouldTrackingFingerprint) {
          await checkFingerprintLoaded();
        }
        dispatch({ type: AUTH_SET_USER, payload: res.data });
        setTimeout(() => checkLocale(res), 1000);
      }
      if (includeWallet) {
        dispatch(onGetWallets());
      }

      dispatch(onGetFavoriteGames());
      dispatch(onGetCashbackProgress());
      dispatch(onCountNotification());

      if (shouldRedirectAfterSignUp) {
        const state = getState();
        const redirectAfterSignUp = get(
          state,
          'app.cmsSettings.route_config.redirect_after_sign_up'
        );
        if (redirectAfterSignUp) {
          if (redirectAfterSignUp === '/account/kyc' && res.data?.kyc_status === KYC_STATUS.NEW) {
            const player_kyc = get(state, 'app.systemSetting.players.player_kyc', false);
            if (player_kyc) {
              routerPush(redirectAfterSignUp);
            }
          } else {
            routerPush(redirectAfterSignUp);
          }
        }
      }
    } catch (error) {
      dispatch(handleErrors(error));
    } finally {
      dispatch({ type: AUTH_FETCH_PROFILE_SUCCESS });
    }
  };
};

export const onGetBanks = () => {
  return async (dispatch) => {
    try {
      const res = await getBanks();
      dispatch({ type: AUTH_GET_BANKS, payload: res.data.data });
    } catch (error) {
      dispatch(handleErrors(error));
    }
  };
};

export const onRequestOTPSignUp = (data, actions) => {
  return async (dispatch) => {
    try {
      dispatch(fetchStart());
      const res = await requestOTP(data);
      if (res.status === 200) {
        dispatch(fetchSuccess());
        dispatch(showSuccess('common.otpHasBeenSent'));
      }
      return true;
    } catch (error) {
      if (error.status === 422) {
        if (actions.setFieldError) {
          const resError = get(error, 'data.errors');
          if (typeof resError === 'object' && resError.phone_number && resError.phone_number[0]) {
            actions.setFieldError('phone_number', resError.phone_number[0]);
            return false;
          }
        }
      }
      let defaultErrorMessage = <IntlMessages id="error.systemError.description" />;
      let errorMessage = get(error, 'data.message');
      actions.setFieldError('phone_number', errorMessage ? errorMessage : defaultErrorMessage);
      return false;
    }
  };
};

export const onSetRequireOTP = (required) => {
  return (dispatch) => {
    dispatch({ type: AUTH_SET_REQUIRE_OTP, payload: required });
  };
};

export const onSetUserInfo = (userInfo) => {
  return (dispatch) => {
    dispatch({ type: AUTH_SET_USERINFO, payload: userInfo });
  };
};

export const onChangePassword = (data, actions) => {
  return async (dispatch) => {
    try {
      const res = await changePassword(data);
      setTimeout(() => {
        dispatch(onSignOut());
      }, 1000);
      logd('onChangePassword.res', res);
      return true;
    } catch (error) {
      logd('onChangePassword.error', error);
      if (error?.data?.errors || error?.data?.message);
      const msg = error?.data?.errors?.password[0];
      actions.setFieldError('password', <IntlMessages id={msg} />);
      return false;
    }
  };
};

export const onResetForgotPasswordData = () => {
  return (dispatch) => {
    dispatch({
      type: AUTH_FORGOT_PASSWORD_RESET
    });
  };
};

export const onUpdateCurrency = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: WALLET_SET_FIAT_LOADING, payload: true });
      const res = await updateProfile(data);
      if (res?.data) {
        dispatch({
          type: AUTH_SET_USER,
          payload: res.data
        });
        dispatch(onGetWallets());
        dispatch(onReloadGameLauncher());
      }
      dispatch({ type: WALLET_SET_FIAT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: WALLET_SET_FIAT_LOADING, payload: false });
      dispatch(handleErrors(error));
    }
  };
};

export const onSignUpAgent = (data, actions, callback) => {
  return async (dispatch, getState) => {
    try {
      const res = await signUpAgent(data);
      if (res) {
        dispatch(showSuccess('agent.signupSuccess'));
        callback();
      }
    } catch (error) {
      if (isValidFunction(actions?.setStatus)) {
        actions.setStatus('error');
      }

      let defaultError = get(error, 'data.message', 'error.systemError.description');
      // client error
      if (error.status >= 400 && error.status < 500) {
        // fill form error
        if (error.status === 400 || error.status === 422) {
          const resError = get(error, 'data.errors');
          if (resError.agent_code && resError.agent_code?.[0]) {
            return dispatch(fetchError(resError.agent_code?.[0]));
          }
          if (resError.captcha_token && resError.captcha_token?.[0]) {
            const { app } = getState();
            const systemSetting = getFeatureToggle(app?.systemSetting);

            if (!systemSetting.signup.captcha.enabled) {
              if (isValidFunction(actions?.setStatus)) {
                actions.setStatus('execute_after_render');
              }
              dispatch({ type: APP_SET_REQUIRE_PLAYER_SIGNUP_CAPTCHA });
            } else {
              dispatch(fetchError(resError.captcha_token?.[0]));
            }

            return;
          }
          if (actions.setFieldError) {
            if (typeof resError === 'object') {
              forOwn(resError, function (value, key) {
                const errorMessage = value?.[0];
                if (errorMessage) {
                  actions.setFieldError(key, errorMessage);
                }
              });
              return;
            }
          }
        } else if (error.status === 401) {
          dispatch(onSignOut());
          // prevent show toast error message
          return;
        } else {
          defaultError = defaultError !== '' ? defaultError : 'error.systemError.description';
        }
      }
      // server error
      else if (error.status >= 500) {
        // handle server error
        defaultError = 'error.systemError.description';
      }
      return dispatch(fetchError(defaultError));
    }
  };
};

export const onSendEmailVerification = () => {
  return async (dispatch) => {
    try {
      const res = await verifyEmail();
      if (res?.data) {
        dispatch(showSuccess(res?.data?.message));
        return res?.data;
      }
    } catch (error) {
      dispatch(handleErrors(error));
      return false;
    }
  };
};

export const onVerifyEmail = (link) => {
  return async () => {
    try {
      await verifyEmailByLink(link);
      return { state: 'success' };
    } catch (error) {
      const code = get(error, 'data.errors.code.0', '');
      const link = get(error, 'data.errors.link.0', '');
      return { state: 'failed', code, link };
    }
  };
};

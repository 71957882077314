export const CMS_PAGES = {
  Home: 'home',
  ResponsibleGamingNotice: 'responsible-gaming-notice',
  Leaderboard: 'leaderboard',
  Promotions: 'promotions',
  Sportsbook: 'sportsbook',
  FavoritesGame: 'favorite-games',
  LiveCasino: 'live-casino',
  Casino: 'casino'
};

export const CMS_PAGES_SLUG = {
  Games: 'games-',
  GameCategories: 'game-categories-',
  LiveCasino: 'live-casino-'
};

export const CMS_SINGLETON = {
  LandingPage: 'landing_page',
  LandingPageV2: 'landing_page_v2',
  Setting: 'setting',
  RestrictionCountryPopup: 'restriction_popup',
  ReferFriend: 'refer_friend_page'
};

import { Box, Button, makeStyles } from '@material-ui/core';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative'
  },
  wrapperLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
}));

const LoadingButton = ({ loading = false, children, color = 'primary', ...otherProps }) => {
  const classes = useStyles();

  const configButton = {
    type: 'submit',
    variant: 'contained',
    fullWidth: true,
    disabled: loading,
    color,
    ...otherProps
  };

  return (
    <Box className={classes.wrapper}>
      <Button {...configButton}>{children}</Button>
      {loading && (
        <Box className={classes.wrapperLoading}>
          <Loading color={color} />
        </Box>
      )}
    </Box>
  );
};

export default LoadingButton;

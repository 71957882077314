import { COLORS } from '@/shared/constants/ColorSets';
import { darken, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.primary,
    backgroundColor: COLORS.red,
    '&:hover': {
      backgroundColor: darken(COLORS.red, 0.25)
    }
  }
}));

export default useStyles;

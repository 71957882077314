import { isValidArray } from '@/@crema/utility/utils';
import {
  PAYMENT_GET_ADDRESS_ERROR,
  PAYMENT_GET_ADDRESS_START,
  PAYMENT_GET_ADDRESS_SUCCESS,
  PAYMENT_GET_ERROR,
  PAYMENT_GET_START,
  PAYMENT_GET_SUCCESS,
  PAYMENT_PROFILE_GET_AREA,
  PAYMENT_RESET,
  PAYMENT_RESET_PENDING_DEPOSIT,
  PAYMENT_SET_DATA,
  PAYMENT_SET_NETWORK,
  PAYMENT_SET_PENDING_DEPOSIT,
  PAYMENT_SET_SELECTED_CURRENCY,
  PAYMENT_SET_SELECTED_PAYMENT
} from '@/shared/constants/ActionTypes';
import { useSelector } from 'react-redux';
import { getActiveCurrencyCode } from './Wallets';

const INIT_ADDRESS = {
  data: {},
  loading: false
};

const INIT_STATE = {
  data: [],
  selectedCurrency: {},
  selectedPayment: null,
  loading: true,
  address: INIT_ADDRESS,
  country: [],
  state: [],
  city: [],
  pendingDeposit: null
};

export const usePayment = () => {
  return useSelector(({ payment }) => payment);
};

export const usePaymentAddress = () => {
  return useSelector(({ payment }) => payment.address);
};

export const getDefaultPayment = (paymentList, wallets) => {
  const defaultCurrencyCode = 'BTC';
  const activeCurrencyCode = getActiveCurrencyCode(wallets);

  if (isValidArray(paymentList)) {
    // validate active currency code in payment list
    const foundPayment = paymentList.find((i) => i.currency_code === activeCurrencyCode);
    if (foundPayment && foundPayment?.cms_property?.type === 'crypto') {
      return foundPayment;
    }

    // validate btc currency code in payment list
    const foundPaymentBTC = paymentList.find((i) => i.currency_code === defaultCurrencyCode);
    if (foundPaymentBTC) {
      return foundPaymentBTC;
    }

    return paymentList[0];
  }

  return null;
};

export const usePendingDeposit = () => {
  return useSelector(({ payment }) => payment.pendingDeposit);
};

export const PaymentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAYMENT_GET_START:
      return {
        ...state,
        loading: true
      };
    case PAYMENT_GET_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case PAYMENT_GET_ERROR:
      return {
        ...state,
        loading: false
      };
    case PAYMENT_SET_DATA:
      return {
        ...state,
        data: action.payload
      };
    case PAYMENT_SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload
      };
    case PAYMENT_GET_ADDRESS_START:
      return {
        ...state,
        address: {
          loading: true,
          data: state.address.data
        }
      };
    case PAYMENT_GET_ADDRESS_SUCCESS:
      return {
        ...state,
        address: {
          loading: false,
          data: {
            ...state.address.data,
            ...action.payload
          }
        }
      };
    case PAYMENT_GET_ADDRESS_ERROR:
      return {
        ...state,
        address: {
          ...state.address,
          loading: false
        }
      };
    case PAYMENT_SET_NETWORK:
      return {
        ...state,
        selectedCurrency: {
          ...state.selectedCurrency,
          network: action.payload
        }
      };
    case PAYMENT_SET_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: action.payload
      };
    case PAYMENT_RESET:
      return INIT_STATE;
    case PAYMENT_PROFILE_GET_AREA:
      return {
        ...state,
        loading: false,
        country: action.payload.country,
        state: action.payload.state,
        city: action.payload.city
      };
    case PAYMENT_SET_PENDING_DEPOSIT:
      return {
        ...state,
        pendingDeposit: action.payload
      };
    case PAYMENT_RESET_PENDING_DEPOSIT:
      return {
        ...state,
        pendingDeposit: null
      };
    default:
      return state;
  }
};

import { getApiEndpoint } from '@/@crema/services/Helper';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { API_SERVICE } from '../..';

export const getSetting = () => {
  return API_SERVICE.get(getApiEndpoint('setting'));
};

/**
 * api get localization without headers
 * @returns response
 */
export const getLocalization = async (ctx) => {
  let response = { status: 200, data: null };
  try {
    let defaultHeaders = {};
    /**
     * forwarded from cloudflare request
     */
    if (ctx?.req?.headers) {
      Object.keys(ctx.req.headers).forEach((key) => {
        defaultHeaders[`x-next-${key}`] = ctx.req.headers[key];
      });
    }

    const url = `${APP_CONFIG.NEXT_PUBLIC_API_HOST}${getApiEndpoint('localization')}`;

    const res = await fetch(url, { headers: defaultHeaders });

    if (res) {
      response.data = await res.json();
      return response;
    }
    return response;
  } catch (error) {
    return response;
  }
};

export const getPageSitemap = () => {
  return API_SERVICE.get(getApiEndpoint('page-sitemap'));
};

export const getPageSitemapBySlug = (slug) => {
  return API_SERVICE.get(getApiEndpoint(`page-sitemap/${slug}`));
};

export const getGamesSitemap = (params) => {
  return API_SERVICE.get(getApiEndpoint('page-sitemap/games'), { params });
};

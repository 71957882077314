import GlobalAlert from './Alert.styles';
import GlobalAspectRatio from './AspectRatio.styles';
import GlobalBackdrop from './Backdrop.styles';
import GlobalBlur from './Blur.styles';
import GlobalBorderRadius from './BorderRadius.style';
import GlobalButton from './Button.styles';
import GlobalContainer from './Container.styles';
import GlobalCustomClassName from './Custom.styles';
import GlobalDialog from './Dialog.styles';
import GlobalMenu from './Menu.styles';
import GlobalRadio from './Radio.styles';
import GlobalTable from './Table.styles';
import GlobalTabs from './Tabs.styles';
import GlobalTextField from './TextField.styles';
import GlobalTypography from './Typography.style';

const GlobalStyles = () => {
  return (
    <>
      <GlobalButton />
      <GlobalBackdrop />
      <GlobalDialog />
      <GlobalMenu />
      <GlobalTable />
      <GlobalTabs />
      <GlobalTextField />
      <GlobalRadio />
      <GlobalAlert />
      <GlobalBlur />
      <GlobalTypography />
      <GlobalContainer />
      <GlobalCustomClassName />
      <GlobalBorderRadius />
      <GlobalAspectRatio />
    </>
  );
};

export default GlobalStyles;

import { Dialog, Icon, IconButton } from '@/@crema';
import { Box, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import useStyles from './DialogDetailsBase.style';

const DialogDetailsBase = ({ children, open, title, onClose }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const heading = formatMessage({ id: title });

  return (
    <Dialog name={heading} open={open} onBackdropClick={onClose}>
      <Box className={classes.containerTransactionDetail}>
        <Box className={classes.header}>
          <Typography variant="h3" className="bold" id="transaction-dialog-title">
            {heading}
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <Icon name="close" />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};

export default DialogDetailsBase;

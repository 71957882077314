import { COLORS } from '@/shared/constants/ColorSets';
import { withStyles } from '@material-ui/core';

const GlobalTypography = withStyles(
  (theme) => {
    return {
      '@global': {
        '.light': {
          fontWeight: theme.typography.fontWeightLight
        },
        '.regular': {
          fontWeight: theme.typography.fontWeightRegular
        },
        '.medium': {
          fontWeight: theme.typography.fontWeightMedium
        },
        '.semibold': {
          fontWeight: theme.typography.fontWeightSemiBold
        },
        '.bold': {
          fontWeight: theme.typography.fontWeightBold
        },
        '.line-clamp-1': {
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        },
        '.line-clamp-2': {
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        },
        '.line-clamp-3': {
          display: '-webkit-box',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        },
        '.line-clamp-4': {
          display: '-webkit-box',
          WebkitLineClamp: '4',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        },
        '.text-gradient-fi': {
          background: `linear-gradient(135deg, ${COLORS.mintGreen} 0%, ${COLORS.primary} 100%)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        },

        primary: {
          color: theme.palette.primary.main
        },

        secondary: {
          color: theme.palette.secondary.main
        }
      }
    };
  },
  { name: 'GlobalTypography' }
)(() => null);

export default GlobalTypography;

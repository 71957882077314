import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  noDataFound: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));

const NoDataFound = ({ message, keyword, className, isUppercase }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const messageIntl = formatMessage({ id: message, defaultMessage: message });

  return (
    <Box className={clsx(classes.noDataFound, className)}>
      <Box mb={6} width="100%">
        <Typography
          color="textSecondary"
          gutterBottom
          align="center"
          style={{ wordWrap: 'break-word' }}
          className={isUppercase ? 'uppercase' : undefined}>
          {messageIntl}
        </Typography>
        {keyword && (
          <Typography
            color="primary"
            variant="body1"
            className="bold"
            align="center"
            gutterBottom
            style={{ wordWrap: 'break-word' }}>
            {keyword}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

NoDataFound.defaultProps = {
  message: 'common.noDataFound',
  isUppercase: true
};

export default memo(NoDataFound);

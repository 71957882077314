import { getSingleton, getStaticPage } from '@/@crema/services/apis/page';
import { logd, loge } from '@/@crema/utility/Logging';
import { isEmptyObj } from '@/@crema/utility/utils';
import {
  PAGE_GET_ERROR,
  PAGE_GET_START,
  PAGE_GET_SUCCESS,
  PAGE_RESET
} from '@/shared/constants/ActionTypes';
import { CMS_PAGES } from '@/shared/constants/CMSConst';
import Router from 'next/router';

export const onGetPage = (name, query = {}, forceRender = true, isSingleton = false) => {
  return async (dispatch, getState) => {
    try {
      if (!name) {
        return;
      }
      const { page } = getState();

      logd('onGetPage', { name, query, forceRender, isSingleton, data: page?.data[name] });

      // check exist data
      if (page?.data[name] && !forceRender) return page?.data[name];

      dispatch({ type: PAGE_GET_START });

      let res = {};

      if (isSingleton) {
        res = await getSingleton(name);
      } else {
        res = await getStaticPage(name, query);
      }

      if (isEmptyObj(res.data)) {
        res.data = null;
      }

      dispatch({ type: PAGE_GET_SUCCESS, payload: { name, data: res.data } });

      return res.data;
    } catch (error) {
      loge(`onGetPage.${name}.error`, { status: error.status, data: error.data });
      dispatch({ type: PAGE_GET_ERROR });
      return;
    }
  };
};

export const onReloadDataHome = () => {
  return async (dispatch) => {
    if (Router.pathname === '/') {
      await dispatch(onGetPage(CMS_PAGES.Home, {}, true));
    }
  };
};

export const onResetPage = () => (dispatch) => dispatch({ type: PAGE_RESET });

export const onGetSingleton = (name) => {
  return async (dispatch, getState) => {
    try {
      if (!name) {
        return;
      }
      const { page } = getState();
      // check exist data
      if (page?.data[name]) return page?.data[name];

      let res = {};

      res = await getSingleton(name);

      if (isEmptyObj(res.data)) {
        res.data = null;
      }

      dispatch({ type: PAGE_GET_SUCCESS, payload: { name, data: res.data } });

      return res.data;
    } catch (error) {
      loge(`onGetPage.${name}.error`, { status: error.status, data: error.data });
      return;
    }
  };
};

import Sentry from './sentry';

function getSentryExtraData(data, error) {
  try {
    if (!error) {
      return {
        data
      };
    }

    return {
      data,
      error,
      response: error?.data || {}
    };
  } catch (e) {
    console.error(e);
    return {};
  }
}

export function captureExceptionFeature(data, error, feature) {
  try {
    if (feature) {
      let _error = new Error(`${feature} error`);

      if (error && error.stack && error.message) {
        _error = error;
      }

      Sentry.captureException(_error, {
        tags: { feature },
        extra: getSentryExtraData(data, error)
      });
    }
  } catch (e) {
    console.error(e);
  }
}

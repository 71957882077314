import { Typography, makeStyles } from '@material-ui/core';
import { Fragment } from 'react';
const useStyles = makeStyles((theme) => ({
  cellCurrencySportsSingle: {
    color: theme.palette.text.main,
    display: 'inline',
    '& .cell-secondary-color': {
      color: theme.palette.secondary.main
    },
    '& > *': {
      marginRight: theme.spacing(2)
    }
  },
  cellCurrencySportsMuilti: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }
}));
const SingleCell = (props) => {
  const { name, outcome, odds } = props;
  const classes = useStyles();
  return (
    <Typography variant="inherit" size="md" className={classes.cellCurrencySportsSingle}>
      {name && <span>{name}</span>}
      {outcome && <span>{outcome}</span>}
      {odds && <span className="cell-secondary-color">{odds}</span>}
    </Typography>
  );
};
export function CellCurrencySports({ value }) {
  const classes = useStyles();
  const { combo_detail = [], detail = [], is_combo_bet } = value?.sportbook_bet_detail || {};
  const isSingle = !is_combo_bet;
  const outcome = detail?.[0]?.outcome;
  const odds = detail?.[0]?.odds;

  if (isSingle) return <SingleCell outcome={outcome} odds={odds} />;

  return (
    <div className={classes.cellCurrencySportsMuilti}>
      {combo_detail.map((detail, index) => {
        const { name, odds } = detail || {};
        const isFirst = index === 0;
        if (!name && !odds) return null;
        return (
          <Fragment key={name}>
            {!isFirst && ' / '}
            <SingleCell key={name} odds={odds} name={name} />
          </Fragment>
        );
      })}
    </div>
  );
}
export default function renderBetSelection(_, item) {
  return <CellCurrencySports value={item} />;
}

import LoadingButton from '@/@crema/core/Button/LoadingButton';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useStyles from './index.style';

const ButtonCancel = ({ loading, onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const handleClick = (evt) => {
    evt.stopPropagation();
    onClick();
  };

  return (
    <LoadingButton loading={loading} onClick={handleClick} size="small" className={classes.button}>
      {formatMessage({ id: 'common.cancel' })}
    </LoadingButton>
  );
};

ButtonCancel.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func
};

ButtonCancel.defaultProps = {
  loading: false,
  onClick: () => {}
};

export default ButtonCancel;

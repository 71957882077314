import {
  PAGE_GET_ERROR,
  PAGE_GET_START,
  PAGE_GET_SUCCESS,
  PAGE_RESET
} from '@/shared/constants/ActionTypes';
import { useSelector } from 'react-redux';

const INIT_STATE = {
  loading: false,
  error: null,
  data: {}
};

export const PageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAGE_GET_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case PAGE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.data
        }
      };
    case PAGE_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case PAGE_RESET:
      return INIT_STATE;
    default:
      return state;
  }
};

export const usePageData = (slug) => {
  return useSelector(({ page }) => page.data[slug]);
};

export const usePageLoading = () => {
  return useSelector(({ page }) => page.loading);
};

export const usePageError = () => {
  return useSelector(({ page }) => page.error);
};

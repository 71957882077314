import { STATUS_CODES } from '@/shared/constants/StatusCodes';
import Error from '../Error';
import MainLayout from './MainLayout';
import MaintenanceLayout from './MaintenanceLayout';

const AppLayout = ({ router, pageProps, children }) => {
  if (router?.pathname === '/maintenance') {
    return <MaintenanceLayout>{children}</MaintenanceLayout>;
  }

  if (pageProps && pageProps.statusCode === STATUS_CODES.SERVER_ERROR) {
    return <Error {...pageProps} />;
  }

  if (pageProps && pageProps.statusCode) {
    return (
      <MainLayout>
        <Error {...pageProps} />
      </MainLayout>
    );
  }

  return <MainLayout>{children}</MainLayout>;
};

export default AppLayout;

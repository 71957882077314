export function isValidArray(arr) {
  return Array.isArray(arr) && arr.length > 0;
}

export function mergeByProperty(arr1, arr2, prop) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return [];
  }

  if (!prop) {
    return [];
  }

  const iteratee = (item) => item[prop];

  return arr1
    .concat(arr2)
    .filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
}

export function groupBy(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export function differenceBy(arr1, arr2, iteratee) {
  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item) => item[prop];
  }
  return arr1.filter((c) => !arr2.map(iteratee).includes(iteratee(c)));
}

import LoadingButton from '@/@crema/core/Button/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  loadMore: {
    margin: '0 auto',
    width: '100%',
    ['@media (min-width: 768px)']: {
      width: '20%'
    }
  }
}));

const TableLoadMore = ({ canLoadMore, isLoadMore, loadMore, className }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  if (!canLoadMore) return null;

  return (
    <div className={clsx(classes.loadMore, className)}>
      <LoadingButton
        onClick={loadMore}
        variant="outlined"
        fullWidth
        color="default"
        loading={isLoadMore}>
        {formatMessage({ id: isLoadMore ? 'common.loading' : 'common.loadMore' })}
      </LoadingButton>
    </div>
  );
};

export default TableLoadMore;

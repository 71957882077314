import { getImageFullUrl, isValidFunction, isValidString } from '@/@crema/utility/utils';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import NextImageComp from 'next/image';
import PropsType from 'prop-types';
import { memo, useCallback, useState } from 'react';

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadiusPaper,
      background: ({ backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.palette.background.paper
    }
  };
});

/**
 *
 * @param {import('next/image').ImageProps} props
 * @returns
 */
function NextImage({
  src,
  classNameContainer,
  className,
  alt,
  backgroundColor,
  backgroundColorSkeleton,
  ratio,
  skeleton,
  borderRadius,
  objectFit,
  width,
  height,
  layout,
  onLoadingComplete,
  onError,
  ...nest
}) {
  const [loading, setLoading] = useState(skeleton);
  const classes = useStyles({ backgroundColor });
  const url = getImageFullUrl(src);

  const isRatio = !!(ratio && ASPECT_RATIO[ratio]);
  const hasSkeleton = skeleton && loading;

  const containerClass = clsx(classes.rootContainer, classNameContainer, {
    [`aspect-ratio-${ASPECT_RATIO[ratio]}`]: isRatio,
    'h-auto': isRatio,
    skeleton: hasSkeleton
  });

  const stopLoading = useCallback(
    (e) => {
      if (!hasSkeleton) return;
      setLoading(false);
    },
    [hasSkeleton, setLoading]
  );

  const _onError = useCallback(
    (e) => {
      stopLoading(e);
      isValidFunction(onError) && onError(e);
    },
    [onError, stopLoading]
  );

  const _onLoadingComplete = useCallback(
    (e) => {
      stopLoading(e);
      isValidFunction(onLoadingComplete) && onLoadingComplete(e);
    },
    [onLoadingComplete, stopLoading]
  );

  if (!isValidString(url)) {
    return <div className={containerClass} />;
  }

  /**
   * @param {import('next/image').ImageLoaderProps} resolverProps
   * @returns {String} url
   */
  const onLoader = (resolverProps) => {
    return resolverProps.src;
  };

  return (
    <div className={containerClass} style={{ borderRadius }}>
      <NextImageComp
        {...nest}
        layout={layout}
        objectFit={objectFit}
        src={url}
        alt={alt ? alt : APP_CONFIG.defaultAlt}
        className={className}
        loader={onLoader}
        width={width}
        height={height}
        blurDataURL=""
        onLoadingComplete={_onLoadingComplete}
        onError={_onError}
      />
    </div>
  );
}

NextImage.propTypes = {
  src: PropsType.oneOfType([PropsType.string, PropsType.object]),
  className: PropsType.string,
  classNameContainer: PropsType.string,
  backgroundColor: PropsType.string,
  backgroundColorSkeleton: PropsType.string,
  alt: PropsType.string,
  ratio: PropsType.string,
  borderRadius: PropsType.number,
  objectFit: PropsType.string,
  layout: PropsType.string,
  skeleton: PropsType.bool,
  width: PropsType.oneOfType([PropsType.string, PropsType.number]),
  height: PropsType.oneOfType([PropsType.string, PropsType.number])
};

NextImage.defaultProps = {
  src: '',
  className: '',
  classNameContainer: '',
  backgroundColor: undefined,
  backgroundColorSkeleton: 'base0',
  alt: APP_CONFIG.defaultAlt,
  ratio: undefined,
  borderRadius: undefined,
  objectFit: 'cover',
  layout: 'fill',
  skeleton: false,
  width: undefined,
  height: undefined
};

export default memo(NextImage);

export const WALLET_TYPE = Object.freeze({
  LOCKED: 0,
  MAIN: 1,
  WITHDRAW: 3,
  SPORTS_BONUS: 5,
  LIVE_CASINO_BONUS: 6,
  SLOTS_BONUS: 7,
  VIRTUAL_SPORTS_BONUS: 8,
  FISH_GAMES_BONUS: 9
});

export const WALLET_CURRENCY_TYPE = Object.freeze({
  FIAT: 1,
  CRYPTO: 2
});

export const ZERO_BALANCE = '0.00';

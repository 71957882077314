import { Icon } from '@/@crema';
import IconButtonOutlined from '@/@crema/core/Button/IconButtonOutlined';
import FilterDateRangePicker from '@/@crema/core/FilterDate';
import { getEndOfDay, getStartOfDay } from '@/@crema/utility/utils';
import { onSetFilterSportHistory } from '@/redux/actions';
import { DEFAULT_FILTER_SPORT_HISTORY } from '@/shared/constants/Transaction';
import { Checkbox, FormControlLabel, FormGroup, Typography, makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  groupFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 0px 16px 0px'
  },
  itemFitler: {
    '&, .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0
    },

    '& .itemSelect': {
      border: `1px solid ${theme.palette.text.disabled}`,
      padding: '4px 10px',
      margin: '8px 8px 8px 0px',
      flex: '0 0 auto',
      borderRadius: 20,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      opacity: 0.5,
      cursor: 'pointer',
      userSelect: 'none',
      '& span': {
        fontSize: 12
      },
      [theme.breakpoints.down('sm')]: {
        margin: '4px 4px 4px 0px',
        padding: '4px 10px'
      }
    }
  },
  formControlChecked: {
    display: 'none',
    marginLeft: 16,
    '&.Mui-checked': {
      '& + .MuiFormControlLabel-label .itemSelect': {
        border: `1px solid ${theme.palette.text.primary}`,
        opacity: 1
      }
    }
  },
  iconFilter: {
    width: 32,
    height: 32
  }
}));

export default function Filter() {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(0);
  const [values, setValue] = useState([null, null]);
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const handleQuickFilters = useCallback(
    (newValue) => {
      setSelected(newValue);
      dispatch(onSetFilterSportHistory({ is_settled: newValue }));
    },
    [dispatch]
  );

  const onChangeValueDate = useCallback((value) => {
    setValue([...value]);
  }, []);

  const onAccept = useCallback(
    (value) => {
      dispatch(
        onSetFilterSportHistory({
          start_time: getStartOfDay(value?.[0]) || getStartOfDay(new Date()),
          end_time: getEndOfDay(value?.[1]) || getEndOfDay(new Date())
        })
      );
      setIsOpen(false);
      setIsFilter(true);
    },
    [dispatch]
  );

  const handeResetFilter = useCallback(() => {
    const { is_settled, ...filterInit } = DEFAULT_FILTER_SPORT_HISTORY;
    dispatch(onSetFilterSportHistory(filterInit));
    // setSelected(0);
    setValue([null, null]);
    setIsFilter(false);
  }, [dispatch]);

  const handleActiveButton = useCallback(
    (value) => () => {
      handleQuickFilters(value);
    },
    [handleQuickFilters]
  );

  return (
    <div className={classes.groupFilter}>
      <FormGroup row className={classes.itemFitler}>
        <FormControlLabel
          control={<Checkbox checked={selected == 0} className={classes.formControlChecked} />}
          key={0}
          label={
            <div className="itemSelect" onClick={handleActiveButton(0)}>
              <Typography component="span" variant="h6">
                {formatMessage({ id: 'transaction.sports.unsettled' })}
              </Typography>
            </div>
          }
        />
        <FormControlLabel
          control={<Checkbox checked={selected == 1} className={classes.formControlChecked} />}
          key={1}
          label={
            <div className="itemSelect" onClick={handleActiveButton(1)}>
              <Typography component="span" variant="h6">
                {formatMessage({ id: 'transaction.sports.settled' })}
              </Typography>
            </div>
          }
        />
      </FormGroup>
      <FilterDateRangePicker
        values={values}
        onChangeValueDate={onChangeValueDate}
        isForcePickerOpen={isForcePickerOpen}
        setIsOpen={setIsOpen}
        onAccept={onAccept}
      />
      {isFilter && (
        <IconButtonOutlined
          aria-label="clear-filter"
          className={classes.iconFilter}
          onClick={handeResetFilter}>
          <Icon name="clear-filter" />
        </IconButtonOutlined>
      )}
    </div>
  );
}

import IntlMessages from '@/@crema/utility/IntlMessages';
import { logd } from '@/@crema/utility/Logging';
import { isCardNumber, isValidArray, validateBirthday } from '@/@crema/utility/utils';
import { DEFAULT_CHARACTERS_BE, LIMIT_CHARACTERS_ADDRESS } from '@/shared/constants/AppEnums';
import WAValidator from 'wallet-address-validator';
import * as yup from 'yup';
import Amount from '../../Amount';

const getAmount = (min = 0, max = 0, requiredMsg, minMaxMsg) => {
  const requiredMessage = <IntlMessages id={requiredMsg} />;
  const minMaxMessage = (
    <IntlMessages
      id={minMaxMsg}
      values={{
        min: <Amount showPrefix={false} value={min} />,
        max: <Amount showPrefix={false} value={max} />
      }}
    />
  );
  let validate = yup.number().required(requiredMessage).positive(requiredMessage);
  if (min > 0) {
    validate = validate.concat(validate.min(min, minMaxMessage));
  }
  if (max > 0) {
    validate = validate.concat(validate.max(max, minMaxMessage));
  }
  return validate;
};

const getWithdrawAmount = (max, requiredMsg, minMaxMsg) => {
  const requiredMessage = <IntlMessages id={requiredMsg} />;
  const minMaxMessage = <IntlMessages id={minMaxMsg} />;
  let validate = yup.number().required(requiredMessage).positive(requiredMessage);

  // try {
  //   max = parseStrFormatToNumber(max);
  //   validate = validate.concat(validate.max(max, minMaxMessage));
  // } catch (err) {
  //   // max is not a valid number, ignore it
  // }
  return validate;
};

export const getAgeProfileRequired = (age) => {
  const validate = yup
    .date(<IntlMessages id="validation.invalidDateFormat" />)
    .nullable()
    .typeError(<IntlMessages id="validation.invalidDateFormat" />)
    .required(<IntlMessages id="validation.birthdayRequired" />)
    .test('DOB', <IntlMessages id="validation.yearOld" values={{ age }} />, (value) => {
      if (!value) {
        return true;
      }
      return validateBirthday(value, age);
    });
  return validate;
};

const otp_code = yup
  .string()
  .required(<IntlMessages id="validation.otpRequired" />)
  .min(6, <IntlMessages id="validation.mustBeExactlyDigits" values={{ number: 6 }} />)
  .max(6, <IntlMessages id="validation.mustBeExactlyDigits" values={{ number: 6 }} />);

const email = yup
  .string()
  .email(<IntlMessages id="validation.emailFormat" />)
  .required(<IntlMessages id="validation.emailRequired" />)
  .max(
    DEFAULT_CHARACTERS_BE,
    <IntlMessages id="validation.emailMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
  );

const password = yup
  .string()
  .required(<IntlMessages id="validation.passwordRequired" />)
  .max(
    DEFAULT_CHARACTERS_BE,
    <IntlMessages id="validation.passwordMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
  )
  .min(6, <IntlMessages id="validation.passwordMinLength" values={{ min: 6 }} />);

const tag = yup.string().required(<IntlMessages id="validation.tagRequired" />);

const memo = yup.string().required(<IntlMessages id="validation.memoRequired" />);

export const getCardNumber = (number) => {
  const validate = yup
    .string()
    .required(<IntlMessages id="validation.deposit.payment.requiredCardNumber" />)
    .test(
      'DOB',
      <IntlMessages id="validation.invalidCardNumber" values={{ number }} />,
      (number) => {
        return isCardNumber(number);
      }
    );
  return validate;
};

export const getAge = (age) => {
  const validate = yup
    .date()
    .typeError(<IntlMessages id="validation.invalidDateFormat" />)
    .required(<IntlMessages id="validation.birthdayRequired" />)
    .test('DOB', <IntlMessages id="validation.yearOld" values={{ age }} />, (value) => {
      return validateBirthday(value, age);
    });
  return validate;
};

export const getAgeProfile = (age) => {
  const validate = yup
    .date()
    .nullable()
    .typeError(<IntlMessages id="validation.invalidDateFormat" />)
    .test('DOB', <IntlMessages id="validation.yearOld" values={{ age }} />, (value) => {
      if (!value) {
        return true;
      }
      return validateBirthday(value, age);
    });
  return validate;
};

const repeatPassword = yup
  .string()
  .required(<IntlMessages id="validation.passwordMatch" />)
  .oneOf([yup.ref('password'), null], <IntlMessages id="validation.passwordMatch" />);

export const VALIDATIONS = {
  getCardNumber,
  getAge,
  getAgeProfile,
  getAmount,
  getWithdrawAmount,
  getAgeProfileRequired,
  otp_code,
  otp_code_obj: yup.object({ otp_code }),
  middle_name: yup
    .string()
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages id="validation.middleNameMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
    ),
  email,
  username: yup
    .string()
    .required(<IntlMessages id="validation.usernameRequired" />)
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages id="validation.usernameMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
    )
    .min(6, <IntlMessages id="validation.usernameMinLength" values={{ min: 6 }} />),
  password,
  repeatPassword,
  current_password: yup
    .string()
    .required(<IntlMessages id="validation.currentPasswordRequired" />)
    .matches(/^\S*$/, { message: <IntlMessages id="validation.disallowSpaceCurrentPassword" /> })
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages
        id="validation.currentPasswordMaxLength"
        values={{ max: DEFAULT_CHARACTERS_BE }}
      />
    )
    .min(6, <IntlMessages id="validation.currentPasswordMinLength" values={{ min: 6 }} />),
  new_password: yup
    .string()
    .required(<IntlMessages id="validation.newPasswordRequired" />)
    .matches(/^\S*$/, { message: <IntlMessages id="validation.disallowSpaceNewPassword" /> })
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages id="validation.newPasswordMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
    )
    .min(6, <IntlMessages id="validation.newPasswordMinLength" values={{ min: 6 }} />)
    .notOneOf(
      [yup.ref('password')],
      <IntlMessages id="validation.newPasswordMustBeDifferentThanCurrentPassword" />
    ),
  confirm_new_password: yup
    .string()
    .required(<IntlMessages id="validation.confirmNewPasswordRequired" />)
    .matches(/^\S*$/, { message: <IntlMessages id="validation.disallowSpaceConfirmPassword" /> })
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages
        id="validation.confirmNewPasswordMaxLength"
        values={{ max: DEFAULT_CHARACTERS_BE }}
      />
    )
    .min(6, <IntlMessages id="validation.confirmNewPasswordMinLength" values={{ min: 6 }} />)
    .oneOf([yup.ref('new_password'), null], <IntlMessages id="validation.passwordMisMatch" />),
  kiosk_code_obj: yup.object({
    kiosk_code: yup.string().required(<IntlMessages id="validation.kioskCodeRequired" />)
  }),
  terms: yup.boolean().oneOf([true], <IntlMessages id="validation.acceptTerms" />),
  currency_code: yup.string().required(<IntlMessages id="validation.currencyRequired" />),
  address: yup
    .string()
    .max(
      LIMIT_CHARACTERS_ADDRESS,
      <IntlMessages id="validation.addressMaxLength" values={{ max: LIMIT_CHARACTERS_ADDRESS }} />
    ),
  addressWithdraw: (currency) =>
    yup
      .string()
      .required(<IntlMessages id="validation.addressRequired" />)
      .test('addr', <IntlMessages id="validation.invalidAddressWithdraw" />, (value) => {
        try {
          if (!currency) {
            throw new Error("currency can't be empty");
          }
          const valid = WAValidator.validate(value, currency);
          return valid;
        } catch (err) {
          return true;
        }
      }),
  blockchain_network: yup.string().required(<IntlMessages id="account.deposit.selectNetwork" />),
  withdrawBy: yup.string().required(<IntlMessages id="account.withdraw.form.pleaseSelectOption" />),
  password_withdrawal: yup
    .string()
    .required(<IntlMessages id="account.withdraw.form.pleaseInputPassword" />)
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages id="validation.passwordMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
    )
    .min(6, <IntlMessages id="validation.passwordMinLength" values={{ min: 6 }} />),
  calling_code: yup.string().nullable(),
  phone_number: yup.string().min(4, <IntlMessages id="validation.invalidPhoneNumber" />),
  calling_code_required: yup
    .string()
    .nullable()
    .required(<IntlMessages id="validation.callingCodeRequired" />),
  phone_number_required: yup
    .string()
    .required(<IntlMessages id="validation.phoneNumberRequired" />)
    .min(4, <IntlMessages id="validation.invalidPhoneNumber" />),
  card_number: yup
    .string()
    .required(<IntlMessages id="validation.deposit.payment.requiredCardNumber" />)
    .min(16, <IntlMessages id="validation.minCardNumber" values={{ min: 16 }} />)
    .max(16, <IntlMessages id="validation.maxCardNumber" values={{ max: 16 }} />),
  card_holder: yup
    .string()
    .required(<IntlMessages id="validation.deposit.payment.requiredCardholderNumber" />),
  expiry_date: yup
    .string()
    .required(<IntlMessages id="validation.deposit.payment.requiredExpiration" />),
  cvv: yup
    .string()
    .required(<IntlMessages id="validation.deposit.payment.requiredCVV" />)
    .min(3, <IntlMessages id="validation.minCVV" values={{ min: 3 }} />)
    .max(4, <IntlMessages id="validation.maxCVV" values={{ max: 4 }} />),
  card: yup.string().nullable(),
  myCard: yup.string().required(<IntlMessages id="account.withdraw.selectCard" />),
  bankAccount: yup.string().required(<IntlMessages id="account.withdraw.pleaseSelectBank" />),
  account_name: yup
    .string()
    .required(<IntlMessages id="account.bank.form.accountNameRequire" />)
    .max(50, <IntlMessages id="validation.accountNameMaxlength" values={{ max: 50 }} />)
    .test('char', <IntlMessages id="validation.cardholderName" />, (value) => {
      const pattern = /^[a-z][a-z\s]*$/i;
      return pattern.test(value);
    }),
  account_number: yup.string().required(<IntlMessages id="validation.pleaseInputAccountNumber" />),
  bic: yup
    .string()
    .required(<IntlMessages id="validation.pleaseInputSwiftBic" />)
    .test('swift_bic_len', <IntlMessages id="validation.invalidSwiftBic" />, (value) => {
      return value?.length === 8 || value?.length === 11;
    }),
  bank_name: yup
    .string()
    .required(<IntlMessages id="account.deposit.pleaseInputYourBankName" />)
    .max(50, <IntlMessages id="validation.bankNameMaxLength" values={{ max: 50 }} />),
  branch: yup
    .string()
    .required(<IntlMessages id="account.withdraw.branchNameRequired" />)
    .max(50, <IntlMessages id="validation.bankNameMaxLength" values={{ max: 50 }} />),
  branch_address: yup
    .string()
    .required(<IntlMessages id="account.withdraw.branchAddressRequired" />)
    .max(250, <IntlMessages id="validation.bankAddressMaxLength" values={{ max: 250 }} />),
  branch_code: yup.string().required(<IntlMessages id="account.withdraw.branchCodeRequired" />),
  post_code: yup
    .string()
    .required(<IntlMessages id="validation.beneficiaryZipRequired" />)
    .min(2, <IntlMessages id="validation.postCodeMinLength" values={{ min: 2 }} />)
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages id="validation.postCodeMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
    )
    .test('char', <IntlMessages id="validate.postCode" />, (value) => {
      const pattern = /^[a-z0-9]+$/i;
      return pattern.test(value);
    }),
  city_name: yup
    .string()
    .required(<IntlMessages id="validation.cityRequired" />)
    .max(
      DEFAULT_CHARACTERS_BE,
      <IntlMessages id="validation.cityMaxLength" values={{ max: DEFAULT_CHARACTERS_BE }} />
    ),
  account: yup.string().required(<IntlMessages id="account.withdraw.pleaseSelectAccount" />),
  iban: yup.string().required(<IntlMessages id="validation.pleaseInputIban" />)
};

const getDynamicFormSchema = (form_config, minAmount, maxAmount) => {
  let schema = {};
  if (isValidArray(form_config?.components)) {
    form_config.components.forEach((item) => {
      const { errorMessage, minMaxMessage, min, max, required } = item?.validate || {};
      const requiredMsg = errorMessage || 'The field is required.';

      if (item.type === 'textfield' || item.type === 'numberformat') {
        if (required) {
          schema[item.key] = yup.string().required(requiredMsg);
          if (min > 0) {
            schema[item.key] = schema[item.key].concat(
              schema[item.key].min(min, <IntlMessages id={minMaxMessage} values={{ min }} />)
            );
          }
          if (max > 0) {
            schema[item.key] = schema[item.key].concat(
              schema[item.key].max(max, <IntlMessages id={minMaxMessage} values={{ max }} />)
            );
          }
        }
      }
      if (item.type === 'amount') {
        schema[item.key] = getAmount(
          minAmount,
          maxAmount,
          'validation.deposit.payment.requiredAmount',
          'validation.deposit.payment.requireMinMaxAmount'
        );
      }
    });
  }
  return yup.object(schema);
};

export const VALIDATION_SCHEMA = {
  login: yup.object({
    username: VALIDATIONS.username,
    password
  }),
  signup: yup.object({
    username: VALIDATIONS.username,
    email: VALIDATIONS.email,
    password: VALIDATIONS.password,
    terms: VALIDATIONS.terms
  }),
  withdrawCrypto: ({ requireTag, requireMemo, max, currency }) => {
    let validateSchema = {
      amount: getWithdrawAmount(
        max,
        'account.withdraw.form.pleaseInputAmount',
        'account.withdraw.form.yourBalanceIsNotSufficient'
      ),
      password,
      address: VALIDATIONS.addressWithdraw(currency)
    };

    if (requireTag) validateSchema.tag = tag;

    if (requireMemo) validateSchema.memo = memo;

    logd('withdraw.validateSchema', requireTag, requireMemo, validateSchema);
    return yup.object().shape({
      ...validateSchema
    });
  },
  newCard: (min, max) => {
    return yup.object({
      card_number: VALIDATIONS.card_number,
      card_holder: VALIDATIONS.card_holder,
      expiry_date: VALIDATIONS.expiry_date,
      cvv: VALIDATIONS.cvv,
      amount: VALIDATIONS.getAmount(
        min,
        max,
        'validation.deposit.payment.requiredAmount',
        'validation.deposit.payment.requireMinMaxAmount'
      )
    });
  },
  oldCard: (min, max) => {
    return yup.object({
      cvv: VALIDATIONS.cvv,
      card: VALIDATIONS.card,
      amount: VALIDATIONS.getAmount(
        min,
        max,
        'validation.deposit.payment.requiredAmount',
        'validation.deposit.payment.requireMinMaxAmount'
      )
    });
  },
  onlineBanking: (min, max) => {
    return yup.object({
      amount: VALIDATIONS.getAmount(
        min,
        max,
        'validation.deposit.payment.requiredAmount',
        'validation.deposit.payment.requireMinMaxAmount'
      )
    });
  },

  withdrawCreditCard: ({ max }) =>
    yup.object({
      player_bank_id: VALIDATIONS.myCard,
      password,
      amount: getWithdrawAmount(
        max,
        'account.withdraw.form.pleaseInputAmount',
        'account.withdraw.form.yourBalanceIsNotSufficient'
      )
    }),
  withdrawBankTransfer: ({ max }) =>
    yup.object({
      player_bank_id: VALIDATIONS.bankAccount,
      amount: getWithdrawAmount(
        max,
        'account.withdraw.form.pleaseInputAmount',
        'account.withdraw.form.yourBalanceIsNotSufficient'
      ),
      password
    }),
  withdrawSkrill: ({ max }) =>
    yup.object({
      player_bank_id: VALIDATIONS.account,
      amount: getWithdrawAmount(
        max,
        'account.withdraw.form.pleaseInputAmount',
        'account.withdraw.form.yourBalanceIsNotSufficient'
      ),
      password
    }),
  withdrawBankTransferAddNewBankInternational: yup.object({
    // https://docu-portal-test.paymentiq.io/docs/apis_and_integration/front_api/BankInternationalWithdrawal
    account_name: VALIDATIONS.account_name,
    account_number: VALIDATIONS.account_number,
    bic: VALIDATIONS.bic,
    branch_code: VALIDATIONS.branch_code,
    branch: VALIDATIONS.branch,
    bank_name: VALIDATIONS.bank_name,
    branch_address: VALIDATIONS.branch_address
  }),
  withdrawBankTransferAddNewIBAN: yup.object({
    account_name: VALIDATIONS.account_name,
    bic: VALIDATIONS.bic,
    iban: VALIDATIONS.iban
  }),
  updateProfile: (age_restriction) => {
    return yup.object({
      first_name: yup
        .string()
        .max(DEFAULT_CHARACTERS_BE, <IntlMessages id="validation.firstNameMaxLength" />)
        .required(<IntlMessages id="validation.firstNameRequired" />),
      last_name: yup
        .string()
        .max(DEFAULT_CHARACTERS_BE, <IntlMessages id="validation.lastNameMaxLength" />)
        .required(<IntlMessages id="validation.lastNameRequired" />),
      birthday: VALIDATIONS.getAge(age_restriction),
      city_name: VALIDATIONS.city_name,
      address: yup
        .string()
        .required(<IntlMessages id="validation.addressRequired" />)
        .max(
          LIMIT_CHARACTERS_ADDRESS,
          <IntlMessages
            id="validation.addressMaxLength"
            values={{ max: LIMIT_CHARACTERS_ADDRESS }}
          />
        ),
      post_code: VALIDATIONS.post_code,
      calling_code: VALIDATIONS.calling_code_required,
      phone_number: VALIDATIONS.phone_number_required
    });
  },
  profile: (age_restriction) =>
    yup.object({
      middle_name: VALIDATIONS.middle_name,
      email: VALIDATIONS.email,
      calling_code: VALIDATIONS.calling_code,
      phone_number: VALIDATIONS.phone_number,
      birthday: VALIDATIONS.getAgeProfile(age_restriction),
      address: VALIDATIONS.address,
      city_name: VALIDATIONS.city_name,
      post_code: VALIDATIONS.post_code
    }),
  getDynamicFormSchema,
  agentRegister: (age_restriction) =>
    yup.object({
      email: VALIDATIONS.email,
      password: VALIDATIONS.password,
      confirm_password: VALIDATIONS.repeatPassword,
      currency_code: yup.string().required(<IntlMessages id="Currency is required." />),
      birthday: VALIDATIONS.getAgeProfileRequired(age_restriction),
      phone_number: VALIDATIONS.phone_number_required,
      first_name: yup.string().required(<IntlMessages id="First name is required." />),
      last_name: yup.string().required(<IntlMessages id="Last name is required." />),
      calling_code: yup
        .object()
        .required(<IntlMessages id="validation.callingCodeRequired" />)
        .nullable()
    })
};

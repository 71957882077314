import { ConfirmDialog } from '@/@crema';
import { onCancelTransaction } from '@/redux/actions';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TransactionContext } from '../../TransactionList/ListContext';
import ButtonCancel from '../ButtonCancel';

const CancelButtonWithConfirm = ({ transactionType }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [openDialogConfirmCancel, setOpenDialogConfirmCancel] = useState(false);
  const transaction = useContext(TransactionContext);
  const { onUnselectItem } = transaction;

  const handleOpenDialogConfirmCancel = () => {
    setOpenDialogConfirmCancel(true);
  };

  const handleCloseDialogConfirmCancel = () => {
    setOpenDialogConfirmCancel(false);
  };

  const handleCancel = async () => {
    setOpenDialogConfirmCancel(false);
    setLoading(true);
    const result = await dispatch(onCancelTransaction(transactionType));
    setLoading(false);
    result && onUnselectItem();
  };

  return (
    <>
      <ButtonCancel loading={loading} onClick={handleOpenDialogConfirmCancel} />
      <ConfirmDialog
        open={openDialogConfirmCancel}
        onClose={handleCloseDialogConfirmCancel}
        onClickYes={handleCancel}
        onClickCancel={handleCloseDialogConfirmCancel}
        title={formatMessage({
          id: 'account.transaction.cancelTransaction.title'
        })}
        content={
          <Box>
            <Typography color="primary" component="span" variant="h5">
              {formatMessage({
                id: 'account.transaction.cancelTransaction.description'
              })}
            </Typography>
          </Box>
        }
      />
    </>
  );
};

CancelButtonWithConfirm.propTypes = {
  transactionType: PropTypes.string.isRequired
};

CancelButtonWithConfirm.defaultProps = {};

export default CancelButtonWithConfirm;

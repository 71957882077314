import { isValidArray } from './utils-array';
import { isValidString } from './utils-string';

export function isValidObject(params) {
  return typeof params === 'object' && params !== null && params !== undefined;
}

export function isEmpty(obj) {
  if (!isValidObject(obj)) {
    return true;
  }

  return Object.keys(obj).length === 0;
}

export function hasOwnProperty(obj, prop) {
  return isValidObject(obj) && Object.prototype.hasOwnProperty.call(obj, prop);
}

export function isEmptyObj(params) {
  for (var _i in params) {
    return false;
  }
  return true;
}

export function isEmptyProperty(obj) {
  let isObj = false;
  if (isValidObject(obj)) {
    Object.values(obj).forEach((item) => {
      if (!isEmpty(item) || isValidArray(item) || isValidString(item)) {
        isObj = true;
      }
    });
  }
  return isObj;
}

/**
 * get
 * @link https://youmightnotneed.com/lodash#get
 * @param {Object} obj
 * @param {String|Array} path
 * @param {*} defValue
 * @returns {*} result
 */
export function get(obj, path, defValue) {
  if (!path) return undefined;
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
  return result === undefined || result === null || result === '' ? defValue : result;
}

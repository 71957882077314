import {
  claimPromotion,
  getCMSPromotions,
  getCMSPromotionsDetail
} from '@/@crema/services/apis/promotion';
import ToastService from '@/@crema/services/ToastService';
import { logd } from '@/@crema/utility/Logging';
import { handleErrors } from '@/redux/actions';

import {
  PROMOTION_CLAIM_BONUS_ERROR,
  PROMOTION_CLAIM_BONUS_START,
  PROMOTION_CLAIM_BONUS_SUCCESS,
  PROMOTION_GET_CMS,
  PROMOTION_GET_DETAIL,
  PROMOTION_GET_DETAIL_ERROR,
  PROMOTION_GET_DETAIL_START,
  PROMOTION_LOAD_MORE_CMS_ERROR,
  PROMOTION_LOAD_MORE_CMS_START,
  PROMOTION_LOAD_MORE_CMS_SUCCESS
} from '@/shared/constants/ActionTypes';

export const onLoadMoreCmsPromotion = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: PROMOTION_LOAD_MORE_CMS_START
      });
      dispatch(onGetCmsPromotion(true));
    } catch (error) {
      dispatch({
        type: PROMOTION_LOAD_MORE_CMS_ERROR
      });
      logd('onLoadMoreGames.error', error);
    }
  };
};

export const onGetCmsPromotion = (isLoadMore = false, query) => {
  return async (dispatch, getState) => {
    try {
      let params = { ...query, sorting: query?.sorting ? query?.sorting : 'popular' };
      if (isLoadMore) {
        const { promotion } = getState();
        const { paging } = promotion;
        params = {
          page: isLoadMore ? paging.current_page + 1 : paging.current_page,
          per_page: paging.per_page,
          ...query
        };
      }
      const response = await getCMSPromotions(params);

      const { data } = response;
      if (isLoadMore) {
        dispatch({ type: PROMOTION_LOAD_MORE_CMS_SUCCESS, payload: data });
      } else {
        dispatch({ type: PROMOTION_GET_CMS, payload: data });
      }
      return data;
    } catch (error) {
      dispatch(handleErrors(error));
      dispatch({
        type: PROMOTION_LOAD_MORE_CMS_ERROR
      });
      return error;
    }
  };
};

export const onGetCmsPromotionDetail = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PROMOTION_GET_DETAIL_START });
      const res = await getCMSPromotionsDetail(id);
      if (res.data) {
        dispatch({ type: PROMOTION_GET_DETAIL, payload: res.data });
        return res.data;
      }

      return;
    } catch (error) {
      dispatch({ type: PROMOTION_GET_DETAIL_ERROR });
      return;
    }
  };
};

export const onClaimPromotion = (data) => {
  return async (dispatch) => {
    try {
      if (data && data.key) {
        dispatch({ type: PROMOTION_CLAIM_BONUS_START });
        const res = await claimPromotion(data.key);
        dispatch({ type: PROMOTION_CLAIM_BONUS_SUCCESS });
        ToastService.success('promotion.claim.successful');
        // AnalyticsService.logPromotionClaimSuccess(data);
      }
    } catch (error) {
      dispatch({ type: PROMOTION_CLAIM_BONUS_ERROR });
      // AnalyticsService.logPromotionClaimFailed(data, error);
      dispatch(handleErrors(error));
    }
  };
};

import { withStyles } from '@material-ui/core';

const GlobalTextField = withStyles(
  (theme) => ({
    '@global': {
      '.MuiSelect-icon': {
        top: 'calc(50% - 10px)'
      }
    }
  }),
  { name: 'GlobalTextField' }
)(() => null);

export default GlobalTextField;

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

const styles = (theme) => {
  return {
    root: {
      padding: 5,
      width: 28,
      height: 28,

      '&.MuiButton-root': {
        minWidth: 'unset',
        '& .MuiButton-label': {
          color: theme.palette.text.primary
        }
      },
      '&.MuiButton-outlinedPrimary': {
        border: `2px solid ${theme.palette.text.primary}`,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: 'unset',
          '& .MuiButton-label': {
            color: theme.palette.text.primary
          }
        }
      },
      '&.MuiButton-outlinedSecondary': {
        border: `2px solid ${theme.palette.text.primary}`,
        borderRadius: '50%',
        '&:hover': {
          border: `2px solid ${theme.palette.text.primary}`,
          backgroundColor: 'unset'
        }
      },
      '&.MuiButton-outlinedSizeSmall': {
        width: 32,
        height: 32
      },
      '&.MuiButton-outlinedSizeLarge': {
        width: 48,
        height: 48
      },
      '&.MuiButton-containedPrimary': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.text.disabled,
        border: 'unset',
        color: theme.palette.text.primary,
        boxShadow: 'unset',
        '&:hover': {
          backgroundColor: 'none'
        }
      },
      '&.MuiButton-containedSecondary': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'unset',
        border: 'unset',
        color: theme.palette.text.primary,
        boxShadow: 'unset'
      },
      '&.MuiButton-containedSizeSmall': {
        width: 32,
        height: 32
      },
      '&.MuiButton-containedSizeLarge': {
        width: 48,
        height: 48
      },
      '&.Mui-disabled': {
        opacity: 0.5
      }
    }
  };
};

const IconButtonOutlined = withStyles(styles)((props) => {
  const { className, ...rest } = props;
  const classes = props.classes || {};
  return (
    <Button
      aria-label="icon button outlined"
      variant="outlined"
      {...rest}
      className={clsx(classes.root, className)}
    />
  );
});

export default IconButtonOutlined;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerTransactionDetail: {
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.up('sm')]: {
      minWidth: 500
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  }
}));

export default useStyles;

import {
  PROMOTION_CLAIM_BONUS_ERROR,
  PROMOTION_CLAIM_BONUS_START,
  PROMOTION_CLAIM_BONUS_SUCCESS,
  PROMOTION_GET_CMS,
  PROMOTION_GET_DETAIL,
  PROMOTION_GET_DETAIL_ERROR,
  PROMOTION_GET_DETAIL_START,
  PROMOTION_LOAD_MORE_CMS_ERROR,
  PROMOTION_LOAD_MORE_CMS_START,
  PROMOTION_LOAD_MORE_CMS_SUCCESS
} from '@/shared/constants/ActionTypes';
import { PAGING_DEFAULT } from '@/shared/constants/PagingConst';
import { useSelector } from 'react-redux';

export const initialPromotionState = {
  list: null,
  selected: null,
  loading: false,
  paging: {
    current_page: 1,
    last_page: 0,
    per_page: PAGING_DEFAULT.perPage,
    total: 0
  },
  isLoadMore: false,
  messageError: null,
  loadingClaim: false
};

export const usePromotion = () => {
  return useSelector(({ promotion }) => promotion);
};

export const usePromotionList = () => {
  return useSelector(({ promotion }) => promotion.list);
};

export const usePromotionPaging = () => {
  return useSelector(({ promotion }) => promotion.paging);
};

export const usePromotionIsLoadMore = () => {
  return useSelector(({ promotion }) => promotion.isLoadMore);
};

export const useMessErrorPromotion = () => {
  return useSelector(({ promotion }) => promotion.messageError);
};

export const PromotionReducer = (state = initialPromotionState, action) => {
  switch (action.type) {
    case PROMOTION_GET_CMS:
      return {
        ...state,
        list: action.payload?.data,
        paging: {
          current_page: action.payload?.current_page,
          last_page: action.payload?.last_page,
          per_page: action.payload?.per_page,
          total: action.payload?.total
        },
        selected: null
      };
    case PROMOTION_LOAD_MORE_CMS_START:
      return {
        ...state,
        isLoadMore: true
      };
    case PROMOTION_LOAD_MORE_CMS_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.payload?.data],
        paging: {
          current_page: action.payload?.current_page,
          last_page: action.payload?.last_page,
          per_page: action.payload?.per_page,
          total: action.payload?.total
        },
        isLoadMore: false
      };
    case PROMOTION_LOAD_MORE_CMS_ERROR:
      return {
        ...state,
        isLoadMore: false
      };
    case PROMOTION_GET_DETAIL:
      return {
        ...state,
        selected: action.payload,
        loading: false
      };
    case PROMOTION_GET_DETAIL_START:
      return {
        ...state,
        loading: true,
        selected: null,
        messageError: null
      };
    case PROMOTION_GET_DETAIL_ERROR:
      return {
        ...state,
        loading: false
      };
    case PROMOTION_CLAIM_BONUS_START:
      return {
        ...state,
        messageError: null,
        loadingClaim: true
      };
    case PROMOTION_CLAIM_BONUS_SUCCESS:
      return {
        ...state,
        loadingClaim: false
      };
    case PROMOTION_CLAIM_BONUS_ERROR:
      return {
        ...state,
        messageError: action.payload,
        loadingClaim: false
      };
    default:
      return state;
  }
};

import { getLocalization } from '@/@crema/services/apis/app';
import { logd } from '@/@crema/utility/Logging';
import { redirectTo } from '@/@crema/utility/Route';
import { getCookie } from '@/@crema/utility/Session';
import { get, getFallbackRedirect, getFutureDate, isValidString } from '@/@crema/utility/utils';
import { APP_SET_LOCALIZATION } from '@/shared/constants/ActionTypes';
import { COOKIES } from '@/shared/constants/CookieConst';
const TAG = '[REDIRECT]';
/**
 *
 * @param {String} destination assume destination includes a splash + some locale already
 * @param {String} locale
 * @returns
 */
const shorten = (destination, locale) => {
  if (destination && locale && destination.startsWith(`/${locale}`)) {
    const pattern = new RegExp(`/${locale}`);
    return destination.replace(pattern, '');
  }
  return destination;
};

/**
 *
 * @param {import('next').NextPageContext} ctx
 * @returns {boolean} redirect
 */
async function isFirstRequest(ctx) {
  const { locale, defaultLocale, asPath, req, res } = ctx;

  logd(TAG, 'isFirstRequest.cookie', req?.headers?.cookie);

  if (locale !== defaultLocale) {
    const next30days = getFutureDate(30, 'd');
    const cookie = `NEXT_LOCALE=${locale};path=/;Expires=${next30days}`;
    res.setHeader('set-cookie', cookie);
    return false;
  }

  const cookieLocale = getCookie(COOKIES.next_locale, req?.headers);

  // locale is en (default)
  if (!cookieLocale) {
    let detectedLocale;

    try {
      const res = await getLocalization(ctx);
      if (res.data) {
        detectedLocale = res?.data?.preferred_language?.code;
        ctx.store.dispatch({
          type: APP_SET_LOCALIZATION,
          payload: res.data
        });
      }
    } catch (error) {
      console.error(error);
    }

    if (detectedLocale) {
      logd(TAG, 'cookie', ctx?.req?.headers?.cookie);

      logd(TAG, `checkRedirect locale ${locale} detectedLocale ${detectedLocale}`);

      let redirectUrl;

      if (isValidString(detectedLocale)) {
        const next30days = getFutureDate(30, 'd');
        let cookie = `NEXT_LOCALE=${detectedLocale};path=/;Expires=${next30days}`;
        res.setHeader('set-cookie', cookie);
        logd(TAG, 'cookie', cookie);

        // detectedLocale is fi
        if (locale !== detectedLocale) {
          redirectUrl = `/${detectedLocale}${asPath}`;
        }

        logd(TAG, 'res.headers', res?.headers);

        logd(TAG, 'redirectUrl', redirectUrl);

        if (redirectUrl) {
          redirectUrl = shorten(redirectUrl, defaultLocale);
          redirectTo(redirectUrl, { res });
          return true;
        }
      }
    }
  }

  return false;
}

/**
 *
 * @param {import('next').NextPageContext} ctx
 * @param {import('next/router').NextRouter} router
 * @param {object} systemSetting
 * @returns {boolean} redirect
 */
function isMaintenance(ctx, router, systemSetting) {
  const is_maintenance = systemSetting?.system?.is_maintenance;
  const locale = router.locale;
  let redirectUrl;

  logd(TAG, 'is_maintenance', is_maintenance, ctx?.pathname);

  if (is_maintenance) {
    if (ctx?.pathname !== '/maintenance') {
      redirectUrl = `/${locale}/maintenance/`;
      redirectUrl = shorten(redirectUrl, router.defaultLocale);
      redirectTo(redirectUrl, { res: ctx.res });
      return true;
    }
  } else {
    // redirect to home from maintenance
    if (ctx?.pathname === '/maintenance') {
      redirectUrl = `/${locale}/`;
      redirectUrl = shorten(redirectUrl, router.defaultLocale);
      redirectTo(redirectUrl, { res: ctx.res });
      return true;
    }
  }

  return false;
}

/**
 *
 * @param {import('next').NextPageContext} ctx
 * @param {import('next/router').NextRouter} router
 * @param {object} systemSetting
 * @returns {boolean} redirect
 */
function isCMSRedirect(ctx, router, systemSetting) {
  let redirectUrl;
  if (ctx.res) {
    // Fallback Redirect on Server side
    const setting = get(systemSetting, 'cms.setting');
    let destination = getFallbackRedirect(setting, ctx.asPath);

    logd(TAG, 'isCMSRedirect.destination', destination);

    if (destination.to) {
      const locale = router.locale;
      redirectUrl = `/${locale}${destination.to}`;
      redirectUrl = shorten(redirectUrl, router.defaultLocale);
      redirectTo(redirectUrl, { res: ctx.res, status: destination.redirection_code });
      return true;
    }
  } else {
    // Fallback Redirect on Client side

    logd(TAG, 'isCMSRedirect.ctx.asPath', ctx.asPath);

    const cookieLocale = getCookie(COOKIES.next_locale);
    let path = ctx.asPath;

    if (path.startsWith(`/${cookieLocale}`)) {
      path = path.replace(`/${cookieLocale}`, '');
    }

    const setting = ctx.store.getState()?.app?.cmsSettings;
    const destination = getFallbackRedirect(setting, path);

    logd(TAG, 'isCMSRedirect.destination', destination);

    if (destination.to) {
      router.replace(destination.to, undefined, { locale: cookieLocale });
      return true;
    }
  }

  return false;
}

/**
 *
 * @param {import('next').NextPageContext} ctx
 * @param {import('next/router').NextRouter} router
 * @param {object} systemSetting
 * @returns {boolean} redirect
 */
function isLocaleRedirect(ctx, router, systemSetting) {
  if (ctx.res) {
    // use cookie locale for Redirect on Server side
    const locale = router.locale;
    const cookieLocale = getCookie(COOKIES.next_locale, ctx?.req?.headers);

    if (cookieLocale && router.locales.includes(cookieLocale)) {
      let url;

      if (locale !== cookieLocale) {
        // router is en -> locale is from cookie
        if (locale === router.defaultLocale) {
          url = `/${cookieLocale}${router.asPath}`;
        }
        // router is if -> locale is from router
        else {
          const next30days = getFutureDate(30, 'd');
          const value = `NEXT_LOCALE=${locale};path=/;Expires=${next30days}`;
          ctx.res.setHeader('set-cookie', value);
          url = `/${locale}${router.asPath}`;
        }
      }

      logd(TAG, 'isLocaleRedirect.url', url);

      if (url) {
        url = shorten(url, router.defaultLocale);
        logd(TAG, 'isLocaleRedirect.url', url);

        redirectTo(url, { res: ctx.res });
        return true;
      }
    }
  } else {
    // Fallback Redirect on Client side
    // const setting = ctx.store.getState()?.app?.cmsSettings;
    // const destination = getFallbackRedirect(setting, ctx.asPath);
    // if (destination) {
    //   router.replace(destination);
    //   return true;
    // }
  }

  return false;
}

/**
 *
 * @param {import('next').NextPageContext} ctx
 * @param {object} systemSetting
 * @returns {boolean} redirect
 */
export default async function checkRedirect(ctx, router, systemSetting) {
  try {
    const { locale, asPath, pathname, locales, query, res } = ctx;

    logd(TAG, 'checkRedirect', { locale, asPath, pathname, locales, query });

    // server
    if (res) {
      // check first request
      const _isFirstRequest = await isFirstRequest(ctx);
      logd(TAG, '_isFirstRequest', _isFirstRequest);
      if (_isFirstRequest) {
        return true;
      }

      // check maintenance mode and redirect
      const _isMaintenance = isMaintenance(ctx, router, systemSetting);
      logd(TAG, '_isMaintenance', _isMaintenance);
      if (_isMaintenance) {
        return true;
      }

      // check cms redirect
      const _isCMSRedirect = isCMSRedirect(ctx, router, systemSetting);
      logd(TAG, '_isCMSRedirect', _isCMSRedirect);
      if (_isCMSRedirect) {
        return true;
      }

      // check cms redirect
      const _isLocaleRedirect = isLocaleRedirect(ctx, router, systemSetting);
      logd(TAG, '_isLocaleRedirect', _isLocaleRedirect);
      if (_isLocaleRedirect) {
        return true;
      }
    } else {
      if (isCMSRedirect(ctx, router, systemSetting)) {
        return true;
      }
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

import { makeStyles } from '@material-ui/core';
import { CellTextCopy } from '../../configure/renderTextCopy';
import CellDetailSport from './CellDetailSport';

const useStyle = makeStyles((theme) => ({
  sportBook: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(4),
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px ${theme.spacing(4)}px`
  },
  sportBookContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '& .text-copy-expand-detail': {
      placeContent: 'start'
    }
  }
}));

export default function SportBook({ row }) {
  const classes = useStyle();
  const { round_id, game = {}, sportbook_bet_detail = {} } = row;
  const { detail = [] } = sportbook_bet_detail;
  const gameTypeName = game?.game_type?.name;

  return (
    <div className={classes.sportBook}>
      <div className={classes.sportBookContent}>
        <div>
          <CellTextCopy className="text-copy-expand-detail" text={round_id} />
        </div>
        <div className="flex column gap-12">
          {detail.map((item, i) => {
            const { event, home_name, away_name, odds } = item;
            return (
              <CellDetailSport
                className="flex column"
                key={`${event} - ${home_name} - ${away_name} - ${odds}`}
                detail={item}
                gameTypeName={gameTypeName}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

import IntlMessages from '@/@crema/utility/IntlMessages';
import { COLORS } from '@/shared/constants/ColorSets';
import { Box, Button, FormControl, fade, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import Icon from '../Icon';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rootBtn: {
    marginTop: 8,
    '&.is-dynamic-form': {
      marginTop: 28,
      '& .MuiButtonBase-root': {
        height: 48,
        '&.btn-submit-fi': {
          background: COLORS.mintGreen,
          '&:hover': {
            background: COLORS.mintGreen,
            opacity: 0.5
          }
        },
        [theme.breakpoints.down('xs')]: {
          height: 36,
          borderRadius: theme.spacing(2),
          '& .MuiButton-label': {
            fontSize: 12
          }
        }
      },
      '& .Mui-disabled': {
        '& .MuiButton-label': {
          color: fade(theme.palette.text.primary, 0.7)
        }
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 16
      }
    }
  }
}));

const SubmitButton = ({
  children,
  disabled,
  showNextIcon = false,
  isDynamicForm,
  ...otherProps
}) => {
  const classes = useStyles();
  const { isSubmitting } = useFormikContext() ?? {};

  const configButton = {
    type: 'submit',
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disabled: isSubmitting || disabled,
    isDynamicForm,
    ...otherProps
  };

  return (
    <Box className={clsx(classes.rootBtn, { ['is-dynamic-form']: isDynamicForm })}>
      <FormControl margin="normal" fullWidth>
        <Button {...configButton} endIcon={showNextIcon ? <Icon name="forward" /> : null}>
          {children ? children : <IntlMessages id="common.next" />}
        </Button>
        {isSubmitting && (
          <Box className={classes.buttonProgress}>
            <Loading />
          </Box>
        )}
      </FormControl>
    </Box>
  );
};

export default SubmitButton;

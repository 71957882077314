import { hasOwnProperty } from './utils-object';

export function isValidString(param) {
  return typeof param === 'string' && param !== '';
}

export function capitalize(str) {
  if (!isValidString(str)) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValidUrl(str) {
  const regexUrl = /(http(s?)):\/\//i;
  return regexUrl.test(str);
}

export function isValidFunction(param) {
  return typeof param === 'function';
}

export function trim(str) {
  if (!isValidString(str)) {
    return '';
  }

  return str.trim();
}

export function lowerCase(str) {
  if (!isValidString(str)) {
    return '';
  }
  return str.toLowerCase();
}

export function searchTwoStr(a, b) {
  return lowerCase(trim(a)).includes(lowerCase(trim(b)));
}

export function serialize(obj) {
  const str = [];
  for (var p in obj)
    if (hasOwnProperty(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

export function isNA(param) {
  return param === undefined || param === null || (typeof param === 'string' && param === '');
}

export function uniqueId() {
  var d = new Date().getTime();

  if (performance && typeof performance.now === 'function') {
    d += performance.now();
  }

  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
}

export function isCardNumber(number) {
  const regex =
    /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/;
  if (number) {
    return regex.test(number);
  }
}

export function parseTokenValidateTurnstile(token) {
  if (!token) {
    return '';
  }
  const tokenObject = {
    token: token || ''
  };
  const tokenString = Buffer.from(JSON.stringify(tokenObject)).toString('base64');
  return tokenString;
}

import { FastField, getIn } from 'formik';
import { memo } from 'react';
import BaseTextField from '../../Inputs/TextField';

const _TextField = ({
  disabled,
  field,
  form,
  helperText,
  onChange,
  onError = () => {},
  showError = true,
  variant,
  loading,
  ...nest
}) => {
  const { isSubmitting, touched, errors } = form;
  const fieldError = getIn(errors, field.name);
  const fieldTouch = getIn(touched, field.name);
  const hasError = showError && !!fieldTouch && !!fieldError;
  const _helperText = hasError ? fieldError : helperText;

  const _onChange = (evt) => {
    field.onChange(evt);
    onChange?.(evt.target.value, form);
  };

  return (
    <BaseTextField
      {...field}
      {...nest}
      onChange={_onChange}
      fullWidth
      variant={variant}
      error={hasError}
      helperText={_helperText}
      disabled={disabled ?? isSubmitting}
      loading={loading}
    />
  );
};

const shouldUpdate = (prevProps, nextProps) => {
  return (
    (typeof nextProps.select === 'boolean' && prevProps.children !== nextProps.children) ||
    prevProps.disabled !== nextProps.disabled ||
    prevProps.loading !== nextProps.loading ||
    prevProps.type !== nextProps.type ||
    prevProps.label !== nextProps.label ||
    prevProps.name !== nextProps.name ||
    getIn(prevProps.formik.values, nextProps.name) !==
      getIn(nextProps.formik.values, nextProps.name) ||
    getIn(prevProps.formik.errors, nextProps.name) !==
      getIn(nextProps.formik.errors, nextProps.name) ||
    getIn(prevProps.formik.touched, nextProps.name) !==
      getIn(nextProps.formik.touched, nextProps.name) ||
    Object.keys(nextProps).length !== Object.keys(prevProps).length ||
    prevProps.formik.isSubmitting !== nextProps.formik.isSubmitting
  );
};

const TextField = (props) => {
  return <FastField {...props} component={_TextField} shouldUpdate={shouldUpdate}></FastField>;
};

export default memo(TextField);

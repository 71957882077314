import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { configure, getValueByPathAndSetDefaultValue } from '../../configure/';
import useStyles from './TransactionListItem.style';

const TransactionListItem = ({ item, variant, onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const parseFn = () => {
    const name = variant === 'deposit' ? 'paymentName' : 'withdrawBy';
    const status = variant === 'deposit' ? 'statusDeposit' : 'statusWithdraw';
    const value = getValueByPathAndSetDefaultValue(item, configure.fields.createdAt.name);

    return {
      name: configure.fields[name].renderContent(null, item),
      amount: configure.fields.amount.renderContent(null, item),
      status: configure.fields[status].renderContent(null, item),
      date: configure.fields.createdAt.renderContent(value)
    };
  };

  const { name, amount, status, date } = parseFn(item);

  const renderTitle = () => {
    if (variant === 'deposit') {
      return (
        <Typography variant="subtitle2" component="div" className={classes.name}>
          {formatMessage({ id: 'tab.wallet.depositFrom' })}&nbsp;
          <Typography variant="subtitle2" className="bold" component="span">
            {name}
          </Typography>
        </Typography>
      );
    }
    return (
      <Typography variant="subtitle2" component="span">
        {name}
      </Typography>
    );
  };

  const handleClick = () => {
    // allow ripple effect happens ahead
    setTimeout(() => {
      onClick(item);
    }, 150);
  };

  return (
    <div className={classes.transactionItem} onClick={handleClick}>
      <div className={classes.row}>
        <Typography variant="subtitle2" component="div" className={classes.name}>
          {renderTitle()}
        </Typography>
        <Typography variant="subtitle2" component="div" className={classes.amount}>
          {amount}
        </Typography>
      </div>
      <div className={clsx(classes.row, 'mt-2')}>
        <Typography variant="caption" component="div" className={classes.date}>
          {date}
        </Typography>
        {status}
      </div>
    </div>
  );
};

TransactionListItem.propTypes = {
  item: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['deposit', 'withdraw']),
  onClick: PropTypes.func
};

TransactionListItem.defaultProps = {
  variant: 'deposit',
  onClick: () => {}
};

export default TransactionListItem;

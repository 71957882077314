import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  transactionListTabContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  tabs: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  tab: {
    flex: '1 0 auto',
    padding: 0,
    '& a': {
      width: '100%',
      padding: `${theme.spacing(3)}px 0`
    },
    '&.Mui-selected': {
      background: theme.palette.background.card
    }
  },
  flexContainer: {
    justifyContent: 'space-between'
  }
}));

export default useStyles;

import { logd } from '../Logging';

export function dispatchGlobalEvent(params) {
  try {
    if (typeof params !== 'object') {
      return;
    }

    if (typeof window === 'undefined') {
      return;
    }

    if (!window.dataLayer) {
      return;
    }

    if (params.ecommerce) {
      // Clear the previous ecommerce object
      window.dataLayer.push({ ecommerce: null });
    }

    logd('dispatchGlobalEvent.params', params);

    window.dataLayer.push(params);
  } catch (error) {
    console.error('dispatchGlobalEvent.error', error);
  }
}

import NextImage from '@/@crema/core/ImageOptimized/NextImage';
import { getCMSName } from '@/@crema/utility/utils';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { ListItem, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { configure, getValueByPathAndSetDefaultValue } from '../../configure/';
import useStyles from './TransactionListItemGame.style';

const parseFn = (item) => {
  const date = getValueByPathAndSetDefaultValue(item, configure.fields.betTime.name);

  return {
    name: configure.fields.gameName.renderContent(null, item),
    amount: configure.fields.amountWithPrefix.renderContent(null, item),
    date: configure.fields.betTime.renderContent(date),
    src: item?.game?.cms_property?.mobile_image?.path ?? '',
    alt: getCMSName(item?.game),
    gameProvider: configure.fields.providerName.renderContent(null, item),
    gameType: configure.fields.gameType.renderContent(null, item),
    betAmount: configure.fields.betAmount.renderContent(null, item),
    payout: configure.fields.payout.renderContent(null, item)
  };
};

const TransactionListItemGame = ({ item, onClick }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const theme = useTheme();

  const { name, date, src, alt, gameProvider, gameType, betAmount, payout } = parseFn(item);

  const handleClick = useCallback(() => {
    // allow ripple effect happens ahead
    setTimeout(() => {
      onClick(item);
    }, 150);
  }, [item, onClick]);

  return (
    <ListItem button onClick={handleClick}>
      <div className={classes.transactionItemGame}>
        <div className={classes.side}>
          <NextImage
            src={src}
            alt={alt}
            skeleton
            classNameContainer={classes.image}
            ratio={ASPECT_RATIO['1-1']}
            borderRadius={theme.shape.borderRadius}
          />
        </div>
        <div className={classes.head}>
          <Typography variant="subtitle2" component="div" className={classes.oneLine}>
            {name}
          </Typography>
        </div>
        <Typography variant="caption" component="div" className={classes.body}>
          <div className={classes.descItem1}>
            <div className={classes.oneLine}>
              <span className={classes.textDesc}>{`${gameProvider}${` - ${gameType}`}`}</span>
            </div>
            <div className={classes.oneLine}>
              <span className={classes.textDesc}>{date}</span>
            </div>
          </div>
          <div className={classes.descItem2}>
            <div className={classes.descItem2Inner}>
              <div className={classes.oneLine}>
                <span className={classes.textDesc}>
                  {formatMessage({ id: 'transaction.game.bet' })}
                </span>
                &nbsp;
                {betAmount}
              </div>
              <div className={classes.oneLine}>
                <span className={classes.textDesc}>
                  {formatMessage({ id: 'transaction.game.payout' })}
                </span>
                &nbsp;
                {payout}
              </div>
            </div>
          </div>
        </Typography>
      </div>
    </ListItem>
  );
};

TransactionListItemGame.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

TransactionListItemGame.defaultProps = {
  onClick: () => {}
};

export default TransactionListItemGame;

import { CremaPage, ImageOptimized } from '@/@crema';
import { isValidFunction } from '@/@crema/utility/utils';
import { STATUS_CODES } from '@/shared/constants/StatusCodes';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import LottieError500 from '../Lottie/LottieError500';
import LottieMaintenance from '../Lottie/LottieMaintenance';
import LottieOffline from '../Lottie/LottieOffline';

function getErrorData(statusCode) {
  switch (statusCode) {
    case STATUS_CODES.OFFLINE:
      return {
        title: 'common.youAreCurrentlyOffline',
        description: null,
        statusCode,
        href: '/',
        image: <LottieOffline />
      };
    case STATUS_CODES.NOT_FOUND:
      return {
        title: 'error.404.title',
        description: 'error.404.description',
        image: (
          <ImageOptimized src="/images/404.png" alt="404 image" isStaticUrl bgColor="transparent" />
        ),
        statusCode,
        href: '/'
      };
    case STATUS_CODES.SERVER_ERROR:
      return {
        title: 'error.500.title',
        description: 'error.500.description',
        image: <LottieError500 />,
        statusCode,
        href: '/'
      };
    case STATUS_CODES.MAINTENANCE:
      return {
        title: 'error.maintenanceMessage1',
        description: 'error.maintenanceMessage2',
        image: <LottieMaintenance />,
        statusCode,
        href: null
      };
    default:
      return {
        title: 'error.systemError.title',
        description: 'error.systemError.description',
        image: <LottieError500 />,
        statusCode,
        href: '/'
      };
  }
}

const useStyles = makeStyles((theme) => ({
  errorContainer: (props) => ({
    paddingLeft: props.hasSpacing ? theme.spacing(4) : '0px !important',
    paddingRight: props.hasSpacing ? theme.spacing(4) : '0px !important'
  }),
  errorPanel: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  btnNext: {}
}));

const Error = ({
  statusCode,
  title,
  description,
  href,
  // image,
  className,
  renderButton,
  imageBgColor,
  hasSpacing
}) => {
  const classes = useStyles({ hasSpacing });
  const { formatMessage } = useIntl();
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    href: defaultHref
  } = getErrorData(statusCode);
  const _title = title
    ? formatMessage({ id: title, defaultMessage: title })
    : formatMessage({ id: defaultTitle });

  const _description = description
    ? formatMessage({ id: description, defaultMessage: description })
    : defaultDescription
    ? formatMessage({ id: defaultDescription })
    : '';

  // const _image = image || defaultImage;

  const _href = href || defaultHref;

  const btnTextIntl = formatMessage({ id: 'error.goBackToHome' });

  return (
    <CremaPage title={_title} description={_description} hasSpacing={hasSpacing}>
      <Container disableGutters className={classes.errorContainer}>
        <Box className={clsx(classes.errorPanel, className)}>
          <Box width="100%" maxWidth={{ sm: 300, md: 500 }}>
            <Box m={4}>{image}</Box>
            <Box mb={{ xs: 4, md: 8 }}>
              <Typography variant="h3" className="bold">
                {_title}
              </Typography>
            </Box>
            <Box mb={{ xs: 4, md: 8 }}>
              <Typography color="textSecondary">
                <div dangerouslySetInnerHTML={{ __html: _description }}></div>
              </Typography>
            </Box>
            <Box mb={{ xs: 4, md: 8 }}>
              {isValidFunction(renderButton) ? (
                renderButton()
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnNext}
                  href={_href}>
                  {btnTextIntl}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </CremaPage>
  );
};

Error.defaultProps = {
  className: '',
  hasSpacing: true
};

export default Error;

import { isNA, isValidFunction } from '@/@crema/utility/utils';
import { Box, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { configure, getValueByPathAndSetDefaultValue } from '../../configure';
import useStyles from './TransactionInformation.style';

export const VALUE_NA = '-';

const TransactionInformation = ({ data, fields }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const renderRow = (key, index) => {
    const { name, label, renderContent } = configure.fields[key];

    let value = getValueByPathAndSetDefaultValue(data, name);

    if (isNA(value)) {
      value = VALUE_NA;
    }

    if (!label) {
      return (
        <div key={key} className={classes.row}>
          {isValidFunction(renderContent) ? renderContent(value, data) : value}
        </div>
      );
    }

    return (
      <Fragment key={key}>
        {index !== 0 && <Divider />}
        <Typography
          key={key}
          variant="body2"
          component="div"
          className={clsx('semibold', classes.row, classes.rowLabel)}>
          <div className={classes.left}>{formatMessage({ id: label })}</div>
          <div className={classes.right}>
            {isValidFunction(renderContent) ? renderContent(value, data) : value}
          </div>
        </Typography>
      </Fragment>
    );
  };

  if (!data) {
    return null;
  }

  return <Box>{fields.map(renderRow)}</Box>;
};

TransactionInformation.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string)
};

TransactionInformation.defaultProps = {
  fields: []
};

export default TransactionInformation;

import { isValidFunction } from '@/@crema/utility/utils';
import { getValueByPathAndSetDefaultValue } from '@/modules/Account/Transactions/configure/';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useCallback, useState } from 'react';

const CellContent = ({ column, row, value, expanded, toggleExpanded, onActionClick }) => {
  try {
    if (column && isValidFunction(column.renderContent)) {
      return column.renderContent(value, row, expanded, toggleExpanded, onActionClick);
    }
    return value ? value : '-';
  } catch (error) {
    return value ? value : '-';
  }
};

export const ExpandableTableRowBase = ({
  row,
  columns,
  renderExpandedRow,
  onRowClick,
  onActionClick
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => setExpanded((value) => !value), []);
  const onClick = useCallback(() => onRowClick(row), [onRowClick, row]);

  return (
    <>
      <TableRow hover selected={expanded} onClick={onClick}>
        {columns.map((column, columnIndex) => {
          const { id, defaultName, name, align, width } = column;
          const value = getValueByPathAndSetDefaultValue(row, name, defaultName);

          return (
            <TableCell key={id || columnIndex} align={align} width={width}>
              <CellContent
                column={column}
                row={row}
                value={value}
                expanded={expanded}
                toggleExpanded={toggleExpanded}
                onActionClick={onActionClick}
              />
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow className="expandedRow">
        {isValidFunction(renderExpandedRow) && renderExpandedRow(row, expanded, toggleExpanded)}
      </TableRow>
    </>
  );
};

ExpandableTableRowBase.propTypes = {
  onRowClick: PropTypes.func,
  onActionClick: PropTypes.func
};

ExpandableTableRowBase.defaultProps = {
  onRowClick: () => {},
  onActionClick: () => {}
};

export const ExpandableTableRow = memo(ExpandableTableRowBase);

ExpandableTableRow.displayName = 'ExpandableTableRowMemo';

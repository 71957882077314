import {
  BONUS_GET_PENDING_BONUS,
  BONUS_GET_PLAYER,
  BONUS_GET_RECOMMENDATION,
  BONUS_GET_REWARD_HISTORY,
  BONUS_LOAD_MORE_PENDING_BONUS_ERROR,
  BONUS_LOAD_MORE_PENDING_BONUS_START,
  BONUS_LOAD_MORE_PENDING_BONUS_SUCCESS,
  BONUS_LOAD_MORE_REWARD_HISTORY_ERROR,
  BONUS_LOAD_MORE_REWARD_HISTORY_START,
  BONUS_LOAD_MORE_REWARD_HISTORY_SUCCESS,
  BONUS_RESET,
  BONUS_SET_LOADING,
  BONUS_SET_SWITCH
} from '@/shared/constants/ActionTypes';
import { LOCAL_STORAGE } from '@/shared/constants/LocalStorageConst';
import { useSelector } from 'react-redux';

const INIT_DATA_ACTIVE_BONUSES = {
  data: []
};

const INIT_DATA_PENDING_BONUSES = {
  data: [],
  loading: false,
  isLoadMore: false,
  paging: {
    current_page: 1,
    last_page: 0,
    per_page: 10,
    total: 0
  }
};

const INIT_DATA_REWARD_HISTORY = {
  data: [],
  loading: false,
  isLoadMore: false,
  paging: {
    current_page: 1,
    last_page: 0,
    per_page: 10,
    total: 0
  }
};

const INIT_STATE = {
  activeBonuses: INIT_DATA_ACTIVE_BONUSES,
  rewardHistory: INIT_DATA_REWARD_HISTORY,
  pendingBonuses: INIT_DATA_PENDING_BONUSES,
  bonusFund: null,
  error: null,
  loading: false,
  recommend: {
    switch: false
  }
};

export const useBonus = () => {
  return useSelector(({ bonus }) => bonus);
};

export const useBonusLoading = () => {
  return useSelector(({ bonus }) => bonus.loading);
};

export const useBonusRewardHistory = () => {
  return useSelector(({ bonus }) => bonus.rewardHistory);
};

export const useHasRewardHistoryLoadMore = () => {
  const { rewardHistory } = useBonus();
  const { paging } = rewardHistory;
  return paging?.current_page < paging?.last_page;
};

export const useBonusPending = () => {
  return useSelector(({ bonus }) => bonus.pendingBonuses);
};

export const useHasBonusPendingLoadMore = () => {
  const { pendingBonuses } = useBonus();
  const { paging } = pendingBonuses;
  return paging?.current_page < paging?.last_page;
};

const getPagingData = (payload) => {
  return {
    current_page: payload?.current_page,
    last_page: payload?.last_page,
    per_page: payload?.per_page,
    total: payload?.total
  };
};

export const useSwitchBonus = () => {
  return useSelector(({ bonus }) => bonus.recommend.switch);
};

export const useBonusRecommend = () => {
  return useSelector(({ bonus }) => bonus.recommend);
};

export const BonusReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BONUS_GET_PLAYER:
      return {
        ...state,
        loading: false,
        activeBonuses: {
          ...state.activeBonuses,
          data: action.payload?.activeData?.data
        },
        rewardHistory: {
          ...state.rewardHistory,
          data: action.payload?.historyData?.data,
          paging: getPagingData(action.payload?.historyData)
        },
        pendingBonuses: {
          ...state.pendingBonuses,
          data: action.payload?.pendingData?.data,
          paging: getPagingData(action.payload?.pendingData)
        }
      };
    case BONUS_SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    // REWARD HISTORY
    case BONUS_GET_REWARD_HISTORY:
      return {
        ...state,
        rewardHistory: {
          ...state.rewardHistory,
          data: action.payload?.data,
          paging: getPagingData(action.payload),
          loading: false
        }
      };
    case BONUS_LOAD_MORE_REWARD_HISTORY_START:
      return {
        ...state,
        rewardHistory: {
          ...state.rewardHistory,
          isLoadMore: true
        }
      };
    case BONUS_LOAD_MORE_REWARD_HISTORY_SUCCESS:
      return {
        ...state,
        rewardHistory: {
          ...state.rewardHistory,
          data: [...state.rewardHistory.data, ...action.payload?.data],
          paging: getPagingData(action.payload),
          isLoadMore: false
        }
      };
    case BONUS_LOAD_MORE_REWARD_HISTORY_ERROR:
      return {
        ...state,
        rewardHistory: {
          ...state.rewardHistory,
          isLoadMore: false
        }
      };
    // PENDING BONUS
    case BONUS_GET_PENDING_BONUS:
      return {
        ...state,
        pendingBonuses: {
          ...state.pendingBonuses,
          data: action.payload?.data,
          paging: getPagingData(action.payload),
          loading: false
        }
      };
    case BONUS_LOAD_MORE_PENDING_BONUS_START:
      return {
        ...state,
        pendingBonuses: {
          ...state.pendingBonuses,
          isLoadMore: true
        }
      };
    case BONUS_LOAD_MORE_PENDING_BONUS_SUCCESS:
      return {
        ...state,
        pendingBonuses: {
          ...state.pendingBonuses,
          data: [...state.pendingBonuses.data, ...action.payload?.data],
          paging: getPagingData(action.payload),
          isLoadMore: false
        }
      };
    case BONUS_LOAD_MORE_PENDING_BONUS_ERROR:
      return {
        ...state,
        pendingBonuses: {
          ...state.pendingBonuses,
          isLoadMore: false
        }
      };
    case BONUS_RESET:
      return {
        ...state,
        loading: false,
        bonusFund: null,
        activeBonuses: INIT_DATA_ACTIVE_BONUSES,
        rewardHistory: INIT_DATA_REWARD_HISTORY,
        pendingBonuses: INIT_DATA_PENDING_BONUSES,
        recommend: {
          switch: false
        }
      };
    case BONUS_SET_SWITCH:
      localStorage.setItem(LOCAL_STORAGE.switch_bonus, String(!state.recommend.switch));
      return {
        ...state,
        recommend: {
          ...state.recommend,
          switch: !state.recommend.switch
        }
      };
    case BONUS_GET_RECOMMENDATION:
      return {
        ...state,
        loading: false,
        recommend: {
          ...state.recommend,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

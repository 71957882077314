import { getImageFullUrl, isValidString } from '@/@crema/utility/utils';
import { APP_CONFIG } from '@/shared/constants/AppConfig';
import { ASPECT_RATIO } from '@/shared/constants/AppEnums';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropsType from 'prop-types';
import { memo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.bgColor || theme.palette.background.paper,
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: 'auto'
  },
  [`aspect-ratio-${ASPECT_RATIO['16-9']}`]: {
    paddingTop: 'calc(9 / 16 * 100%)' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  },
  [`aspect-ratio-${ASPECT_RATIO['4-3']}`]: {
    paddingTop: 'calc(3 / 4 * 100%)' /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
  },
  [`aspect-ratio-${ASPECT_RATIO['3-4']}`]: {
    paddingTop: 'calc(4 / 3 * 100%)' /* 3:4 Aspect Ratio (divide 4 by 3 = 1.3333) */
  },
  [`aspect-ratio-${ASPECT_RATIO['1-1']}`]: {
    paddingTop: '100%'
  },
  [`aspect-ratio-${ASPECT_RATIO['2-1']}`]: {
    paddingTop: 'calc(1 / 2 * 100%)' /* 2:1 Aspect Ratio (divide 2 by 1 = 2) */
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius
  },
  skeleton: {
    transform: 'translateX(-100%)',
    backgroundImage:
      'linear-gradient(90deg, rgba(49, 51, 64, 0), rgba(49, 51, 64, 0.5), rgba(49, 51, 64, 1), rgba(49, 51, 64, 0))',
    animation: 'shimmer 1.5s infinite',
    backgroundRepeat: 'no-repeat'
  },
  absolute: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0'
  }
}));

function ImageOptimized({
  src,
  classNameContainer,
  className,
  alt,
  bgColor,
  skeleton,
  isStaticUrl,
  ratio
}) {
  const classes = useStyles({ bgColor, ratio });
  const url = isStaticUrl ? src : getImageFullUrl(src);
  const shouldLoading = url !== '' && skeleton;
  const [loading, setLoading] = useState(shouldLoading);

  // calc aspect ratio
  const imgClass = isValidString(ratio)
    ? clsx(classes.img, classes.absolute, className)
    : clsx(classes.img, className);
  const containerClass = isValidString(ratio)
    ? clsx(classes.root, classNameContainer, classes[`aspect-ratio-${ASPECT_RATIO[ratio]}`])
    : clsx(classes.root, classNameContainer);

  const stopLoading = () => {
    setLoading(false);
  };

  return (
    <div className={containerClass}>
      {isValidString(url) && (
        <>
          <img
            src={url}
            className={imgClass}
            alt={alt ? alt : APP_CONFIG.defaultAlt}
            width={50}
            height={50}
            onLoad={stopLoading}
            onError={stopLoading}
            style={loading ? { width: 0, height: 0 } : {}}
          />
          {skeleton && loading && <div className={clsx(classes.skeleton, classes.absolute)} />}
        </>
      )}
    </div>
  );
}

ImageOptimized.propTypes = {
  src: PropsType.string,
  className: PropsType.string,
  classNameContainer: PropsType.string,
  alt: PropsType.string,
  layout: PropsType.string,
  ratio: PropsType.string
};

ImageOptimized.defaultProps = {
  className: '',
  classNameContainer: '',
  alt: APP_CONFIG.defaultAlt,
  layout: 'fill',
  skeleton: false,
  isStaticUrl: false,
  ratio: undefined
};

export default memo(ImageOptimized);
